/* eslint-disable jsx-a11y/tabindex-no-positive */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import Select from 'react-select';
import Editor from '../../layout/editor';
import axios from 'axios';
import {
  updateProfile,
  getProfile,
  getAccountBalanceByClient,
  deactivateUser,
  activateUser,
  getContractorTaxInfo,
} from '../../../storage/actions/profile';
import ContractorToolsBox from './contractor/ContractorToolsBox';
import { toggleModal } from '../../../storage/actions/modalsActions';
import Loader from '../../dashboard/common/Loader';
import ContractorCertificatesChecks from '../../layout/ContractorCertificatesChecks';
import Notification from '../../notification';
import ExplanationDeactivationModal from '../ExplanationDeactivationModal';
import { normalizePhoneInput } from '../../../utils/validators';
import ButtonControl from './contractor/ButtonControl';
import { localCurrencySettings } from '../../../utils/constans';
import FileUpload from "../../forms/file-upload/FileUpload";
import FileListPreview from "../../forms/file-upload/FileListPreview";
import NotesSection from '../contractorDetailsModal/NotesSection';

const Profile = ({
  profile,
  modalStatus,
  auth,
  updateProfile,
  toggleModal,
  getProfile,
  getAccountBalanceByClient,
  deactivateUser,
  activateUser,
}) => {
  const [isEdit, changeEditStatus] = useState(false);
  const [files, setFiles] = useState(profile.cards || [])
  const [countries, setCountries] = useState([]);
  const [statesOfCountry, setStatesOfCountry] = useState([]);
  const [form, setForm] = useState({});
  const [file, setFile] = useState(null);
  const [isDeactivateModal, setDeactivateModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const getStatesOfCountry = async (country) => {
    if (!country) return;
    try {
      if (!country.value) {
        const countryName = country.label || 'United States';
        const value = countries.filter((item) => item.label === countryName)[0];
        // eslint-disable-next-line consistent-return
        return handlerChanges({
          name: 'country',
          value,
        });
      }
      const res = await axios.get('/api/address/getStatesOfCountry', {
        params: { countryId: country?.value || '' },
      });
      setStatesOfCountry(res.data?.payload?.states || []);
    } catch (error) {
      console.error(error);
    }
  };

  const handlerChanges = ({ name, value }) => {
    const newForm = {
      ...form,
      [name]: value,
    };
    if (name === 'country') {
      if (!form.country || form.country.label !== value.label) {
        newForm.state = {};
      }
      getStatesOfCountry(value);
    }
    setForm(newForm);
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      getProfile(auth.user.id);
      if (profile.status === 'active') {
        if (auth.user.userType === 'client') {
          getAccountBalanceByClient();
        }
      }
    }
  }, [auth]);

  useEffect(() => {
    getStatesOfCountry(form.country);
  }, [countries]);

  const validateEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email)
      .toLowerCase());
  };

  const handlerSubmit = async (event) => {
    event.preventDefault();
    if (validateEmail(form.email)) {
      const fd = new FormData();
      if (auth.user.userType === 'contractor') {
        fd.append('credentials', form.credentials || '');
        fd.append('other_tools', form.other_tools || '');
        fd.append('tools', form.tools ? JSON.stringify(form.tools) : '');
        fd.append('payPalEmail', form.payPalEmail || '');

        const saved = [], uploaded = [];
        for (const file of files) {
          if (typeof file === "string") saved.push(file);
          else uploaded.push(file)
        }
        uploaded.forEach(file => fd.append('files[]', file, file.name));
        saved.forEach(saved => fd.append('saved[]', saved))
      } else {
        fd.append('companyName', form.company);
        fd.append('companyId', profile.company._id);
      }
      fd.append('firstName', form.firstName);
      fd.append('lastName', form.lastName);
      fd.append('phone', form.phone);
      fd.append('email', form.email);
      fd.append('website', form.website || '');
      fd.append('address', form.address || '');
      fd.append('address2', form.address2 || '');
      fd.append('country', form.country?.label ? JSON.stringify(form.country) : '');
      fd.append('state', form.state?.label ? JSON.stringify(form.state) : '');
      fd.append('about', form.about || '');
      fd.append('city', form.city?.label ? JSON.stringify(form.city) : '');
      fd.append('zipcode', form.zipcode || '');
      fd.append('oldPassword', form.oldPassword || '');
      fd.append('password', form.password || '');
      fd.append('confirmPassword', form.confirmPassword || '');

      if (typeof form.image !== "string") {
        fd.append('file', file);
      }
      const res = await updateProfile(fd, auth.user);
      if (res.success) {
        if (auth.user.userType === 'client') {
          getAccountBalanceByClient();
        }
        return changeEditStatus(false);
      }
      return res;
    }
    return Notification('warning', {
      message: 'Please enter valid email address.',
    });
  };

  const saveChangedFiles = async (files) => {
    const fd = new FormData();

    const saved = [], uploaded = [];
    for (const file of files) {
      if (typeof file === "string") saved.push(file);
      else uploaded.push(file)
    }
    uploaded.forEach(file => fd.append('files[]', file, file.name));
    saved.forEach(saved => fd.append('saved[]', saved))

    await updateProfile(fd, auth.user, true);
  }

  const handleFileUpload = (event) => {
    const file = event.target.files;
    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onloadend = function () {
      handlerChanges({
        name: 'image',
        value: [reader.result],
      });
    };
    setFile(event.target.files[0]);
  };

  const getCountries = async () => {
    try {
      const res = await axios.get('/api/address/getAllCountries');
      if (!(res.data.length && res.data.length > 0)) throw new Error('Can\'t get list of countries!');
      setCountries(res.data.map((item) => ({
        label: item.name,
        value: item.isoCode,
      })));
    } catch (error) {
      console.error(error);
    }
  };

  const editProfile = () => {
    setForm({
      ...profile,
      firstName: profile.user.firstName,
      lastName: profile.user.lastName,
      company: profile.company?.name,
    });
    setFile('');
    getCountries();
    changeEditStatus(true);
  };

  const onClose = () => {
    changeEditStatus(false);
    toggleModal(!modalStatus.isOpen, 'profile');
  };

  const onActivateAccount = () => {
    toggleModal(true, 'confirmModal', true, { onAccept: onAcceptActivateAccount });
  };

  const onAcceptActivateAccount = async () => {
    toggleModal(false, 'confirmModal', true);
    setLoading(true);
    const { success } = await activateUser();
    if (success) {
      if (auth.user.userType === 'client') {
        getAccountBalanceByClient();
      }
    }
    setLoading(false);
  };

  const onDeactivateAccount = () => {
    setDeactivateModal(true);
    toggleModal(true, 'explanationDeactivationModal', true);
  };

  const getReasonForDeactivateAccount = async (reason) => {
    toggleModal(false, 'explanationDeactivationModal', true);
    setDeactivateModal(false);
    setLoading(true);
    await deactivateUser(reason);
    setLoading(false);
  };

  const approvedBalance = profile.userType === 'contractor' ? (
    <div>
      balance WO:
      {' '}
      {profile.approvedBalance.toLocaleString('en-US', localCurrencySettings)}
    </div>
  ) : null;

  return modalStatus.isOpen
    ? (
      <MDBModal
        className="editprofilepopclient"
        isOpen={modalStatus.isOpen}
        toggle={onClose}
        fullHeight
        position="right"
      >
        <MDBModalBody>
          {
            !isLoading
              && profile
              && (profile.userId === auth.user.id)
              ? (
                <>
                  <div>
                    <MDBRow style={{ marginBottom: '15px' }}>
                      <MDBCol md="6" />
                      <MDBCol md="6" style={{ textAlign: 'end' }}>
                        <MDBIcon
                          icon="times-circle"
                          size="lg"
                          style={{
                            color: '#41566a',
                            cursor: 'pointer',
                          }}
                          onClick={onClose}
                        />
                      </MDBCol>
                    </MDBRow>
                  </div>
                  <MDBRow style={{ padding: '10px 0px 10px 0px' }}>
                    <MDBCol>
                      <div style={{
                        textAlign: 'end',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                      >
                        {profile.status === 'active' && (
                          <>
                            {
                              auth.user.userType === 'contractor'
                                ? (
                                  <MDBBtn
                                    color="red"
                                    onClick={onDeactivateAccount}
                                    className="profileconedit_but"
                                  >
                                    Deactivate Account
                                  </MDBBtn>
                                )
                                : <></>
                            }
                            <MDBBtn
                              color="info"
                              onClick={editProfile}
                              className="profileconedit_but"
                            >
                              <img
                                src={profile.image || 'https://www.w3schools.com/css/img_avatar.png'}
                                alt="Avatar"
                                className="profileedit_img"
                                style={{ cursor: 'pointer' }}
                              />
                              EDIT
                            </MDBBtn>
                          </>
                        )}
                        {profile.status === 'deactivated' && (
                          <MDBBtn
                            color="green"
                            onClick={onActivateAccount}
                            className="profileconedit_but"
                          >
                            Activate Account
                          </MDBBtn>
                        )}
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <form onSubmit={handlerSubmit}>
                    {
                      !isEdit
                        ? (
                          <div className="viewprofilec">
                            <MDBRow>
                              <MDBCol md="9">
                                <MDBRow>
                                  <MDBCol className="d-flex">
                                    <div className="font12">
                                      <h5 style={{ fontWeight: 'bold' }} className="">
                                        {profile.company?.name}
                                      </h5>
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow style={{ padding: '0px 0px 20px 0px' }}>
                                  <MDBCol>
                                    <div className="font12">
                                      <h5 style={{ fontWeight: 'bold' }} className="">{profile.name}</h5>
                                    </div>
                                    {
                                      profile.date
                                        ? (
                                          <div className="font12">
                                            Date Registered:
                                            {' '}
                                            {new Date(profile.date).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                                          </div>
                                        )
                                        : null
                                    }
                                    {
                                      profile.lastLoginAt
                                        ? (
                                          <div className="font12">
                                            Last Login:
                                            {' '}
                                            {new Date(profile.lastLoginAt).toLocaleString('en-US')}
                                          </div>
                                        )
                                        : null
                                    }
                                  </MDBCol>
                                  {
                                    profile.userType === 'contractor'
                                    && (
                                      <MDBCol className="d-flex">
                                        Rating:
                                        {' '}
                                        {/* eslint-disable-next-line radix */}
                                        {parseInt(profile.currentRating || 100)}
                                        %
                                      </MDBCol>
                                    )
                                  }
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <div>
                                      Address:
                                    </div>
                                    <div className="font12" style={{ height: '40px' }}>
                                      {profile.address !== 'undefined' ? profile.address : ''}
                                      <br />
                                      {profile.address2 !== 'undefined' && profile.address !== '' ? profile.address2 : ''}
                                      {(profile.city && profile.city.label) ? `${profile.city.label}, ` : ''}
                                      {' '}
                                      {(profile.state && profile.state.value) ? profile.state.label : ''}
                                      {' '}
                                      {profile.zipcode || ''}
                                      {' '}
                                      <br />
                                      {(profile.country && profile.country.value) ? profile.country.label : ''}
                                    </div>
                                  </MDBCol>
                                  <MDBCol>
                                    <div className="font12" style={{ height: '40px' }}>
                                      Website:
                                      {' '}
                                      <a
                                        href={profile.website !== 'undefined' ? profile.website : ''}
                                      >
                                        {profile.website !== 'undefined' ? profile.website : ''}
                                      </a>
                                      <br />
                                      Phone:
                                      {' '}
                                      <a
                                        href={profile.phone !== 'undefined' ? `tel:${profile.phone}` : ''}
                                      >
                                        {profile.phone !== 'undefined' ? profile.phone : ''}
                                      </a>
                                      <br />
                                      Email:
                                      {' '}
                                      <a
                                        href={profile.email !== 'undefined' ? `mailto:${profile.email}` : ''}
                                      >
                                        {profile.email !== 'undefined' ? profile.email : ''}
                                      </a>
                                      <br />
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                              <MDBCol md="3" style={{ paddingLeft: '0px' }}>
                                <img
                                  alt=""
                                  style={{
                                    width: '160px',
                                    height: '160px',
                                  }}
                                  src={profile.image || ''}
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="no-margins" style={{ paddingBottom: '1rem' }}>
                              <MDBCol md="8" style={{ padding: 0 }}>
                                <MDBRow>
                                  <MDBCol style={{ padding: '25px 0px 0px 15px' }}>
                                    <h6
                                      style={{ fontWeight: 'bold' }}
                                      className="font13"
                                    >
                                      Bio / About:
                                    </h6>
                                    <div
                                      style={{ fontSize: 12 }}
                                      dangerouslySetInnerHTML={{ __html: profile.about !== 'undefined' ? profile.about : '' }}
                                    />
                                  </MDBCol>
                                </MDBRow>
                                {
                                  profile.userType === 'contractor' && (
                                    <>
                                      <ButtonControl profile={profile} />
                                      <MDBRow>
                                        <MDBCol md="12" style={{ padding: '25px 0px 30px 15px' }}>
                                          <h6
                                            style={{ fontWeight: 'bold' }}
                                            className="font12"
                                          >
                                            Credentials:
                                          </h6>
                                          <div
                                            style={{ fontSize: 12 }}
                                            dangerouslySetInnerHTML={{ __html: profile.credentials !== 'undefined' ? profile.credentials : '' }}
                                          />
                                        </MDBCol>
                                      </MDBRow>
                                    </>
                                  )
                                }
                              </MDBCol>
                              <MDBCol className="wo-balance">
                                {approvedBalance}
                              </MDBCol>
                            </MDBRow>
                            {
                              profile.userType === 'contractor'
                              && <FileUpload values={files} onChange={saveChangedFiles} accept={['image/*', '.pdf']} />
                            }
                            {
                              profile.userType === 'contractor'
                                && profile.certificates?.length
                                ? (
                                  <MDBRow style={{ marginBottom: 20 }}>
                                    <MDBCol>
                                      <h6 className="font12">Certifications :</h6>
                                      <MDBCol>
                                        <ContractorCertificatesChecks
                                          certificates={profile.certificates}
                                          infoType="name"
                                          size="100%"
                                        />
                                      </MDBCol>
                                    </MDBCol>
                                  </MDBRow>
                                )
                                : <></>
                            }
                            {
                              profile.userType !== 'contractor'
                                && profile.contractorGroups?.length
                                ? (
                                  <>
                                    <MDBRow style={{ marginBottom: 20 }}>
                                      <MDBCol>
                                        <h6 className="font12">Prefered groups :</h6>
                                      </MDBCol>
                                    </MDBRow>
                                    <MDBRow style={{ marginBottom: 20 }}>
                                      {profile.contractorGroups.map((group, index) =>
                                        <MDBCol md='2' key={index}>
                                          {group}
                                        </MDBCol>
                                      )}
                                    </MDBRow>
                                  </>
                                )
                                : <></>
                            }
                            {
                              profile.userType !== 'contractor' &&
                              <NotesSection
                                contractorId={profile.userId}
                                notes={profile.notes}
                              />
                            }
                          </div>
                        )
                        : (
                          <div className="editprofilec">
                            <MDBRow>
                              <MDBCol className="profile-col-padding">
                                <div className="form-group">
                                  <label htmlFor="formGroupExampleInput">First Name</label>
                                  <input
                                    required
                                    type="text"
                                    className="form-control"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={form.firstName}
                                    onChange={(event) => handlerChanges(event.target)}
                                    /* eslint-disable-next-line jsx-a11y/tabindex-no-positive */
                                    tabIndex="1"
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="formGroupExampleInput">Phone</label>
                                  <input
                                    required
                                    type="text"
                                    className="form-control"
                                    name="phone"
                                    placeholder="Phone"
                                    value={form.phone}
                                    onChange={(event) => handlerChanges({
                                      name: 'phone',
                                      value: normalizePhoneInput(event.target.value, form.phone),
                                    })}
                                    /* eslint-disable-next-line jsx-a11y/tabindex-no-positive */
                                    tabIndex="3"
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="formGroupExampleInput">Country</label>
                                  <Select
                                    reqiered={profile.userType === 'contractor'}
                                    options={countries}
                                    styles={{
                                      singleValue: (styles) => ({
                                        ...styles,
                                        color: 'white',
                                      }),
                                      control: (styles) => ({
                                        ...styles,
                                        backgroundColor: '#1e2f41',
                                        borderColor: '#1e2f41',
                                      }),
                                      option: (styles, {
                                        isFocused, isSelected,
                                      }) => ({
                                        ...styles,
                                        fontWeight: isFocused ? 'bold' : 'normal',
                                        backgroundColor: isSelected ? '#e6e3e3' : 'white',
                                        color: '#000000',
                                      }),
                                    }}
                                    onChange={(value) => handlerChanges({
                                      name: 'country',
                                      value,
                                    })}
                                    value={form.country}
                                    tabIndex="5"
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="formGroupExampleInput">Address</label>
                                  <input
                                    required={profile.userType === 'contractor'}
                                    type="text"
                                    className="form-control"
                                    name="address"
                                    placeholder="Address"
                                    value={form.address}
                                    onChange={(event) => handlerChanges(event.target)}
                                    tabIndex="7"
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="formGroupExampleInput">City</label>
                                  <input
                                    required={profile.userType === 'contractor'}
                                    type="text"
                                    className="form-control"
                                    name="city"
                                    placeholder="city"
                                    value={form.city && form.city.label}
                                    onChange={(event) => handlerChanges({
                                      name: 'city',
                                      value: {
                                        value: event.target.value,
                                        label: event.target.value,
                                      },
                                    })}
                                    tabIndex="9"
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="formGroupExampleInput">Website</label>
                                  <input
                                    required={profile.userType !== 'contractor'}
                                    type="text"
                                    className="form-control"
                                    name="website"
                                    placeholder="Website"
                                    value={form.website}
                                    onChange={(event) => handlerChanges(event.target)}
                                    tabIndex="11"
                                  />
                                </div>

                              </MDBCol>
                              <MDBCol className="profile-col-padding">
                                <div className="form-group">
                                  <label htmlFor="formGroupExampleInput">Last Name</label>
                                  <input
                                    required
                                    type="text"
                                    className="form-control"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={form.lastName}
                                    onChange={(event) => handlerChanges(event.target)}
                                    tabIndex="2"
                                  />
                                </div>

                                <div className="form-group">
                                  <label htmlFor="formGroupExampleInput">Email</label>
                                  <input
                                    required
                                    type="text"
                                    className="form-control"
                                    name="email"
                                    placeholder="Email"
                                    value={form.email}
                                    onChange={(event) => handlerChanges(event.target)}
                                    tabIndex="4"
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="formGroupExampleInput">State</label>
                                  <Select
                                    reqiered
                                    isSearchable
                                    options={statesOfCountry}
                                    styles={{
                                      singleValue: (styles) => ({
                                        ...styles,
                                        color: 'white',
                                      }),
                                      input: (base) => ({
                                        ...base,
                                        color: '#fff',
                                      }),
                                      control: (styles) => ({
                                        ...styles,
                                        backgroundColor: '#1e2f41',
                                        borderColor: '#1e2f41',
                                      }),
                                      option: (styles, {
                                        isFocused, isSelected,
                                      }) => ({
                                        ...styles,
                                        fontWeight: isFocused ? 'bold' : 'normal',
                                        backgroundColor: isSelected ? '#e6e3e3' : 'white',
                                        color: isSelected ? '#fff' : '#000000',
                                      }),
                                    }}
                                    onChange={(value) => handlerChanges({
                                      name: 'state',
                                      value,
                                    })}
                                    value={form.state}
                                    tabIndex="6"
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="formGroupExampleInput">Suite, Apt., Etc.</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="address2"
                                    placeholder="Address2"
                                    value={form.address2}
                                    onChange={(event) => handlerChanges(event.target)}
                                    tabIndex="8"
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="formGroupExampleInput">Zip</label>
                                  <input
                                    required={profile.userType === 'contractor'}
                                    type="text"
                                    className="form-control"
                                    name="zipcode"
                                    placeholder="Zip"
                                    value={form.zipcode}
                                    onChange={(event) => handlerChanges(event.target)}
                                    maxLength="6"
                                    tabIndex="10"
                                  />
                                </div>
                                {
                                  profile.userType === 'client'
                                    ? (
                                      <div className="form-group">
                                        <label htmlFor="formGroupExampleInput">Company Name</label>
                                        <input
                                          required
                                          type="text"
                                          className="form-control"
                                          name="company"
                                          placeholder="Company"
                                          value={form.company}
                                          onChange={(event) => handlerChanges(event.target)}
                                          disabled={form.userType === 'user'}
                                          tabIndex="12"
                                        />
                                      </div>
                                    )
                                    : <></>
                                }
                              </MDBCol>
                              <MDBCol className="profile-col-padding">
                                <div className="conprofilepiccdiv">
                                  <img alt="" className="profilepicc" src={form.image} />
                                </div>
                                <div>
                                  <div className="form-group">
                                    <label>Add/Edit Image</label>
                                    <div className="input-group">
                                      <div
                                        className="custom-file upload_deliverables"
                                      >
                                        <input
                                          className="form-control"
                                          label="upload file"
                                          type="file"
                                          onChange={handleFileUpload}
                                          tabIndex="8.5"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {/*
                                    profile.userType === 'contractor'
                                      ? (
                                        <div className="form-group">
                                          <label htmlFor="formGroupExampleInput">PP Email</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            name="payPalEmail"
                                            placeholder="PayPal Email"
                                            value={form.payPalEmail}
                                            onChange={(event) => handlerChanges(event.target)}
                                            tabIndex="10.5"
                                          />
                                        </div>
                                      )
                                      : <></>
                                  */}
                                </div>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow style={{ paddingBottom: 20 }}>
                              <MDBCol className="profile-col-padding">
                                <label htmlFor="formGroupExampleInput">Current Password</label>
                                <input
                                  type="password"
                                  className="form-control"
                                  name="oldPassword"
                                  placeholder="Old Password"
                                  value={form.oldPassword}
                                  onChange={(event) => handlerChanges(event.target)}
                                  maxLength="30"
                                  minLength="6"
                                  tabIndex="13"
                                />
                              </MDBCol>
                              <MDBCol className="profile-col-padding">
                                <label htmlFor="formGroupExampleInput"> New Password</label>
                                <input
                                  type="password"
                                  className="form-control"
                                  name="password"
                                  placeholder="Password"
                                  value={form.password}
                                  onChange={(event) => handlerChanges(event.target)}
                                  maxLength="30"
                                  minLength="6"
                                  tabIndex="14"
                                />
                              </MDBCol>
                              <MDBCol className="profile-col-padding">
                                <label htmlFor="formGroupExampleInput">Confirm New password</label>
                                <input
                                  type="password"
                                  className="form-control"
                                  name="confirmPassword"
                                  placeholder="Confirm password"
                                  value={form.confirmPassword}
                                  onChange={(event) => handlerChanges(event.target)}
                                  maxLength="30"
                                  minLength="6"
                                  tabIndex="15"
                                />
                              </MDBCol>
                            </MDBRow>
                            {isEdit && profile.userType === 'contractor'
                              && <MDBRow style={{ paddingBottom: 20 }}>
                                <MDBCol className="profile-col-padding">
                                  <label>Insurance/COI’s or Resume/CV</label>
                                  <FileUpload values={files} onChange={setFiles} accept={['image/*', '.pdf']} />
                                </MDBCol>
                              </MDBRow>}
                            <MDBRow style={{ paddingBottom: 20 }}>
                              <MDBCol className="profile-col-padding">
                                <div>
                                  <label>Bio / About</label>
                                  <Editor
                                    data={form.about}
                                    onChange={(value) => handlerChanges({
                                      name: 'about',
                                      value: value,
                                    })}
                                  />
                                </div>
                              </MDBCol>
                            </MDBRow>
                            {
                              profile.userType === 'contractor'
                              && (
                                <MDBRow>
                                  <MDBCol className="profile-col-padding">
                                    <div>
                                      <label>Credentials</label>
                                      <Editor
                                        data={form.credentials}
                                        onChange={(value) => handlerChanges({
                                          name: 'credentials',
                                          value: value,
                                        })}
                                      />
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                              )
                            }
                          </div>
                        )
                    }
                    {
                      profile.userType === 'contractor' && profile.status === 'active'
                        ? (
                          <ContractorToolsBox
                            isEdit={isEdit}
                            form={form}
                            handlerChanges={handlerChanges}
                          />
                        )
                        : null
                    }
                    {
                      isEdit
                      && (
                        <MDBRow
                          className=""
                          style={{
                            justifyContent: 'flex-end',
                            marginTop: '10px',
                          }}
                        >
                          <MDBBtn
                            color="primary"
                            className="canbut"
                            onClick={() => changeEditStatus(false)}
                          >
                            Cancel
                          </MDBBtn>
                          <MDBBtn
                            color="info"
                            className="submitbut"
                            type="submit"
                          >
                            Submit
                          </MDBBtn>
                        </MDBRow>
                      )
                    }
                  </form>
                  {isDeactivateModal && (
                    <ExplanationDeactivationModal
                      returnExplanation={getReasonForDeactivateAccount}
                      title="Do you really wish to deactivate your account?"
                      isCloseAvailable
                    />
                  )}
                </>
              )
              : <Loader />
          }
        </MDBModalBody>
      </MDBModal>
    )
    : null;
};

const mapDispatchToProps = {
  updateProfile,
  toggleModal,
  getProfile,
  getAccountBalanceByClient,
  deactivateUser,
  activateUser,
  getContractorTaxInfo,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  modalStatus: state.modalState.profile,
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(Profile));
