import React from 'react';
import { connect } from 'react-redux';
import { setFiltersObject } from '../../../../storage/actions/workOrdersActions';
import FilterBadge from './FilterBadge';
import getWorkOrderStatusSet from '../../../../utils/getWorkOrderStatusSet';
import { getLocalDateString } from '../../../../utils/getDateWithOffset';
import { getLocalCurrencyString } from '../../../../utils/formatString/currency';

const FilterBadges = ({
  filters,
  userType,
  setFiltersObject,
}) => {
  const getWorkOrderStatusNames = (values) => {
    const selectedWOTypes = getWorkOrderStatusSet(
      values,
      filters.needFilterByRouterStatus,
      filters.needFilterWithoutRouterStatus,
    );
    return [...selectedWOTypes].map(value => value.replace(/^\w/, c => c.toUpperCase())).join(', ');
  };

  const getFiltersLabel = (name) => {
    switch (name) {
      case 'contractorId': return `Contractor Id: ${filters[name]}`;
      case 'startDate': return `Start Date: ${getLocalDateString(filters[name].from)} < ${getLocalDateString(filters[name].to)}`;
      case 'endDate': return `End Date: ${getLocalDateString(filters[name].from)} < ${getLocalDateString(filters[name].to)}`;
      case 'selectProject': return `Project(s): ${filters[name].map(item => item.label).join(', ')}`;
      case 'selectCompany': {
        if (userType === 'superAdmin') return `Customer: ${filters[name].label}`;
        return `Company: ${filters[name].label}`;
      }
      case 'selectCountry': return `Country: ${filters[name].label}`;
      case 'selectContractorCountry': return `Contractor Country: ${filters[name].label}`;
      case 'workOrderId': return `Company WO ID: ${filters[name]}`;
      case 'workOrderTitle': return `WO Title: ${filters[name]}`;
      case 'cityName': return `City: ${filters[name]}`;
      case 'selectState': return `State: ${filters[name].label}`;
      case 'zipCode': return `Zip: ${filters[name]}`;
      case 'siteId': return `Site Id: ${filters[name]}`;
      case 'siteName': return `Site Name: ${filters[name]}`;
      case 'payRate': return `Base Pay / per: ${getLocalCurrencyString(filters[name].from)
      } < ${getLocalCurrencyString(filters[name].to)
      }${filters[name]?.type?.label
        ? ` / ${filters[name]?.type?.label}`
        : ''
      }`;
      case 'finalPay': return `Final Pay / per: ${getLocalCurrencyString(filters[name].from)
      } < ${getLocalCurrencyString(filters[name].to)
      }${filters[name]?.type?.label
        ? ` / ${filters[name]?.type?.label}`
        : ''
      }`;
      case 'winNumbers': return `WIN: ${filters[name]}`;
      case 'createdDate': return `Date Created: ${getLocalDateString(filters[name].from)} < ${getLocalDateString(filters[name].to)}`;
      case 'assignedDate': return `Date Assigned: ${getLocalDateString(filters[name].from)} < ${getLocalDateString(filters[name].to)}`;
      case 'completedDate': return `Date Completed: ${getLocalDateString(filters[name].from)} < ${getLocalDateString(filters[name].to)}`;
      case 'approvedDate': return `Date Approved: ${getLocalDateString(filters[name].from)} < ${getLocalDateString(filters[name].to)}`;
      case 'paidDate': return `Date Paid: ${getLocalDateString(filters[name].from)} < ${getLocalDateString(filters[name].to)}`;
      case 'selectedCustomFields': return `Custom Field(s): ${filters[name].map(item => item.label).join(', ')}`;
      case 'selectedCompanies': return `Companies: ${filters[name].map(item => item.label).join(', ')}`;
      case 'selectedClients': return `Clients Previously worked for: ${filters[name].map(item => item.label).join(', ')}`;
      case 'selectedWorkTypes': return `Type(s) of work: ${filters[name].join(', ')}`;
      case 'selectedWOTypes': return `WO Status: ${getWorkOrderStatusNames(filters[name])}`;
      case 'filterByStatusOnHold': return `On Hold: ${filters[name] ? 'YES' : 'NO'}`;
      case 'filterByDistance': return `Miles: ${filters[name]}`;
      case 'payLocation': return `Payment Location: ${filters[name].label}`;
      case 'selectedTemplates': return `Template(s): ${filters[name].map(item => item.label).join(', ')}`;
      default: return name;
    }
  };

  const disableFilter = filterName => {
    const newFilters = { ...filters };
    delete newFilters[filterName];
    setFiltersObject(newFilters);
  };

  return (
    <div className="flex-box flex-wrap">
      {
        Object.keys(filters).filter(name => !(
          [
            'needFilterWithoutRouterStatus',
            'needFilterByRouterStatus',
          ].includes(name)
        )).map(name => (
          <FilterBadge
            key={name}
            label={getFiltersLabel(name)}
            deleteTools={() => disableFilter(name)}
          />
        ))
      }
    </div>
  );
};

const mapDispatchToProps = {
  setFiltersObject,
};

const mapStateToProps = state => ({
  filters: state?.workOrder?.filters,
  userType: state?.auth?.user?.userType,
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterBadges);
