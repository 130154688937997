import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MDBContainer } from 'mdbreact';
import {
  sendContractorRate,
  setSortType,
  setSearchObject,
} from '../storage/actions/workOrdersActions';
import { toggleModal } from '../storage/actions/modalsActions';
import Offer from '../components/layout/Offer';
import RightControlPanel from '../components/layout/rightControlPanel';
// eslint-disable-next-line import/no-cycle
import DashboardContent from '../components/dashboard/DashboardContent';
// eslint-disable-next-line import/no-cycle
import { SocketContext } from '.';

const Dashboard = ({
  profile,
  auth,
  workOrder,
  location,
  toggleModal,
  sendContractorRate,
  mainContainer,
  setSortType,
  setSearchObject,
  useIntercom,
  history,
  match
}) => {
  // Array of Work Order ID`s prepared to export
  const { notificationSocket: socket } = useContext(SocketContext);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    socket && socket.on('notification', (response) => {
      console.log(response);
    });
  }, [socket]);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const typeParameter = params.get('type');
      switch (typeParameter) {
        case 'work-order': {
          toggleModal(true, 'viewDetailsWorkOrderModal', false, { id: params.get('id'), win: params.get('win') });
          const { tab } = match.params;
          const pathname = tab ? `/dashboard/${tab}` : '/dashboard';
          history.push({
            pathname,
            search: location.search,
          });
          break;
        }
        case 'contractor': {
          toggleModal(true, 'contractorDetailsModal', false, { contractorId: params.get('id') });
          break;
        }
        case 'thank_you_for_rate': {
          sendContractorRate({
            id: params.get('id'),
            rate: params.get('rate'),
          });
          break;
        }
        default: break;
      }
    }
  }, []);

  return (
    <>
      <MDBContainer className="clientdascontentmaindiv" fluid>
        <Offer
          getSearched={setSearchObject}
        />
        <MDBContainer fluid className="d-flex">
          <DashboardContent
            filters={workOrder.filters}
            search={workOrder.searchData}
            sortBy={workOrder.sortBy}
            mainContainer={mainContainer}
            useIntercom={useIntercom}
            profile={profile}
          />
          <RightControlPanel
            setSort={setSortType}
            auth={auth}
            sortType={workOrder.sortBy?.sortType}
            order={workOrder.sortBy?.order}
          />
        </MDBContainer>
      </MDBContainer>
    </>
  );
};

const mapDispatchToProps = {
  toggleModal,
  sendContractorRate,
  setSortType,
  setSearchObject,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  workOrder: state.workOrder,
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(Dashboard));
