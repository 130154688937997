import React from 'react';

const Tab = ({title, active, current, handler, count}) => {
    return (
        <button
            style={{
                background:'none',
                borderTop:'none',
                borderLeft:'none',
                borderRight:'none',
                margin:'10px'
            }}
            onClick={()=>handler(current)}
            className={active ? "nonactivectab": "activectab"}
        >
            {title} ({count || 0})
        </button>
    );
};

export default Tab;
