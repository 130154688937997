import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  MDBBtn, MDBCol, MDBInput, MDBRow,
} from 'mdbreact';
import workOrderStatusControl from '../../../utils/workOrderStatusControl';

const toggleCheckbox = (workOrder, selected, select) => {
  if (workOrder.status === 'completed') ;
  const id = workOrder._id;
  if (selected.includes(id)) {
    return select(selected.filter((el) => el !== id));
  }
  return select([...selected, id]);
};

const ControlsButton = ({
  workOrder,
  setCurrentWorkOrder,
  selectedWorkOrders,
  selectWorkOrder,
  fetchApplicantsForWorkOrder,
  toggleModal,
  publishWorkOrder,
  history,
  match,
  createWorkOrderTemplateByWorkOrder,
}) => {
  const workOrderControlByStatus = workOrder ? workOrderStatusControl(workOrder.status) : {};

  const handlerCreateTemplate = () => {
    const saveCopy = name => {
      createWorkOrderTemplateByWorkOrder(name, workOrder._id);
    };
    toggleModal(true, 'confirmModal', true, {
      onAccept: saveCopy,
      header: 'Create template by Work Order',
      inputLabel: 'Name',
      buttonLabels: { reject: 'Cancel' },
    });
  };

  return (
    <td>
      <MDBRow>
        <MDBCol
          md="12"
          className="d-flex client_tablecheck"
        >
          <span className="date_table ">
            <MDBBtn
              className="font12 copybutton float-right"
              color="secondary"
              style={{ textTransform: 'none' }}
              onClick={() => {
                toggleModal(true, 'copyWorkOrderModal', false, { workOrderId: workOrder._id });
              }}
            >
              Duplicate
            </MDBBtn>
          </span>
          <MDBInput
            label="."
            type="checkbox"
            className="float-right"
            id={`checkbox4${workOrder._id}`}
            checked={selectedWorkOrders.includes(workOrder._id)}
            onChange={() => {
              toggleCheckbox(workOrder, selectedWorkOrders, selectWorkOrder);
            }}
          />
        </MDBCol>
        <MDBCol>
          <MDBRow className="no-margins">
            <MDBBtn
              rounded
              className="font12 detailbut"
              color="info"
              style={{ textTransform: 'none' }}
              onClick={() => {
                const { tab } = match.params;
                const pathname = tab ? `/dashboard/${tab}` : '/dashboard';
                history.push({
                  pathname,
                  search: `?type=work-order&id=${workOrder._id}`,
                });
                toggleModal(true, 'viewDetailsWorkOrderModal', false, { id: workOrder._id });
              }}
            >
              Details
            </MDBBtn>
            {
              workOrderControlByStatus.draft
              && (
                <MDBBtn
                  className="font12 detailbut"
                  color="info"
                  style={{ textTransform: 'none' }}
                  onClick={() => {
                    publishWorkOrder(workOrder._id);
                  }}
                >
                  Publish
                </MDBBtn>
              )
            }
            {
              workOrderControlByStatus.beforeAssigned && !workOrderControlByStatus.canceled
              && (
                <>
                  {
                    (!workOrderControlByStatus.draft || workOrder.routedStatus)
                    && (
                      <MDBBtn
                        className="font12 detailbut"
                        color="info"
                        style={{ textTransform: 'none' }}
                        onClick={() => {
                          fetchApplicantsForWorkOrder(workOrder._id);
                          setCurrentWorkOrder(workOrder);
                          toggleModal(true, 'viewAllApplicantsForWorkOrderModal');
                        }}
                      >
                        View Applicants
                      </MDBBtn>
                    )
                  }
                  <MDBBtn
                    className="font12 detailbut"
                    color="info"
                    style={{ textTransform: 'none' }}
                    onClick={() => {
                      setCurrentWorkOrder(workOrder);
                      fetchApplicantsForWorkOrder(workOrder._id);
                      toggleModal(true, 'nearByContractorModal');
                    }}
                  >
                    Contractors Near-by
                  </MDBBtn>
                </>
              )
            }
            <MDBBtn
              className="font12 detailbut"
              color="info"
              style={{ textTransform: 'none' }}
              onClick={handlerCreateTemplate}
            >
              Create Template
            </MDBBtn>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </td>
  );
};

export default withRouter(ControlsButton);
