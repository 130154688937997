import React from 'react';
import {
  MDBIcon, MDBSideNav, MDBSideNavLink, MDBSideNavNav,
} from 'mdbreact';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TaxInfoButton from '../../sidebar/taxInfoButton';
import BankInfoButton from '../../sidebar/bankInfoButton';

const Sidebar = ({
  isOpen, auth, profile, changeSidebarShowState,
}) => {
  const closeSidebar = () => changeSidebarShowState(false);

  return (
    <MDBSideNav
      triggerOpening={isOpen}
      mask="strong"
      breakWidth={1000000000}
      className="sidenavsw9"
      fixed
    >
      <MDBSideNavNav className="sidenavsw9">
        <MDBSideNavLink
          to={
            auth.user.userType === 'contractor'
              ? '/dashboard/upcoming-work'
              : '/dashboard/assigned'
          }
          activeClassName="activenavitem"
          onClick={closeSidebar}
        >
          <img alt="" className="dashboardimg" src="/home-run@2x.png" />
          Dashboard
        </MDBSideNavLink>
        {
          auth.user.userType === 'client'
            ? (
              <>
                <MDBSideNavLink to="/find-contractor" activeClassName="activenavitem" onClick={closeSidebar}>
                  <img alt="" className="bancolimg" src="/binocular@2x.png" />
                  Find Contractor
                </MDBSideNavLink>
                <MDBSideNavLink to="/contractor-groups" activeClassName="activenavitem" onClick={closeSidebar}>
                  <MDBIcon className="vwimg" icon="users" />
                  Preferred
                </MDBSideNavLink>
                <MDBSideNavLink
                  to="/manage-custom-fields"
                  activeClassName="activenavitem"
                  onClick={closeSidebar}
                >
                  <MDBIcon className="vwimg" far icon="edit" />
                  Manage Custom Fields
                </MDBSideNavLink>
                <MDBSideNavLink
                  to="/PMTools"
                  activeClassName="activenavitem"
                  onClick={closeSidebar}
                  className="side-nav-link-with-icon"
                >
                  <MDBIcon far className="vwimg" icon="clipboard" />
                  PM Tools
                </MDBSideNavLink>
                <MDBSideNavLink
                  to="/manage-teams"
                  activeClassName="activenavitem"
                  onClick={closeSidebar}
                  className="side-nav-link-with-icon"
                >
                  <MDBIcon className="vwimg" icon="users" />
                  Manage Teams
                </MDBSideNavLink>
                {
                  (profile.clientUser?.accessctrl !== 'Manager' && profile.clientUser?.accessctrl !== 'Dispatcher')
                    ? (
                      <>
                        <MDBSideNavLink
                          to="/user-management"
                          activeClassName="activenavitem"
                          onClick={closeSidebar}
                        >
                          <img alt="" className="vwimg" src="/stamp@2x.png" />
                          User Management
                        </MDBSideNavLink>
                        <MDBSideNavLink
                          to="/manage-funds"
                          activeClassName="activenavitem"
                          onClick={closeSidebar}
                          className="side-nav-link-with-icon"
                        >
                          <MDBIcon className="vwimg" icon="dollar-sign" />
                          Manage Funds
                        </MDBSideNavLink>
                        <MDBSideNavLink
                          to="/payment-methods"
                          activeClassName="activenavitem"
                          onClick={closeSidebar}
                          className="side-nav-link-with-icon"
                        >
                          <MDBIcon far className="vwimg" icon="credit-card" />
                          Payment Methods
                        </MDBSideNavLink>
                      </>
                    )
                    : <></>
                }
                <MDBSideNavLink
                  to="/templates"
                  activeClassName="activenavitem"
                  onClick={closeSidebar}
                  className="side-nav-link-with-icon"
                >
                  <MDBIcon className="vwimg" icon="newspaper" far />
                  Templates
                </MDBSideNavLink>
              </>
            )
            : <></>
        }
        {
          auth.user.userType === 'contractor'
            ? (
              <>
                <MDBSideNavLink
                  to="/manage-funds"
                  activeClassName="activenavitem"
                  onClick={closeSidebar}
                  className="side-nav-link-with-icon"
                >
                  <MDBIcon className="vwimg" icon="dollar-sign" />
                  Payment History
                </MDBSideNavLink>
                {/* <MDBSideNavLink
                  to="/certifications/"
                  activeClassName="activenavitem"
                  onClick={closeSidebar}
                  className="side-nav-link-with-icon"
                >
                  <MDBIcon className="vwimg" icon="certificate" />
                  Credentials
                </MDBSideNavLink> */}
              </>
            )
            : <></>
        }
        {
          auth.user.userType === 'superAdmin'
            ? (
              <>
                <MDBSideNavLink
                  to="/certificates-contractors"
                  activeClassName="activenavitem"
                  onClick={closeSidebar}
                  className="side-nav-link-with-icon"
                >
                  <MDBIcon className="vwimg" icon="user-astronaut" />
                  Certification
                </MDBSideNavLink>
                <MDBSideNavLink
                  to="/recruits"
                  activeClassName="activenavitem"
                  onClick={closeSidebar}
                >
                  <MDBIcon className="vwimg" icon="users" />
                  Recruits
                </MDBSideNavLink>
                <MDBSideNavLink
                  to="/manage-companies"
                  activeClassName="activenavitem"
                  onClick={closeSidebar}
                  className="side-nav-link-with-icon"
                >
                  <MDBIcon className="vwimg" icon="users-cog" />
                  Manage Companies
                </MDBSideNavLink>
                <MDBSideNavLink
                  to="/manage-funds"
                  activeClassName="activenavitem"
                  onClick={closeSidebar}
                  className="side-nav-link-with-icon"
                >
                  <MDBIcon className="vwimg" icon="dollar-sign" />
                  Manage Funds
                </MDBSideNavLink>
                <MDBSideNavLink
                  to="/funding-sources"
                  activeClassName="activenavitem"
                  onClick={closeSidebar}
                  className="side-nav-link-with-icon"
                >
                  <MDBIcon far className="vwimg" icon="credit-card" />
                  Funding Sources
                </MDBSideNavLink>
                <MDBSideNavLink
                  to="/clients-transactions-history"
                  activeClassName="activenavitem"
                  onClick={closeSidebar}
                  className="side-nav-link-with-icon"
                >
                  <MDBIcon fas className="vwimg" icon="history" />
                  Clients Transactions
                </MDBSideNavLink>
                <TaxInfoButton />
                <BankInfoButton />
              </>
            )
            : <></>
        }
      </MDBSideNavNav>
    </MDBSideNav>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps)(withRouter(Sidebar));
