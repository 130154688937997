import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import BasePaySection from './BasePaySection';
import DividedPaySection from './DividedPaySection';
import VariablePaySection from './VariablePaySection';
import FundsInfoSection from './FundsInfoSection';

const PayInfoSection = () => (
  <MDBCol>
    <MDBContainer fluid className="font-size-08">
      <MDBRow className="justify-content-between">
        <BasePaySection />
        <DividedPaySection />
      </MDBRow>
      <MDBRow className="justify-content-between">
        <VariablePaySection />
        <FundsInfoSection hideElements={{ balance: true }} />
      </MDBRow>
    </MDBContainer>
  </MDBCol>
);

export default PayInfoSection;
