import React from 'react';
import { MDBContainer, MDBRow } from 'mdbreact';

const SectionContainer = ({ children }) => (
  <MDBContainer fluid>
    {
      children.map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <MDBRow key={index} className="border-bottom-line">
          {child}
        </MDBRow>
      ))
    }
  </MDBContainer>
);

export default SectionContainer;
