import React from 'react';
import { MDBBtn, MDBRow } from 'mdbreact';
import axios from 'axios';
import workOrderStatusControl from '../../../utils/workOrderStatusControl';
import FilePreviewElement from '../../layout/FilePreviewElement';
import getFileInfo from '../../../utils/getFileInfoForDisplay';
import { Spinner } from 'react-bootstrap';

const UploadDeliverables = ({
  auth,
  currentWorkOrder,
  uploadDocument,
  deleteUpload,
  toggleModal,
  documentsDownloadInProcess,
  setDocumentsDownloadInProcess
}) => {
  const deleteFile = (index) => {
    deleteUpload(
      currentWorkOrder.uploadDeliverables[index],
      currentWorkOrder._id,
    );
  };

  const handleFileUpload = (event) => {
    const { files } = event.target;
    if (files) {
      const fd = new FormData();
      fd.append('workOrderId', currentWorkOrder._id);
      Array.from(files).forEach(file => {
        fd.append('files', file);
      });
      uploadDocument(fd);
    }
  };

  const workOrderControlByStatus = currentWorkOrder
    ? workOrderStatusControl(currentWorkOrder.status)
    : {};

  return (
    (
      (
        auth.user.userType !== 'contractor'
        && currentWorkOrder.uploadDeliverables
        && currentWorkOrder.uploadDeliverables.length
      )
      || currentWorkOrder.contractor_id === auth.user.id
    )
      ? (
        <div style={{ textAlign: 'left' }}>
          <hr className="my-4 hrline2" />
          <div className="d-flex" style={{ justifyContent: 'space-between', position: 'relative' }}>
            <h6
              className="font14 flex-box flex-align-center"
              style={{ justifyContent: 'space-between' }}
            >
              <b>Upload Deliverables</b>
            </h6>
            <div
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {
                auth.user.userType === 'contractor'
                && currentWorkOrder.isNeedDeliverables
                && (
                  !currentWorkOrder.uploadDeliverables
                  || !currentWorkOrder.uploadDeliverables.length
                )
                && (
                  <div style={{ color: 'orange' }}>
                    To Complete Upload Deliverables Required!
                  </div>
                )
              }
              {
                auth.user.userType === 'client'
                  ? (
                    <MDBBtn
                      style={{ marginBottom: 20 }}
                      color="info"
                      className="markcomplete_but"
                      onClick={() => {
                        setDocumentsDownloadInProcess(true)
                        axios.post('/api/users/fetch_file',
                        { workOrderId: currentWorkOrder._id },
                        { responseType: 'blob' })
                        .then(response => {
                          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute('download', `${currentWorkOrder.WIN}_${currentWorkOrder._id}.zip`);
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                          window.URL.revokeObjectURL(url);
                          setDocumentsDownloadInProcess(false)
                        })
                        .catch(error => {
                          console.error('There was an error downloading the file:', error);
                        });
                      }}
                      disabled={documentsDownloadInProcess}
                    >
                      download all documents {documentsDownloadInProcess ? <Spinner animation="border" size='sm' variant="secondary" /> : ''}
                    </MDBBtn>
                  )
                  : null
              }
              {
                auth.user.userType === 'contractor' && workOrderControlByStatus.inWork
                  ? (
                    <form style={{ textAlign: 'right' }}>
                      <input
                        multiple
                        disabled={currentWorkOrder.onHold}
                        type="file"
                        name="file"
                        id="file"
                        className="uploadcheck font11 position-absolute"
                        style={{
                          margin: 0,
                          opacity: 0,
                          width: '100%',
                          display: 'block',
                          height: '100%',
                          left: 0,
                          top: 0,
                        }}
                        placeholder="Upload file"
                        onChange={handleFileUpload}
                      />
                      <label
                        htmlFor="file"
                        className="font11 cursor_pointer"
                        style={{
                          padding: '0.5rem 1rem',
                          borderRadius: '4px',
                          backgroundColor: '#182837',
                        }}
                      >
                        Upload file
                      </label>
                    </form>
                  )
                  : null
              }
            </div>
          </div>
          <MDBRow style={{ marginBottom: '15px' }}>
            {
              currentWorkOrder.uploadDeliverables
              && currentWorkOrder.uploadDeliverables.length
                ? currentWorkOrder.uploadDeliverables.map((data, i) => FilePreviewElement({
                  fileInfo: getFileInfo(data),
                  index: i,
                  data,
                  toggleModal,
                  removeImage: auth.user.userType === 'contractor' && workOrderControlByStatus.inWork
                    ? deleteFile
                    : false,
                }))
                : null
            }
          </MDBRow>
        </div>
      )
      : null
  );
};

export default UploadDeliverables;
