import React from 'react';
import { MDBCol, MDBIcon } from 'mdbreact';
import { connect } from 'react-redux';
import {
  toggleModal,
} from '../../storage/actions/modalsActions';
import sendClientInvite from '../../utils/api/post/sendClientInvite';
import { reloadData } from '../../storage/actions/applicationStateActions';

const UserManagementActions = ({
  toggleModal,
  item,
}) => {
  const handleOpenEdit = () => {
    toggleModal(true, 'addOrEditUsersForManagement', false, item);
  };

  const resendInvite = async () => {
    if (item.status === 'Accepted') return;
    toggleModal(true, 'loader');
    // Resend value if truthy indicates this is existing user. Otherwise new user will be created
    await sendClientInvite({ ...item, resend: 'resend' });

    toggleModal(false, 'loader');
  };

  const deleteUser = () => {
    const userInfo = {
      ...item,
      firstname: item.firstName,
      lastname: item.lastName,
      userType: item.role,
      number: item.phone,
    };

    toggleModal(true, 'deleteClientFromCompany', false, {
      client: userInfo,
    });
  };

  return (
    <MDBCol className="flex-box">
      <MDBIcon
        color="green"
        icon="edit"
        className="v-paddings-half"
        size="lg"
        onClick={handleOpenEdit}
      />
      <MDBIcon
        icon="trash-alt"
        className="v-paddings-half"
        size="lg"
        onClick={deleteUser}
      />
      {item.status !== 'Accepted' ? (
        <MDBIcon
          icon="fa fa-reply"
          size="lg"
          className="v-paddings-half"
          onClick={resendInvite}
        />
      ) : <></> }
    </MDBCol>
  );
};
const mapDispatchToProps = {
  toggleModal,
  reloadData,
};

export default connect(null, mapDispatchToProps)(UserManagementActions);
