import React from 'react';
import { MDBBtn, MDBCol } from 'mdbreact';
import { withRouter } from 'react-router-dom';
import workOrderStatusControl from '../../../utils/workOrderStatusControl';
import ExplanationInputModal from '../ExplenationInputModal';

const WorkOrderDetailsButtonControl = ({
  currentWorkOrder,
  history,
  deleteWorkOrderDetails,
  cancelWorkOrder,
  editOnHoldStatusWorkOrder,
  toggleModal,
  toggleSecondModalClose,
  fetchApplicantsForWorkOrder,
  createWorkOrderTemplateByWorkOrder,
}) => {
  const workOrderControlButtonAvailable = workOrderStatusControl(currentWorkOrder.status);

  const handlerCreateTemplate = () => {
    const saveCopy = name => {
      createWorkOrderTemplateByWorkOrder(name, currentWorkOrder._id);
    };
    toggleModal(true, 'confirmModal', true, {
      onAccept: saveCopy,
      header: 'Create template by Work Order',
      inputLabel: 'Name',
      buttonLabels: { reject: 'Cancel' },
    });
  };

  const editWorkOrder = () => {
    toggleModal(false, 'viewDetailsWorkOrderModal');
    history.push(`/create-work-order/${currentWorkOrder._id}`);
  };

  const deleteWorkOrder = () => {
    deleteWorkOrderDetails({ workOrdersId: [currentWorkOrder._id] }, history);
  };

  const getReasonForCancelWorkOrder = reason => {
    toggleSecondModalClose('explanationInputModal', true);
    cancelWorkOrder({
      workOrdersId: [currentWorkOrder._id],
      reason,
    });
  };

  const handlerOnHoldStatusChange = () => {
    editOnHoldStatusWorkOrder({
      workOrdersId: [currentWorkOrder._id],
      onHold: !currentWorkOrder.onHold,
    });
  };

  return (
    <MDBCol md="3" className="flex-box flex-wrap">
      {
        !workOrderControlButtonAvailable.canceled
          && (
            <MDBBtn
              color="secondary"
              className="applicantbut"
              onClick={() => {
                fetchApplicantsForWorkOrder(currentWorkOrder._id);
                toggleModal(true, 'viewAllApplicantsForWorkOrderModal');
              }}
            >
              Applicants
            </MDBBtn>
          )
      }
      {
        workOrderControlButtonAvailable.beforeAssigned && !workOrderControlButtonAvailable.canceled
          && (
            <MDBBtn
              color="info"
              className="invitebut"
              onClick={() => {
                toggleModal(true, 'nearByContractorModal');
              }}
            >
              Invite
            </MDBBtn>
          )
      }
      {
        workOrderControlButtonAvailable.workUncompleted && !workOrderControlButtonAvailable.canceled
          && <MDBBtn color="info" className="editbuttonwom" onClick={editWorkOrder}>Edit</MDBBtn>
      }
      {
        workOrderControlButtonAvailable.beforeAssigned && !workOrderControlButtonAvailable.canceled
          && <MDBBtn color="deep-orange" className="deletebutton" onClick={deleteWorkOrder}>Delete</MDBBtn>
      }
      {
        workOrderControlButtonAvailable.assigned
          && (
            <>
              <MDBBtn
                color="deep-orange"
                className="deletebutton"
                onClick={handlerOnHoldStatusChange}
              >
                {`${currentWorkOrder.onHold ? 'Remove' : ''} On Hold`}
              </MDBBtn>
              <MDBBtn
                color="deep-orange"
                className="deletebutton"
                onClick={() => toggleModal(true, 'explanationInputModal', true, 'cancel')}
              >
                Cancel
              </MDBBtn>
              <ExplanationInputModal
                returnExplanation={getReasonForCancelWorkOrder}
                title="Reason to cancel work order"
                type="cancel"
                isCloseAvailable
              />
            </>
          )
      }
      <MDBBtn
        className="editbuttonwom"
        color="secondary"
        onClick={() => {
          toggleModal(true, 'copyWorkOrderModal', false, { workOrderId: currentWorkOrder._id });
        }}
      >
        Copy
      </MDBBtn>
      <MDBBtn
        className="editbuttonwom"
        color="info"
        onClick={handlerCreateTemplate}
      >
        Create Template
      </MDBBtn>
    </MDBCol>
  );
};

export default withRouter(WorkOrderDetailsButtonControl);
