import React, { useState } from 'react';
import { MDBIcon } from 'mdbreact';
import DatePicker from 'react-datepicker/es';
import { getDateWithOffset, getISODateAndTime } from '../../../../utils/getDateWithOffset';
import Notification from '../../../notification';

const EditExpense = ({
  expense,
  saveExpense,
  cancelEdit,
}) => {
  const [date, setDate] = useState(getDateWithOffset(expense.date));
  const [amount, setAmount] = useState(expense.amount);
  const [description, setDescription] = useState(expense.description);

  const saveChanges = async (event) => {
    event.preventDefault();
    if (!amount || !(amount > 0)) {
      Notification('warning', { message: 'Please enter a dollar amount (greater than zero)!' });
      return;
    }
    if (!description) {
      Notification('warning', { message: 'Please type description first!' });
      return;
    }
    const result = await saveExpense({
      amount,
      date: getISODateAndTime(date, true, true),
      description,
      id: expense._id,
      workOrderId: expense.work_order_id,
    });
    if (result) {
      setDate(new Date());
      setAmount('');
      setDescription('');
    }
  };

  return (
    <>
      <td>
        <DatePicker
          className="form-control coutd"
          dateFormat="MM/dd/yy"
          name="date"
          placeholder="Date"
          selected={date}
          value={date}
          onChange={date => setDate(date)}
        />
      </td>
      <td>
        <input
          type="text"
          name="expensesDescription"
          className="form-control descheck font11"
          placeholder="Type Expense Description"
          value={description}
          onChange={event => setDescription(event.target.value)}
        />
      </td>
      <td style={{ textAlign: 'end' }}>
        <input
          type="number"
          name="expensesAmount"
          className="form-control descheck font11"
          placeholder="Amount ($)"
          value={amount}
          onChange={event => setAmount(event.target.value)}
        />
      </td>
      <td style={{ padding: 0, position: 'relative' }}>
        <div className="flex-center" style={{ position: 'absolute', width: '100%' }}>
          <MDBIcon
            icon="save"
            size="lg"
            style={{ color: '#41566a', cursor: 'pointer' }}
            onClick={saveChanges}
          />
        </div>
      </td>
      <td style={{ padding: 0, position: 'relative' }}>
        <div className="flex-center" style={{ position: 'absolute', width: '100%' }}>
          <MDBIcon
            icon="times-circle"
            size="lg"
            style={{ color: '#41566a', cursor: 'pointer' }}
            onClick={cancelEdit}
          />
        </div>
      </td>
    </>
  );
};

export default EditExpense;
