import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import ContractorCertificatesChecks from '../../layout/ContractorCertificatesChecks';

const detailsBlockStyles = { padding: '25px 0px 0px 15px' };

const ContractorDetailsAdditional = ({
  contractorDetails = null,
}) => (
  <>
    {
      contractorDetails.about
        ? (
          <MDBRow>
            <MDBCol md="12" style={detailsBlockStyles}>
              <h6 className="font12">Bio/About :</h6>
              <div
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{
                  __html: contractorDetails.about,
                }}
                className="font12"
              />
            </MDBCol>
          </MDBRow>
        )
        : null
    }
    {
      contractorDetails.credentials
        ? (
          <MDBRow>
            <MDBCol md="12" style={detailsBlockStyles}>
              <h6 className="font12">Credentials :</h6>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: contractorDetails.credentials,
                }}
                className="font12"
              />
            </MDBCol>
          </MDBRow>
        )
        : null
    }
    {
      contractorDetails.certificates
        ? (
          <MDBRow>
            <MDBCol>
              <h6 className="font12">Certificates :</h6>
              <MDBCol>
                <ContractorCertificatesChecks
                  certificates={contractorDetails.certificates}
                  infoType="name"
                  size="100%"
                />
              </MDBCol>
            </MDBCol>
          </MDBRow>
        )
        : null
    }
    {
      contractorDetails.groups.length
        ? (
          <>
            <MDBRow>
              <MDBCol>
                <h6 className="font12">Preferred groups :</h6>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              {contractorDetails.groups.map((group, index) => (
                <MDBCol md='2' key={index}>
                  {group}
                </MDBCol>
              ))}
            </MDBRow>
          </>
        )
        : null
    }
    {
      contractorDetails.other_tools
        ? (
          <MDBRow style={{ marginBottom: 20 }}>
            <MDBCol md="12" style={detailsBlockStyles}>
              <h6 className="font12">Other types of work I can do :</h6>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: contractorDetails.other_tools,
                }}
                style={{ fontSize: 12 }}
              />
            </MDBCol>
          </MDBRow>
        )
        : null
    }
  </>
);

export default ContractorDetailsAdditional;
