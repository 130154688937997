import React, { useEffect, useState } from 'react';
import {
  MDBRow, MDBContainer, MDBCol, MDBBtn,
} from 'mdbreact';
import { connect } from 'react-redux';
import { toggleModal } from '../../storage/actions/modalsActions';
// eslint-disable-next-line
import Modal from '../../components/modal';
import getUsersForUserManagement from '../../utils/api/get/getUsersForUserManagement';
import Tablet from '../../components/layout/tablet';
import { datatableColumnsForUserManagement } from '../../utils/constans';
import UserManagementActions from '../../components/userManagement/UserManagementActions';

const UserManagement = ({ mainContainer, toggleModal, isReload }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, [isReload]);

  const fetchUsers = async () => {
    toggleModal(true, 'loader');
    const response = await getUsersForUserManagement();
    const newUsers = response.map(user => ({
      _id: user._id,
      id: user._id,
      companyId: user.companyId,
      email: user.email,
      phone: user.phone,
      firstName: user.firstname,
      lastName: user.lastname,
      role: user.accessctrl || 'Manager',
      status: user.status,
    }));
    setUsers(newUsers);
    toggleModal(false, 'loader');
  };

  const openAddUserModal = () => {
    toggleModal(true, 'addOrEditUsersForManagement');
  };

  return (
    <MDBContainer
      style={{ width: '75%' }}
      className="clientcreateworkorderdiv"
      fluid
    >
      <MDBRow style={{ paddingBottom: '15px' }}>
        <MDBCol md="6">
          <h5 style={{ margin: '10px' }}>User Management</h5>
        </MDBCol>
        <MDBCol md="6" style={{ textAlign: 'right' }}>
          <MDBBtn
            className="adduser"
            color="btn Ripple-parent btn-success"
            onClick={openAddUserModal}
          >
            Add User
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBContainer fluid>
          <Tablet
            className="color-white"
            columns={datatableColumnsForUserManagement}
            rows={users}
            enableActionsCol
            ActionElement={UserManagementActions}
            placeholder="No users found"
          />
        </MDBContainer>
      </MDBRow>
      <Modal mainContainer={mainContainer?.current} />
    </MDBContainer>
  );
};

const mapStateToProps = state => ({
  isReload: state.applicationState.isReload,
});

export default connect(mapStateToProps, { toggleModal })(UserManagement);
