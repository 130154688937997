import React from 'react';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBLink,
} from 'mdbreact';

const VeriShareCard = ({
  owned, name, cerificateTypeId, openBuyModal, amount,
}) => (
  <MDBCard
    alignment="center"
    style={{ backgroundColor: '#f7931e', width: '260px', margin: '2rem' }}
  >
    <MDBCardBody style={{ padding: '1.25rem 0 1.25rem 0', textAlign: 'center' }}>
      <MDBCardTitle>Background Check</MDBCardTitle>
      <MDBCardText className="text-white">
        Many clients are starting to require background checks!
      </MDBCardText>
      {!owned ? (
        <MDBCol
          className="flex-box justify-content-center"
          style={{ padding: '0' }}
        >
           <MDBBtn
            className="btn btn-primary"
            href={`https://verishare.com/products`}
            target='_blank'
            color="primary"
            style={{
              display: 'inline-block',
              border: 'solid 1px black',
              fontSize: '10px',
            }}
          >
            Order Now
          </MDBBtn>
        </MDBCol>
      ) : (
        <MDBCol
          className="flex-box justify-content-center pad"
          style={{ padding: '0' }}
        >
          <MDBLink
            className="btn btn-primary inline-block"
            to={cerificateTypeId === undefined ? '/' : `/certifications/${cerificateTypeId}/video`}
            style={{
              border: 'solid 1px black',
              fontSize: '10px',
            }}
          >
            Re-watch Video
          </MDBLink>
          <MDBLink
            className="btn btn-primary"
            to={`/certifications/${cerificateTypeId}/tests`}
            style={{
              display: 'inline-block',
              border: 'solid 1px black',
              fontSize: '10px',
            }}
          >
            Retake Exam
          </MDBLink>
        </MDBCol>
      )}
    </MDBCardBody>
  </MDBCard>
);

export default VeriShareCard;
