import React from 'react';
import {
  MDBCol, MDBRow, MDBTable, MDBTableBody,
} from 'mdbreact';
import InvitedContractorCard from '../../layout/contractorCards/InvitedContractorCard';

const InvitedContractorDetails = ({
  userType,
  routedApplicants,
  toggleModal,
  setCurrentApplicants,
  currentWorkOrder,
  unRouteWorkOrder,
  acceptInvite,
}) => (
  routedApplicants && routedApplicants.data
    ? (
      <MDBRow style={{ marginBottom: '0px' }}>
        <MDBCol>
          <MDBRow><hr className="my-4 hrline2" /></MDBRow>
          <h6 style={{ fontWeight: 'bold' }}>
            Invited Contractor Details
          </h6>
          <div>
            <MDBRow style={{ marginBottom: '15px' }} />
            <MDBRow>
              <MDBCol>
                <MDBTable className="applicantappiliedwo_table">
                  {
                    routedApplicants && routedApplicants.data
                      ? (
                        <MDBTableBody>
                          {
                            routedApplicants.data.map((contractor) => (
                              contractor.contractor_details
                                ? (
                                  <InvitedContractorCard
                                    userType={userType}
                                    key={contractor.contractor_id}
                                    contractorInfo={contractor}
                                    setCurrentApplicants={setCurrentApplicants}
                                    toggleModal={toggleModal}
                                    currentWorkOrder={currentWorkOrder}
                                    unRouteWorkOrder={unRouteWorkOrder}
                                    acceptInvite={acceptInvite}
                                  />
                                )
                                : null
                            ))
                          }
                        </MDBTableBody>
                      )
                      : <></>
                  }
                </MDBTable>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBCol>
      </MDBRow>
    )
    : null
);

export default InvitedContractorDetails;
