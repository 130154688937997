import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import Select from 'react-select';
import { white as customSelectStyle } from '../../../utils/customSelectStyle';
import CustomFieldValueInput from './CustomFieldValueInput';
import Notification from '../../notification';

const CustomFieldFiltersSection = ({
  customFields,
  selectedCustomFields,
  selectCustomField,
}) => {
  const onChange = (customFieldId, value) => {
    const newSelectedCustomFields = selectedCustomFields.map(customField => (
      customField.value !== customFieldId
        ? customField
        : {
          ...customField,
          customFieldValue: value,
        }
    ));
    selectCustomField(newSelectedCustomFields);
  };

  // eslint-disable-next-line consistent-return
  const onSelect = values => {
    if (values && values.length > 5) {
      return Notification('warning', { message: 'You can select a maximum of 5 custom fields' });
    }
    selectCustomField(values);
  };

  const unSelectCustomField = customFieldId => {
    const newSelectedCustomFields = selectedCustomFields.filter(customField => (
      customField.value !== customFieldId
    ));
    selectCustomField(newSelectedCustomFields);
  };

  return (
    <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
      <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
        Custom Field(s)
      </MDBCol>
      <MDBCol md="4" className="no-margins v-paddings">
        <Select
          isClearable
          isSearchable
          isMulti
          closeMenuOnSelect={false}
          options={customFields}
          value={[...selectedCustomFields]}
          styles={customSelectStyle}
          onChange={onSelect}
        />
      </MDBCol>
      <MDBCol md="5">
        {
          selectedCustomFields.map(customField => (
            <CustomFieldValueInput
              key={customField.value}
              id={customField.value}
              value={customField.customFieldValue}
              name={customField.label}
              onChange={event => onChange(customField.value, event.target.value)}
              remove={() => unSelectCustomField(customField.value)}
            />
          ))
        }
      </MDBCol>
    </MDBRow>
  );
};

export default CustomFieldFiltersSection;
