import React, { useEffect, useState } from "react";
import documentView from '../../../assets/doc_image.png';
import {FileName, FileCard, FileView, RemoveIcon, DownloadIcon} from "./FileUploadStyled";

const IMAGE_TYPES = ["image/webp", "image/svg+xml", "image/png", "image/jpeg", "image/gif", "image/avif", "image/apng"]
const IMAGE_EXTENSIONS = ['webp', 'svg', 'png', 'jpeg', 'jpg', 'gif', 'avif', 'apng']

const FilePreview = ({ file, onDelete }) => {
  const [preview, setPreview] = useState("")
  const [source, setSource] = useState("")
  const [name, setName] = useState("")

  useEffect(() => {
    let source = file;

    if(typeof file === "string") {
      const ext = file.split(".").at(-1)
      const name = file.split("/").at(-1)
      const isImage = IMAGE_EXTENSIONS.includes(ext)

      setName(name)
      setSource(source)
      setPreview(isImage ? file : documentView)
      return;
    }

    source = URL.createObjectURL(file)

    setName(file.name)
    setSource(source)
    setPreview(IMAGE_TYPES.includes(file.type) ? source : documentView)

    return () => URL.revokeObjectURL(source)
  }, [])

  const handleDelete = (e) => {
    onDelete(file)
    e.stopPropagation()
    e.preventDefault()
  }

  const handleDownload = () => {
    // if(e.detail < 2) return;

    const downloader = document.createElement('a')
    downloader.setAttribute('href', source)
    downloader.setAttribute('download', name)
    document.body.appendChild(downloader);
    downloader.click();
    downloader.remove();
  }

  return <FileCard onClick={handleDownload}>
    {onDelete && <RemoveIcon onClick={handleDelete}/>}
    <DownloadIcon />
    <FileView src={preview}/>
    <FileName>{name.slice(0, 10)}...</FileName>
  </FileCard>
}

export default FilePreview