import React, { useEffect, useMemo, useState } from "react";
import { MDBContainer, MDBInput, MDBRow } from "mdbreact";

const CertificatesList = ({ certificates, handlerCheck, checkGroup, userGroup }) => {
  const [rows, setRows] = useState([]);
  
  useEffect(() => {
    setRows(Array.from({ length: Math.ceil(certificates.length / 4) }, (_, index) => index))
  }, [certificates.length])
  
  const sortedCertificates = useMemo(() => {
    return rows.map((_, rowIndex) => {
      return certificates.slice(rowIndex * 4, rowIndex * 4 + 4);
    });
  }, [rows, certificates]);

  return (
    <MDBContainer className="d-flex justify-content-start">
      {sortedCertificates.map((sortedCertificate, index) => (
        <MDBRow key={index} className="flex-column mr-5">
          {sortedCertificate.map((certificate) => (
            <div key={certificate._id} md="6" style={{ fontSize: 13 }}>
              <MDBInput
                type="checkbox"
                id={certificate._id}
                label={certificate.name}
                name={certificate.name}
                value={certificate._id}
                onChange={handlerCheck}
              />
            </div>
          ))}
          {index === sortedCertificates.length - 1 && 
            <div md="6" style={{ fontSize: 13 }}>
              <MDBInput
                type="checkbox"
                id={"group"}
                label={"Preferred Groups"}
                name={'preferedGroups'}
                value={userGroup}
                onChange={checkGroup}
              />
            </div>
          }
        </MDBRow>
      ))}
    </MDBContainer>
  )
}

export default CertificatesList;
