import React from 'react';
import {
  MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import { connect } from 'react-redux';
import { toggleModal } from '../../storage/actions/modalsActions';
import AddFundingSourceToDwollaBySuperAdminReduxForm from '../layout/reduxForm/AddFundingSourceToDwollaBySuperAdmin';
import ControlButtonsSection from '../layout/reduxForm/section/addFundingSourceToDwollaBySuperAdmin/ControlButtonsSection';
import onSubmit from '../../utils/reduxForm/onSubmit/addFundingSourceToDwollaBySuperAdmin';

const AddFundingSourceToDwollaBySuperAdmin = ({
  modalState,
  toggleModal,
}) => {
  const closeModal = () => {
    toggleModal(!modalState.isOpen, 'addFundingSourceToDwollaBySuperAdmin');
  };

  return (
    <MDBModal
      className="applypop"
      isOpen={modalState.isOpen}
      toggle={closeModal}
      fullHeight
      position="right"
    >
      <MDBModalBody style={{ color: 'white' }}>
        <MDBRow className="mb-2">
          <MDBCol md="10">
            <h5 style={{ fontWeight: 'bold' }} className="">Add Funding Sources</h5>
          </MDBCol>
          <MDBCol md="2" style={{ textAlign: 'end' }}>
            <MDBIcon
              far
              icon="times-circle"
              onClick={closeModal}
              size="lg"
              className="blue-grey-text"
            />
          </MDBCol>
        </MDBRow>
        <AddFundingSourceToDwollaBySuperAdminReduxForm onSubmit={onSubmit} />
        <MDBRow className="no-margins">
          <MDBCol className="p-0">
            <label className="font-size-08">
              Verification of micro deposits usually takes 2-3 days.
            </label>
          </MDBCol>
        </MDBRow>
        <ControlButtonsSection closeModal={closeModal} />
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  modalState: state.modalState.addFundingSourceToDwollaBySuperAdmin,
});

export default connect(mapStateToProps, {
  toggleModal,
})(AddFundingSourceToDwollaBySuperAdmin);
