import React, { useEffect, useState } from 'react';
import { MDBBtn } from 'mdbreact';
import { connect } from 'react-redux';
import { exportWorkOrders } from '../../../storage/actions/workOrdersActions';
import prepareFilters from '../../../utils/prepareFilters';
import { getStatus } from '../../../utils/api/get/getWorkOrders';

const WorkOrderButtonControl = ({
  selectedWorkOrders, userType, exportWorkOrders,
  search, filters, activeTab,
}) => {
  const [requestData, setRequestData] = useState({});

  useEffect(() => {
    const newRequestData = selectedWorkOrders.length
      ? { workOrdersId: selectedWorkOrders }
      : {
        ...search,
        filters: prepareFilters(filters),
      };
    setRequestData({ ...newRequestData, ...getStatus(activeTab) });
  }, [selectedWorkOrders, search, filters, activeTab]);

  return (
    <>
      <span>
        <MDBBtn
          className="cwo_butt"
          color="secondary"
          onClick={() => exportWorkOrders(requestData, userType)}
          style={{ height: 'calc(100% - 16px)' }}
        >
          Export Excel
        </MDBBtn>
      </span>
    </>
  );
};

const mapDispatchToProps = {
  exportWorkOrders,
};

const mapStateToProps = state => ({
  selectedWorkOrders: state.applicationState.selectedWorkOrders,
  activeTab: state.applicationState.activeTab,
  search: state.workOrder.searchData,
  filters: state.workOrder.filters,
});
export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderButtonControl);
