import React, { useEffect, useRef, useState } from 'react';
import {
  MDBBtn, MDBCol, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import Select from 'react-select';
import axios from 'axios';
import Loader from '../dashboard/common/Loader';
import Notification from '../notification';
import { toggleModal, toggleSecondModalClose } from '../../storage/actions/modalsActions';
import { white as customSelectStyle } from '../../utils/customSelectStyle';
import { fundsInputValueValidator } from '../../utils/validators';
import { localCurrencySettings } from '../../utils/constans';
import { addPaymentTransaction } from '../../storage/actions/paymentActions';

import onSubmit from '../../utils/reduxForm/onSubmit/addFundingSourceAndFundsToDwollaBalance';
import AddFundingSourceAndFundsToDwollaBalance from "../layout/reduxForm/AddFundingSourceAndFundsToDwollaBalance";
import ControlButtonsSection
  from "../layout/reduxForm/section/addFundingSourceAndFundsToDwollaBalance/ControlButtonsSection";

const AddFunds = ({
  modalState, toggleModal, user,
  addPaymentTransaction,
  toggleSecondModalClose,
}) => {
  const [addMethod, setAddMethod] = useState("card")
  const [saveCard, setSaveCard] = useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [amount, setAmount] = useState('');
  const [cardName, setCardName] = useState('');
  const [selectedCard, setSelectedCard] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [agreeToFee, setAgreeToFee] = useState(false);
  const [processingFee, setProcessingFee] = useState(0);

  const yearInput = useRef(null);

  const closeModal = () => {
    toggleModal(false, 'addFunds');
  };

  const getPaymentMethods = () => (
    axios.get('/api/payment/payment-methods')
      .then((res) => setPaymentMethods(res.data.paymentMethods
        .map((el) => ({
          label: `${el.brand} ending in: ${el.maskedCardNumber}`,
          value: el._id,
        }))))
      .catch(error => {
        Notification('error', error);
        closeModal();
      })
  );

  const getCompanyProcessingFee = () => (
    axios.get('/api/companies/processing-fee')
      .then(res => setProcessingFee(res.data?.payload?.processingFee))
      .catch(error => {
        Notification('error', error);
        closeModal();
      })
  );

  const fetchData = async () => {
    toggleModal(true, 'loader', true);
    await getPaymentMethods();
    await getCompanyProcessingFee();
    toggleSecondModalClose('loader', true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlerCardNumberInput = (event) => {
    const typedCardNumber = event.target.value;

    if (typedCardNumber === '') return setCardNumber('');

    if (typedCardNumber.slice(-1) !== ' '
        && !Number(typedCardNumber.slice(-1))
        && Number(typedCardNumber.slice(-1)) !== 0) {
      return setCardNumber(cardNumber);
    }

    if (
      typedCardNumber.length < cardNumber.length
      && (
        typedCardNumber.length === 5
        || typedCardNumber.length === 10
        || typedCardNumber.length === 15
      )
    ) {
      return setCardNumber(typedCardNumber.slice(0, -1));
    }

    if (
      typedCardNumber.length > cardNumber.length
      && (
        typedCardNumber.length === 4
        || typedCardNumber.length === 9
        || typedCardNumber.length === 14
      )
    ) {
      return setCardNumber(`${typedCardNumber} `);
    }

    return setCardNumber(typedCardNumber);
  };

  const handlerSetMonth = (event) => {
    const value = Number(event.target.value);
    if (event.target.value.length === 2 || value > 1) {
      if (value && value > 0) {
        if (value <= 12) {
          yearInput.current.focus();
          return setMonth(event.target.value);
        }
        return Notification('warning', { message: 'max value for month 12' });
      }
      setMonth('01');
      return Notification('warning', { message: 'min value for month 1 or 01' });
    }
    if (event.target.value === '00') {
      return Notification('warning', { message: 'min value for month 1 or 01' });
    }
    return setMonth(event.target.value);
  };

  const handlerSetYear = (event) => {
    const value = Number(event.target.value) || '';
    setYear(value);
  };

  const handlerAmount = (event) => {
    const typedAmount = fundsInputValueValidator(event.target.value);
    if (typedAmount.success) setAmount(typedAmount.value);
  };

  const handlerFeeAgreement = (event) => {
    setAgreeToFee(event.target.checked);
  };

  const submitPayment = (amountValue, id) => {
    const paymentMethod = id ? { id } : {
      cardNumber, month, year, cardName, saveCard,
    };

    addPaymentTransaction(amountValue, paymentMethod);
  };

  const handlerSubmit = (event) => {
    event.preventDefault();
    submitPayment(amount, selectedCard);
  };

  if (modalState.isOpen) {
    if (user) {
      return (
        <MDBModal
          className="applypop modal-width-500"
          isOpen={modalState.isOpen}
          toggle={closeModal}
          fullHeight
          position="right"
        >
          <MDBModalBody style={{ color: 'white' }}>
            <MDBRow className="h-paddings-half">
              <MDBCol md="10" className="flex-box flex-column">
                <h4>Add Funding Source</h4>
                {/*<select className="form-control no-border-radius" onChange={(e) => setAddMethod(e.target.value)}>*/}
                {/*  <option value="bank">Bank Account</option>*/}
                {/*  <option value="card">Card Number</option>*/}
                {/*</select>*/}
              </MDBCol>
              <MDBCol md="2" style={{textAlign: 'end'}}>
                <MDBIcon size="lg" far icon="times-circle" className="blue-grey-text" onClick={closeModal}/>
              </MDBCol>
            </MDBRow>
            {/*<hr className="hr border-white"/>*/}
{/*            {addMethod === "bank" && <>
              <MDBRow className="h-paddings-half">
                <MDBCol md="10" className="flex-box flex-column">
                  <AddFundingSourceAndFundsToDwollaBalance onSubmit={onSubmit} closeModal={closeModal}/>
                  <ControlButtonsSection  closeModal={closeModal} />
                </MDBCol>
              </MDBRow>
            </>}*/}
            {addMethod === "card" && <>
              <MDBRow className="h-paddings-half">
                <MDBCol md="10" className="flex-box flex-column">
                  <label>Use Existing Card</label>
                  <Select
                    isClearable
                    styles={customSelectStyle}
                    options={paymentMethods}
                    onChange={(e) => ((e) ? setSelectedCard(e.value) : setSelectedCard(null))}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6" style={{textAlign: 'center'}}>
                  <label>OR</label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6">
                  <label>Enter New Card Info</label>
                </MDBCol>
              </MDBRow>
              <MDBRow className="h-paddings-half">
                <MDBCol md="6">
                  <input
                    className="input-field"
                    style={{
                      width: 'inherit',
                    }}
                    type="string"
                    name="name"
                    disabled={!!selectedCard}
                    placeholder="Name On Card"
                    value={cardName}
                    maxLength={150}
                    onChange={(event) => setCardName(event.target.value)}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="h-paddings-half">
                <MDBCol md="6">
                  <input
                    className="input-field"
                    style={{
                      width: 'inherit',
                    }}
                    name="cardNumber"
                    disabled={!!selectedCard}
                    placeholder="Card Number"
                    maxLength="19"
                    onChange={handlerCardNumberInput}
                    value={cardNumber}
                  />
                </MDBCol>
                <MDBCol md="6">
                  <MDBRow>
                    <MDBCol md="8" style={{paddingLeft: 0}}>
                      <div
                        className="card-date-input flex-box"
                        style={selectedCard ? {backgroundColor: 'grey'} : {}}
                      >
                        <input
                          className="input-field"
                          name="cardLifeCycle"
                          placeholder="mm"
                          disabled={!!selectedCard}
                          style={{
                            width: '40px',
                            flexGrow: 2,
                          }}
                          maxLength="2"
                          onChange={handlerSetMonth}
                          value={month}
                        />
                        <div>/</div>
                        <input
                          className="input-field"
                          name="cardLifeCycle"
                          ref={yearInput}
                          placeholder="yy"
                          disabled={!!selectedCard}
                          style={{
                            width: '40px',
                            flexGrow: 2,
                          }}
                          maxLength="2"
                          pattern="^\d{2}$"
                          onChange={handlerSetYear}
                          value={year}
                        />
                        <MDBIcon
                          far
                          icon="calendar-alt"
                          size="lg"
                          style={{
                            flexGrow: 1,
                            cursor: 'none',
                          }}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow className="h-paddings-half">
                <MDBCol>
                  <MDBInput
                    filled
                    type="checkbox"
                    label="Save Card"
                    name="saveCard"
                    className="float-right"
                    id="saveCard"
                    disabled={!!selectedCard}
                    onChange={() => setSaveCard(!saveCard)}
                    checked={saveCard}
                    labelClass="font11 color-white"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="h-paddings-half">
                <MDBCol md="8">
                  <MDBRow className="h-paddings-half">
                    <MDBCol className="woddiv flex-grow-0">
                      <label className="text-in-one-line">Payment amount</label>
                    </MDBCol>
                    <MDBCol>
                      <input
                        className="input-field width-inherit"
                        style={{textAlign: 'right'}}
                        name="amount"
                        placeholder="$ 0.00"
                        value={amount}
                        onChange={handlerAmount}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="h-paddings-half">
                    <MDBCol>
                      <label className="text-in-one-line">Service Processing Fee:</label>
                    </MDBCol>
                    <MDBCol style={{textAlign: 'right'}}>
                      {/* eslint-disable-next-line max-len */}
                      {(amount ? ((amount * processingFee)) : 0)
                        .toLocaleString('en-US', localCurrencySettings)}
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="8">
                  <MDBRow className="no-margins">
                    <MDBInput
                      filled
                      className="float-right"
                      label="Click here to agree to processing fees"
                      type="checkbox"
                      id="agreeToFee"
                      name="agreeToFee"
                      onChange={handlerFeeAgreement}
                      checked={agreeToFee}
                      labelClass="font11 color-white text-in-one-line"
                    />
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBRow>
                    <MDBCol>
                      <div
                        style={{
                          textAlign: 'center',
                          background: '#ffff32',
                          color: '#333333',
                        }}
                      >
                      <span>
                        FUNDS AVAILABILITY:  4-5 Business Days
                      </span>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="8">
                  <MDBRow className="font-weight-bold h-paddings-half">
                    <MDBCol className="text-in-one-line">
                      Total:
                    </MDBCol>
                    <MDBCol
                      style={{
                        textAlign: 'right',
                        paddingTop: '0.1rem',
                        paddingBottom: '0.1rem',
                      }}
                    >
                      {/* eslint-disable-next-line max-len */}
                      {(amount ? (amount * 1 + (amount * processingFee)) : 0)
                        .toLocaleString('en-US', localCurrencySettings)}
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol
                  md="4"
                  className="flex-box h-paddings-half"
                  style={{
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                  }}
                >
                  <MDBBtn
                    disabled={!agreeToFee || !amount}
                    className="no-margins"
                    style={{
                      padding: '0.3rem 0.5rem',
                      color: '#333333',
                      backgroundColor: '#00ff54',
                    }}
                    color="false"
                    onClick={handlerSubmit}
                  >
                    <MDBIcon far className="vwimg" icon="credit-card"/>
                    Submit
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </>}
          </MDBModalBody>
        </MDBModal>
      );
    }
    return (<Loader />);
  }
  return null;
};

const mapDispatchToProps = {
  toggleModal,
  toggleSecondModalClose,
  addPaymentTransaction,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  modalState: state.modalState.addFunds,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFunds);
