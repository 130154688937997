import React, { useMemo } from 'react';
import { MDBCol, MDBContainer, MDBInput, MDBRow } from "mdbreact";
import Select from "react-select";
import { white as customSelectStyle } from "../../../utils/customSelectStyle";

const SelectMessageRecipients = ({
    companyMembers,
    activeTab,
    selectedUsers,
    setSelectedUsers,
    isForContractor,
    setIsNeedSendToContractor
}) => {
    const handlerOnChange = ({ value, name }) => {
        switch (name) {
            case 'selectedCompanyUsers': setSelectedUsers(value || []); break;
            case 'isNeedSendToContractor': setIsNeedSendToContractor(value); break;
            // case 'selectedApplicants': setSelectedApplicants(value || []); break;
        }
    }

    const tabName = useMemo(() => {
        return activeTab === 'assigned' || activeTab === 'completed' ? 'Assigned' : 'Applied/Routed'
    }, [activeTab])

    return (
        <MDBContainer>
            <MDBRow>
                <MDBCol>
                    <label
                        style={{
                            fontSize: '1.3rem',
                            color: '#ffda6d'
                        }}
                    >
                        Send Message To
                    </label>
                </MDBCol>
            </MDBRow>

            <MDBRow>
                <MDBCol>
                    <label>
                        {tabName + ' Contractor\'s'}
                    </label>
                </MDBCol>
            </MDBRow>

            <MDBRow>
                <MDBCol className='flex-box'>
                    <label
                        className='flex-box flex-align-center'
                        style={{
                            fontSize: '0.85rem',
                            whiteSpace: 'nowrap',
                            margin: '0 0.5rem',
                        }}
                    >
                        Company User(s):
                    </label>
                    <Select
                        isClearable
                        isSearchable
                        isMulti
                        closeMenuOnSelect={false}
                        options={companyMembers}
                        value={selectedUsers}
                        styles={customSelectStyle}
                        onChange={value => handlerOnChange({ name: 'selectedCompanyUsers', value })}
                    />
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default SelectMessageRecipients;
