import React, { useEffect, useState } from 'react';
import {
  MDBBtn, MDBCol, MDBContainer, MDBRow,
} from 'mdbreact';
import axios from 'axios';
import CetCertificatesForContractor from '../../components/layout/certificates/CetCertificatesForContractor';
import SelectContractor from '../../components/layout/SelectContractor';
import Notification from '../../components/notification';
import FileUpload from "../../components/forms/file-upload/FileUpload";

const CertificatesContractors = () => {
  const [contractors, setContractors] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [selectedContractor, setSelectedContractor] = useState('');
  const [selectedCertificates, setSelectedCertificates] = useState(new Set());
  const [contractorCertificates, setContractorCertificates] = useState([]);
  const [cards, setCards] = useState([])

  useEffect(() => {
    fetchContractors();
    fetchCertificates();
  }, []);

  useEffect(() => {
    if(!selectedContractor || !selectedContractor.value || !selectedContractor.value.cards)
      return setCards([]);

    setCards([... selectedContractor.value.cards])
  }, [selectedContractor?.value])

  const fetchContractors = () => {
    axios.get('/api/users/contractors')
      .then(res => {
        const newContractorsList = res.data.contractors || [];
        setContractors(newContractorsList.map(contractor => (
          {
            value: contractor,
            label: `${contractor.name} - ${contractor.unique_id} - ${contractor.address || '!?ADDRESS!?'}, ${contractor.city?.label || '!?CITY!?'}, ${contractor.state?.label || '!?STATE!?'} ${contractor.zipcode || '!?ZIPCODE!?'}`,
          }
        )));
      }).catch(error => {
        Notification('error', {
          message: error.response?.data?.message || error.message,
        });
      });
  };

  const fetchCertificates = () => {
    axios.get('/api/certificates')
      .then(res => {
        setCertificates(res.data.certificates || []);
      }).catch(error => {
        Notification('error', {
          message: error.response?.data?.message || error.message,
        });
      });
  };

  const handlerCertificatesCheckboxChange = ({ target }) => {
    const newSet = new Set([...selectedCertificates]);
    const command = target.checked ? 'add' : 'delete';
    if (!target.checked && contractorCertificates[target.value]) handlerDate(target.value, '');
    newSet[command](target.value);
    setSelectedCertificates(newSet);
  };

  const handlerContractorSelect = (item) => {
    setSelectedContractor(item);
    if (item) {
      setSelectedCertificates(new Set(
        item.value.certificates.map(item => item.certificate_type_id),
      ));
      const certif = {};
      // eslint-disable-next-line no-return-assign
      item.value.certificates.forEach(el => (
        certif[el.certificate_type_id] = {
          ...el,
          passed_at: el.passed_at ? new Date(el.passed_at) : '',
          expires_at: el.expires_at ? new Date(el.expires_at) : '',
        }
      ));
      setContractorCertificates(certif);
    }
  };

  const handlerDate = (key, date) => {
    const newContractorCertificates = { ...contractorCertificates };
    if (!selectedCertificates.has(key)) {
      handlerCertificatesCheckboxChange({ target: { value: key, checked: true } });
    }
    newContractorCertificates[key] = {
      ...(newContractorCertificates[key] || {}),
      passed_at: date,
    };
    setContractorCertificates(newContractorCertificates);
  };
  const handlerExpiresDate = (key, date) => {
    const newContractorCertificates = { ...contractorCertificates };
    if (!selectedCertificates.has(key)) {
      handlerCertificatesCheckboxChange({ target: { value: key, checked: true } });
    }
    newContractorCertificates[key] = {
      ...(newContractorCertificates[key] || {}),
      expires_at: date,
    };
    setContractorCertificates(newContractorCertificates);
  };
  const saveContractorCertificates = () => {
    const saved = [], uploaded = [];
    for (const file of cards) {
      if(typeof file === "string") saved.push(file);
      else uploaded.push(file)
    }
    const data = {
      contractorId: selectedContractor.value.userId,
      selectedCertificates: [...selectedCertificates],
      contractorCertificates,
    };

    const fd = new FormData()

    fd.append("payload", JSON.stringify(data))
    uploaded.forEach(file => fd.append('files[]', file, file.name));
    saved.forEach(saved => fd.append('saved[]', saved))

    axios.post('/api/admin/contractor-certificates', fd)
      .then(res => {
        const newCertificatesList = res.data.certificates || [];
        setContractors(contractors.map(contractor => {
          if (contractor.value.userId !== selectedContractor.value.userId) return contractor;
          const newContractorInfo = {
            ...contractor,
            value: {
              ...contractor.value,
              cards: cards,
              certificates: newCertificatesList,
            },
          };
          handlerContractorSelect(newContractorInfo);
          return newContractorInfo;
        }));
        Notification('success', {
          message: res.data.message,
        });
      })
      .catch(error => {
        Notification('error', {
          message: error.response?.data?.message || error.message,
        });
      })
  };

  return (
    <MDBContainer fluid className="clientdascontentmaindiv">
      <MDBRow>
        <MDBCol md="5">
          <SelectContractor
            selectedContractor={selectedContractor}
            getSelectedContractor={handlerContractorSelect}
            placeholder="Enter 3 or more letters to search contractors!"
          />
        </MDBCol>
      </MDBRow>
      {
        selectedContractor
        && (
          <div className="w-50">
            <CetCertificatesForContractor
              checkedList={selectedCertificates}
              certificates={certificates}
              handlerCheck={handlerCertificatesCheckboxChange}
              contractorCertificates={contractorCertificates}
              handlerDate={handlerDate}
              handlerExpiresDate={handlerExpiresDate}
            />
            <MDBRow className="mt-5">
              <MDBCol>
                <FileUpload values={cards} onChange={setCards}/>
              </MDBCol>
            </MDBRow>
            <MDBRow className="justify-content-end">
              <MDBCol style={{ flexGrow: 0 }}>
                <MDBBtn onClick={saveContractorCertificates}>
                  Save
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </div>
        )
      }
    </MDBContainer>
  );
};

export default CertificatesContractors;