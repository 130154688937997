import React, { useState } from 'react';
import Header from './NavHeader';
import Sidebar from './NavSidebar';

const ControlPanel = () => {
  const [isSidebarOpen, changeSidebarShowState] = useState(false);
  return (
    <>
      <Header openSidebar={() => changeSidebarShowState(!isSidebarOpen)} />
      <Sidebar isOpen={isSidebarOpen} changeSidebarShowState={changeSidebarShowState} />
    </>
  );
};

export default ControlPanel;
