import React, { useEffect, useState } from 'react';
import { MDBContainer } from 'mdbreact';
import { connect } from 'react-redux';
import getCompaniesList from '../../utils/api/get/getCompaniesList';
import { toggleModal } from '../../storage/actions/modalsActions';
import Tablet from '../layout/tablet';
import { datatableManagerCompaniesColumnsForSuperAdmin as columns } from '../../utils/constans';
import CompanyActions from './CompanyActions';

const Content = ({
  isReload,
  toggleModal,
}) => {
  const [companies, setCompanies] = useState([]);
  const [sortBy, setSortBy] = useState({
    value: 'name',
    direction: 1,
  });

  const fetchCompaniesList = async () => {
    toggleModal(true, 'loader');
    const newCompaniesList = await getCompaniesList(sortBy);
    setCompanies(newCompaniesList);
    toggleModal(false, 'loader');
  };

  useEffect(() => {
    fetchCompaniesList();
  }, [isReload, sortBy]);

  const handlerSort = item => {
    if (item.value === sortBy.value) {
      return setSortBy({
        ...sortBy,
        direction: sortBy.direction * -1,
      });
    }
    return setSortBy({
      value: item.value,
      direction: 1,
    });
  };

  return (
    <MDBContainer fluid>
      <Tablet
        className="color-white"
        columns={columns}
        rows={companies}
        enableActionsCol
        sortBy={sortBy}
        handlerSort={handlerSort}
        ActionElement={CompanyActions}
        placeholder="Companies not found"
      />
    </MDBContainer>
  );
};

const mapDispatchToProps = {
  toggleModal,
};

const mapStateToProps = state => ({
  isReload: state.applicationState.isReload,
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
