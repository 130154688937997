import React from 'react';
import {
  MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import { toggleModal } from '../../storage/actions/modalsActions';
import VerifyFundingSourceReduxForm from '../layout/reduxForm/VerifyFundingSource';
import ControlButtonsSection from '../layout/reduxForm/section/verifyFundingSource/ControlButtonsSection';
import onSubmit from '../../utils/reduxForm/onSubmit/verifyFundingSource';

const VerifyFundingSource = ({
  toggleModal,
  modalState,
}) => {
  const closeModal = () => toggleModal(false, 'verifyFundingSource');

  return (
    <MDBModal
      isOpen={modalState.isOpen}
      toggle={closeModal}
      centered
    >
      <MDBModalBody
        style={{
          fontFamily: 'Arial, Helvetica, sans-serif',
          backgroundColor: 'white',
        }}
      >
        <MDBRow
          style={{
            justifyContent: 'flex-end',
            paddingRight: '10px',
          }}
        >
          <MDBIcon
            far
            icon="times-circle"
            onClick={closeModal}
            size="lg"
            className="blue-grey-text"
          />
        </MDBRow>
        <MDBRow style={{ justifyContent: 'center' }}>
          <h5
            style={{ color: 'black' }}
          >
            Verify Funding Source
          </h5>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <p className="no-margins">
              {modalState.type.fundingSource.name}
            </p>
            <p className="no-margins">
              {`Routing: ${modalState.type.fundingSource.routing}`}
            </p>
            <p className="no-margins">
              {`Account: ${modalState.type.fundingSource.account}`}
            </p>
          </MDBCol>
        </MDBRow>
        <VerifyFundingSourceReduxForm
          onSubmit={onSubmit}
          initialValues={{
            fundingSourceId: modalState.type.fundingSource.value,
            amount1: 0,
            amount2: 0,
          }}
        />
        <ControlButtonsSection closeModal={closeModal} />
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
};

const mapStateToProps = state => ({
  modalState: state.modalState.verifyFundingSource,
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyFundingSource);
