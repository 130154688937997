import React, { useState } from 'react';
import {
  MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import { toggleSecondModalClose } from '../../storage/actions/modalsActions';
import Loader from '../dashboard/common/Loader';

const ExplanationDeactivationModal = ({
  toggleSecondModalClose,
  returnExplanation,
  title,
  modalState,
  isCloseAvailable = false,
}) => {
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  const onSubmit = () => {
    returnExplanation(message);
    setMessage('');
    setShowLoader(true);
  };

  const onToggle = () => {
    if (isCloseAvailable && !showLoader) toggleSecondModalClose('explanationDeactivationModal', true);
  };

  return (
    <MDBModal
      isOpen={modalState.isOpen}
      toggle={onToggle}
      centered
    >
      <MDBModalBody style={{ fontFamily: 'Arial, Helvetica, sans-serif', backgroundColor: 'white' }}>
        {showLoader
          ? (
            <div className="flex-box flex-center" style={{ color: 'black' }}>
              <Loader color="black" />
            </div>
          )
          : (
            <>
              {isCloseAvailable && (
                <MDBRow style={{ justifyContent: 'flex-end', paddingRight: '10px' }}>
                  <MDBIcon
                    far
                    icon="times-circle"
                    onClick={() => {
                      toggleSecondModalClose('explanationDeactivationModal', true);
                    }}
                    size="lg"
                    className="blue-grey-text"
                  />
                </MDBRow>
              )}
              <div className="buttondivsignin">
                <MDBRow style={{ justifyContent: 'center' }}>
                  <h5
                    style={{ color: 'black' }}
                  >
                    {title}
                  </h5>
                </MDBRow>
                <MDBCol md="12">
                  <div className="form-group signinput">
                    <label htmlFor="reason_to_deactivate" className="font12">
                      Deactivating your account will prevent you from receiving work.
                    </label>
                    <textarea
                      placeholder="Reason (optional)"
                      className="form-control"
                      id="reason_to_deactivate"
                      value={message}
                      onChange={({ target }) => setMessage(target.value)}
                      required
                    />
                  </div>
                </MDBCol>
                <MDBRow style={{ justifyContent: 'center' }}>
                  <MDBBtn
                    color="info"
                    className="signbutton"
                    onClick={onSubmit}
                  >
                    Submit
                  </MDBBtn>
                </MDBRow>
              </div>
            </>
          )}
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = state => ({
  modalState: state.modalState.explanationDeactivationModal,
});

export default connect(mapStateToProps, { toggleSecondModalClose })(ExplanationDeactivationModal);
