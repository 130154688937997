import React from "react";
import {MDBCol} from "mdbreact";
import {errorDescription} from '../../../utils/constans';

const ErrorDescription = ({error}) => {
    const message = (errorDescription[error.reqType] && errorDescription[error.reqType][error.message])
        || error.message;
    return <MDBCol md="12">
        <div className="form-group signinput">
            <label htmlFor="formGroupExampleInput" className="font12">
                {message}
            </label>
        </div>
    </MDBCol>
}

export default ErrorDescription;