import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  MDBCol, MDBContainer, MDBLink, MDBRow,
} from 'mdbreact';
// eslint-disable-next-line import/no-cycle
import Modal from '../../../components/modal';
import getCertificateInfo from '../../../utils/api/get/getCertificateInfo';
import { toggleModal } from '../../../storage/actions/modalsActions';

const CertificationTestVideo = ({ mainContainer, toggleModal }) => {
  const { id: certificateId } = useParams();
  const [certificate, setCertificate] = useState({});

  useEffect(() => {
    fetchCertificateData();
  }, []);

  const fetchCertificateData = async () => {
    toggleModal(true, 'loader');

    const newCertificate = await getCertificateInfo(certificateId);
    setCertificate(newCertificate);

    toggleModal(false, 'loader');
  };

  return (
    <MDBContainer fluid>
      {
        certificate?.info?.video
          ? (
            <>
              <MDBRow>
                <MDBCol
                  className="text-align-center text-white"
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                >
                  <h6>{certificate?.info.label}</h6>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className="flex-box justify-content-center">
                  <iframe
                    src={`${certificate.info.video}?modestbranding=1&rel=0&iv_load_policy=3&theme=light&color=white`}
                    title="Certifications video"
                    width="640"
                    height="360"
                    frameBorder="0"
                    allowFullScreen
                  />
                </MDBCol>
              </MDBRow>
            </>
          )
          : <></>
        }
      <MDBRow
        className="flex-box justify-content-center"
        style={{
          margin: '4rem',
        }}
      >
        {
          certificate?.info?.video
            ? (
              <MDBLink
                className="inline-block btn btn-primary"
                to={`/certifications/${certificateId}/tests`}
              >
                Take test
              </MDBLink>
            )
            : <></>
        }

        <MDBLink className="inline-block btn btn-primary" to="/certifications">
          Back to certificates
        </MDBLink>
      </MDBRow>

      <Modal mainContainer={mainContainer.current} />
    </MDBContainer>
  );
};

const mapDispatchToProps = {
  toggleModal,
};

const mapStateToProps = state => ({
  profile: state.profile,
  isReload: state.applicationState.isReload,
});

export default connect(mapStateToProps, mapDispatchToProps)(CertificationTestVideo);
