import React from 'react';
import {
  MDBIcon, MDBLink, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import { connect } from 'react-redux';
import { toggleModal } from '../../../storage/actions/modalsActions';
import LoginReduxForm from '../../layout/reduxForm/LoginReduxForm';
import handleLogin from '../../../utils/reduxForm/onSubmit/login';


const LoginModal = ({
  modalState, toggleModal
}) => {
  const closeModal = () => {
    toggleModal(false, 'loginModal');
  };

  const initialValues = modalState?.type?.email ? { email: modalState.type.email } : { email: '' };

  return (
    <MDBModal isOpen={modalState.isOpen} toggle={closeModal} centered className="signindiv">
      <MDBRow style={{ justifyContent: 'flex-end', paddingRight: '10px' }}>
        <MDBIcon far icon="times-circle" onClick={closeModal} size="lg" />
      </MDBRow>
      <MDBModalBody style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
        <LoginReduxForm onSubmit={handleLogin} initialValues={initialValues} />
        <MDBRow
          className="font12"
          style={{
            justifyContent: 'center',
            marginBottom: '0px',
          }}
        >
          <MDBLink
            to="#"
            onClick={() => toggleModal(true, 'forgotPasswordModal')}
            style={{
              cursor: 'pointer',
              fontSize: '12px',
              textDecoration: 'none',
              color: 'black',
            }}
          >
            Forgot password ?
          </MDBLink>
          <MDBLink
            to="#"
            onClick={() => toggleModal(true, 'verifyAccountModal')}
            style={{
              cursor: 'pointer',
              marginLeft: '0.5rem',
              fontSize: '12px',
              textDecoration: 'none',
              color: 'black',
            }}
          >
            Resend link for verify?
          </MDBLink>
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = (state) => ({
  modalState: state.modalState.loginModal,
});

export default connect(mapStateToProps, {
  toggleModal,
})(LoginModal);
