import React, { useState } from 'react';
import { MDBTable } from 'mdbreact';
import Headers from './headers';
import Body from './body';
import Footer from './footer';

const Tablet = ({
  className,
  columns,
  handlerSort = () => {},
  sortBy,
  rows,
  ActionElement,
  enableActionsCol,
  placeholder,
  enablePagination,
  entriesOptions = [5, 20, 25],
  entries,
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [perPage, setPerPage] = useState(entries || 5);

  return (
    <>
      <MDBTable className={className}>
        <Headers
          columns={columns}
          handlerSort={handlerSort}
          enableActionsCol={enableActionsCol}
          sortBy={sortBy}
        />
        <Body
          rows={rows}
          columns={columns}
          ActionElement={ActionElement}
          enableActionsCol={enableActionsCol}
          placeholder={placeholder}
          enablePagination={enablePagination}
          perPage={perPage}
          pageNumber={pageNumber}
        />
      </MDBTable>
      <Footer
        enablePagination={enablePagination}
        rowsCount={rows.length}
        entriesOptions={entriesOptions}
        perPage={perPage}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        setPerPage={setPerPage}
      />
    </>
  );
};

export default Tablet;
