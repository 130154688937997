import React from 'react';
import { connect } from 'react-redux';
import './setupAxios';
import './utils/api';
import checkAndroidVersion from './utils/checkAndroidVersion';
import { setCurrentUser, init } from './storage/actions/authActions';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/layout/Footer';
import './components/dashboard/css/Snehalcss.css';
import './components/dashboard/css/Responsive.css';
import './components/dashboard/css/Stylecommoncss.css';
import Pages from './pages';
import Spiner from './components/dashboard/common/Loader';
import Notification from './components/notification';
import NotSupportedAndroidVersion from './pages/NotSupportedAndroidVersion';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      isLowerAndroidVersion: false,
    };
  }

  componentDidMount() {
    this.props.init();
    this.setState({ isLowerAndroidVersion: checkAndroidVersion() });
  }

  render() {
    if (this.state.isLowerAndroidVersion) {
      return (<NotSupportedAndroidVersion />);
    }

    return (
      <div className="App">
        <Notification />
        {
          this.props.auth.init
            ? (
              <>
                <Pages />
                <Footer />
              </>
            )
            : <Spiner />
        }
      </div>
    );
  }
}

const mapDispatchToProps = {
  setCurrentUser, init,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
