import React from 'react';
import { toast, ToastContainer, MDBContainer } from 'mdbreact';

import Elements from './elements';

const Notification = (type, event) => {
  switch (type) {
    case 'info':
      toast.info(<Elements event={event} />, {
        closeButton: false,
      });
      break;
    case 'success':
      toast.success(event.message, {
        closeButton: false,
      });
      break;
    case 'warning':
      toast.warn(event.message, {
        closeButton: false,
      });
      break;
    case 'error':
      toast.error(<Elements event={event} />, {
        closeButton: false,
      });
      break;
    default:
  }

  return (
    <MDBContainer>
      <ToastContainer
        hideProgressBar
        newestOnTop
        autoClose={10000}
        style={{
          zIndex: 9999999,
        }}
      />
    </MDBContainer>
  );
};

export default Notification;
