import styled from 'styled-components';
import {createTheme} from "react-data-table-component";

createTheme('sourcew9', {
  text: {
    primary: '#ffffff',
    secondary: '#fffffff0',
  },
  background: {
    default: '#00000000',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');

export const TableWrapper = styled.div`
  padding-top: 10px;
  
  [role=table] [role=rowgroup]:first-child {
    background: #45526e;
    font-size: .9rem;
    
    [role=row] > div {
      padding: 20px;
      justify-content: space-between;
    }
  }
`

export const FiltersWrapper = styled.div`
  margin: 15px 0;
  position: relative;
`