import React from 'react';
import NearByCard from '../../layout/contractorCards/NearByCard';

const UserList = ({
  users,
  handler,
  profileInfo,
  blocked,
  invitedContractors,
  selectedContractors,
  handlerCheckbox,
}) => (
  <div>
    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '15px' }}>
      <div className="font20" style={{ width: '50%' }}>Contractor</div>
      <div className="font20" style={{ width: '35%', textAlign: 'right' }}>Work Performed</div>
      <div className="font20" style={{ textAlign: 'right' }}>Distance</div>
    </div>
    {
      users.map(user => (
        <NearByCard
          key={user._id}
          user={user}
          handler={handler}
          blocked={blocked}
          invitedContractors={invitedContractors}
          profileInfo={profileInfo}
          handlerCheckbox={handlerCheckbox}
          selectedContractors={selectedContractors}
        />
      ))
    }
  </div>
);

export default UserList;
