import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import ApplyRow from './ApplyRow';

const PreviousApplicantsHistory = ({
  applicants, inviteContractor, currentWorkOrder, blockedContractors,
  showContractorProfile,
}) => (
  <MDBRow>
    <MDBCol>
      <h6 style={{ fontWeight: 'bold' }}>
        Previous Applicants
      </h6>
      <div>
        <MDBRow style={{ marginBottom: '15px' }} />
        <MDBRow>
          <MDBCol>
            <table className="full-width">
              <tbody>
                {
                  applicants.map(applyInfo => (
                    <ApplyRow
                      key={applyInfo._id}
                      applyInfo={applyInfo}
                      invite={
                        () => inviteContractor({
                          workOrdersId: [currentWorkOrder._id],
                          contractorId: applyInfo.contractor_id
                        })
                      }
                      baseType={currentWorkOrder.basicType}
                      variableType={currentWorkOrder.variableType}
                      blocked={blockedContractors.some(id => id === applyInfo.contractor_id)}
                      showContractorProfile={showContractorProfile}
                    />
                  ))
                }
              </tbody>
            </table>
          </MDBCol>
        </MDBRow>
      </div>
    </MDBCol>
  </MDBRow>
);

export default PreviousApplicantsHistory;
