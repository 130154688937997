import React from 'react';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import DatePicker from 'react-datepicker/es';

import CustomTimePicker from '../../../layout/CustomTimePicker';
import Notification from '../../../notification';

import combineDateAndTime from '../../../../utils/addTimeToDatefromAM_PMFormatString';
import formatAMPM from '../../../../utils/formatAMPM';

class AddCheckInCheckOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: formatAMPM(new Date()),
      date: new Date(),
      time: formatAMPM(new Date()),
      description: '',
    };
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!prevProps.showCheckField && this.props.showCheckField) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        startTime: formatAMPM(new Date()),
        date: new Date(),
        time: formatAMPM(new Date()),
        description: '',
      });
    }
  }

  handlerSubmitButton = async (event) => {
    event.preventDefault();
    const { date, time, description } = this.state;
    const { showCheckIn, currentWorkOrder, checkInCheckOutToWorkOrder } = this.props;
    if (!date || !time || (!description.trim() && !showCheckIn)) {
      Notification('warning', { message: 'Please Enter Description of Work Performed!' });
      return;
    }
    const sendData = {
      date: await combineDateAndTime(date, time),
    };
    if (!showCheckIn) {
      if (
        new Date(sendData.date) < new Date(currentWorkOrder?.checkInOutDetails[0]?.check_in_date)
      ) {
        // eslint-disable-next-line consistent-return
        return Notification('warning', { message: 'Check-out time must be later than check-in time' });
      }
      sendData.description = description || '';
    }
    const result = await checkInCheckOutToWorkOrder(
      currentWorkOrder._id,
      !showCheckIn && currentWorkOrder.checkInOutDetails[0]?._id,
      sendData,
    );
    if (result) {
      this.setState((prevState) => ({
        ...prevState,
        date: '',
        time: '',
        description: '',
      }));
    }
  }

  handlerChange = (date, name) => {
    this.setState({
      [name]: date,
    });
  };

  render() {
    const { showCheckIn, currentWorkOrder, showCheckField } = this.props;
    const {
      date, description, time, startTime,
    } = this.state;
    return (
      <form onSubmit={this.handlerSubmitButton}>
        {currentWorkOrder.onHold && (
          <h6 className="font14">
            <i>Work order is On Hold</i>
          </h6>
        )}
        {showCheckField && (
          <MDBRow>
            <MDBCol>
              <MDBRow style={{ marginBottom: '15px' }}>
                <MDBCol md="4">
                  <label className="font11">
                    {`Check ${showCheckIn ? 'In' : 'Out'} Date`}
                  </label>
                  <DatePicker
                    className="form-control coutd"
                    dateFormat="MM/dd/yy"
                    name="date"
                    selected={date}
                    value={date}
                    onChange={(value) => {
                      this.handlerChange(value, 'date');
                    }}
                  />
                </MDBCol>
                <MDBCol md="4">
                  <label className="font11">
                    {`Check ${showCheckIn ? 'In' : 'Out'} Time`}
                  </label>
                  <CustomTimePicker
                    className="cint"
                    id="timePicker"
                    hours={startTime.slice(0, startTime.indexOf(':'))}
                    minutes={startTime.slice(startTime.indexOf(':') + 1, -2)}
                    dayTime={startTime.slice(-2)}
                    name="time"
                    hoursFormat={12}
                    getValue={(value) => {
                      this.handlerChange(value, 'time');
                    }}
                    value={time}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow style={{ marginBottom: '15px' }}>
                <MDBCol>
                  {!showCheckIn && (
                    <>
                      <MDBRow style={{ marginBottom: '15px' }}>
                        <MDBCol>
                          <label className="font11">Description of Work Performed</label>
                          <textarea
                            type="text"
                            className="form-control descheck font11"
                            placeholder="Description/Remark"
                            name="description"
                            value={description}
                            onChange={({ target }) => {
                              this.handlerChange(target.value, 'description');
                            }}
                          />
                        </MDBCol>
                      </MDBRow>
                    </>
                  )}
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol
              md="2"
              className="flex-center"
              style={{ height: 'auto' }}
            >
              <MDBBtn
                type="submit"
                color="info"
                className="check_inbutton"
                disabled={currentWorkOrder.onHold}
              >
                Save
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        )}
      </form>
    );
  }
}

export default AddCheckInCheckOut;
