import React from 'react';
import {
  MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import { connect } from 'react-redux';
import {
  toggleModal,
  toggleSecondModalClose,
} from '../../storage/actions/modalsActions';
import { reloadData } from '../../storage/actions/applicationStateActions';
import AddOrEditUsersForManagementReduxForm from '../layout/reduxForm/AddOrEditUsersForManagementReduxForm';
import initialValues from '../../utils/reduxForm/InitialValues/addOrEditUsersForUserManagement';
import handleSubmit from '../../utils/reduxForm/onSubmit/addOrEditUsersForUserManagement';

const AddOrEditUsersForManagement = ({
  modalState,
  toggleModal,
}) => {
  const closeModal = () => {
    toggleModal(false, 'addOrEditUsersForManagement');
  };

  return (
    <MDBModal
      isOpen={modalState.isOpen}
      toggle={closeModal}
      fullHeight
      position="right"
      className="applypop color-white"
    >
      <MDBModalBody>
        <MDBRow style={{ paddingBottom: '0px' }}>
          <MDBCol md="6">
            <h6 style={{ fontWeight: 'bold', marginBottom: '15px' }}>
              {modalState.type.id ? 'Edit user' : 'Add user'}
            </h6>
          </MDBCol>
          <MDBCol md="6" style={{ textAlign: 'end' }}>
            <MDBIcon
              icon="times-circle"
              size="lg"
              style={{ color: '#41566a', cursor: 'pointer' }}
              onClick={closeModal}
            />
          </MDBCol>
        </MDBRow>
        <AddOrEditUsersForManagementReduxForm
          initialValues={
            modalState.type.id ? modalState.type : initialValues
          }
          onSubmit={handleSubmit}
          closeModal={closeModal}
        />
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = state => ({
  modalState: state.modalState.addOrEditUsersForManagement,
});

export default connect(mapStateToProps, {
  toggleModal,
  reloadData,
  toggleSecondModalClose,
})(AddOrEditUsersForManagement);
