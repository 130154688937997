import React, { useEffect, useState } from 'react';
import {
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBRow,
} from 'mdbreact';
import { connect } from 'react-redux';
import { toggleModal, toggleSecondModalClose } from '../../storage/actions/modalsActions';
import AddOrEditTeamsReduxForm from '../layout/reduxForm/AddOrEditTeamReduxFrom';
import getClients from '../../utils/api/get/getClients';
import getTeam from '../../utils/api/get/getTeam';
import { reloadData } from '../../storage/actions/applicationStateActions';
import addOrEditTeam from '../../utils/reduxForm/onSubmit/addOrEditTeam';
import emptyValues from '../../utils/reduxForm/InitialValues/addTeam';

const AddOrEditTeams = ({ modalState, toggleModal, toggleSecondModalClose }) => {
  const { id } = modalState.type;

  const [clients, setClients] = useState([]);
  const [teamUsers, setTeamUsers] = useState([]);

  useEffect(() => {
    fetchClients();
    if (id) fetchTeamUsers();
  }, []);

  const fetchTeamUsers = async () => {
    toggleModal(true, 'loader', true);

    const res = await getTeam(id);

    setTeamUsers({
      _id: res._id,
      name: res.name,
      members: res.teamUsers.map(cur => cur.userId),
    });
    toggleSecondModalClose('loader', true);
  };

  const fetchClients = async () => {
    toggleModal(true, 'loader', true);

    const res = await getClients();
    setClients(res);

    toggleSecondModalClose('loader', true);
  };

  const closeModal = () => {
    toggleModal(!modalState.isOpen, 'addOrEditTeams', false);
  };

  return (
    <MDBModal
      className="applypop"
      isOpen={modalState.isOpen}
      toggle={closeModal}
      fullHeight
      position="right"
    >
      <MDBModalBody style={{ color: 'white' }}>
        <MDBRow className="mb-2">
          <MDBCol md="10">
            <h5 style={{ fontWeight: 'bold' }} className="">
              {`${id ? 'Edit' : 'Add'} team`}
            </h5>
          </MDBCol>
          <MDBCol md="2" style={{ textAlign: 'end' }}>
            <MDBIcon
              far
              icon="times-circle"
              onClick={closeModal}
              size="lg"
              className="blue-grey-text"
            />
          </MDBCol>
        </MDBRow>
        <AddOrEditTeamsReduxForm
          users={clients}
          initialValues={id ? teamUsers : emptyValues}
          closeModal={closeModal}
          onSubmit={addOrEditTeam}
        />
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = state => ({
  modalState: state.modalState.addOrEditTeams,
  isReload: state.applicationState.isReload,
});

export default connect(mapStateToProps, {
  toggleModal,
  reloadData,
  toggleSecondModalClose,
})(AddOrEditTeams);
