import React, { useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
} from 'mdbreact';
import { toggleModal } from '../../../storage/actions/modalsActions';
import {
  getWorkOrdersForBulk,
  bulkApproveWorkOrders,
} from '../../../storage/actions/workOrdersActions';
import { getAccountBalanceByClient } from '../../../storage/actions/profile';
import Loader from '../../dashboard/common/Loader';
import prepareFilters from '../../../utils/prepareFilters';
import Content from './content';

const BulkWorkOrderApproveModal = ({
  modalState,
  balance,
  selectedWorkOrders,
  toggleModal,
  bulkApproveWorkOrders,
  getWorkOrdersForBulk,
  getAccountBalanceByClient,
  filters,
  search,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [contentType, setContentType] = useState('');
  const [workOrdersInfo, setWorkOrdersInfo] = useState([]);
  const [fundsInfo, setFundsInfo] = useState({
    holdFunds: 0,
    fundsNeededFromWallet: 0,
    expensesNeededFromWallet: 0,
    expenses: 0,
    fundsNeeded: 0,
    fundsExpected: 0,
  });
  const [requestData, setRequestData] = useState({});

  const initRequestData = () => {
    const newRequestData = modalState?.type?.mode === 'useFilters'
      ? {
        ...search,
        filters: prepareFilters(filters),
      }
      : { workOrdersId: selectedWorkOrders };
    setRequestData({ ...newRequestData, status: 'completed' });
    return ({ ...newRequestData, status: 'completed' });
  };

  const fetchData = async () => {
    setIsLoading(true);
    await getAccountBalanceByClient();
    const ordersInfo = await getWorkOrdersForBulk(initRequestData());
    const funds = {
      holdFunds: 0,
      fundsNeededFromWallet: 0,
      expensesNeededFromWallet: 0,
      expenses: 0,
      fundsNeeded: 0,
      fundsExpected: 0,
    };
    ordersInfo.forEach(info => {
      funds.fundsExpected += info.fundsExpected;
      funds.fundsNeeded += info.fundsNeeded;
      if (!info._id) {
        funds.holdFunds += info.holdFunds;
        funds.fundsNeededFromWallet += info.fundsNeeded;
        funds.expensesNeededFromWallet += info.expenses;
      }
      funds.expenses += info.expenses;
    });
    setFundsInfo(funds);
    setWorkOrdersInfo(ordersInfo || []);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (fundsInfo.expenses > 0) return setContentType('workOrderHaveExpenses');
    if (fundsInfo.expenses === 0) return setContentType('approveAvailable');
    return setContentType('Error!!!');
  }, [balance, fundsInfo]);

  const onClickContinue = () => {
    return setContentType('approveAvailable')
  }
  const closeModal = () => toggleModal(!modalState.isOpen, 'bulkWorkOrderApproveModal');

  const submitApprove = () => {
    if (
      (balance.total_balance - balance.on_hold)
      - (fundsInfo.fundsNeededFromWallet - fundsInfo.holdFunds) < 0
    ) return setContentType('notEnoughFunds');
    return bulkApproveWorkOrders(requestData);
  };

  const submitApproveWorkOrderWithoutExpenses = () => {
    bulkApproveWorkOrders({
      ...requestData,
      approvedExpenses: 'skip',
    });
  };

  const addFunds = () => {
    toggleModal(true, 'addFunds');
  };

  return (
    <MDBModal
      isOpen={modalState.isOpen}
      toggle={closeModal}
      centered
    >
      <MDBModalBody style={{ fontFamily: 'Arial, Helvetica, sans-serif', backgroundColor: 'white' }}>
        <MDBContainer>
          {
            isLoading || !contentType
              ? (
                <div className="flex-box flex-center full-width">
                  <Loader color="black" />
                </div>
              )
              : (
                <Content
                  contentType={contentType}
                  workOrdersCount={workOrdersInfo.reduce((acc, cur) => acc + cur.count, 0)}
                  fundsInfo={fundsInfo}
                  closeModal={closeModal}
                  submitApprove={submitApprove}
                  addFunds={addFunds}
                  workOrderCountWithExpenses={
                    workOrdersInfo.reduce((acc, cur) => acc + cur.countWithExpenses, 0)
                  }
                  submitApproveAll={submitApprove}
                  onClickContinue={onClickContinue}
                  submitApproveWorkOrderWithoutExpenses={submitApproveWorkOrderWithoutExpenses}
                />
              )
          }
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
  bulkApproveWorkOrders,
  getWorkOrdersForBulk,
  getAccountBalanceByClient,
};

const mapStateToProps = state => ({
  modalState: state.modalState.bulkWorkOrderApproveModal,
  selectedWorkOrders: state.applicationState.selectedWorkOrders,
  balance: state.profile.balance,
  filters: state.workOrder?.filters,
  search: state.workOrder?.searchData,
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkWorkOrderApproveModal);
