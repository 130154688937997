import React from 'react';
import { MDBModal } from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import { toggleSecondModalClose } from '../../storage/actions/modalsActions';
import GoogleMaps from '../layout/GoogleMaps';

const GoogleMapsModal = ({
  modalState,
  currentWorkOrder,
  toggleSecondModalClose,
}) => (
  currentWorkOrder
    ? (
      <MDBModal
        isOpen={modalState.isOpen}
        toggle={() => {
          toggleSecondModalClose('googleMapsModal', true);
        }}
        centered
      >
        <GoogleMaps
          coordinates={currentWorkOrder.coords}
          address={
            `${currentWorkOrder.address1} ${currentWorkOrder.city} ${currentWorkOrder.state.label}`
          }
        />
      </MDBModal>
    )
    : null
);

const mapStateToProps = state => ({
  currentWorkOrder: state.workOrder.currentWorkOrder,
  currentApplicant: state.workOrder.currentApplicant,
  modalState: state.modalState.googleMapsModal,
});

export default connect(mapStateToProps, { toggleSecondModalClose })(GoogleMapsModal);
