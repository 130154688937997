/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  MDBCol, MDBContainer, MDBInput, MDBRow,
} from 'mdbreact';
import DatePicker from 'react-datepicker/es';

const CetCertificatesForContractor = (
  {
    certificates,
    handlerCheck,
    checkedList = new Set(),
    contractorCertificates,
    handlerDate,
    handlerExpiresDate,
  },
) => (
  <MDBContainer className="no-margins d-inline">
    <MDBRow>
      <MDBCol>
        <MDBRow
          style={{
            width: 'auto',
            alignItems: 'center',
            height: '40px',
            margin: '5px 0',
          }}
        >
          <MDBCol md="6">
            Certification
          </MDBCol>
          <MDBCol md="3">
            Date Passed
          </MDBCol>
          <MDBCol md="3">
            Date Expires
          </MDBCol>
        </MDBRow>
        {
          certificates.map(certificate => (
            <MDBRow
              key={certificate._id}
              style={{
                width: 'auto',
                alignItems: 'center',
                height: '40px',
                margin: '5px 0',
              }}
            >
              <MDBCol md="6">
                <MDBInput
                  type="checkbox"
                  id={certificate._id}
                  label={certificate.name}
                  name={certificate.name}
                  value={certificate._id}
                  onChange={handlerCheck}
                  checked={checkedList.has(certificate._id)}
                />
              </MDBCol>
              {
                  certificate.passed_date_required
                  && (
                  <MDBCol md="3">
                    <DatePicker
                      className="form-control sm"
                      dateFormat="MM/dd/yy"
                      placeholder="MM/dd/yy"
                      name="date"
                      selected={contractorCertificates[certificate._id]?.passed_at}
                      onChange={date => handlerDate(certificate._id, date)}
                    />
                  </MDBCol>
                  )
              }
              {
                certificate.expires_date_required
                && (
                <>
                  <MDBCol />
                  <MDBCol md="3">
                    <DatePicker
                      className="form-control sm"
                      dateFormat="MM/dd/yy"
                      placeholder="MM/dd/yy"
                      name="expiresdate"
                      selected={contractorCertificates[certificate._id]?.expires_at}
                      onChange={expiresdate => handlerExpiresDate(certificate._id,
                        expiresdate)}
                    />
                  </MDBCol>
                </>
                )
              }
            </MDBRow>
          ))
        }
      </MDBCol>
    </MDBRow>
  </MDBContainer>
);

export default CetCertificatesForContractor;
