import React, { useEffect, useRef, useState } from 'react';
import AddFundingSourceToDwollaBySuperAdminReduxForm from '../layout/reduxForm/AddFundingSourceToDwollaBySuperAdmin';
import ControlButtonsSection from '../layout/reduxForm/section/addFundingSourceToDwollaBySuperAdmin/ControlButtonsSection';
import onSubmit from '../../utils/reduxForm/onSubmit/addFundingSourceToDwollaBySuperAdmin';
import {
  MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import axios from 'axios';
import connect from 'react-redux/es/connect/connect';

import Loader from '../dashboard/common/Loader';
import Notification from '../notification';

import { toggleModal } from '../../storage/actions/modalsActions';
import { reloadData } from '../../storage/actions/applicationStateActions';

const AddOrEditPaymentMethod = ({
  modalState,
  toggleModal,
  reloadData,
}) => {
  const { type: paymentMethod } = modalState;
  const [cardNumber, setCardNumber] = useState('');
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [cardName, setCardName] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [addMethod, setAddMethod] = useState('bank')
  const [loaded, setLoadedStatus] = useState(true);
  const yearInput = useRef(null);

  useEffect(() => {
    if (paymentMethod) {
      setCardName(paymentMethod.cardName || '');
      setMonth(paymentMethod.expMonth || '');
      setYear(paymentMethod.expYear || '');
      setIsEditMode(true);
      setAddMethod('card');
    }
  }, [paymentMethod]);

  const handlerCardNumberInput = (event) => {
    const typedCardNumber = event.target.value;
    if (
      typedCardNumber.length < cardNumber.length
      && (
        typedCardNumber.length === 5
        || typedCardNumber.length === 10
        || typedCardNumber.length === 15
      )
    ) {
      return setCardNumber(typedCardNumber.slice(0, -1));
    }
    if (
      typedCardNumber.length > cardNumber.length
      && (
        typedCardNumber.length === 4
        || typedCardNumber.length === 9
        || typedCardNumber.length === 14
      )
    ) {
      return setCardNumber(`${typedCardNumber} `);
    }
    return setCardNumber(typedCardNumber);
  };

  const handlerSetMonth = (event) => {
    const value = Number(event.target.value);
    if (event.target.value.length === 2 || value > 1) {
      if (value && value > 0) {
        if (value <= 12) {
          yearInput.current.focus();
          return setMonth(event.target.value);
        }
        return Notification('warning', { message: 'max value for month 12' });
      }
      setMonth('01');
      return Notification('warning', { message: 'min value for month 1 or 01' });
    }
    if (event.target.value === '00') {
      return Notification('warning', { message: 'min value for month 1 or 01' });
    }
    return setMonth(event.target.value);
  };

  const handlerSetYear = ({ target }) => {
    const value = Number(target.value) || '';
    setYear(value);
  };

  const clearingDataFields = () => {
    setCardNumber('');
    setYear('');
    setMonth('');
    setCardName('');
    setIsEditMode(false);
  };

  const closeModal = () => {
    clearingDataFields();
    reloadData();
    toggleModal(!modalState.isOpen, 'addOrEditPaymentMethod');
  };

  const refreshPaymentMethodsListAndCloseModal = () => {
    clearingDataFields();
    reloadData();
    closeModal();
  };

  const createPaymentMethod = async (number, expMonth, expYear, cardName) => {
    await axios.post('/api/payment/payment-methods',
      {
        expYear,
        number,
        expMonth,
        cardName,
      })
      .then((res) => {
        const notificationType = res.data.success ? 'success' : 'error';
        Notification(notificationType, { message: res.data.message });
        if (res.data.success) refreshPaymentMethodsListAndCloseModal();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        Notification('error', { message: error.response?.data?.message || error.message });
        setLoadedStatus(true);
      });
  };

  const editPaymentMethod = async (cardName, expMonth, expYear, id) => {
    await axios.patch('/api/payment/payment-methods', {
      cardName,
      expMonth,
      expYear,
      id,
    })
      .then((res) => {
        const notificationType = res.data.success ? 'success' : 'error';
        Notification(notificationType, { message: res.data.message });
        if (res.data.success) refreshPaymentMethodsListAndCloseModal();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        Notification('error', { message: error.response?.data?.message || error.message });
        setLoadedStatus(true);
      });
  };

  const handlerSubmit = async (event) => {
    event.preventDefault();
    setLoadedStatus(false);
    if (isEditMode) {
      await editPaymentMethod(cardName, month, year, paymentMethod._id);
      return;
    }
    await createPaymentMethod(cardNumber, month, year, cardName);
  };

  return (
    <MDBModal
      className="applypop"
      isOpen={modalState.isOpen}
      toggle={closeModal}
      fullHeight
      position="right"
    >
      {loaded ? (
        <MDBModalBody style={{ color: 'white' }}>
          <MDBRow>
            <MDBCol md="10">
              {!isEditMode && <>
                <select className="form-control no-border-radius" onChange={(e) => setAddMethod(e.target.value)}>
                  <option value="bank" selected={addMethod === 'bank'}>Bank Account</option>
                  <option value="card" selected={addMethod === 'card'}>Card Number</option>
                </select>
              </>}
              </MDBCol>
            <MDBCol md="2" style={{ textAlign: 'end' }}>
              <MDBIcon
                far
                icon="times-circle"
                onClick={closeModal}
                size="lg"
                className="blue-grey-text"
              />
            </MDBCol>
          </MDBRow>
          {!isEditMode && <hr className="hr border-white"/>}
          {addMethod === 'bank' && <>
            <AddFundingSourceToDwollaBySuperAdminReduxForm onSubmit={onSubmit}/>
            <MDBRow className="no-margins">
              <MDBCol className="p-0">
                <label className="font-size-08">
                  Verification of micro deposits usually takes 2-3 days.
                </label>
              </MDBCol>
            </MDBRow>
            <ControlButtonsSection closeModal={closeModal}/>
          </>}
          {addMethod === 'card' && <>
            <MDBRow>
              <MDBCol md="10">
                <label>{isEditMode ? "Edit" : "Add"} Card Information</label>
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings-half">
              <MDBCol>
                <input
                  className="input-field"
                  style={{
                    width: 'inherit',
                  }}
                  type="string"
                  name="name"
                  placeholder="Name Or Card"
                  value={cardName}
                  maxLength={15}
                  onChange={(event) => setCardName(event.target.value)}
                />
              </MDBCol>
            </MDBRow>
            {!isEditMode && (
              <MDBRow className="h-paddings-half">
                <MDBCol>
                  <input
                    className="input-field"
                    style={{
                      width: 'inherit',
                    }}
                    name="cardNumber"
                    placeholder="Card Number"
                    maxLength="19"
                    onChange={handlerCardNumberInput}
                    value={cardNumber}
                  />
                </MDBCol>
              </MDBRow>
            )}
            <MDBRow className="h-paddings-half">
              <MDBCol md="8">
                <div className="card-date-input flex-box">
                  <input
                    className="input-field"
                    name="cardLifeCycle"
                    placeholder="mm"
                    style={{
                      width: '40px',
                      flexGrow: 2,
                    }}
                    maxLength="2"
                    onChange={handlerSetMonth}
                    value={month}
                  />
                  <div>/</div>
                  <input
                    className="input-field"
                    name="cardLifeCycle"
                    ref={yearInput}
                    placeholder="yy"
                    style={{
                      width: '40px',
                      flexGrow: 2,
                    }}
                    maxLength="2"
                    pattern="^\d{4}$"
                    onChange={handlerSetYear}
                    value={year}
                  />
                  <MDBIcon
                    far
                    icon="calendar-alt"
                    size="lg"
                    style={{
                      flexGrow: 1,
                      cursor: 'none',
                    }}
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings-half">
              <MDBCol
                className="flex-box"
                style={{alignItems: 'flex-end'}}
              >
                <MDBBtn
                  className="background-green no-margins"
                  style={{
                    padding: '0.3rem 0.5rem',
                    color: '#333333',
                    backgroundColor: '#6cff2e',
                  }}
                  color="false"
                  onClick={handlerSubmit}
                >
                  <MDBIcon far className="vwimg" icon="credit-card"/>
                  Submit
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </>}
        </MDBModalBody>
      ) : <Loader />}
    </MDBModal>
  );
};

const mapStateToProps = (state) => ({
  modalState: state.modalState.addOrEditPaymentMethod,
});

export default connect(mapStateToProps, { toggleModal, reloadData })(AddOrEditPaymentMethod);
