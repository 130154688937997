import React, { useState } from 'react';
import { MDBRow } from 'mdbreact';
import LabelRow from './LabelRow';
import EditRow from './EditRow';
import Notification from '../../notification';

const TemplateRow = ({
  template,
  rename,
  deleteTemplate,
  copyTemplate,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const copyTemplateId = async () => {
    try {
      await navigator.clipboard.writeText(template.value);
      Notification('success', {
        message: 'Template ID copied to clipboard!',
      });
    } catch (error) {
      console.error('Unable to copy item to clipboard:', error);
    }
  };
  return (
    <MDBRow className="border-bottom-line-white-bold">
      {
        isEditing
          ? (
            <EditRow
              template={template}
              cancel={() => setIsEditing(false)}
              save={rename}
            />
          )
          : (
            <LabelRow
              template={template}
              edit={() => setIsEditing(true)}
              copy={() => copyTemplate(template.value)}
              copyTemplateId={() => copyTemplateId()}
              deleteTemplate={() => deleteTemplate(template.value)}
            />
          )
      }
    </MDBRow>
  );
};

export default TemplateRow;
