import React from 'react';
import {
  MDBCol, MDBIcon, MDBRow,
} from 'mdbreact';

const CustomFieldValueInput = ({
  id,
  value,
  name,
  onChange,
  remove,
}) => (
  <MDBRow className="font-size-08" style={{ padding: '0.1rem 0' }}>
    <MDBCol
      md="3"
      className="text-align-right flex-box align-items-center justify-content-end no-paddings"
    >
      <label
        className="text-in-one-line no-margins overflow-hidden text-ellipsis"
        title={name}
      >
        {name}
      </label>
    </MDBCol>
    <MDBCol md="5">
      <input
        type="text"
        className="input-field width-inherit"
        name={id}
        value={value}
        onChange={onChange}
      />
    </MDBCol>
    <MDBCol className="flex-grow-0 no-paddings flex-box align-items-center">
      <MDBIcon icon="times" onClick={remove} />
    </MDBCol>
  </MDBRow>
);

export default CustomFieldValueInput;
