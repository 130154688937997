import React from 'react';
import { connect } from 'react-redux';
import {
  MDBBtn, MDBBtnGroup, MDBCol, MDBContainer, MDBIcon, MDBRow,
} from 'mdbreact';
import { toggleModal } from '../../storage/actions/modalsActions';
import {
  datatableManagerFundsColumnsForClient as columns,
  localCurrencySettings,
} from '../../utils/constans';
// eslint-disable-next-line import/no-cycle
import Modal from '../../components/modal';
import { getAccountBalanceByClient } from '../../storage/actions/profile';
import {ResourceDataTable} from "../../components/datatables/ResourceDataTable";

const ClientManageFunds = ({
  toggleModal, mainContainer, profile, isReload,
}) => {
  return (
    <MDBContainer className="clientdascontentmaindiv" fluid>
      <MDBRow>
        <MDBCol style={{ textAlign: 'center' }}>
          Transaction History
        </MDBCol>
        <MDBCol style={{ flexGrow: 0 }}>
          <MDBBtn
              className="background-green flex-box flex-align-center text-nowrap mr-1"
              onClick={() => toggleModal(true, 'addFunds')}
            >
              <MDBIcon far className="vwimg" icon="credit-card" />
              Add funds
            </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <ResourceDataTable
            entity="/api/dwolla/history"
            columns={columns}
            isReload={isReload}/>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="mt-5">
          <h5 style={{ textAlign: 'end', fontWeight: 'bold' }}>
            {`Balance: ${
              profile?.balance.total_balance
                ? Number(profile.balance.total_balance - (profile.balance.on_hold || 0))
                  .toLocaleString('en-US', localCurrencySettings)
                : '$0.00'
            }`}
          </h5>
        </MDBCol>
      </MDBRow>
      <Modal mainContainer={mainContainer.current} />
    </MDBContainer>
  );
};

const mapDispatchToProps = {
  toggleModal,
  getAccountBalanceByClient,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  isReload: state.applicationState.isReload,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientManageFunds);
