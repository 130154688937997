import React from 'react';
import { MDBRow } from 'mdbreact';
import PaymentMethodCard from './PaymentMethodCard';

const PaymentMethodsList = ({
  paymentMethods = [], editPaymentMethod, deletePaymentMethod, makePrimary, auth,
}) => (paymentMethods
&& paymentMethods.length
  ? (
    <MDBRow>
      {
        paymentMethods.map(paymentMethod => (
          <PaymentMethodCard
            key={paymentMethod._id}
            paymentMethod={paymentMethod}
            editPaymentMethod={editPaymentMethod}
            deletePaymentMethod={deletePaymentMethod}
            makePrimary={makePrimary}
            auth={auth}
          />
        ))
        }
    </MDBRow>
  )
  : <></>);

export default PaymentMethodsList;
