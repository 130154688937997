import React, { useEffect, useState } from 'react';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdbreact';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import Modal from '../../../components/modal';
import getContractorCertificates from '../../../utils/api/get/getContractorCertificates';
import CertificateCard from '../../../components/certification/CertificateCard';
import { toggleModal } from '../../../storage/actions/modalsActions';
import VeriShareCard from '../../../components/certification/VeriShareCard';

const Certifications = ({ mainContainer, toggleModal }) => {
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    fetchCertificates();
    return () => setCertificates([]);
  }, []);

  const fetchCertificates = async () => {
    toggleModal(true, 'loader');

    const { certificates } = await getContractorCertificates();
    setCertificates(certificates);

    toggleModal(false, 'loader');
  };

  const openBuyModal = (certificateId, amount) => {
    toggleModal(true, 'purchaseCertificate', true, { id: certificateId, amount });
  };

  let isTableEmpty = true;

  return (
    <MDBContainer className="clientdascontentmaindiv" fluid>
      <MDBRow className="justify-content-center">
        <h6>Credentials</h6>
      </MDBRow>

      <MDBRow className="flex-box">
        {certificates.map(certificate => {
          const {
            bought, label, _id, passed, amount,
          } = certificate;

          if (!passed) {
            return (
              <CertificateCard
                key={_id}
                name={label}
                owned={bought}
                cerificateTypeId={_id}
                amount={amount}
                openBuyModal={() => openBuyModal(_id, amount)}
              />
            );
          }
          return null;
        })}
        <VeriShareCard />
      </MDBRow>

      <MDBRow>
        <MDBCol>
          <MDBTable className="ccwo_table">
            <MDBTableHead>
              <tr>
                <th>
                  <h6>Credentials</h6>
                </th>
                <th>
                  <h6>Date Passed</h6>
                </th>
                <th>
                  <h6>Date Expires</h6>
                </th>
              </tr>
            </MDBTableHead>
            <MDBTableBody className="font12">
              {certificates.map(certificate => {
                if (certificate.passed) {
                  isTableEmpty = false;
                  return (
                    <tr key={certificate._id}>
                      <th>{certificate.label}</th>
                      <th>
                        {new Date(
                          certificate.passed,
                        ).toLocaleDateString('en-US')}
                      </th>
                      <th>
                        {new Date(
                          certificate.expires,
                        ).toLocaleDateString('en-US')}
                      </th>
                    </tr>
                  );
                }
                return null;
              })}
              {isTableEmpty ? <tr><th>You have no passed certificates</th></tr> : <></>}
            </MDBTableBody>
          </MDBTable>
        </MDBCol>
      </MDBRow>

      <MDBRow className="justify-content-center">
        {/* <MDBCol> */}
        {/* <MDBCol style={{ backgroundColor: '#f7931e' }}> */}
        <MDBCol>
          <p>
            We use
            {' '}
            <a href="https://verishare.com/products" target="_blank" rel="noreferrer">Verishare</a>
            {' '}
            for our credentialing
            as it’s the leading  edge credentialing system that allows you to share your results
            with anyone you authorize to see them. Once you get the results, you own the them and
            you control who sees them.
          </p>
          <p>
            <b>IMPORTANT:</b>
            {' '}
            By clicking over to
            {' '}
            <a href="https://verishare.com/products" target="_blank" rel="noreferrer">Verishare</a>
            {' '}
            from here, you authorize SW9 to share your
            credential results in your profile.  Note, for drug and background checks, we will not
            post failed checks, only if you pass. Once passed, it will be reflected on your SW9
            profile and clients will know you passed and are ready to go to work on their projects.
          </p>
        </MDBCol>
      </MDBRow>
      <Modal mainContainer={mainContainer.current} />
    </MDBContainer>
  );
};

const mapDispatchToProps = {
  toggleModal,
};

export default connect(null, mapDispatchToProps)(Certifications);
