import React from "react";
import FilePreview from "./FilePreview";
import { Results } from "./FileUploadStyled";


const FileListUpload = ({name, files = [], handleDelete}) => {
    return (
        <Results>
            {files.map((file, index) => 
                <FilePreview key={`${name}-${index}-${Date.now()}`} file={file} onDelete={handleDelete}/>
            )}
        </Results>
    )
}

export default FileListUpload