import React from 'react';

const HistoryCard = ({ historyInfo }) => (
  <tr>
    <td>
      <p>
        {
          new Date(historyInfo.createdAt).toLocaleString('en-US')
        }
      </p>
      <p>
        {
          historyInfo.creator?.name || ''
        }
      </p>
    </td>
    <td>
      {
        historyInfo.type
      }
    </td>
    <td>
      {
        historyInfo.work_order_status.replace(/^\w/, c => c.toUpperCase())
      }
    </td>
    <td>
      {
        historyInfo.description
      }
    </td>
  </tr>
);

export default HistoryCard;
