import React, { Component } from 'react';
import {
  MDBModal, MDBModalBody,
} from 'mdbreact';
import { connect } from 'react-redux';
import { toggleModal } from '../../../storage/actions/modalsActions';
import ForgotPasswordReduxForm from '../../layout/reduxForm/ForgotPasswordReduxForm';
import handleVerifyAccount from '../../../utils/reduxForm/onSubmit/verifyAccount';

class VerifyAccountModal extends Component {
  closeModal = () => {
    const { modalState, toggleModal } = this.props;
    toggleModal(!modalState.isOpen, 'verifyAccountModal');
  };

  render() {
    const { modalState } = this.props;
    const params = new URLSearchParams(window.location.search);

    return (
      <MDBModal isOpen={modalState.isOpen} toggle={this.closeModal} centered className="register_div">
        <MDBModalBody>
          <ForgotPasswordReduxForm onSubmit={handleVerifyAccount} id={params.get('id')} />
        </MDBModalBody>
      </MDBModal>
    );
  }
}

const mapStateToProps = (state) => ({
  modalState: state.modalState.verifyAccountModal,
});

export default connect(mapStateToProps, {
  toggleModal,
})(VerifyAccountModal);
