import React from 'react';
import { MDBModal } from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import { toggleSecondModalClose } from '../../storage/actions/modalsActions';

const ImageViewModal = ({
  modalState,
  toggleSecondModalClose,
  url
}) => {
  console.log({url, modalState})
  return (
      <MDBModal
        isOpen={modalState.isOpen}
        toggle={() => {
          toggleSecondModalClose('imageViewModal', true);
        }}
        centered
      >
        <div className='d-flex justify-content-center align-items-center'>
          <img src={modalState.type.url} alt=""  style={{width: '200%'}}/>
        </div>
      </MDBModal>

  )
};

const mapStateToProps = state => ({
  currentWorkOrder: state.workOrder.currentWorkOrder,
  currentApplicant: state.workOrder.currentApplicant,
  modalState: state.modalState.imageViewModal,
});

export default connect(mapStateToProps, { toggleSecondModalClose })(ImageViewModal);
