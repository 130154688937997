import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';

const ContentConfirmModalOnBlockCompanyByContractor = () => (
  <MDBRow className="no-margins">
    <MDBCol>
      <p
        style={{
          whiteSpace: 'break-spaces',
          textAlign: 'center',
          color: 'black',
        }}
      >
        By blocking this client you will no longer be able to see any of their Work Orders.
      </p>
      <p
        style={{
          whiteSpace: 'break-spaces',
          textAlign: 'center',
          color: 'black',
        }}
      >
        Are you sure to you want to continue?
      </p>
    </MDBCol>
  </MDBRow>
);

export default ContentConfirmModalOnBlockCompanyByContractor;
