import React from 'react';
import {
  MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';

import { toggleModal } from '../../storage/actions/modalsActions';
import Loader from '../dashboard/common/Loader';
import { submitApply, cancelApplication } from '../../storage/actions/workOrdersActions';
import { localCurrencySettings } from '../../utils/constans';
import FormApplyModal from './FormApplyModal';

const ApplyModal = ({
  currentWorkOrder, modalState, toggleModal, submitApply, cancelApplication,
}) => (
  // eslint-disable-next-line no-nested-ternary
  modalState.isOpen
    ? currentWorkOrder
      ? (
        <MDBModal
          className="applypop"
          isOpen={modalState.isOpen}
          toggle={() => {
            toggleModal(!modalState.isOpen, 'applyModal');
          }}
          fullHeight
          position="right"
        >
          <MDBModalBody style={{ color: 'white' }}>
            <MDBRow style={{ marginBottom: '15px' }}>
              <MDBCol md="6" style={{ textAlign: 'left' }}>
                <h6
                  style={{
                    fontWeight: 'bold',
                    marginBottom: '15px',
                  }}
                >
                  Apply For Work
                </h6>
              </MDBCol>
              <MDBCol md="6" style={{ textAlign: 'end' }}>
                <MDBIcon
                  icon="times-circle"
                  size="lg"
                  style={{
                    color: '#41566a',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    toggleModal(!modalState.isOpen, 'applyModal');
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <div style={{ padding: '10px' }}>
                  <span
                    className="font12"
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                    }}
                  >
                    Company WO ID:
                  </span>
                  <span className="font12">{currentWorkOrder.siteId}</span>
                </div>
                <div style={{ padding: '10px' }}>
                  <span
                    className="font12"
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                    }}
                  >
                    Project Name:
                  </span>
                  <span className="font12">{currentWorkOrder.title}</span>
                </div>
                <div style={{ padding: '10px' }}>
                  <span
                    className="font12"
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                    }}
                  >
                    Start Date:
                  </span>
                  <span className="font12">
                    {
                        new Date(currentWorkOrder.startDate)
                          .toLocaleDateString('en-US', { timeZone: 'UTC' }) === 'Invalid Date'
                          ? ''
                          : new Date(currentWorkOrder.startDate)
                            .toLocaleDateString('en-US', { timeZone: 'UTC' })
                      }
                  </span>
                </div>
                <div style={{ padding: '10px' }}>
                  <span
                    className="font12"
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                    }}
                  >
                    End Date:
                  </span>
                  <span className="font12">
                    {
                        new Date(currentWorkOrder.endDate)
                          .toLocaleDateString('en-US', { timeZone: 'UTC' }) === 'Invalid Date'
                          ? ''
                          : new Date(currentWorkOrder.endDate)
                            .toLocaleDateString('en-US', { timeZone: 'UTC' })
                    }
                  </span>
                </div>
                <div style={{ padding: '10px' }}>
                  <span
                    className="font12"
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                    }}
                  >
                    Start Time:
                  </span>
                  <span className="font12">{currentWorkOrder.startTime}</span>
                </div>
                <div style={{ padding: '10px' }}>
                  <span
                    className="font12"
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                    }}
                  >
                    End Time:
                  </span>
                  <span className="font12">{currentWorkOrder.endTime}</span>
                </div>
                <div style={{ padding: '10px' }}>
                  <span
                    className="font12"
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                    }}
                  >
                    Distance:
                  </span>
                  <span
                    className="font12"
                  >
                    {
                        (currentWorkOrder.distance || (currentWorkOrder.distance === 0))
                          ? `${currentWorkOrder.distance.toFixed(2)} Miles`
                          : ''
                      }
                  </span>
                </div>
                <div style={{ padding: '10px' }}>
                  <span
                    className="font12"
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                    }}
                  >
                    Work Description:
                  </span>
                  <div
                    dangerouslySetInnerHTML={{ __html: currentWorkOrder.description }}
                    style={{ fontSize: 12 }}
                  />
                </div>
                <div style={{ padding: '10px' }}>
                  <span
                    className="font12"
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                    }}
                  >
                    Tools Required:
                  </span>
                  <div
                    dangerouslySetInnerHTML={{ __html: currentWorkOrder.customTools }}
                    style={{ fontSize: 12 }}
                  />
                </div>
                <div style={{ padding: '10px' }}>
                  <span
                    className="font12"
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                    }}
                  >
                    Work Type:
                  </span>
                  <div>
                    {
                        (
                          currentWorkOrder.workTypes !== undefined
                          && currentWorkOrder.workTypes instanceof Array
                        )
                        && currentWorkOrder.workTypes.map(toolsType => (
                          <span
                            key={toolsType.title}
                            className="chip1"
                            style={{ marginTop: 10 }}
                          >
                            {toolsType.title}
                          </span>
                        ))
                      }
                  </div>
                </div>
                <div style={{ padding: '10px' }}>
                  <span
                    className="font12"
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                    }}
                  >
                    Base Pay:
                  </span>
                  <span className="font12">
                    {
                        `${
                          currentWorkOrder.amount.toLocaleString('en-US', localCurrencySettings)
                        } Per ${currentWorkOrder.basicType} `
                      }
                    {
                        currentWorkOrder.basicType !== 'Site'
                          ? `for the first ${currentWorkOrder.quantity} ${currentWorkOrder.basicType
                            .toLocaleLowerCase()}${currentWorkOrder.quantity > 1 ? 's' : ''}`
                          : ''
                      }
                    .
                  </span>
                  {
                      currentWorkOrder.payBaseRateAsMinimum
                        ? (
                          <span
                            style={{
                              marginLeft: '0.5rem',
                              color: 'red',
                              padding: '0.5rem',
                              border: '3px solid red',
                            }}
                          >
                            MIN
                          </span>
                        )
                        : <></>
                    }
                </div>
                {
                    (currentWorkOrder.variableAmount)
                    && (currentWorkOrder.variableAmount !== 0)
                    && (currentWorkOrder.variableAmount !== '0')
                      ? (
                        <div style={{ padding: '10px' }}>
                          <span
                            className="font12"
                            style={{
                              fontWeight: 'bold',
                              marginRight: '10px',
                            }}
                          >
                            Variable Pay:
                          </span>
                          <span className="font12">
                            {
                              `${
                                currentWorkOrder.variableAmount.toLocaleString('en-US', localCurrencySettings)
                              } Per ${currentWorkOrder.variableType}`
                            }
                            {
                              currentWorkOrder.variableType !== 'Site'
                                ? ` AFTER the first ${currentWorkOrder.variablePayAfter} ${currentWorkOrder.variableType
                                  .toLocaleLowerCase()}${currentWorkOrder.variablePayAfter > 1 ? 's' : ''}`
                                : ''
                            }
                            .
                          </span>
                        </div>
                      )
                      : <></>
                  }
                <div style={{ padding: '10px' }}>
                  <span
                    className="font12"
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                    }}
                  >
                    Total Potential Pay Amount:
                  </span>
                  <span
                    className="font12"
                  >
                    {
                        (currentWorkOrder.holdFounds || 0)
                          .toLocaleString('en-US', localCurrencySettings)
                      }
                  </span>
                </div>
                {
                    currentWorkOrder.payOutsideOfApp
                      && (
                        <div
                          style={{
                            padding: '10px',
                            color: '#009933',
                          }}
                        >
                          <span
                            className="font14"
                            style={{
                              fontWeight: 'bold',
                              marginRight: '10px',
                            }}
                          >
                            Paid Outside of SW9 Directly by Client!
                          </span>
                        </div>
                      )
                  }
              </MDBCol>
            </MDBRow>
            <MDBRow
              className="justify-content-end"
              style={{ textAlign: 'end' }}
            >

              <FormApplyModal
                currentWorkOrder={currentWorkOrder}
                modalState={modalState}
                toggleModal={toggleModal}
                submitApply={submitApply}
              />

            </MDBRow>
            {
              (currentWorkOrder.applied === true)
              && (
              <MDBRow
                className="justify-content-end"
                style={{ textAlign: 'end' }}
              >
                <MDBBtn
                  color="danger"
                  className="closeapply"
                  onClick={() => cancelApplication(currentWorkOrder._id)}
                >
                  Cancel Application
                </MDBBtn>
              </MDBRow>
              )
            }
          </MDBModalBody>
        </MDBModal>
      )
      : <Loader />
    : null
);

const mapStateToProps = state => ({
  currentWorkOrder: state.workOrder.currentWorkOrder,
  user: state.auth.user,
  modalState: state.modalState.applyModal,
});

export default connect(mapStateToProps, {
  toggleModal,
  submitApply,
  cancelApplication,
})(ApplyModal);
