import React from 'react';
import { MDBIcon } from 'mdbreact';

const EstimatedTimeOverageLabel = ({
  text,
  icon = 'exclamation-triangle',
  cardClassName = '',
}) => (
  <div className="flex-box card-label-container">
    <div className={`card-label yellow-card-label ${cardClassName}`}>
      <MDBIcon icon={icon} />
      <span>{text}</span>
    </div>
  </div>
);

export default EstimatedTimeOverageLabel;
