import React from 'react';
import { MDBCol } from 'mdbreact';
import { connect } from 'react-redux';
import { Field, formValueSelector, change } from 'redux-form';
import { fetchCountries } from '../../../storage/actions/workOrdersActions';
import CheckboxField from '../../layout/reduxForm/customFields/CheckboxField';

const createWorkOrderReduxFormSelector = formValueSelector('createWorkOrderReduxForm');

const RemoteWFHSection = ({
  certificates,
  change,
  isRemote
}) => {

  return (
    <>
      <MDBCol md="2">
        <Field
          name="isRemote"
          component={CheckboxField}
          label="Remote/WFH"
        />
      </MDBCol>
      <MDBCol md='2'>
        <Field
          name="projectManager"
          component={CheckboxField}
          label="Project Manager"
          onChange={(e) => {
            let values = [...(certificates || [])];
            if(e.target.checked) {
              values.push('Project Manager')
            } else {
              values = values.filter(item => item !== 'Project Manager')
            }
            change('createWorkOrderReduxForm', 'certificates', values);
          }}
          checked={certificates?.includes('Project Manager')}
          disabled={!isRemote }
        />
      </MDBCol>
      <MDBCol md='2'>
        <Field
          name="projecctCoordinator"
          component={CheckboxField}
          label="Project Coordinator"
          onChange={(e) => {
            let values = [...(certificates || [])];
            if(e.target.checked) {
              values.push('Project Coordinator')
            } else {
              values = values.filter(item => item !== 'Project Coordinator')
            }
            change('createWorkOrderReduxForm', 'certificates', values);
          }}
          checked={true}
          disabled={!isRemote}
        />
      </MDBCol>
      <MDBCol md='2'>
        <Field
          name="projectAdministrator"
          component={CheckboxField}
          label="Project Administrator"
          onChange={(e) => {
            let values = [...(certificates || [])];
            if(e.target.checked) {
              values.push('Project Administrator')
            } else {
              values = values.filter(item => item !== 'Project Administrator')
            }
            change('createWorkOrderReduxForm', 'certificates', values);
          }}
          checked={certificates?.includes('Project Administrator')}
          disabled={!isRemote}
        />
      </MDBCol>
      <MDBCol md='2'>
        <Field
          name="otherMisc"
          component={CheckboxField}
          label="Other/Misc."
          onChange={(e) => {
            let values = [...(certificates || [])];
            if(e.target.checked) {
              values.push('other')
            } else {
              values = values.filter(item => item !== 'other')
            }
            change('createWorkOrderReduxForm', 'certificates', values);
          }}
          checked={certificates?.includes('other')}
          disabled={!isRemote}
        />
      </MDBCol>
    </>
  );
};

const mapDispatchToProps = {
  change,
  fetchCountries,
};

const mapStateToProps = state => ({
  certificates: createWorkOrderReduxFormSelector(state, 'certificates'),
  isRemote: createWorkOrderReduxFormSelector(state, 'isRemote')
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoteWFHSection);
