import React, {useEffect, useRef, useState} from "react";
import {Label, Results, Storage, Wrapper} from "./FileUploadStyled"
import FileListPreview from "./FileListPreview";


const FileUpload = ({ name, values = [], onChange = () => {}, accept = [] }) => {
  const dropzone = useRef(null);
  const [oldFiles, setOldFiles] = useState(values)
  const [files, setFiles] = useState(values)

  useEffect(() => {
    if(files === values) return;

    setFiles(values)
    setOldFiles(values)
  }, [values])

  useEffect(() => {
    if(oldFiles === files) return;

    onChange(files)
    setOldFiles(files)
  }, [files])

  useEffect(() => {
    const handleEventsList = ['dragenter', 'dragover', 'dragleave', 'drop']
    dropzone.current.addEventListener('drop', handleDrop, false)

    handleEventsList.forEach(eventName => {
      dropzone.current.addEventListener(eventName, preventDefaults, false)
    })
  }, [])

  const preventDefaults = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleFileChange = (e) => {
    if(!e.target.files) return;

    setFiles([... files, ... e.target.files])
    e.target.value = null
  }

  const handleDrop = (e) => {
    let dt = e.dataTransfer
    let drop = dt.files

    setFiles(files => [... files, ... drop])
    e.target.value = null
  }

  const handleDelete = (file) => {
    const index = files.indexOf(file)
    if(index === -1) return;

    files.splice(index, 1)
    setFiles([... files])
  }

  return (
    <>
      <Wrapper ref={dropzone}>
        <Storage name={name} onChange={handleFileChange} accept={accept.join(", ")} multiple/>
        <Label>Insurance/COI’s or Resume/CV</Label>
      </Wrapper>
      <FileListPreview files={files} handleDelete={handleDelete}/>
    </>
  )
}

export default FileUpload