import React, { useState } from 'react';
import {
  MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import axios from 'axios';
import { connect } from 'react-redux';
import docImage from '../../assets/doc_image.png';
import { toggleModal } from '../../storage/actions/modalsActions';
import { reloadData } from '../../storage/actions/applicationStateActions';
import Notification from '../notification';

const ImportRecruitsModal = props => {
  const [file, setFile] = useState(null);
  const [messageImportFile, setMessageImportFile] = useState(null);

  // eslint-disable-next-line consistent-return
  const handlerUploadFile = () => {
    if (!file) return setMessageImportFile('Please select file to import.');
    if (!file.type.includes('csv') && !file.type.includes('excel')) return setMessageImportFile('Please select .csv file to import.');
    setMessageImportFile('');
    props.toggleModal(true, 'loader', true);
    const fd = new FormData();
    fd.append('file', file);

    axios
      .post('/api/recruits/import', fd)
      .then(res => {
        const notificationType = res.data?.success ? 'info' : 'error';
        Notification(notificationType, {
          message: res.data.message,
        });
        if(res.data.skippedLines) {
          setMessageImportFile(`Skipped lines on upload process <br> ${res.data.skippedLines.join('<br>')} <br> Please check email or zip!`)
        } else closeModal();
      })
      .catch(error => {
        console.error('Import Work Order Error: ', error);
        setMessageImportFile(error.response?.data.message || error.message);
      }).finally(() => {
        props.toggleModal(false, 'loader', true);
        props.reloadData();
      })
  };

  const closeModal = () => {
    setFile(null);
    props.toggleModal(!props.modalState.isOpen, 'importWorkOrderModal');
  };

  const handlerFile = (event) => {
    if (!event.target.files[0]) {
      setFile(null);
      return setMessageImportFile('Please select .csv file to import.');
    }
    setFile(event.target.files[0]);
    return setMessageImportFile('');
  };

  return (
    <MDBModal
      /* eslint-disable-next-line react/destructuring-assignment */
      isOpen={props.modalState.isOpen}
      toggle={closeModal}
      centered
      className="signindiv"
    >
      <MDBRow
        style={{
          justifyContent: 'flex-end',
          paddingRight: '10px',
        }}
      >
        <MDBIcon
          far
          icon="times-circle"
          onClick={closeModal}
          size="lg"
          className="blue-grey-text"
        />
      </MDBRow>
      <MDBModalBody style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
        <div>
          <MDBRow style={{ justifyContent: 'center' }}>
            <p style={{ color: 'black' }}>Import Recruits</p>
          </MDBRow>
          <MDBCol md="12">
            <div className="form-group signinput" style={{ lineHeight: '1.15' }}>
              <label htmlFor="formGroupExampleInput" className="font12 no-margins">
                Select your CSV file to import
              </label>
              <input
                type="file"
                name="file"
                onChange={handlerFile}
                accept=".csv"
                style={{
                  fontSize: 12,
                  cursor: 'pointer',
                  marginTop: '10px',
                }}
              />
            </div>
          </MDBCol>
          {
            file
            && (
              <div>
                <img
                  alt=""
                  src={docImage}
                  style={{
                    marginBottom: 5,
                    marginLeft: 0,
                    height: 100,
                    cursor: 'pointer',
                  }}
                />
                <div style={{
                  textAlign: 'center',
                  color: 'black',
                  width: 100,
                  marginLeft: 0,
                  fontSize: 12,
                }}
                >
                  {file.name}
                </div>
              </div>
            )
          }
          <MDBRow style={{ justifyContent: 'center' }}>
            <div
              style={{ color: '#e87b68', padding: '0 15px 0 25px', overflowWrap: 'anywhere', fontSize: 12, maxHeight: 300, overflow: 'auto' }}
              dangerouslySetInnerHTML={{__html: messageImportFile}}
            />
          </MDBRow>
          <MDBRow style={{ justifyContent: 'center' }}>
            <MDBBtn
              color="info"
              className="signbutton"
              onClick={handlerUploadFile}
            >
              Upload
            </MDBBtn>
          </MDBRow>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = state => ({
  modalState: state.modalState.importRecruitsModal,
});

export default connect(mapStateToProps, { toggleModal, reloadData })(ImportRecruitsModal);
