import React, { useRef, useState } from 'react';
import { MDBIcon, MDBTooltip } from 'mdbreact';

const TableElement = ({
  label,
  value,
  onChange,
  onDelete,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const inputElement = useRef();

  const handlerCloseEdit = event => {
    event.preventDefault();
    setIsEdit(false);
  };

  const handlerDelete = event => {
    event.preventDefault();
    onDelete(value);
  };

  const handlerSubmit = event => {
    event.preventDefault();
    onChange(value, inputElement.current.value);
  };

  return (
    <tr>
      {
        isEdit
          ? (
            <>
              <td>
                <input
                  ref={inputElement}
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="editName"
                  defaultValue={label}
                />
              </td>
              <td>
                <MDBTooltip
                  domElement
                  tag="span"
                  placement="top"
                >
                  <span>
                    <button
                      className="disabled-standard-button-style"
                      type="button"
                      onClick={handlerSubmit}
                    >
                      <MDBIcon
                        style={{ marginTop: 10 }}
                        far
                        icon="check-circle mr-3"
                      />
                    </button>
                  </span>
                  <span>Save</span>
                </MDBTooltip>
                <MDBTooltip
                  domElement
                  tag="span"
                  placement="top"
                >
                  <span>
                    <MDBIcon
                      style={{ marginTop: 10 }}
                      far
                      icon="times-circle"
                      onClick={handlerCloseEdit}
                    />
                  </span>
                  <span>Close</span>
                </MDBTooltip>
              </td>
            </>
          )
          : (
            <>
              <td>{label}</td>
              <td>
                <MDBTooltip
                  domElement
                  tag="span"
                  placement="top"
                >
                  <span>
                    <MDBIcon
                      far
                      icon="edit mr-3"
                      onClick={() => setIsEdit(true)}
                    />
                  </span>
                  <span>Edit</span>
                </MDBTooltip>
                <MDBTooltip
                  domElement
                  tag="span"
                  placement="top"
                >
                  <span>
                    <MDBIcon
                      far
                      icon="trash-alt"
                      onClick={handlerDelete}
                    />
                  </span>
                  <span>Delete</span>
                </MDBTooltip>
              </td>
            </>
          )
      }
    </tr>
  );
};

export default TableElement;
