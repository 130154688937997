import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  MDBBtn, MDBCol, MDBInput, MDBRow,
} from 'mdbreact';
import workOrderStatusControl from '../../../utils/workOrderStatusControl';

const toggleCheckbox = (workOrder, selected, select) => {
  if (workOrder.status === 'completed') ;
  const id = workOrder._id;
  if (selected.includes(id)) {
    return select(selected.filter((el) => el !== id));
  }
  return select([...selected, id]);
};

const ControlsButton = ({
  workOrder,
  activeTab,
  setCurrentWorkOrder,
  selectedWorkOrders,
  selectWorkOrder,
  toggleModal,
  history,
  match,
  acceptInvite,
  confirmWorkOrder,
}) => {
  const workOrderControlByStatus = workOrder ? workOrderStatusControl(workOrder.status) : {};
  return (
    <td style={{ verticalAlign: 'middle' }}>
      <MDBRow
        className="no-margins"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {
          (
            (!workOrderControlByStatus.beforeAssigned || activeTab === 'routed')
            && !workOrderControlByStatus.canceled
          )
          && (
            <MDBCol md="12" className="client_tablecheck">
              <MDBRow style={{ justifyContent: 'flex-end' }}>
                <MDBInput
                  label="."
                  type="checkbox"
                  id={`${workOrder.status}Checkbox${workOrder._id}`}
                  checked={selectedWorkOrders.includes(workOrder._id)}
                  onChange={() => {
                    toggleCheckbox(workOrder, selectedWorkOrders, selectWorkOrder);
                  }}
                />
              </MDBRow>
            </MDBCol>
          )
        }
        {
          activeTab === 'routed'
          && (
            <MDBBtn
              color="info"
              className="applybutton"
              onClick={(event) => {
                event.preventDefault();
                acceptInvite({ workOrdersId: [workOrder._id] });
              }}
            >
              Accept Assignment
            </MDBBtn>
          )
        }
        {
          activeTab === 'routed'
          && (
            <MDBBtn
              color={workOrder.applied ? ('success') : ('info')}
              className="applybutton"
              onClick={() => {
                setCurrentWorkOrder(workOrder);
                toggleModal(true, 'applyModal', false, { openCounterOffer: true });
              }}
            >
                {workOrder.applied ? (<>Edit Counter Offer</>) : <>Counter Offer</>}
            </MDBBtn>
          )
        }

        {
            (activeTab === 'available') || (activeTab === 'applied')
              ? (
                <MDBBtn
                  color={activeTab === 'applied' ? ('success') : ('info')}
                  style={{ width: '100%' }}
                  onClick={() => {
                    setCurrentWorkOrder(workOrder);
                    toggleModal(true, 'applyModal');
                  }}
                >
                  Apply/Counter
                </MDBBtn>
              ) : null
          }

        <MDBBtn
          color="info"
          style={{ width: '100%' }}
          onClick={() => {
            const { tab } = match.params;
            const pathname = tab ? `/dashboard/${tab}` : '/dashboard';
            history.push({
              pathname,
              search: `?type=work-order&id=${workOrder._id}`,
            });
            toggleModal(true, 'viewDetailsWorkOrderModal', false, { id: workOrder._id });
          }}
        >
          View
        </MDBBtn>
        {
          // eslint-disable-next-line no-nested-ternary
          workOrderControlByStatus.assigned
            ? workOrder.confirmed
              ? (
                <MDBBtn
                  color="success"
                  style={{ width: '100%' }}
                  disabled
                >
                  Confirmed
                </MDBBtn>
              )
              : (
                <MDBBtn
                  color="success"
                  style={{ width: '100%' }}
                  onClick={() => {
                    confirmWorkOrder([workOrder._id]);
                  }}
                >
                  Confirm
                </MDBBtn>
              )
            : null
        }
      </MDBRow>
    </td>
  );
};

export default withRouter(ControlsButton);
