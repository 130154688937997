

export const calculateFundsRequiredSum = ({
  amount,
  basicType,
  quantity,
  variableAmount,
  maxQuantity,
  variablePayAfter
}) => ((amount * (basicType === 'Site' ? 1 : quantity)) || 0)
  + ((variableAmount * (maxQuantity - variablePayAfter)) || 0)
