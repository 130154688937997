import React from 'react';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { MDBIcon, MDBBtn } from 'mdbreact';
import Notification from '../notification';

const BankInfoButton = () => (
  <div style={Container}>
    <MDBBtn className="width-inherit" onClick={exportBankInfoContractors}>
      <MDBIcon className="vwimg exportimg" icon="angle-double-left" />
      Export bank info
    </MDBBtn>
  </div>
);
export default BankInfoButton;

const Container = {
  width: '190px',
};

const exportBankInfoContractors = async () => {
  await axios.get('/api/admin/contractors/bankinfo/export', {
    responseType: 'blob',
  }).then((res) => {
    fileDownload(res.data, 'bankinfo.xlsx');
  }).catch((error) => {
    Notification('error', {
      message: error.response?.data?.message || error.message,
    });
  });
};
