import React from 'react';
import { MDBCol } from 'mdbreact';
import { Field } from 'redux-form';
import TimePickedField from '../../layout/reduxForm/customFields/TimePickerField';

const TimeSection = () => (
  <>
    <MDBCol className="flex-grow-0" style={{ minWidth: 'max-content' }}>
      <Field
        name="startTime"
        component={TimePickedField}
        label="Start Time"
      />
    </MDBCol>
    <MDBCol className="flex-grow-0" style={{ minWidth: 'max-content' }}>
      <Field
        name="endTime"
        component={TimePickedField}
        label="End Time"
      />
    </MDBCol>
  </>
);

export default TimeSection;
