import React from 'react';
import { connect } from 'react-redux';
import ClientFilterModal from './ClientDashboardWOFiltersModal';
import ContractorFilterModal from './ContractorDashboardWOFiltersModal';
import AdminDashboardWOFiltersModal from './AdminDashboardWOFiltersModal';

const FiltersModal = ({ auth }) => {
  switch (auth.user.userType) {
    case 'contractor': return <ContractorFilterModal />;
    case 'client': return <ClientFilterModal />;
    case 'superAdmin': return <AdminDashboardWOFiltersModal />;
    default: return <></>;
  }
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(FiltersModal);
