import React from 'react';
import { MDBIcon } from 'mdbreact';
import { connect } from 'react-redux';
import { toggleModal } from '../../storage/actions/modalsActions';
import { generateToken } from '../../storage/actions/superAdmin';
import Notification from '../notification';

const CompanyActions = ({
  toggleModal, item, generateToken
}) => {
  const handlerEditCompany = () => {
    toggleModal(true, 'editCompanyBySuperAdmin', false, item);
  };

  const generateAuthKey = () => {
    generateToken(item.creatorEmail)
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(item.authToken);
      Notification('success', {
        message: 'Copied to clipboard!',
      });
    } catch (error) {
      console.error('Unable to copy item to clipboard:', error);
    }
  };


  return (
    <>
      <MDBIcon
        far
        icon="edit"
        className="v-paddings-half"
        size="lg"
        onClick={handlerEditCompany}
      />
      <MDBIcon
        fas
        icon="key"
        className="v-paddings-half"
        size="lg"
        onClick={generateAuthKey}
      />
      <MDBIcon
        fas
        icon="copy"
        className="v-paddings-half"
        size="lg"
        onClick={copyToClipboard}
      />
    </>
  );
};
const mapDispatchToProps = {
  toggleModal,
  generateToken,
};

export default connect(null, mapDispatchToProps)(CompanyActions);
