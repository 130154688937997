import React from 'react';
import { MDBBtn, MDBCol } from 'mdbreact';

const ControlButtons = ({
  currentWorkOrder, setShowAddReportIssue, showAddReportIssue,
}) => (
  <MDBCol style={{ textAlign: 'end' }}>
    {
      !showAddReportIssue
        ? (
          <MDBBtn
            color="deep-orange"
            className="reportan_issuebut"
            onClick={() => setShowAddReportIssue(true)}
          >
            {`Report ${currentWorkOrder?.report_issue_details?.length > 0 ? 'another' : 'an'} issue`}
          </MDBBtn>
        )
        : <></>
    }
  </MDBCol>
);

export default ControlButtons;
