import React, { Component } from 'react';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import DatePicker from 'react-datepicker/es';
import combineDateAndTime from '../../../../utils/addTimeToDatefromAM_PMFormatString';
import CustomTimePicker from '../../../layout/CustomTimePicker';
import Notification from '../../../notification';

class EditCheckInCheckOut extends Component {
  state = { ...this.props.checkInCheckOutToEdit };

  handlerChange = (date, name) => {
    this.setState({
      [name]: date,
    });
  };

  saveEditedData = async (event) => {
    event.preventDefault();
    const sendData = {
      checkInDate: (!this.state.edit_check_in_date) ? '' : await combineDateAndTime(this.state.edit_check_in_date, this.state.edit_check_in_time),
      checkOutDate: (!this.state.edit_check_out_date) ? '' : await combineDateAndTime(this.state.edit_check_out_date, this.state.edit_check_out_time),
      description: this.state.work_description || '',
    };
    if (sendData.checkOutDate) {
      if (new Date(sendData.checkOutDate) < new Date(sendData?.checkInDate)) {
        // eslint-disable-next-line consistent-return
        return Notification('warning', { message: 'Check-out time must be later than check-in time' });
      }
      if ((!sendData.description.trim())) {
        return Notification('warning', { message: 'Please Enter Description of Work Performed!' });
      }
    }
    // eslint-disable-next-line react/destructuring-assignment
    const result = await this.props
      .editCheckInCheckOutData(
        sendData,
        this.props.currentWorkOrder._id,
        this.state.check_in_out_id,
      );
    if (result) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        showEditCheckInfo: false,
        edit_check_in_time: '12:00AM',
        edit_check_out_time: '12:00AM',
        edit_check_in_date: '',
        edit_check_out_date: '',
      });
    }
  };

  render() {
    const { cancelEdit, currentWorkOrder } = this.props;
    const {
      edit_check_in_date,
      edit_check_out_date,
      work_description,
      edit_check_in_time,
      edit_check_out_time,
      check_in_time_string,
      check_out_time_string,
    } = this.state;
    return (
      <div className=" d-flex edit_file_div">
        <MDBCol>
          <MDBRow style={{ marginBottom: '15px' }}>
            <MDBCol>
              <label className="font11">Check In Date</label>
              <DatePicker
                disabled={currentWorkOrder.status !== 'assigned'}
                className="form-control cind"
                dateFormat={
                  currentWorkOrder.status !== 'assigned'
                    ? 'MM/dd/yyyy h:mm'
                    : 'MM/dd/yyyy'
                }
                selected={edit_check_in_date}
                id="edit_check_in_date"
                value={edit_check_in_date}
                onChange={(date) => {
                  this.handlerChange(date, 'edit_check_in_date');
                }}
              />
            </MDBCol>
            {
              currentWorkOrder.status === 'assigned'
              && (
                <MDBCol>
                  <label className="font11">Check In Time</label>
                  <CustomTimePicker
                    className="cint"
                    id="timePickerEditCheckIn"
                    getValue={(date) => {
                      this.handlerChange(date, 'edit_check_in_time');
                    }}
                    hours={check_in_time_string && check_in_time_string.slice(0, check_in_time_string.indexOf(':'))}
                    minutes={check_in_time_string && check_in_time_string.slice(check_in_time_string.indexOf(':') + 1, -2)}
                    dayTime={check_in_time_string && check_in_time_string.slice(-2)}
                    hoursFormat={12}
                    value={edit_check_in_time}
                  />
                </MDBCol>
              )
            }
            <MDBCol>
              <label className="font11">Check Out Date</label>
              <DatePicker
                disabled={currentWorkOrder.status !== 'assigned'}
                className="form-control coutd"
                dateFormat={
                  currentWorkOrder.status !== 'assigned'
                    ? 'MM/dd/yyyy h:mm'
                    : 'MM/dd/yyyy'
                }
                selected={edit_check_out_date}
                id="edit_check_out_date"
                value={edit_check_out_date}
                onChange={(date) => {
                  this.handlerChange(date, 'edit_check_out_date');
                }}
              />
            </MDBCol>
            {
              currentWorkOrder.status === 'assigned'
              && (
                <MDBCol>
                  <label className="font11">Check Out Time</label>
                  <CustomTimePicker
                    className="cint"
                    id="timePickerEditCheckOut"
                    getValue={(date) => {
                      this.handlerChange(date, 'edit_check_out_time');
                    }}
                    hoursFormat={12}
                    hours={check_out_time_string && check_out_time_string.slice(0, check_out_time_string.indexOf(':'))}
                    minutes={check_out_time_string && check_out_time_string.slice(check_out_time_string.indexOf(':') + 1, -2)}
                    dayTime={check_out_time_string && check_out_time_string.slice(-2)}
                    value={edit_check_out_time}
                  />
                </MDBCol>
              )
            }
          </MDBRow>
          <MDBRow style={{ marginBottom: '15px' }}>
            <MDBCol>
              <label className="font11">Work Description</label>
              <textarea
                type="text"
                className="form-control descheck font11"
                placeholder="Description/Remark"
                id="work_description"
                name="work_description"
                value={work_description}
                onChange={(event) => {
                  this.handlerChange(event.target.value, 'work_description');
                }}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="font11" style={{ marginBottom: '15px' }}>
            <MDBCol
              className="upcomviewbuttondiv"
              style={{
                justifyContent: 'flex-end',
                textAlign: 'end',
              }}
            >
              <MDBBtn
                color="info"
                className=" editbuttonwom"
                onClick={this.saveEditedData}
              >
                Save
              </MDBBtn>
              <MDBBtn
                color="deep-orange"
                className="deletebuttonwom"
                onClick={cancelEdit}
              >
                Cancel
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </div>
    );
  }
}

export default EditCheckInCheckOut;
