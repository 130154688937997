import React from 'react';
import { connect } from 'react-redux';
import {
  MDBBtn,
  MDBCol,
  MDBIcon, MDBModal,
  MDBModalBody,
  MDBRow,
} from 'mdbreact';
import { SubmissionError, submit } from 'redux-form';
import { toggleModal } from '../../storage/actions/modalsActions';
import Notification from '../notification';
import CreateCustomFieldReduxForm from '../layout/reduxForm/CreateCustomField';
import validate from '../../utils/reduxForm/validate/createCustomField';

const EditCustomFieldModal = ({
  modalState,
  toggleModal,
  submit,
}) => {
  const closeModal = () => {
    toggleModal(!modalState.isOpen, 'editCustomFieldModal');
  };

  const addCustomFieldToWorkOrder = customFiled => {
    if (!(modalState.type?.input.value?.length > 0)) {
      modalState.type.input.onChange([customFiled]);
    } else if (modalState.type?.index > 0 || modalState.type?.index === 0) {
      const newCustomFields = modalState.type?.input.value.map((item, index) => (
        index !== modalState.type?.index
          ? item
          : customFiled
      ));
      modalState.type.input.onChange(newCustomFields);
    } else {
      modalState.type.input.onChange([...modalState.type?.input.value, customFiled]);
    }
    closeModal();
  };

  const onSubmit = async (values, dispatch, props) => {
    const validationErrors = validate(values);
    if (Object.keys(validationErrors).length) throw new SubmissionError(validationErrors);
    if (!props.dirty) return Notification('warning', { message: 'None changes found!' });
    const {
      name,
      value,
      type,
      customFieldId,
    } = values;
    addCustomFieldToWorkOrder({
      customFieldId,
      name,
      value,
      type: type.value,
    });
    return {};
  };

  const handlerSubmit = async event => {
    event.preventDefault();
    submit('createCustomFieldReduxForm');
  };

  return (
    <MDBModal
      className="applypop modal-width-500"
      isOpen={modalState.isOpen}
      toggle={closeModal}
      fullHeight
      position="right"
    >
      <MDBModalBody style={{ color: 'white' }}>
        <MDBRow className="h-paddings-half">
          <MDBCol style={{ textAlign: 'left' }}>
            <h6
              className="font-weight-bold"
              style={{ marginBottom: '15px' }}
            >
              Edit Custom Field
            </h6>
          </MDBCol>
          <MDBCol md="2" style={{ textAlign: 'end' }}>
            <MDBIcon
              far
              icon="times-circle"
              onClick={closeModal}
              size="lg"
              className="blue-grey-text"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <CreateCustomFieldReduxForm
              enableReinitialize
              onSubmit={onSubmit}
              initialValues={modalState?.type?.field || {}}
              disabled
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <div style={{ textAlign: 'end' }}>
              <MDBBtn
                type="button"
                color="secondary"
                onClick={closeModal}
                className="clientclose"
              >
                Close
              </MDBBtn>
              <MDBBtn
                color="primary"
                className="clientsave"
                type="submit"
                onClick={handlerSubmit}
              >
                Save
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
  submit,
};

const mapStateToProps = state => ({
  modalState: state.modalState.editCustomFieldModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomFieldModal);
