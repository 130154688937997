import React from 'react';
import {
  MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import { connect } from 'react-redux';
import { toggleModal } from '../../storage/actions/modalsActions';
import ContractorBankingInformationReduxForm from '../layout/reduxForm/ContractorBankingInformation';
import ControlButtonsSection from '../layout/reduxForm/section/contractorBankingInformation/ControlButtonsSection';
import onSubmit from '../../utils/reduxForm/onSubmit/contractorBankingInformation';

const AddBankInfoForContractor = ({
  modalState,
  toggleModal,
}) => {
  const closeModal = () => {
    toggleModal(!modalState.isOpen, 'addBankInfoForContractor');
  };

  return (
    <MDBModal
      className="applypop"
      isOpen={modalState.isOpen}
      toggle={closeModal}
      fullHeight
      position="right"
    >
      <MDBModalBody style={{ color: 'white' }}>
        <MDBRow className="mb-2">
          <MDBCol md="10">
            <h5 style={{ fontWeight: 'bold' }} className="">Payout Details</h5>
            <div className="font12">
              Tell us where you`d like to receive your payments
            </div>
          </MDBCol>
          <MDBCol md="2" style={{ textAlign: 'end' }}>
            <MDBIcon
              far
              icon="times-circle"
              onClick={closeModal}
              size="lg"
              className="blue-grey-text"
            />
          </MDBCol>
        </MDBRow>
        <ContractorBankingInformationReduxForm onSubmit={onSubmit} />
        <ControlButtonsSection closeModal={closeModal} />
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  modalState: state.modalState.addBankInfoForContractor,
});

export default connect(mapStateToProps, {
  toggleModal,
})(AddBankInfoForContractor);
