import React, { useEffect, useState } from 'react';
import {
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBNavLink,
  MDBRow,
} from 'mdbreact';
import { connect } from 'react-redux';
import TemplateRow from './templateRow';
import getTemplatesList from '../../utils/api/get/getTemplatesList';
import { toggleModal } from '../../storage/actions/modalsActions';
import {
  renameWorkOrderTemplate,
  deleteWorkOrderTemplate,
  copyWorkOrderTemplate,
} from '../../storage/actions/workOrdersActions';

const Content = ({
  isReload,
  toggleModal,
  renameWorkOrderTemplate,
  deleteWorkOrderTemplate,
  copyWorkOrderTemplate,
}) => {
  const [templates, setTemplates] = useState([]);

  const fetchTemplatesList = async () => {
    toggleModal(true, 'loader');
    const newTemplatesList = await getTemplatesList();
    setTemplates(newTemplatesList);
    toggleModal(false, 'loader');
  };

  useEffect(() => {
    fetchTemplatesList();
  }, [isReload]);

  const handlerCopy = templateId => {
    const saveCopy = name => {
      copyWorkOrderTemplate(name, templateId);
    };
    toggleModal(true, 'confirmModal', true, {
      onAccept: saveCopy,
      header: 'Copy template',
      inputLabel: 'Name',
      buttonLabels: { reject: 'Cancel' },
    });
  };

  const handlerDelete = templateId => {
    toggleModal(true, 'confirmModal', true, {
      onAccept: () => deleteWorkOrderTemplate(templateId),
      header: 'Are you sure you want to delete this template?',
      buttonLabels: { reject: 'Cancel' },
    });
  };

  return (
    <>
      <MDBCol>
        <MDBContainer>
          <MDBRow className="border-bottom-line-white-bold">
            <MDBCol md="9">
              <h5>Template Name</h5>
            </MDBCol>
            <MDBCol
              md="3"
              className="flex-box"
            >
              <MDBNavLink
                className="btn btn-success padding-02-05"
                to="/create-work-order-template"
              >
                <MDBIcon icon="plus-square" />
                <span
                  className="no-margins"
                  style={{ paddingLeft: '5px' }}
                >
                  Add Template
                </span>
              </MDBNavLink>
            </MDBCol>
          </MDBRow>
          {
            templates?.map(template => (
              <TemplateRow
                key={template.value}
                template={template}
                deleteTemplate={handlerDelete}
                rename={renameWorkOrderTemplate}
                copyTemplate={handlerCopy}
              />
            ))
          }
        </MDBContainer>
      </MDBCol>
    </>
  );
};

const mapDispatchToProps = {
  toggleModal,
  renameWorkOrderTemplate,
  deleteWorkOrderTemplate,
  copyWorkOrderTemplate,
};

const mapStateToProps = state => ({
  isReload: state.applicationState.isReload,
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
