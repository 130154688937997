import React from 'react';
import { MDBBtn } from 'mdbreact';

const CreateDocumentForm = ({
  name,
  handlerCreate,
}) => (
  <form onSubmit={handlerCreate}>
    <div className="form-group flex-column">
      <label htmlFor="formGroupExampleInput">
        {`${name} Name`}
      </label>
      <div className="d-flex">
        <input
          type="text"
          className="form-control"
          placeholder={`${name} Name`}
          style={{
            width: '240px',
            borderRadius: 0,
          }}
          name="documentName"
        />
        <MDBBtn
          color="primary"
          className="addclient no-margins"
          type="submit"
        >
          Add
        </MDBBtn>
      </div>
    </div>
  </form>
);

export default CreateDocumentForm;
