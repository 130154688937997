import React from 'react';
import { MDBCol } from 'mdbreact';
import SortPanel from './SortPanel';
import FiltersPanel from './filtersPanel';

const RightControlPanel = ({
  auth, sortType, order, setSort,
}) => (
  <MDBCol md="2" className="find_apply2">
    <SortPanel
      auth={auth}
      order={order}
      setSort={setSort}
      sortType={sortType}
    />
    <FiltersPanel />
  </MDBCol>
);

export default RightControlPanel;
