import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  MDBBtn, MDBCol, MDBContainer, MDBInput, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import { toggleModal } from '../../storage/actions/modalsActions';
import { acceptPolicingAgreement } from '../../storage/actions/profile';
import { useState } from 'react';

const AcceptAgreements = (
  {
    modalState,
    toggleModal,
    acceptPolicingAgreement,
    profile,
  },
) => {
  const [isAccpeted, setIsAccepted] = useState(false);
  const closeModal = () => toggleModal(!modalState.isOpen, 'acceptAgreements');
  const submitApprove = () => {
    acceptPolicingAgreement(profile.userId);
    closeModal();
  };
  return (
    <MDBModal
      isOpen={modalState.isOpen}
      centered
    >
      <MDBModalBody style={{
        fontFamily: 'Arial, Helvetica, sans-serif',
        backgroundColor: 'white',
      }}
      >
        <MDBContainer>
          <div className="flex-box flex-center full-width" style={{ flexWrap: 'wrap' }}>
            <h6>Accept Policy and Privacy Agreements</h6>
            <MDBRow>
              <MDBInput
                type="checkbox"
                className="regcheck "
                id={`checkbox`}
                containerClass='col-md-1'
                checked={isAccpeted}
                onChange={() => {
                  setIsAccepted(prev => !prev)
                }}
              />
              <label className='col-md-11 ps-2' >

                <span className="font12 ">
                  I have read and agreed to the updated
                  {' '}
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a
                    href="https://www.sourcew9.com/SW9_Terms_Updated_2024.01.29_(002).pdf"
                    target="_blank"
                    style={{
                      textDecorationLine: 'underline',
                      color: '#e87b68',
                    }}
                  >
                    Term of Service
                  </a>
                  {' '}
                  and
                  {' '}
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a
                    href="https://www.sourcew9.com/Privacy_Policy.pdf"
                    target="_blank"
                    style={{
                      textDecorationLine: 'underline',
                      color: '#e87b68',
                    }}
                  >
                    Customer Privacy Policy
                  </a>
                </span>
              </label>

            </MDBRow>

            <MDBRow className="flex-center">
              <MDBCol className="flex-grow-0">
                <MDBBtn color="success" disabled={!isAccpeted} onClick={submitApprove}>agree</MDBBtn>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
  acceptPolicingAgreement,
};

const mapStateToProps = state => ({
  modalState: state.modalState.acceptAgreements,
  profile: state.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptAgreements);
