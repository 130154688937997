import React from 'react';
import {
  MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBRow,
} from 'mdbreact';

const PaymentMethodCard = ({
  paymentMethod, editPaymentMethod, deletePaymentMethod, makePrimary, auth,
}) => (
  <MDBCard className="card-payment-method" style={{ width: '22rem', margin: '1rem' }}>
    <MDBCardBody>
      <MDBCardTitle>
        {paymentMethod.brand}
        {' '}
        card ending in
        {' '}
        {paymentMethod.maskedCardNumber}
      </MDBCardTitle>
      <MDBCardText style={{ color: 'white', fontStyle: 'italic' }}>
        Expires
        {' '}
        {paymentMethod.expMonth}
        {' '}
        /
        {paymentMethod.expYear}
      </MDBCardText>
      <MDBRow>
        <MDBCol md="6">
          {
            !auth.user.primaryPaymentMethod
              && (
              <MDBBtn
                style={{
                  padding: '0.1rem 0.5rem',
                  whiteSpace: 'nowrap',
                  fontSize: '0.8rem',
                }}
                outline
                color="elegant"
                onClick={() => makePrimary(paymentMethod)}
              >
                <span style={{ color: 'white' }}>
                  Make Primary
                </span>
              </MDBBtn>
              )
          }
        </MDBCol>
        <MDBCol md="6" className="flex-box" style={{ paddingLeft: 0 }}>
          <MDBBtn
            style={{
              padding: '0.1rem 0.5rem',
              whiteSpace: 'nowrap',
              fontSize: '0.8rem',
            }}
            outline
            color="elegant"
            onClick={() => deletePaymentMethod(paymentMethod._id)}
          >
            <span style={{ color: 'white' }}>
              Remove
            </span>
          </MDBBtn>
          <MDBBtn
            style={{
              padding: '0.1rem 0.5rem',
              whiteSpace: 'nowrap',
              fontSize: '0.8rem',
            }}
            outline
            color="elegant"
            onClick={() => editPaymentMethod(paymentMethod)}
          >
            <span style={{ color: 'white' }}>
              Edit
            </span>
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </MDBCardBody>
  </MDBCard>
);

export default PaymentMethodCard;
