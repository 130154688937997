import React from 'react';
import { MDBBtn } from 'mdbreact';
import { connect } from 'react-redux';
import { submit, change } from 'redux-form';

const ControlButtonsForCreateAndEditWorkOrder = ({
  editingWorkOrderStatus,
  history,
  submit,
  change,
}) => {
  const cancel = (event) => {
    event.preventDefault();
    history.goBack();
  };

  const handlerSave = async event => {
    event.preventDefault();
    await change(
      'createWorkOrderReduxForm',
      'status',
      editingWorkOrderStatus === 'assigned' ? 'assigned' : 'published',
    );
    submit('createWorkOrderReduxForm');
  };

  const handlerSaveAsDraft = async event => {
    event.preventDefault();
    await change(
      'createWorkOrderReduxForm',
      'status',
      'draft',
    );
    submit('createWorkOrderReduxForm');
  };

  return (
    <div className="flex-box justify-content-end">
      <MDBBtn
        color="info"
        type="button"
        name={editingWorkOrderStatus === 'assigned' ? 'assigned' : 'published'}
        className="sandp"
        onClick={handlerSave}
      >
        Save
        {editingWorkOrderStatus === 'assigned' ? '' : ' And Publish'}
      </MDBBtn>
      {
        editingWorkOrderStatus !== 'assigned'
          ? (
            <MDBBtn
              color="info"
              type="submit"
              name="draft"
              className="sandp"
              onClick={handlerSaveAsDraft}
            >
              Save As Draft
            </MDBBtn>
          )
          : <></>
      }
      <MDBBtn color="deep-orange" type="button" className="sandp" onClick={cancel}>Cancel</MDBBtn>
    </div>
  );
};

const mapDispatchToProps = {
  submit,
  change,
};

export default connect(null, mapDispatchToProps)(ControlButtonsForCreateAndEditWorkOrder);
