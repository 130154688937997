import React from 'react';

const Spiner = ({color = 'white'}) => {
    return (
        <div className='spinner-border' style={{ color }} role='status'>
            <span className='sr-only' >Loading...</span>
        </div>
    );
}

export default Spiner;
