import React from 'react';
import {
  MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import { toggleModal } from '../../storage/actions/modalsActions';
import onSubmit from '../../utils/reduxForm/onSubmit/addFundsToDwollaBalanceBySuperAdmin';
import AddFundsToDwollaBalanceBySuperAdminReduxForm from '../layout/reduxForm/AddFundsToDwollaBalanceBySuperAdmin';

const AddFundsToDwollaBalance = ({
  modalState, toggleModal,
}) => {
  const closeModal = () => {
    toggleModal(false, 'addFundsToDwollaBalance');
  };

  return (
    <MDBModal
      className="applypop modal-width-500"
      isOpen={modalState.isOpen}
      toggle={closeModal}
      fullHeight
      position="right"
    >
      <MDBModalBody style={{ color: 'white' }}>
        <MDBRow className="h-paddings-half justify-content-end">
          <MDBCol md="2" style={{ textAlign: 'end' }}>
            <MDBIcon
              far
              icon="times-circle"
              onClick={closeModal}
              size="lg"
              className="blue-grey-text"
            />
          </MDBCol>
        </MDBRow>
        <AddFundsToDwollaBalanceBySuperAdminReduxForm onSubmit={onSubmit} closeModal={closeModal} />
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
};

const mapStateToProps = (state) => ({
  modalState: state.modalState.addFundsToDwollaBalance,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFundsToDwollaBalance);
