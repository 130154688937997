import React from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import { getContractorTaxInfo, getContractorBankingInfo } from '../../../../storage/actions/profile';
import { toggleModal } from '../../../../storage/actions/modalsActions';

const ButtonControl = ({
  profile,
  toggleModal,
  getContractorTaxInfo,
  getContractorBankingInfo,
}) => {
  const openTaxInformationModal = () => {
    getContractorTaxInfo()
      .then(res => {
        if (res?.data?.success) toggleModal(true, 'contractorTaxInformationModal');
      });
  };

  const openBankingInformationModal = () => {
    getContractorBankingInfo()
      .then(res => {
        if (res?.data?.success) toggleModal(true, 'addBankInfoForContractor');
      });
  };

  return (
    <MDBRow>
      <MDBCol>
        <MDBBtn
          onClick={openTaxInformationModal}
        >
          Tax Info
        </MDBBtn>
        {
          // eslint-disable-next-line no-nested-ternary
          !profile?.policingAgreements
            ? (
              <MDBBtn
                onClick={() => toggleModal(true, 'acceptAgreements')}
              >
                Policing Agreements Update Required
              </MDBBtn>
            )
            : (
              <MDBBtn
                onClick={openBankingInformationModal}
              >
                PAYMENT INFO
              </MDBBtn>
            )
        }
      </MDBCol>
    </MDBRow>
  );
};

const mapDispatchToProps = {
  toggleModal,
  getContractorTaxInfo,
  getContractorBankingInfo,
};

export default connect(null, mapDispatchToProps)(ButtonControl);
