import React from 'react';

const Sel = ({ col, item }) => (
  <td className={col.className}>
    {
      col.format
        ? col.format(item[col.value], item)
        : item[col.value]
    }
  </td>
);

export default Sel;
