import React from "react";
import {MDBBtn} from "mdbreact";

const InfoMessage = ({header, contentArray = [], click = {}}) => {
    return <div className='font11' >
        <h6>{header}</h6>
        {
            contentArray.length
                ? <ul className='ul-info-toast' >
                    {
                        contentArray.map( message => (
                            <li key={message} >
                                {message}
                            </li>
                        ))
                    }
                </ul>
                : <></>
        }
        {
            click.label
                ? <MDBBtn
                    onClick={click.onClick}
                    color={click.color || 'success'}
                >
                    {click.label}
                </MDBBtn>
                : <></>
        }
    </div>
}

export default InfoMessage;