import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdbreact';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import Modal from '../../components/modal';
import { toggleModal } from '../../storage/actions/modalsActions';
import getTeams from '../../utils/api/get/getTeams';
import deleteTeam from '../../utils/api/delete/deleteTeam';
import { reloadData } from '../../storage/actions/applicationStateActions';
import Notification from '../../components/notification';

const tableFields = ['Team name', 'Members'];

const ManageFields = ({
  mainContainer, toggleModal, isReload, reloadData,
}) => {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    fetchTeams();
  }, [isReload]);

  const fetchTeams = async () => {
    toggleModal(true, 'loader');

    const data = await getTeams();

    setTeams(data);
    toggleModal(false, 'loader');
  };

  const openEditTeamModal = (teamId) => {
    toggleModal(true, 'addOrEditTeams', false, { id: teamId });
  };

  const openAddTeamModal = () => {
    toggleModal(true, 'addOrEditTeams');
  };
  
  const copyTeamId = async (teamId) => {
    try {
      await navigator.clipboard.writeText(teamId);
      Notification('success', {
        message: 'Team ID copied to clipboard!',
      });
    } catch (error) {
      console.error('Unable to copy item to clipboard:', error);
    }
  };

  const onDeleteTeam = (teamId, name) => {
    toggleModal(true, 'confirmModal', true, {
      onAccept() {
        deleteTeam(teamId).then(() => reloadData());
      },
      header: `Delete ${name || 'team'}?`,
      buttonLabels: {
        reject: 'Cancel',
        confirm: 'Delete',
      },
    });
  };

  return (
    <MDBContainer className="clientcreateworkorderdiv" fluid>
      <MDBRow className="flex-box justify-content-center">
        <MDBCol size="8">
          <MDBRow>
            <MDBCol md="9" className="flex-box align-items-center">
              <h4>Manage Teams</h4>
            </MDBCol>
            <MDBCol md="3" className="flex-box justify-content-end">
              <MDBBtn color="success" onClick={openAddTeamModal}>
                Add team
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol size="8">
          <MDBTable className="text-white">
            <MDBTableHead>
              <tr>
                {tableFields.map(field => (
                  <th key={field}>{field}</th>
                ))}
                <th> </th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {teams.map(team => {
                const { name, _id, teamUsersCount } = team;
                return (
                  <tr key={_id}>
                    <th
                      className="cursor_pointer"
                      onClick={() => openEditTeamModal(_id, name)}
                    >
                      {name}
                    </th>
                    <th>{teamUsersCount}</th>
                    <th className="text-align-right">
                      <MDBIcon
                        icon="edit"
                        size="lg"
                        onClick={() => openEditTeamModal(_id, name)}
                        title="Edit"
                        style={{
                          color: '#41566a',
                          cursor: 'pointer',
                          padding: '5px',
                        }}
                      />
                      <MDBIcon
                        icon="trash"
                        size="lg"
                        onClick={() => onDeleteTeam(_id, name)}
                        title="Delete"
                        style={{
                          color: '#41566a',
                          cursor: 'pointer',
                          padding: '5px',
                        }}
                      />
                      <MDBIcon
                        icon="copy"
                        size="lg"
                        onClick={() => copyTeamId(_id)}
                        title="Copy"
                        style={{
                          color: '#41566a',
                          cursor: 'pointer',
                          padding: '5px',
                        }}
                      />
                    </th>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
        </MDBCol>
      </MDBRow>
      <Modal mainContainer={mainContainer.current} />
    </MDBContainer>
  );
};

const mapDispatchToProps = {
  toggleModal, reloadData,
};
const mapStateToProps = state => ({
  modalState: state.modalState.addOrEditTeams,
  isReload: state.applicationState.isReload,
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageFields);
