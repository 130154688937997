import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import CheckInCheckOutFields from './checkInCheckOut';
import WorkOrderDetailsButtonControl from './WorkOrderDitalsButtonControl';
import Notification from '../../notification';
import IssueReports from './issueReports';
import ApproveWorkOrder from './approveWorkOrder';
import AppliedContractorDetails from './AppliedContractorDetails';
import InvitedContractorDetails from './InvitedContractorDetails';
import ApplicantCard from '../../layout/contractorCards/ApplicantCard';
import WorkOrderHistory from './workOrderHistory';
import UploadDeliverables from './UploadDeliverables';
import WorkOrderExpenses from './expenses';
import ContractorTasks from './ContractorTasks';
import workOrderStatusControl from '../../../utils/workOrderStatusControl';
import { toggleModal, toggleSecondModalClose } from '../../../storage/actions/modalsActions';
import {
  deleteWorkOrderDetails,
  completeWorkOrder,
  editOnHoldStatusWorkOrder,
  createNewIssueReport,
  resolveIssueReport,
  sendIssueAgain,
  fetchApplicantsForWorkOrder,
  approvedWorkOrder,
  checkInCheckOutToWorkOrder,
  deleteCheckInCheckOutData,
  editCheckInCheckOutData,
  setCurrentApplicants,
  approvePayAdjustmentByContractor,
  deleteUpload, uploadDocument,
  saveExpense, editExpense, deleteExpense,
  cancelWorkOrder,
  assignContractor,
  saveApproveData,
  saveTasks,
  sendInviteFromWorkOrderToContractor,
  unRouteWorkOrder,
  createWorkOrderTemplateByWorkOrder,
  fetchWorkOrderById,
  acceptInvite,
  setDocumentsDownloadInProcess,
  changeCustomField
} from '../../../storage/actions/workOrdersActions';
import {
  sendBlockCompanyRequest,
  sendUnblockCompanyRequest,
} from '../../../storage/actions/profile';
import { localCurrencySettings } from '../../../utils/constans';
import PreviousApplicantsHistory from './previousApplicantsHistory';
import DeviceQty from './DeviceQty';
import isDeviceType from '../../../utils/isDeviceType';
import DocumentSection from './DocumentSection';
import ContentConfirmModalOnBlockCompanyByContractor
  from '../../layout/ContentConfirmModalOnBlockCompanyByContractor';
import ContractorExportWorkOrderButton from './ContractorExportWorkOrderButton';

const ViewDetailsWorkOrderModal = ({
  mainContainer,
  currentWorkOrder,
  auth,
  modalState,
  routedApplicants,
  applicants,
  currentWorkOrderLoaded,
  history, match,
  profile,
  toggleModal, deleteWorkOrderDetails, editOnHoldStatusWorkOrder, completeWorkOrder,
  createNewIssueReport, resolveIssueReport, sendIssueAgain, fetchApplicantsForWorkOrder,
  approvedWorkOrder, checkInCheckOutToWorkOrder, uploadDocument,
  deleteCheckInCheckOutData, editCheckInCheckOutData, setCurrentApplicants, deleteUpload,
  approvePayAdjustmentByContractor, saveExpense, editExpense, deleteExpense, cancelWorkOrder,
  sendBlockCompanyRequest, sendUnblockCompanyRequest, assignContractor, saveApproveData, changeCustomField,
  saveTasks, toggleSecondModalClose, sendInviteFromWorkOrderToContractor,
  unRouteWorkOrder, createWorkOrderTemplateByWorkOrder, fetchWorkOrderById, acceptInvite, setDocumentsDownloadInProcess, 
  documentsDownloadInProcess
}) => {
  const workOrderControlByStatus = currentWorkOrder
    ? workOrderStatusControl(currentWorkOrder.status)
    : {};
  const [tasks, changeStatusTasks] = useState({
    preArrivalRequirements: [],
    onSiteRequirements: [],
    completionRequirements: [],
  });
  const [blockedContractors, setBlockedContractors] = useState([]);
  const [qtyDevices, setQtyDevices] = useState();

  useEffect(() => {
    if (modalState?.type?.id || modalState?.type?.win) {
      fetchWorkOrderById(modalState?.type?.id, modalState?.type?.win);
    }
  }, []);

  useEffect(() => {
    if (currentWorkOrder?._id && currentWorkOrder?._id === modalState?.type?.id) {
      const newBlockedContractors = [
        ...(currentWorkOrder.blocked_users || []),
        ...((currentWorkOrder.companyNCR && currentWorkOrder.companyNCR.blocked_users) || []),
        ...((currentWorkOrder.projectInfo && currentWorkOrder.projectInfo.blocked_users) || []),
        ...((currentWorkOrder.woCompanyInfo && currentWorkOrder.woCompanyInfo.blocked_users) || []),
      ];
      setBlockedContractors(newBlockedContractors);
      if (currentWorkOrder?.tasks) changeStatusTasks(currentWorkOrder.tasks);
      setQtyDevices(currentWorkOrder?.qtyDevices);
    }
  }, [currentWorkOrder]);

  const closeModal = () => {
    const { tab } = match.params;
    const pathname = tab ? `/dashboard/${tab}` : '/dashboard';
    history.push(pathname);
    toggleModal(!modalState.isOpen, 'viewDetailsWorkOrderModal');
  };

  const saveTasksHandler = async (newTasks) => {
    const { _id } = currentWorkOrder;
    await saveTasks(_id, newTasks);
    changeStatusTasks(newTasks);
  };

  const checkAllCheckbox = (data) => [
    ...data.preArrivalRequirements,
    ...data.onSiteRequirements,
    ...data.completionRequirements]
    .every((el) => el.checked === true);
  const completeWorkOrderHandler = (workOrderId, data, isDevice) => {
    if (!checkAllCheckbox(data)) {
      return Notification('warning', { message: 'Need finish required tasks!' });
    }
    if (isDevice && !(qtyDevices > 0)) {
      return Notification('warning', { message: 'Enter the number of devices' });
    }
    return completeWorkOrder(workOrderId, qtyDevices);
  };

  const showContractorProfile = contractorId => {
    toggleModal(true, 'contractorDetailsModal', false, { contractorId });
  };
  const changeCustomFieldValue = (customField) => {
    changeCustomField({customField, workOrderId: currentWorkOrder._id})
  }

  const blockCompanyNCRByContractor = () => {
    toggleModal(true, 'confirmModal', true, {
      onAccept: () => sendBlockCompanyRequest(currentWorkOrder.companyId),
      header: `Block ${currentWorkOrder?.companyNCR?.name || 'NCR'}?`,
      content: <ContentConfirmModalOnBlockCompanyByContractor />,
      buttonLabels: {
        reject: 'Cancel',
        confirm: 'Yes, block',
      },
    });
  };

  const copyShareUrl = async () => {
    const { tab } = match.params;
    console.log({ tab })
    const url = window.location.origin + `/dashboard/${tab}?type=work-order&win=${currentWorkOrder.WIN}`

    await navigator.clipboard.writeText(url);

    Notification('success', { message: 'Copied successfully' });
  }

  if (!(modalState?.isOpen && currentWorkOrder?._id && currentWorkOrderLoaded)) return <></>;

  return (
    <MDBModal
      className="viewpop"
      isOpen={modalState.isOpen && currentWorkOrder?._id && currentWorkOrderLoaded}
      toggle={closeModal}
      fullHeight
      position="right"
    >
      <MDBModalBody style={{ overflow: 'hidden scroll', color: 'white' }}>
        <MDBRow style={{ marginBottom: '15px' }}>
          <MDBCol md="6" style={{ textAlign: 'left' }}>
            <h6 style={{ fontWeight: 'bold', marginBottom: '15px' }}>
              Work Order Details
            </h6>
            <MDBBtn color="info" onClick={copyShareUrl}>
              <MDBIcon icon="share-square" />
            </MDBBtn>
            {auth.user.userType === 'contractor' && 
              ['assigned', 'completed', 'approved', 'paid'].includes(currentWorkOrder.status)
                ? <ContractorExportWorkOrderButton id={currentWorkOrder._id} /> 
                : null
            }
          </MDBCol>
          <MDBCol md="6" style={{ textAlign: 'end' }}>
            <MDBIcon
              icon="times-circle"
              size="lg"
              style={{ color: '#41566a', cursor: 'pointer' }}
              onClick={closeModal}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow style={{ marginBottom: '0px', textAlign: 'left' }}>
          <MDBCol md="8">
            {
              auth.user.userType !== 'superAdmin'
                ? (
                  <div className="flex-box" style={{ justifyContent: 'space-between' }}>
                    <h6 className="font14">
                      <b>{currentWorkOrder.title}</b>
                      <span className="dashdiv">-</span>
                      <span
                        style={{
                          color: 'green',
                          textTransform: 'capitalize',
                        }}
                      >
                        {
                          auth.user.userType === 'contractor'
                          && workOrderControlByStatus.beforeAssigned
                          && !workOrderControlByStatus.canceled
                            ? 'published'
                            : currentWorkOrder.status
                        }
                      </span>
                    </h6>
                    <div
                      role="button"
                      aria-hidden="true"
                      style={{ cursor: 'pointer' }}
                      onClick={() => toggleModal(true, 'workOrderMessageSystemModal', true, { workOrder: currentWorkOrder })}
                    >
                      <MDBIcon icon="comments" />
                      <span style={{ color: '#65ee2f', marginLeft: '0.25rem' }}>Messages</span>
                    </div>
                  </div>
                )
                : <></>
            }
            {
              currentWorkOrder.reasonToCancelWorkOrder
              && workOrderControlByStatus.canceled
              && (
                <div className="font11">
                  Cancel reason:
                  {' '}
                  {currentWorkOrder.reasonToCancelWorkOrder}
                </div>
              )
            }
            <MDBRow>
              <MDBCol>
                <div>
                  <h6 className="font14">
                    <span style={{ fontWeight: 'bold' }}>WIN: </span>
                    {currentWorkOrder.WIN}
                  </h6>
                </div>
              </MDBCol>
            </MDBRow>
            <div className="d-flex" style={{ width: '150%' }}>
              <div style={{ marginRight: '10px' }}>
                <span className="font11">Company WO ID:</span>
                <span className="dashdiv font11Bold">
                  {currentWorkOrder.customTagId}
                </span>
                <br />
                <span className="font11">Created Date:</span>
                <span className="dashdiv font11Bold">
                  {new Date(currentWorkOrder.createdAt).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                </span>
              </div>
              <div style={{ marginRight: '10px' }}>
                <span className="font11">Start Date:</span>
                <span className="dashdiv font11Bold">
                  {new Date(currentWorkOrder.startDate).toLocaleDateString('en-US') === 'Invalid Date'
                    ? ''
                    : new Date(currentWorkOrder.startDate).toLocaleDateString('en-US')}
                </span>
                <br />
                <span className="font11">End Date: </span>
                <span className="dashdiv font11Bold">
                  {new Date(currentWorkOrder.endDate).toLocaleDateString('en-US') === 'Invalid Date'
                    ? ''
                    : new Date(currentWorkOrder.endDate).toLocaleDateString('en-US')}
                </span>
              </div>
              <div>
                <span className="font11">Start Time:</span>
                <span className="dashdiv font11Bold">
                  {currentWorkOrder.startTime}
                </span>
                <br />
                <span className="font11">End Time: </span>
                <span className="dashdiv font11Bold">
                  {currentWorkOrder.endTime}
                </span>
              </div>
            </div>
          </MDBCol>
          <MDBCol md="3">
            <div className="d-flex">
              <h6 className="font14">
                {
                  auth.user.userType === 'contractor'
                    ? (
                      <>
                        <b>Client:</b>
                        <span className="dashdiv">{currentWorkOrder.companyNCR.name}</span>
                      </>
                    )
                    : (
                      <>
                        <b>Created By:</b>
                        <span className="dashdiv">{currentWorkOrder.clientInfo?.name || currentWorkOrder.companyNCR.name}</span>
                      </>
                    )
                }
                <span>{`${currentWorkOrder?.clientInfo?.ratings || 100}%`}</span>
                {
                  auth.user.userType !== 'contractor' && currentWorkOrder?.templateId
                    ? (
                      <div
                        title="Create using template"
                        className="blue-grey-text font-size-08 text-in-one-line"
                      >
                        {`Template${
                          currentWorkOrder?.templateInfo?.name
                            ? ` "${currentWorkOrder?.templateInfo?.name}"`
                            : ''
                        } Used`}
                      </div>
                    )
                    : <></>
                }
              </h6>
            </div>
            {
              profile.userType === 'contractor' && !workOrderControlByStatus.beforeAssigned
                ? (
                  <div>
                    {
                      currentWorkOrder?.contractorInfo?.blockedCompanies?.includes(
                        currentWorkOrder.companyId,
                      )
                        ? (
                          <MDBBtn
                            color="red"
                            style={{ width: '100%' }}
                            className="assignbutton"
                            onClick={() => {
                              sendUnblockCompanyRequest(currentWorkOrder.companyId);
                            }}
                          >
                            Unblock company
                          </MDBBtn>
                        )
                        : (
                          <MDBBtn
                            color="success"
                            style={{ width: '100%' }}
                            className="assignbutton"
                            onClick={blockCompanyNCRByContractor}
                          >
                            Block Client
                          </MDBBtn>
                        )
                    }
                  </div>
                )
                : null
            }
          </MDBCol>
          {
            auth.user.userType === 'client' && (
              <WorkOrderDetailsButtonControl
                currentWorkOrder={currentWorkOrder}
                deleteWorkOrderDetails={deleteWorkOrderDetails}
                editOnHoldStatusWorkOrder={editOnHoldStatusWorkOrder}
                toggleModal={toggleModal}
                toggleSecondModalClose={toggleSecondModalClose}
                fetchApplicantsForWorkOrder={fetchApplicantsForWorkOrder}
                cancelWorkOrder={cancelWorkOrder}
                createWorkOrderTemplateByWorkOrder={createWorkOrderTemplateByWorkOrder}
              />
            )
          }
        </MDBRow>
        <MDBRow>
          <hr className="my-4 hrline2" />
        </MDBRow>
        <h6
          style={{
            fontWeight: 'bold',
            marginBottom: '30px',
          }}
        >
          <b>Offer Details</b>
        </h6>
        {currentWorkOrder.isNeedDeliverables && (
          <div>
            <h6 className="font14" style={{ marginBottom: '1rem' }}>
              Upload Deliverables Required!
            </h6>
          </div>
        )}
        <MDBRow style={{ marginBottom: '30px', textAlign: 'left' }}>
          <MDBCol>
            <div>
              <h6 className="font14">
                <b>Base Pay</b>
              </h6>
            </div>
            <div className="d-flex">
              <MDBCol style={{ paddingLeft: '0px' }}>
                <div className="d-flex">
                  <label className="font11">Amount:</label>
                  <div className="dashdiv font11Bold">
                    {
                      (
                        workOrderControlByStatus.beforeAssigned
                        && !workOrderControlByStatus.canceled
                          ? currentWorkOrder.amount
                          : (currentWorkOrder.bidAmountBase || currentWorkOrder.amount)
                      ).toLocaleString('en-US', localCurrencySettings)
                    }
                  </div>
                </div>
                <div className="d-flex">
                  <label className="font11">Qty:</label>
                  <div className="dashdiv font11Bold">
                    {currentWorkOrder.quantity}
                  </div>
                </div>
                <div className="d-flex">
                  <label className="font11">Est. Pay:</label>
                  <div className="dashdiv font11Bold">
                    {
                      currentWorkOrder.potentialPay
                        ? (currentWorkOrder.potentialPay).toLocaleString('en-US', localCurrencySettings)
                        : '$0.00'
                    }
                    {currentWorkOrder.payBaseRateAsMinimum && (
                      <span
                        style={{
                          marginLeft: '0.5rem',
                          color: 'red',
                          padding: '0.5rem',
                          border: '3px solid red',
                        }}
                      >
                        MIN
                      </span>
                    )}
                  </div>
                </div>
              </MDBCol>
              <MDBCol>
                <div className="d-flex">
                  {currentWorkOrder.basicType
                  && currentWorkOrder.basicType !== ''
                  && (<label className="font11">Per </label>)}
                  {currentWorkOrder.basicType
                  && currentWorkOrder.basicType !== ''
                  && (<div className="dashdiv font11Bold" />)}
                  <div className="font11Bold">
                    {currentWorkOrder.basicType === 'Hours'
                      ? 'Hour'
                      : currentWorkOrder.basicType}
                    {currentWorkOrder.basicType === 'Device'
                      ? ` (${currentWorkOrder.quantity && currentWorkOrder.quantity !== ''
                        ? currentWorkOrder.quantity
                        : '0'})`
                      : ''}
                  </div>
                </div>
              </MDBCol>
            </div>
          </MDBCol>
          <MDBCol>
            <div>
              <h6 className="font14">
                <b>How Pay is Divided</b>
              </h6>
            </div>
            <div>
              <div className="d-flex">
                <label className="font11">Soft Skills:</label>
                <div className="dashdiv font11Bold">
                  {currentWorkOrder.percentCommunication
                  && currentWorkOrder.percentCommunication !== ''
                    ? `${currentWorkOrder.percentCommunication}%`
                    : '0%'}
                </div>
              </div>
              <div className="d-flex">
                <label className="font11">Work:</label>
                <div className="dashdiv font11Bold">
                  {currentWorkOrder.percentWork
                  && currentWorkOrder.percentWork !== ''
                    ? `${currentWorkOrder.percentWork}%`
                    : '0%'}
                </div>
              </div>
              <div className="d-flex">
                <label className="font11">Deliverables:</label>
                <div className="dashdiv font11Bold">
                  {currentWorkOrder.percentDeliverables
                  && currentWorkOrder.percentDeliverables !== ''
                    ? `${currentWorkOrder.percentDeliverables}%`
                    : '0%'}
                </div>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow style={{ marginBottom: '0px', textAlign: 'left' }}>
          <MDBCol>
            <div>
              <h6 className="font14">
                <b>Variable Pay (optional)</b>
              </h6>
            </div>
            <div className="">
              <div className="d-flex">
                <label className="font11">Amount:</label>
                <div className="dashdiv font11">
                  {
                    (
                      workOrderControlByStatus.beforeAssigned
                      && !workOrderControlByStatus.canceled
                        ? currentWorkOrder.variableAmount || 0
                        : (
                          currentWorkOrder.bidAmountVariable
                          || currentWorkOrder.variableAmount
                          || 0
                        )
                    ).toLocaleString('en-US', localCurrencySettings)
                  }
                </div>
              </div>
              <div className="d-flex">
                <label className="font11">After:</label>
                <div className="dashdiv font11">{currentWorkOrder.variablePayAfter || 0}</div>
              </div>
              <div className="d-flex">
                <label className="font11">Max Qty:</label>
                <div className="dashdiv font11">{currentWorkOrder.maxQuantity || 0}</div>
              </div>
            </div>
          </MDBCol>
          <MDBCol>
            {currentWorkOrder.payOutsideOfApp
            && (
              <div style={{ padding: '10px', color: '#009933' }}>
                <span className="font14" style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  Paid Outside of SW9 Directly by Client!
                </span>
              </div>
            )}
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <hr className="my-4 hrline2" />
        </MDBRow>
        <h6
          style={{
            fontWeight: 'bold',
            marginBottom: '30px',
          }}
        >
          <b>Location</b>
          {currentWorkOrder.isRemote && 
            <b className='ml-5'>Remote/WFH</b>
          }
        </h6>
        <MDBRow style={{ marginBottom: '0px', marginTop: 20 }}>
          <MDBCol md="3">
            <span className="font11">Site ID: </span>
            <span className="dashdiv font11Bold">{currentWorkOrder.siteId}</span>
          </MDBCol>
          <MDBCol md="3">
            <span className="font11">Site Name:</span>
            <span className="dashdiv font11Bold">{currentWorkOrder.siteName}</span>
          </MDBCol>
          <MDBCol md="3">
            <div className="font11Bold">
              {
                (!workOrderControlByStatus.beforeAssigned || auth.user.userType !== 'contractor')
                && (
                  <>
                    <div>
                      {`${currentWorkOrder?.address1 || ''} `}
                    </div>
                    <div>
                      {`${currentWorkOrder?.address2 || ''} `}
                    </div>
                  </>
                )
              }
              <div>
                <span>
                  {`${currentWorkOrder?.city || ''} `}
                </span>
                <span>
                  {`${currentWorkOrder?.state?.label || ''} `}
                </span>
                <span>
                  {`${currentWorkOrder?.zipCode || ''} `}
                </span>
              </div>
            </div>
          </MDBCol>
          {
            ((!workOrderControlByStatus.beforeAssigned || auth.user.userType !== 'contractor') && !currentWorkOrder.isRemote)
              ? (
                <MDBCol md="3">
                  <MDBBtn
                    color="deep-orange"
                    className="deletebutton"
                    onClick={() => toggleModal(true, 'googleMapsModal', true)}
                  >
                    [Google Map]
                  </MDBBtn>
                </MDBCol>
              )
              : null
          }
        </MDBRow>
        <MDBRow style={{ marginBottom: '0px', textAlign: 'left' }}>
          {
            (!workOrderControlByStatus.beforeAssigned || auth.user.userType !== 'contractor')
            && (
              <>
                {
                  currentWorkOrder?.projectInfo?.name
                    ? (
                      <MDBCol md="3">
                        <span className="font11">Project:</span>
                        <span className="dashdiv font11Bold">
                          {currentWorkOrder.projectInfo.name}
                        </span>
                      </MDBCol>
                    )
                    : null
                }
                {
                  currentWorkOrder?.woCompanyInfo?.name
                    ? (
                      <MDBCol md="3">
                        <span className="font11">Company:</span>
                        <span className="dashdiv font11Bold">
                          {currentWorkOrder.woCompanyInfo.name}
                        </span>
                      </MDBCol>
                    )
                    : null
                }
              </>
            )
          }
        </MDBRow>
        {currentWorkOrder.customFields
        && currentWorkOrder.customFields.length > 0
        && (
          <>
            <MDBRow>
              <hr className="my-4 hrline2" />
            </MDBRow>
            <MDBRow style={{ marginBottom: '0px' }}>
              <span>
                <MDBCol>
                  <p style={{ fontWeight: 'bold' }} className="font13">
                    Custom Fields
                  </p>
                  <MDBTable style={{ color: 'white' }}>
                    <MDBTableHead>
                      <tr>
                        <th style={{ fontWeight: 'bold' }} className="font13">Name</th>
                        <th style={{ fontWeight: 'bold' }} className="font13">Value</th>
                        <th />
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody className="font13">
                      {
                        currentWorkOrder.customFields.map((sglOrd) => (
                          <tr key={sglOrd.name}>
                            <td>{sglOrd.name}</td>
                            {(sglOrd.type === "RESTRICTED" || sglOrd.type === 'PUBLIC') && auth.user.userType === 'contractor' ? 
                            <>
                              <td>
                                <input
                                  type="text"
                                  name="description"
                                  className="form-control descheck font11"
                                  placeholder="Type to add/change value"
                                  defaultValue={sglOrd.value}
                                  onChange={({ target }) => sglOrd.value = target.value}
                                  />
                              </td>
                              <td>
                                <button
                                  className='btn m-0 Ripple-parent btn-success assignbutton'
                                  onClick={() => changeCustomFieldValue(sglOrd)}
                                  >
                                    Save
                                </button>
                              </td>
                            </> : <td>
                              {sglOrd.value}
                            </td>
                            }
                          </tr>
                        ))
                      }
                    </MDBTableBody>
                  </MDBTable>
                </MDBCol>
              </span>
            </MDBRow>
          </>
        )}
        <MDBRow>
          <hr className="my-4 hrline2" />
        </MDBRow>
        <MDBRow style={{ marginBottom: '0px', textAlign: 'left' }}>
          <MDBCol>
            <h6 className="font14">
              <b>Work Description</b>
            </h6>
            <div
              dangerouslySetInnerHTML={{
                __html: currentWorkOrder.description,
              }}
              style={{ fontSize: 12 }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <hr className="my-4 hrline2" />
        </MDBRow>
        <MDBRow style={{ display: 'block', textAlign: 'left' }}>
          <div>
            <MDBCol>
              <h6 className="font14">
                <b>Work Type</b>
              </h6>
            </MDBCol>
          </div>
          <div>
            <MDBCol>
              {currentWorkOrder.workTypes
              && currentWorkOrder.workTypes instanceof Array
              && currentWorkOrder.workTypes.map((tool) => <span key={tool.title} className="chip1">{tool.title}</span>)}
            </MDBCol>
          </div>
        </MDBRow>
        {currentWorkOrder.customTools && (
          <>
            <MDBRow>
              <hr className="my-4 hrline2" />
            </MDBRow>
            <MDBRow style={{ marginBottom: '0px', textAlign: 'left' }}>
              <MDBCol>
                <h6 className="font14">
                  <b>Tools Required</b>
                </h6>
                <div
                  dangerouslySetInnerHTML={{
                    __html: currentWorkOrder.customTools,
                  }}
                  style={{ fontSize: 12 }}
                />
              </MDBCol>
            </MDBRow>
          </>
        )}
        {(!workOrderControlByStatus.beforeAssigned || auth.user.userType === 'client')
        && currentWorkOrder.confidential
        && (
          <MDBRow style={{ marginBottom: '0px', textAlign: 'left' }}>
            <MDBCol>
              <MDBRow>
                <hr className="my-4 hrline2" />
              </MDBRow>
              <h6 className="font14">
                <b>Confidential</b>
              </h6>
              {/*<p style={{ fontSize: 12, opacity: 0.9}}>SHOWN ONLY AFTER ASSIGNED</p>*/}
              <div
                dangerouslySetInnerHTML={{
                  __html: currentWorkOrder.confidential,
                }}
                style={{ fontSize: 12 }}
              />
            </MDBCol>
          </MDBRow>
        )}
        {tasks && tasks.preArrivalRequirements && (
          <ContractorTasks
            section="preArrivalRequirements"
            tasks={tasks}
            handler={saveTasksHandler}
            isAssigned={workOrderControlByStatus.assigned}
          />
        )}
        <MDBRow>
          <hr className="my-4 hrline2" />
        </MDBRow>
        <DocumentSection
          documents={currentWorkOrder?.documents}
          userType={auth.user.userType}
          uploadDocument={uploadDocument}
          workOrderId={currentWorkOrder._id}
          contractorId={currentWorkOrder.contractor_id}
          documentVisibility={currentWorkOrder.documentVisibility}
          deleteUpload={deleteUpload}
          toggleModal={toggleModal}
          isWorkOrderCanceled={workOrderControlByStatus.canceled}
        />
        {tasks && tasks.onSiteRequirements && (
          <ContractorTasks
            section="onSiteRequirements"
            tasks={tasks}
            handler={saveTasksHandler}
            isAssigned={workOrderControlByStatus.assigned}
          />
        )}
        {!workOrderControlByStatus.beforeAssigned && (
          <>
            <CheckInCheckOutFields
              currentWorkOrder={currentWorkOrder}
              checkInOutDetails={currentWorkOrder.checkInOutDetails}
              userType={auth.user.userType}
              checkInCheckOutToWorkOrder={checkInCheckOutToWorkOrder}
              deleteCheckInCheckOutData={deleteCheckInCheckOutData}
              editCheckInCheckOutData={editCheckInCheckOutData}
            />
            <UploadDeliverables
              currentWorkOrder={currentWorkOrder}
              auth={auth}
              toggleModal={toggleModal}
              deleteUpload={deleteUpload}
              uploadDocument={uploadDocument}
              documentsDownloadInProcess={documentsDownloadInProcess}
              setDocumentsDownloadInProcess={setDocumentsDownloadInProcess}
            />
            <WorkOrderExpenses
              auth={auth}
              currentWorkOrder={currentWorkOrder}
              saveExpense={saveExpense}
              editExpense={editExpense}
              deleteExpense={deleteExpense}
            />
          </>
        )}
        <div style={{ textAlign: 'left' }}>
          {!workOrderControlByStatus.beforeAssigned && (
            <>
              <IssueReports
                user={auth.user}
                currentWorkOrder={currentWorkOrder}
                createNewIssueReport={createNewIssueReport}
                resolveIssueReport={resolveIssueReport}
                sendIssueAgain={sendIssueAgain}
              />
            </>
          )}
        </div>
        {tasks && tasks.completionRequirements && (
          <ContractorTasks
            section="completionRequirements"
            tasks={tasks}
            handler={saveTasksHandler}
            isAssigned={workOrderControlByStatus.assigned}
          />
        )}
        <div style={{ textAlign: 'left' }}>
          {workOrderControlByStatus.assigned
          && currentWorkOrder.checkInOutDetails
          && currentWorkOrder.checkInOutDetails.length > 0
          && currentWorkOrder.checkInOutDetails[0].check_out_date
          && (
            <>
              {
                !(!currentWorkOrder.isNeedDeliverables
                  || (currentWorkOrder.uploadDeliverables
                    && currentWorkOrder.uploadDeliverables.length)
                )
                && (
                  <div style={{ color: 'orange', textAlign: 'center' }}>
                    To Complete Upload Deliverables Required!
                  </div>
                )
              }
              {
                isDeviceType(currentWorkOrder.basicType, currentWorkOrder.variableType, currentWorkOrder.variableAmount)
                  ? <DeviceQty value={qtyDevices} handler={setQtyDevices} />
                  : null
              }

              <MDBBtn
                style={{ marginBottom: 20 }}
                color="info"
                className="markcomplete_but"
                onClick={() => {
                  const { _id } = currentWorkOrder;
                  // eslint-disable-next-line max-len
                  const device = isDeviceType(currentWorkOrder.basicType, currentWorkOrder.variableType, currentWorkOrder.variableAmount);
                  completeWorkOrderHandler(_id, tasks, device);
                }}
                disabled={
                  !(!currentWorkOrder.isNeedDeliverables
                    || (currentWorkOrder.uploadDeliverables
                      && currentWorkOrder.uploadDeliverables.length)
                  )
                }
              >
                Mark as Completed
              </MDBBtn>
            </>
          )}
          {
            !workOrderControlByStatus.workUncompleted && !workOrderControlByStatus.canceled
            && (
              <ApproveWorkOrder
                mainContainer={mainContainer}
                auth={auth}
                profile={profile}
                currentWorkOrder={currentWorkOrder}
                approvedWorkOrder={approvedWorkOrder}
                approvePayAdjustmentByContractor={approvePayAdjustmentByContractor}
                toggleModal={toggleModal}
                toggleSecondModalClose={toggleSecondModalClose}
                saveApproveData={saveApproveData}
              />
            )
          }
        </div>
        <MDBRow>
          <hr className="my-4 hrline2" />
        </MDBRow>
        {(auth.user.userType !== 'contractor' || !workOrderControlByStatus.beforeAssigned)
        && (
          <MDBRow style={{ marginBottom: '30px', textAlign: 'left', marginTop: 20 }}>
            <MDBCol className="clientinner_table">
              <h6 className="font14">
                <b>Contact</b>
              </h6>
              <MDBTable style={{ color: 'white' }}>
                <MDBTableHead>
                  <tr>
                    <th>Name</th>
                    <th>Role</th>
                    <th>Office Phone</th>
                    <th>Mobile</th>
                    <th>Email</th>
                  </tr>
                </MDBTableHead>
                {currentWorkOrder.contactInfo
                && (
                  <MDBTableBody>
                    {currentWorkOrder.contactInfo.map((sglOrd) => (
                      <tr key={sglOrd.mobile || ''}>
                        <td>{sglOrd.fname || ''}</td>
                        <td>{sglOrd.role || ''}</td>
                        <td>{sglOrd.ofc_phn || ''}</td>
                        <td>{sglOrd.mobile || ''}</td>
                        <td>{sglOrd.email || ''}</td>
                      </tr>
                    ))}
                  </MDBTableBody>
                )}
              </MDBTable>
            </MDBCol>
          </MDBRow>
        )}
        {(auth.user.userType !== 'contractor')
        && !workOrderControlByStatus.beforeAssigned
        && currentWorkOrder.contractorInfo
        && (
          <MDBRow>
            <MDBCol>
              <MDBTable className="applicantappiliedwo_table">
                <MDBTableBody>
                  <ApplicantCard
                    userType={auth.user.userType}
                    contractorInfo={
                      {
                        ...currentWorkOrder,
                        contractor_details: currentWorkOrder.contractorInfo,
                      }
                    }
                    setCurrentApplicants={setCurrentApplicants}
                    toggleModal={toggleModal}
                    currentWorkOrder={currentWorkOrder}
                    assignContractor={assignContractor}
                  />
                </MDBTableBody>
              </MDBTable>
            </MDBCol>
          </MDBRow>
        )}
        {(auth.user.userType !== 'contractor')
        && workOrderControlByStatus.beforeAssigned
        && !workOrderControlByStatus.canceled
        && (
          <>
            <AppliedContractorDetails
              userType={auth.user.userType}
              applicants={applicants || []}
              currentWorkOrder={currentWorkOrder}
              toggleModal={toggleModal}
              setCurrentApplicants={setCurrentApplicants}
              assignContractor={assignContractor}
            />
            <InvitedContractorDetails
              userType={auth.user.userType}
              routedApplicants={routedApplicants}
              toggleModal={toggleModal}
              setCurrentApplicants={setCurrentApplicants}
              currentWorkOrder={currentWorkOrder}
              unRouteWorkOrder={unRouteWorkOrder}
              acceptInvite={acceptInvite}
            />
          </>
        )}
        {
          (auth.user.userType !== 'contractor')
          // && workOrderControlByStatus.beforeAssigned
          && currentWorkOrder?.applyHistory?.length
            ? (
              <PreviousApplicantsHistory
                applicants={currentWorkOrder.applyHistory}
                currentWorkOrder={currentWorkOrder}
                inviteContractor={sendInviteFromWorkOrderToContractor}
                blockedContractors={blockedContractors}
                showContractorProfile={showContractorProfile}
              />
            )
            : <></>
        }
        {
          (auth.user.userType !== 'contractor')
          && <WorkOrderHistory history={currentWorkOrder.history} />
        }
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
  deleteWorkOrderDetails,
  editOnHoldStatusWorkOrder,
  completeWorkOrder,
  createNewIssueReport,
  resolveIssueReport,
  sendIssueAgain,
  fetchApplicantsForWorkOrder,
  approvedWorkOrder,
  checkInCheckOutToWorkOrder,
  deleteCheckInCheckOutData,
  editCheckInCheckOutData,
  setCurrentApplicants,
  approvePayAdjustmentByContractor,
  uploadDocument,
  deleteUpload,
  saveExpense,
  editExpense,
  deleteExpense,
  cancelWorkOrder,
  sendBlockCompanyRequest,
  sendUnblockCompanyRequest,
  assignContractor,
  saveApproveData,
  saveTasks,
  changeCustomField,
  toggleSecondModalClose,
  sendInviteFromWorkOrderToContractor,
  unRouteWorkOrder,
  createWorkOrderTemplateByWorkOrder,
  fetchWorkOrderById,
  setDocumentsDownloadInProcess,
  acceptInvite
};

const mapStateToProps = (state) => ({
  currentWorkOrder: state.workOrder.currentWorkOrder,
  currentWorkOrderLoaded: state.workOrder.currentWorkOrderLoaded,
  routedApplicants: state.workOrder.routedApplicants,
  applicants: state.workOrder.applicants,
  documentsDownloadInProcess: state.workOrder.documentsDownloadInProcess,
  auth: state.auth,
  modalState: state.modalState.viewDetailsWorkOrderModal,
  profile: state.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewDetailsWorkOrderModal));
