import React from 'react';
import { connect } from 'react-redux';
import {
  MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import { toggleModal } from '../../storage/actions/modalsActions';
import CompanyInformationForSuperAdminReduxForm from '../layout/reduxForm/CompanyInformationBySuperAdmin';
import onSubmit from '../../utils/reduxForm/onSubmit/companyInformationBySuperAdmin';

const EditCompanyBySuperAdmin = ({
  modalState, toggleModal,
}) => {
  const closeModal = () => {
    toggleModal(false, 'editCompanyBySuperAdmin');
  };

  return (
    <MDBModal
      className="applypop"
      isOpen={modalState.isOpen}
      toggle={closeModal}
      centered
    >
      <MDBModalBody style={{ color: 'white' }}>
        <MDBRow
          className="padding-bottom-05"
          style={{
            justifyContent: 'flex-end',
            paddingRight: '1rem',
          }}
        >
          <MDBIcon
            far
            icon="times-circle"
            onClick={closeModal}
            size="lg"
            className="blue-grey-text"
          />
        </MDBRow>
        <CompanyInformationForSuperAdminReduxForm onSubmit={onSubmit} closeModal={closeModal} />
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
};

const mapStateToProps = state => ({
  modalState: state.modalState.editCompanyBySuperAdmin,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyBySuperAdmin);
