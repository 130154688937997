import React from 'react';
import PropTypes from "prop-types";
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';

const infoRowStyles = { padding: '25px 0px 0px 15px' }

const ContractorBlockInfoRow = ({
  isBlockedForCompany, contractorDetails, toggleModal, clientUser
}) => (
  <>
    <MDBRow className="font12">
      <MDBCol md="12" style={infoRowStyles}>
        {
          isBlockedForCompany
            ? <div><label>Blocked for: Client</label></div>
            : <></>
        }
        {
          contractorDetails?.woCompanies?.length
            ? (
              <div>
                <label>
                  {
                    `Blocked for: ${
                      contractorDetails.woCompanies.reduce(
                        (acc, cur) => (`${acc ? `${acc}, ` : ''}Company ${cur.name}`),
                        '',
                      )
                    }`
                  }
                </label>
              </div>
            )
            : <></>
        }
        {
          contractorDetails?.projects?.length
            ? (
              <div>
                <label>
                  {
                    `Blocked for: ${
                      contractorDetails.projects.reduce(
                        (acc, cur) => (`${acc ? `${acc}, ` : ''}Project ${cur.name}`),
                        '',
                      )
                    }`
                  }
                </label>
              </div>
            )
            : <></>
        }
      </MDBCol>
    </MDBRow>
    {clientUser?.accessctrl !== 'Dispatcher' && 
      <MDBRow>
        <MDBCol>
          <MDBBtn
            color="success"
            className="assignbutton"
            onClick={() => {
              toggleModal(true, 'blockModal', false, { contractorId: contractorDetails.userId });
            }}
          >
            BLOCK USER
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    }
  </>
);

ContractorBlockInfoRow.propTypes = {
  isBlockedForCompany: PropTypes.bool,
  contractorDetails: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default ContractorBlockInfoRow;
