import React, {useRef} from "react";
import MessageSticker from "./MessageSticker";

const MessageList = ({messages, userId, sendMessage, fetchConversation, scrollToBottom, needScrollToBottom}) => {
    const ref = useRef(null);
    needScrollToBottom&&scrollToBottom(ref)
    return(
        <div
            ref={ref}
            style={{
                overflowY: 'scroll',
                overflowX: 'hidden',
                maxHeight: '500px'
            }}
        >
            {
                messages.map((message, ind)=> (
                    <MessageSticker fetchConversation={fetchConversation} userId={userId} key={`${message._id}_${ind}`} sendMessage={sendMessage} message={message} />
                ))
            }
        </div>
    )
}

export default MessageList;
