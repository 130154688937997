import React from 'react';
import {
  MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import { toggleModal } from '../../storage/actions/modalsActions';
import ContractorTaxInformationReduxForm from '../layout/reduxForm/ContractorTaxInformation';
import ControlButtonsSection from '../layout/reduxForm/section/contractorTaxInformation/ControlButtonsSection';
import onSubmit from '../../utils/reduxForm/onSubmit/contractorTaxInformation';

const ContractorTaxInformationModal = ({
  modalState, toggleModal,
}) => {
  const closeModal = () => {
    toggleModal(!modalState.isOpen, 'contractorTaxInformationModal');
  };

  return (
    <MDBModal
      isOpen={modalState.isOpen}
      toggle={closeModal}
      centered
      className="modal-width-to-max-content modal-width-900"
    >
      <MDBModalBody>
        <MDBRow style={{ justifyContent: 'flex-end', paddingRight: '10px' }}>
          <MDBIcon
            far
            icon="times-circle"
            onClick={closeModal}
            size="lg"
            className="blue-grey-text"
          />
        </MDBRow>
        <ContractorTaxInformationReduxForm onSubmit={onSubmit} />
        <ControlButtonsSection closeModal={closeModal} />
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  modalState: state.modalState.contractorTaxInformationModal,
});

export default connect(mapStateToProps, { toggleModal })(ContractorTaxInformationModal);
