import React, { useState } from 'react';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import DatePicker from 'react-datepicker/es';

import Notification from '../../../notification';

import workOrderStatusControl from '../../../../utils/workOrderStatusControl';
import { getISODateAndTime } from '../../../../utils/getDateWithOffset';

const AddExpense = ({
  userType,
  currentWorkOrder,
  saveExpense,
}) => {
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');

  const workOrderControlByStatus = currentWorkOrder
    ? workOrderStatusControl(currentWorkOrder.status)
    : {};

  const addExpenses = async (event) => {
    event.preventDefault();
    if (!amount || !(amount > 0)) {
      Notification('warning', { message: 'Please enter a dollar amount (greater than zero)!' });
      return;
    }
    if (!description) {
      Notification('warning', { message: 'Please type description first!' });
      return;
    }
    const { _id } = currentWorkOrder;
    const result = await saveExpense({
      amount,
      date: getISODateAndTime(date, true, true),
      description,
      workOrderId: _id,
    });
    if (result) {
      setDate(new Date());
      setAmount('');
      setDescription('');
    }
  };

  return userType === 'contractor' && workOrderControlByStatus.assigned
    ? (
      <MDBRow style={{ marginBottom: '15px' }}>
        <MDBCol md="2">
          <DatePicker
            className="form-control coutd"
            dateFormat="MM/dd/yy"
            name="date"
            placeholder="Date"
            selected={date}
            value={date}
            onChange={setDate}
          />
        </MDBCol>
        <MDBCol md="2">
          <input
            type="number"
            name="amount"
            className="form-control descheck font11"
            placeholder="Amount ($)"
            value={amount}
            onChange={({ target }) => setAmount(target.value)}
          />
        </MDBCol>
        <MDBCol>
          <input
            type="text"
            name="description"
            className="form-control descheck font11"
            placeholder="Type Expense Description"
            value={description}
            onChange={({ target }) => setDescription(target.value)}
          />
        </MDBCol>
        <MDBCol
          md="2"
          className="flex-center"
          style={{ minWidth: '11.2rem' }}
        >
          <MDBBtn
            color="orange"
            className="check_inbutton"
            style={{ margin: 0 }}
            disabled={currentWorkOrder.onHold}
            onClick={addExpenses}
          >
            $ Add expenses
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    )
    : null;
};

export default AddExpense;
