import React from 'react';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { MDBIcon, MDBBtn } from 'mdbreact';
import Notification from '../../notification';

const ContractorExportWorkOrderButton = ({ id }) => (
  <div style={Container}>
    <MDBBtn className="width-inherit" onClick={() => exportBankInfoContractors(id)}>
      <MDBIcon className="vwimg exportimg" icon="angle-double-left" />
      Download WO
    </MDBBtn>
  </div>
);
export default ContractorExportWorkOrderButton;

const Container = {
  width: '200px',
};

const exportBankInfoContractors = async (id) => {
  await axios.get(`/api/contractor/exportWorkOrder/${id}`, {
    responseType: 'blob',
  }).then((res) => {
    fileDownload(res.data, 'workOrderDetails.pdf');
  }).catch((error) => {
    Notification('error', {
      message: error.response?.data?.message || error.message,
    });
  });
};
