import React from "react";
import CheckInCheckOutElement from "./CheckInCheckOutElement";
import EditCheckInCheckOut from "./EditCheckInCheckOut";

const CheckInCheckOutList = (
    {
        checkInOutDetails,
        isControlElementAvailable,
        editCheckData,
        deleteCheckData,
        editCheckInCheckOutData,
        checkInCheckOutToEdit,
        showEditCheckInfo,
        cancelEdit,
        currentWorkOrder,
        userType
    }
) => {
    return  (checkInOutDetails && checkInOutDetails.length > 0) &&
        <div>
            {checkInOutDetails.map( checkInCheckOutInfo => {
                return (
                    <div key={checkInCheckOutInfo._id}>
                        {
                            showEditCheckInfo && (checkInCheckOutToEdit.check_in_out_id === checkInCheckOutInfo._id)
                                ? <EditCheckInCheckOut
                                    checkInCheckOutToEdit={checkInCheckOutToEdit}
                                    currentWorkOrder={currentWorkOrder}
                                    cancelEdit={cancelEdit}
                                    editCheckInCheckOutData={editCheckInCheckOutData}
                                />
                                : <CheckInCheckOutElement
                                    userType={userType}
                                    checkInCheckOutInfo={checkInCheckOutInfo}
                                    isControlElementAvailable={isControlElementAvailable}
                                    editCheckData={editCheckData}
                                    deleteCheckData={deleteCheckData}
                                />
                        }
                    </div>
                )
            })}
        </div>
}

export default CheckInCheckOutList;