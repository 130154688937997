/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  MDBModal,
  MDBRow,
  MDBIcon,
  MDBModalBody,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBContainer,
} from 'mdbreact';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import axios from 'axios';
import { connect } from 'react-redux';
import { white as customSelectStyle } from '../../../utils/customSelectStyle';
import InputRange from '../../dashboard/inputRange';
import { toggleModal } from '../../../storage/actions/modalsActions';
import { setFiltersObject } from '../../../storage/actions/workOrdersActions';
import CustomFieldFiltersSection from '../../layout/filters/CustomFieldFiltersSection';

class ModalWOFilters extends React.Component {
  constructor(props) {
    super(props);
    const filters = props.filters || {};
    this.state = {
      form: {
        selectedWorkTypes: new Set(filters.selectedWorkTypes || []),
        startDate: filters.startDate || {},
        selectedCompanies: new Set(filters.selectedCompanies || []),
        zipCode: filters.zipCode || '',
        cityName: filters.cityName || '',
        selectState: filters.selectState || '',
        selectCountry: filters.selectCountry || {
          name: 'United States',
          value: 'US',
          label: 'United States',
        },
        filterByDistance: filters.filterByDistance || '',
        selectCompany: filters.selectCompany || '',
        filterByStatusOnHold: filters.filterByStatusOnHold || '',
        selectProject: filters.selectProject || '',
        workOrderId: filters.workOrderId || '',
        siteName: filters.siteName || '',
        payRate: filters.payRate || {},
        finalPay: filters.finalPay || {},
        selectedCustomFields: new Set(filters.selectedCustomFields || []),
      },
      clients: [],
      companies: [],
      projects: [],
      stateArray: [],
      countries: [],
      rateTypes: [
        {
          value: 'Hour',
          label: 'Hour',
        },
        {
          value: 'Device',
          label: 'Device',
        },
        {
          value: 'Site',
          label: 'Site / Flat',
        },
      ],
      customFields: [],
      workTypes: [],
    };
  }

  // eslint-disable-next-line react/sort-comp
  resetFilters = () => {
    this.setState({
      form: {
        selectedWorkTypes: new Set([]),
        startDate: {},
        selectedCompanies: new Set([]),
        zipCode: '',
        cityName: '',
        selectState: '',
        selectCountry: {
          name: 'United States',
          value: 'US',
          label: 'United States',
        },
        filterByDistance: '',
        selectCompany: '',
        filterByStatusOnHold: '',
        selectProject: '',
        workOrderId: '',
        siteName: '',
        payRate: {},
        finalPay: {},
        selectedCustomFields: new Set([]),
      },
    });
  }

  handlerOnChange = ({ name, value }) => {
    this.setState(
      cur => ({ form: { ...cur.form, [name]: value || '' } }),
      () => {
        if (name === 'selectCountry') this.getStates();
      },
    );
  }

  handlerSetChanges = ({ target }) => {
    const newSet = new Set([...this.state.form[target.name]]);
    const command = target.checked ? 'add' : 'delete';
    newSet[command](target.value);
    this.setState(cur => ({ form: { ...cur.form, [target.name]: newSet } }));
  }

  handlerObjectChanges = (field, name, value) => {
    this.setState(cur => ({
      form: { ...cur.form, [field]: { ...cur.form[field], [name]: value || '' } },
    }));
  }

  closeModal = () => this.props.toggleModal(false, 'filtersModal');

  handlerAccept = event => {
    event.preventDefault();
    const filters = this.getFilters();
    this.props.getFilters(filters);
    this.closeModal();
  }

  getFilters = () => {
    const fields = Object.keys(this.state.form);
    if (!fields.length) return null;
    const result = {};
    fields
      .filter(key => (
        this.state.form[key] !== ''
        && (!(this.state.form[key] instanceof Set) || this.state.form[key].size > 0)
        && (
          !(this.state.form[key] instanceof Object)
          || (this.state.form[key] instanceof Set)
          || (this.state.form[key] instanceof Date)
          || this.state.form[key].from
          || this.state.form[key].to
          || this.state.form[key].value
          || this.state.form[key].length
        )
      ))
      .forEach(key => {
        if (this.state.form[key] instanceof Set) {
          result[key] = [...this.state.form[key]];
          return;
        }
        if (this.state.form[key] instanceof Date) {
          result[key] = this.state.form[key].toString();
          return;
        }
        result[key] = this.state.form[key];
      });
    return result;
  }

  getCountries = () => {
    axios.get('/api/address/getAllCountries')
      .then(res => {
        if (res.data.length && res.data.length > 0) {
          return this.setState({
            countries: res.data.map(item => ({
              ...item,
              label: item.name,
              value: item.isoCode,
            })),
          }, this.getStates);
        }
        throw new Error('Can\'t get list of countries!');
      })
      .catch(error => {
        console.error(error);
      });
  }

  getStates = () => {
    axios.get('/api/address/getStatesOfCountry', {
      params: { countryId: this.state?.form?.selectCountry?.value || '' },
    })
      .then(res => {
        this.setState({
          stateArray: res.data?.payload?.states || [],
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  getWorkTypes = () => {
    axios.get('/api/users/getTools')
      .then(res => {
        if (res.data.Tools && res.data.Tools.length > 0) {
          return this.setState({ workTypes: res.data.Tools });
        }
        throw new Error('Can\'t get list of work types!');
      })
      .catch(error => {
        console.error(error);
      });
  }

  getInfoFromPreviouslyWork = () => {
    axios.get('/api/contractor/fetch_fields_by_previously_work')
      .then(res => {
        if (!res.data.success) throw new Error('Can\'t get list of projects!');
        this.setState({
          projects: res.data.projects,
          companies: res.data.companies,
          customFields: res.data.customFields,
          clients: res.data.clients,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  componentDidMount() {
    this.getCountries();
    this.getWorkTypes();
    this.getInfoFromPreviouslyWork();
  }

  render() {
    const { isOpen } = this.props;
    const {
      companies,
      projects,
      stateArray,
      countries,
      rateTypes,
      customFields,
      workTypes,
      clients,
    } = this.state;
    const {
      selectedWorkTypes,
      startDate,
      selectedCompanies,
      zipCode, cityName, selectState, selectCountry,
      filterByDistance,
      selectCompany,
      filterByStatusOnHold,
      selectProject,
      workOrderId, siteName,
      payRate, finalPay,
      selectedCustomFields,
    } = this.state.form;

    return (
      <MDBModal
        isOpen={isOpen}
        toggle={this.closeModal}
        className="wodpop filters-modal"
        centered
      >
        <MDBModalBody style={{ backgroundColor: '#1f2f41', color: '#fff' }}>
          <MDBContainer>
            <MDBRow className="h-paddings" style={{ justifyContent: 'flex-end', paddingRight: '10px' }}>
              <MDBIcon
                far
                icon="times-circle"
                onClick={this.closeModal}
                size="lg"
                className="blue-grey-text"
              />
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Miles
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <InputRange
                  defaultValue={filterByDistance || NaN}
                  handler={value => this.handlerOnChange({ name: 'filterByDistance', value: value || '' })}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Start Date
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="startDateFrom"
                  selected={startDate.from}
                  onChange={date => this.handlerObjectChanges('startDate', 'from', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="From"
                />
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <DatePicker
                  className="no-margins input-field width-inherit"
                  style={{ width: '7rem' }}
                  name="startDateTo"
                  selected={startDate.to}
                  onChange={date => this.handlerObjectChanges('startDate', 'to', date)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="To"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Company
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <Select
                  isClearable
                  isSearchable
                  options={companies}
                  value={selectCompany}
                  styles={customSelectStyle}
                  onChange={value => this.handlerOnChange({ name: 'selectCompany', value })}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Project
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <Select
                  isClearable
                  isSearchable
                  options={projects}
                  value={selectProject}
                  styles={customSelectStyle}
                  onChange={value => this.handlerOnChange({ name: 'selectProject', value })}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Company WO ID
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <input
                  type="text"
                  name="workOrderId"
                  className="input-field"
                  style={{ width: 'inherit' }}
                  placeholder="Type Company WO ID"
                  value={workOrderId}
                  onChange={event => this.handlerOnChange(event.target)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                City
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <input
                  type="text"
                  name="cityName"
                  className="input-field"
                  style={{ width: 'inherit' }}
                  placeholder="Type city name"
                  value={cityName}
                  onChange={event => this.handlerOnChange(event.target)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                State
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <Select
                  isClearable
                  isSearchable
                  options={stateArray}
                  value={selectState}
                  styles={customSelectStyle}
                  onChange={value => this.handlerOnChange({ name: 'selectState', value })}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Zip
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <input
                  type="text"
                  name="zipCode"
                  className="input-field"
                  style={{ width: 'inherit' }}
                  placeholder="Type zip code"
                  value={zipCode}
                  onChange={event => this.handlerOnChange(event.target)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Country
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <Select
                  options={countries}
                  value={selectCountry}
                  styles={customSelectStyle}
                  onChange={value => this.handlerOnChange({ name: 'selectCountry', value })}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Site Name
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings">
                <input
                  type="text"
                  name="siteName"
                  className="input-field"
                  style={{ width: 'inherit' }}
                  placeholder="Type site name"
                  value={siteName}
                  onChange={event => this.handlerOnChange(event.target)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content', alignItems: 'center' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Base Pay
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <input
                  type="text"
                  name="payRateFrom"
                  className="input-field width-inherit"
                  placeholder="From"
                  value={payRate.from}
                  onChange={event => this.handlerObjectChanges('payRate', 'from', parseFloat(event.target.value) || 0)}
                />
              </MDBCol>
              -
              <MDBCol md="2" className="no-margins v-paddings">
                <input
                  type="text"
                  name="payRateTo"
                  className="input-field width-inherit"
                  placeholder="To"
                  value={payRate.to}
                  onChange={event => this.handlerObjectChanges('payRate', 'to', parseFloat(event.target.value) || 0)}
                />
              </MDBCol>
              per
              <MDBCol md="3" className="no-margins v-paddings flex-box align-center">
                <Select
                  isClearable
                  options={rateTypes}
                  value={payRate.type}
                  styles={customSelectStyle}
                  onChange={value => this.handlerObjectChanges('payRate', 'type', value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content', alignItems: 'center' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Final Pay
              </MDBCol>
              <MDBCol md="2" className="no-margins v-paddings">
                <input
                  type="text"
                  name="finalPayFrom"
                  className="input-field width-inherit"
                  placeholder="From"
                  value={finalPay.from}
                  onChange={event => this.handlerObjectChanges('finalPay', 'from', parseFloat(event.target.value) || 0)}
                />
              </MDBCol>
              -
              <MDBCol md="2" className="no-margins v-paddings">
                <input
                  type="text"
                  name="finalPayTo"
                  className="input-field width-inherit"
                  placeholder="To"
                  value={finalPay.to}
                  onChange={event => this.handlerObjectChanges('finalPay', 'to', parseFloat(event.target.value) || 0)}
                />
              </MDBCol>
              per
              <MDBCol md="3" className="no-margins v-paddings flex-box align-center">
                <Select
                  isClearable
                  options={rateTypes}
                  value={finalPay.type}
                  styles={customSelectStyle}
                  onChange={value => this.handlerObjectChanges('finalPay', 'type', value)}
                />
              </MDBCol>
            </MDBRow>
            <CustomFieldFiltersSection
              customFields={customFields}
              selectCustomField={value => this.handlerOnChange({ name: 'selectedCustomFields', value: new Set(value || []) })}
              selectedCustomFields={[...selectedCustomFields]}
            />
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                On Hold
              </MDBCol>
              <MDBCol md="9" className="no-margins v-paddings flex-box" style={{ flexWrap: 'wrap' }}>
                <MDBInput
                  key="filterByStatusOnHold"
                  className="float-right"
                  type="checkbox"
                  id="filterByStatusOnHold"
                  label={filterByStatusOnHold ? 'On Hold' : 'All'}
                  value={filterByStatusOnHold}
                  name="filterByStatusOnHold"
                  checked={filterByStatusOnHold}
                  onChange={() => this.handlerOnChange({ name: 'filterByStatusOnHold', value: !filterByStatusOnHold })}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Clients Previously worked for
              </MDBCol>
              <MDBCol md="4" className="no-margins v-paddings flex-box" style={{ flexWrap: 'wrap' }}>
                <Select
                  isClearable
                  isSearchable
                  isMulti
                  closeMenuOnSelect={false}
                  options={clients}
                  value={[...selectedCompanies]}
                  styles={customSelectStyle}
                  onChange={value => this.handlerOnChange({ name: 'selectedCompanies', value: new Set(value || []) })}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="h-paddings" style={{ height: 'max-content' }}>
              <MDBCol md="3" className="no-margins v-paddings flex-box flex-align-center">
                Type(s) of work
              </MDBCol>
              <MDBCol md="9" className="no-margins v-paddings flex-box" style={{ flexWrap: 'wrap' }}>
                {
                  workTypes && workTypes.map(workType => (
                    <MDBInput
                      key={workType}
                      className="float-right"
                      label={workType}
                      type="checkbox"
                      id={workType}
                      value={workType}
                      name="selectedWorkTypes"
                      checked={selectedWorkTypes.has(workType)}
                      onChange={this.handlerSetChanges}
                    />
                  ))
                }
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ justifyContent: 'flex-end' }}>
              <MDBBtn
                color="orange"
                className="signbutton"
                onClick={this.resetFilters}
              >
                Reset Filters
              </MDBBtn>
              <MDBBtn
                color="info"
                className="signbutton"
                onClick={(e) => {
                  this.handlerAccept(e);
                }}
              >
                Accept
              </MDBBtn>
              <MDBBtn
                color="red"
                className="signbutton"
                onClick={e => {
                  e.preventDefault();
                  this.closeModal();
                }}
              >
                Close
              </MDBBtn>
            </MDBRow>
          </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );
  }
}

const mapDispatchToProps = {
  toggleModal,
  getFilters: setFiltersObject,
};

const mapStateToProps = state => ({
  isOpen: state.modalState?.filtersModal?.isOpen,
  filters: state?.workOrder?.filters,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalWOFilters);
