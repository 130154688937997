import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBRow,
} from 'mdbreact';
import axios from 'axios';
import { toggleModal } from '../../storage/actions/modalsActions';
import Content from '../../components/clientPaymentMethods/Content';
import Loader from '../../components/dashboard/common/Loader';
// eslint-disable-next-line import/no-cycle
import Modal from '../../components/modal';
import Notification from '../../components/notification';
import FundingSourcesContent from '../../components/fundingSources/Content';
import { useFoundingSourceContext } from "../admin/FundingSources";
import {deleteFundingSource, makePrimaryFundingSource} from "../../storage/actions/superAdmin";

const ClientPaymentMethods = ({
  auth, toggleModal, mainContainer, isReload,  deleteFundingSource, makePrimaryFundingSource
}) => {
  const foundingSourceContext = useFoundingSourceContext({
    isReload,
    toggleModal,
    deleteFundingSource,
    makePrimaryFundingSource
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const getPaymentMethods = () => {
    setIsLoaded(false);
    axios.get('/api/payment/payment-methods')
      .then(res => {
        setPaymentMethods(res.data.paymentMethods);
        setIsLoaded(true);
      });
  };

  const editPaymentMethod = paymentMethod => {
    toggleModal(true, 'addOrEditPaymentMethod', false, paymentMethod);
  };

  const deletePaymentMethod = async (id) => {
    const { data: { message } } = await axios.delete(`/api/payment/payment-methods/?id=${id}`);
    if (message) {
      Notification('success', { message });
      getPaymentMethods();
    }
  };

  const makePrimary = paymentMethod => {
  };

  useEffect(() => {
    getPaymentMethods();
  }, [isReload]);

  return (
    <MDBContainer className="clientdascontentmaindiv" fluid>
      <MDBRow>
        <MDBCol style={{ textAlign: 'center' }}>
          Payment Methods
        </MDBCol>
      </MDBRow>
      <MDBRow className="position-relative" style={{top: "-35px"}}>
        <MDBCol>
          <MDBBtn
            className="background-green flex-box flex-align-center text-nowrap float-right"
            onClick={() => toggleModal(true, 'addOrEditPaymentMethod')}
          >
            <MDBIcon className="vwimg" icon="plus" />
            Add Payment Method
          </MDBBtn>
        </MDBCol>
      </MDBRow>

      {
      isLoaded
        ? (
          <Content
            paymentMethods={paymentMethods}
            editPaymentMethod={editPaymentMethod}
            deletePaymentMethod={deletePaymentMethod}
            makePrimary={makePrimary}
            auth={auth}
          />
        )
        : <Loader />
    }
      <MDBRow className="my-5">
        <MDBCol style={{ textAlign: 'center' }}>
          Bank Info
        </MDBCol>
      </MDBRow>
      <FundingSourcesContent {... foundingSourceContext } auth={auth.user} />
      <Modal mainContainer={mainContainer.current} />
    </MDBContainer>
  );
};

const mapDispatchToProps = {
  toggleModal,
  deleteFundingSource,
  makePrimaryFundingSource,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isReload: state.applicationState.isReload,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientPaymentMethods);
