import React from 'react';
import {
  MDBBtn, MDBBtnGroup, MDBCol, MDBContainer, MDBIcon, MDBRow,
} from 'mdbreact';
import getMathMin from '../../../../utils/getMathMin';

const Footer = ({
  enablePagination,
  rowsCount,
  entriesOptions = [],
  perPage,
  pageNumber,
  setPageNumber,
  setPerPage,
}) => {
  const handlerSelect = event => {
    setPerPage(event.target.value);
    setPageNumber(0);
  };

  if (!enablePagination) return (<></>);

  return (
    <MDBContainer fluid className="border-top-line-white-bold border-bottom-line-white-bold">
      <MDBRow className="justify-content-end">
        <MDBCol className="flex-grow-0">
          <div
            className="flex-box text-in-one-line align-items-center"
            style={{ width: '200px' }}
          >
            <label className="no-margins">Rows per page:</label>
            <select
              name="perPage"
              className="browser-default custom-select selectac backgro "
              onChange={handlerSelect}
              value={perPage}
              placeholder="Select"
              style={{
                margin: '0 0 0 1rem',
                backgroundColor: 'white',
                color: 'black',
              }}
            >
              {
                entriesOptions.map(el => (
                  <option key={el} value={el}>{el}</option>
                ))
              }
            </select>
          </div>
        </MDBCol>
        <MDBCol className="flex-grow-0 flex-box align-items-center">
          <label className="no-margins text-in-one-line">
            {
              `${
                getMathMin(pageNumber * perPage + 1, rowsCount)
              } - ${
                getMathMin((pageNumber + 1) * perPage, rowsCount)
              } of ${rowsCount}`
            }
          </label>
        </MDBCol>
        <MDBCol className="flex-grow-0">
          <MDBBtnGroup>
            <MDBBtn
              color="white"
              className="no-margins"
              disabled={pageNumber === 0}
              onClick={() => setPageNumber(pageNumber - 1)}
            >
              <MDBIcon icon="angle-left" />
            </MDBBtn>
            <MDBBtn
              color="white"
              className="no-margins"
              disabled={pageNumber === Math.ceil(rowsCount / perPage - 1)}
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              <MDBIcon icon="angle-right" />
            </MDBBtn>
          </MDBBtnGroup>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Footer;
