import React from 'react';
import Sel from './Sel';

const Row = ({
  columns,
  item,
  enableActionsCol,
  ActionElement,
}) => (
  <tr>
    {
      columns.map(col => (
        <Sel
          key={col.value}
          col={col}
          item={item}
        />
      ))
    }
    {
      enableActionsCol
        ? (
          <td>
            <ActionElement item={item} />
          </td>
        )
        : <></>
    }
  </tr>
);

export default Row;
