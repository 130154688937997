import React from 'react';
import {
  MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import Notification from '../notification';
import { unAssignWorkOrder } from '../../storage/actions/workOrdersActions';
import { toggleModal } from '../../storage/actions/modalsActions';

const UnAssignModal = ({
  modalState,
  toggleModal,
  unAssignWorkOrder,
}) => {
  const submitUnAssign = (event) => {
    event.preventDefault();
    if (!event.target.message.value.trim() || !modalState.type) {
      Notification('warning', { message: 'Please enter valid reason to unassign the contractor.' });
    } else {
      unAssignWorkOrder({
        ...modalState.type,
        reason: event.target.message.value,
      });
    }
  };

  return (
    <MDBModal
      isOpen={modalState.isOpen}
      toggle={() => {
        toggleModal(!modalState.isOpen, 'unAssignModal');
      }}
      centered
      className="signindiv"
    >
      <MDBRow style={{ justifyContent: 'flex-end', paddingRight: '10px' }}>
        <MDBIcon
          far
          icon="times-circle"
          onClick={() => {
            toggleModal(!modalState.isOpen, 'unAssignModal');
          }}
          size="lg"
          className="blue-grey-text"
        />
      </MDBRow>
      <MDBModalBody style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
        <div className="buttondivsignin">
          <MDBRow style={{ justifyContent: 'center' }}>
            <p
              style={{ color: 'black' }}
            >
              Unassign Contractor
            </p>
          </MDBRow>
          <form onSubmit={submitUnAssign}>
            <MDBCol md="12">
              <div className="form-group signinput">
                <label htmlFor="formGroupExampleInput" className="font12">
                  Reason to unassign contractor
                  <label style={{ fontWeight: 'bold' }}>
                    (This message will be sent to the contractor)
                  </label>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="reason_to_unassign"
                  name="message"
                  required
                />
              </div>
            </MDBCol>
            <MDBRow style={{ justifyContent: 'center' }}>
              <MDBBtn
                color="info"
                className="signbutton"
                type="submit"
              >
                Submit
              </MDBBtn>
            </MDBRow>
          </form>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = (state) => ({
  modalState: state.modalState.unAssignModal,
});

export default connect(mapStateToProps, { toggleModal, unAssignWorkOrder })(UnAssignModal);
