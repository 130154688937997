import React from 'react';
import { MDBInput, MDBTooltip } from 'mdbreact';
import AmountInfo from './AmountInfo';
import { localCurrencySettings } from '../../../../../utils/constans';

const ApplyInfoRow = ({
  applyInfo, selectedApplies, handlerInputChanges, current,
}) => (
  <tr className="text-in-one-line tr-td-font-size-08 tr-td-font-bold" style={{ color: current ? '#ff7043' : 'white' }}>
    <td className="padding-05-02-rem">
      {
        current
          ? (
            <div className="form-check">
              <label className="checkmark-color no-margins">
                {`${applyInfo.WIN}`}
              </label>
            </div>
          )
          : (
            <MDBInput
              filled
              type="checkbox"
              label={`${applyInfo.WIN}`}
              id={applyInfo.workOrderId}
              name={applyInfo.workOrderId}
              checked={selectedApplies?.includes(applyInfo.workOrderId)}
              onChange={handlerInputChanges}
            />
          )
      }
    </td>
    <td className="padding-05-02-rem">
      <ToolTipCustomText text={applyInfo.customTagId} />
    </td>
    <td className="padding-05-02-rem">
      <ToolTipCustomText text={applyInfo.title} />
    </td>

    <td className="padding-05-02-rem" style={{ color: current ? '#ff7043' : '#ffff32' }}>
      {applyInfo.bidAmountExpense ? (
        <ToolTipCustomText text={`${applyInfo.bidAmountExpense.toLocaleString('en-US', localCurrencySettings)} - ${applyInfo.bidDescriptionExpense}`} />
      ) : null}
    </td>

    <td className="padding-05-02-rem">
      <StartDate
        startDate={applyInfo.startDate}
        bidStartDate={applyInfo.bidStartDate}
        current={current}
      />
    </td>
    <td className="padding-05-02-rem">
      {`${applyInfo.city}, ${applyInfo.state}`}
    </td>
    <td className="padding-05-02-rem">
      <AmountInfo
        current={current}
        bidBasicType={applyInfo.bidBasicType}
        amountBase={+applyInfo.amountBase}
        amountVariable={+applyInfo.amountVariable}
        basicWorkType={applyInfo.basicWorkType}
        variableWorkType={applyInfo.variableWorkType}
        bidAmountBase={+applyInfo.bidAmountBase}
        bidAmountVariable={+applyInfo.bidAmountVariable}
      />
    </td>
  </tr>
);

const ToolTipCustomText = ({ text }) => (
  <div className="white-tooltip-title">

    <MDBTooltip
      domElement
      placement="top"
    >

      <div style={{
        textOverflow: 'ellipsis',
        whitesSpace: 'nowrap',
        overflow: 'hidden',
        textAlign: 'center',
      }}
      >
        {text}
      </div>
      <div className="amount-tooltip-title">{text}</div>

    </MDBTooltip>
  </div>
);

const StartDate = ({ startDate, bidStartDate, current }) => {
  let color;
  let date;
  if (!bidStartDate || bidStartDate === startDate) {
    color = { color: current ? '#ff7043' : 'white' };
    date = startDate;
  } else {
    color = { color: current ? '#ff7043' : '#ffff32' };
    date = bidStartDate;
  }

  return (
    <div style={color}>
      {(new Date(date)).toLocaleString('en-US', { timeZone: 'UTC' })}
    </div>
  );
};

export default ApplyInfoRow;
