import React, { createRef } from 'react';
import {
  MDBBtn, MDBCol, MDBInput, MDBRow,
} from 'mdbreact';
import Select from 'react-select';
import { localCurrencySettings, rolloverInfoMessage } from '../../../../utils/constans';
import { dark as customSelectStyle } from '../../../../utils/customSelectStyle';
import CustomValueContainer from '../../../layout/customSelectElements/ValueContainerForMultiSelect';
import CustomMultiValueLabel from '../../../layout/customSelectElements/MultiValueLabel';
import ExplanationInputModal from '../../ExplenationInputModal';
import PortalContainer from '../../Portal';
import Notification from '../../../notification';
import isDeviceType from '../../../../utils/isDeviceType';
import getQtyByPayType from '../../../../utils/getQtyByPayType';
import EstimatedTimeOverageLabel from '../../../layout/EstimatedTimeOverageLabel';
import workOrderStatusControl from '../../../../utils/workOrderStatusControl';
import {connect} from "react-redux";
import { revertWorkOrderTo} from "../../../../storage/actions/workOrdersActions";

class ApproveWorkOrder extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    quantity: 0,
    finalEstimateTotal: 0,
    finalPayAdjustment: 0,
    approvedBasePotentialPay: 0,
    adjustmentReason: '',
    communication: true,
    work: true,
    deliverables: true,
    disabled: false,
    selectedExpenses: [],
    expenses: [],
    rejectedExpensesReasons: {},
    newRejection: '',
  };

  componentDidMount() {
    this.getStateUpdateByProps();
  }

  componentDidUpdate(prevProps) {
    if (
      // eslint-disable-next-line react/destructuring-assignment
      prevProps.currentWorkOrder._id !== this.props.currentWorkOrder._id
      // eslint-disable-next-line react/destructuring-assignment
      || prevProps.currentWorkOrder.status !== this.props.currentWorkOrder.status
      // eslint-disable-next-line react/destructuring-assignment
      || (
        prevProps.currentWorkOrder.checkInOutDetails.length
        // eslint-disable-next-line react/destructuring-assignment
        !== this.props.currentWorkOrder.checkInOutDetails.length
      )
    ) this.getStateUpdateByProps();
  }

  controlBoolValue = value => {
    if (typeof value === 'boolean') return value;
    return true;
  }

  getStateUpdateByProps = () => {
    const { currentWorkOrder, auth } = this.props;
    const expenses = currentWorkOrder.expenses.map(item => ({
      label: `${
        new Date(item.date)
          .toLocaleDateString('en-US', { timeZone: 'UTC' })
      } ${item.amount.toLocaleString('en-US', localCurrencySettings)} ${item.description}`,
      amount: item.amount,
      value: item._id,
      reason: item.rejection_reason,
    }));
    const rejectedExpensesReasons = {};
    expenses
      .filter(item => !currentWorkOrder.approvedExpenses.includes(item.value))
      .forEach(item => {
        rejectedExpensesReasons[item.label] = item.reason;
      });
    return this.setState({
      disabled: currentWorkOrder.status !== 'completed' || auth.user.userType === 'contractor',
      quantity: currentWorkOrder.quantity,
      approvedHoursQty: currentWorkOrder.approvedHoursQty,
      approvedDeviceQty: currentWorkOrder.approvedDeviceQty,
      finalPayAdjustment: currentWorkOrder.finalPayAdjustment || '',
      adjustmentReason: currentWorkOrder.adjustmentReason || '',
      communication: this.controlBoolValue(currentWorkOrder.communicationAcceptable),
      work: this.controlBoolValue(currentWorkOrder.workAcceptable),
      deliverables: this.controlBoolValue(currentWorkOrder.deliverablesAcceptable),
      isApproveAdjustment: currentWorkOrder.isApproveAdjustment,
      selectedExpenses: expenses
        .filter(item => currentWorkOrder.approvedExpenses.includes(item.value)),
      rejectedExpensesReasons,
      expenses,
      payMinimum: currentWorkOrder.payBaseRateAsMinimum,
    }, this.calculateFinalPay);
  }

  getPercentValueString = (percent = 0.00) => (`(${percent}%)`);

  calculateFinalPay = () => {
    const {
      approvedHoursQty,
      approvedDeviceQty,
      quantity,
      payMinimum,
    } = this.state;

    const { currentWorkOrder } = this.props;
    const basicQty = getQtyByPayType(
      currentWorkOrder.basicType,
      approvedHoursQty,
      approvedDeviceQty,
      quantity,
      payMinimum,
    );
    let approvedBasePotentialPay = currentWorkOrder.bidAmountBase * basicQty;
    this.setState({ approvedBasePotentialPay });
    if (parseFloat(currentWorkOrder.bidAmountVariable)) {
      const actualVariableQty = getQtyByPayType(
        currentWorkOrder.variableType,
        approvedHoursQty,
        approvedDeviceQty,
        Infinity,
      );
      if (actualVariableQty > parseFloat(currentWorkOrder.variablePayAfter)) {
        const diffOfBaseTotalHrs = Math.min(
          parseFloat(actualVariableQty) - parseFloat(currentWorkOrder.variablePayAfter),
          parseFloat(currentWorkOrder.maxQuantity) - parseFloat(currentWorkOrder.variablePayAfter),
        );
        const variablePay = diffOfBaseTotalHrs * currentWorkOrder.bidAmountVariable;
        approvedBasePotentialPay += variablePay;
      }
    }
    this.setState({ finalEstimateTotal: approvedBasePotentialPay });
  }

  getApprovedExpensesAmount = () => {
    const { selectedExpenses } = this.state;
    return selectedExpenses.reduce((acc, cur) => acc + cur.amount, 0);
  }

  getReasonForDenyingExpenses = reason => {
    const {
      rejectedExpensesReasons,
      newRejection,
    } = this.state;
    const { toggleSecondModalClose } = this.props;
    const newRejectionReasonObject = {
      ...rejectedExpensesReasons,
      [newRejection]: reason,
    };
    this.setState(cur => ({
      rejectedExpensesReasons: newRejectionReasonObject,
      newRejection: '',
      selectedExpenses: cur.nexSelectValue,
      nexSelectValue: [],
    }));
    toggleSecondModalClose('explanationInputModal', true);
  }

  handlerOnBlurFinalPayParameter = event => {
    if (!event.target.value) this.setState({ [event.target.name]: 0 }, this.calculateFinalPay);
  }

  handlerChanges = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handlerNumberChanges = event => {
    const parseFloatValue = parseFloat(event.target.value);
    const newValue = Number.isNaN(parseFloatValue) ? '' : parseFloatValue;
    this.setState({
      [event.target.name]: newValue,
    }, () => {
      if (typeof newValue === 'number') this.calculateFinalPay();
    });
  };

  handlerCheckedChanges = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handlerMultiCheckedChanges = (name, value) => {
    const {
      disabled,
      ...stateFields
    } = this.state;
    const { toggleModal } = this.props;
    if (disabled) return;
    const isRejected = stateFields[name].length > value.length;
    if (isRejected) {
      const newRejection = stateFields[name]
        .filter(item => !value.filter(el => item.value === el.value).length);
      const rejectedExpenseDescription = newRejection.length > 1 ? 'All rejected expenses' : newRejection[0].label;
      this.setState({
        newRejection: rejectedExpenseDescription,
        nexSelectValue: value,
      });
      // eslint-disable-next-line consistent-return
      return toggleModal(true, 'explanationInputModal', true, 'expenses');
    }
    this.setState({ [name]: value });
  };



  submitApprovals = (event, sendAdjustmentToContractor = false, justSaveData = false) => {
    event.preventDefault();
    const {
      finalPayAdjustment,
      adjustmentReason,
      expenses,
      selectedExpenses,
      rejectedExpensesReasons,
      approvedHoursQty,
      approvedDeviceQty,
      communication,
      work,
      deliverables,
      finalEstimateTotal,
    } = this.state;
    const {
      currentWorkOrder,
      saveApproveData,
      approvedWorkOrder,
      toggleModal,
      profile,
    } = this.props;
    const finalPay = currentWorkOrder.status === 'approved'
        ? currentWorkOrder.finalPay
        : parseFloat(finalPayAdjustment || 0)
          + parseFloat(finalEstimateTotal)
          + this.getApprovedExpensesAmount()
    if(!justSaveData && profile.balance.total_balance < finalPay) {
      toggleModal(true, 'confirmModal', true, {
        onAccept: () => {
          setTimeout(() => {
            this.submitApprovals(event, false, true)
          }, 0)
        },
        header: "Your funds balance is not enough to approve and pay",
        buttonLabels: {
          reject: 'CANCEL',
          confirm: 'SAVE ONLY',
        }
      });
      return;
    }

    if (finalPayAdjustment < 0 && !adjustmentReason) {
      Notification('warning', {
        message: 'Type your reason of a negative adjustment amount first!',
      });
      return;
    }
    if (finalPay < 0) {
      Notification('error', {
        message: 'Work Order can\'t be approved with negative final pay!',
      });
      return;
    }
    const newRejectedExpensesReasons = [];
    expenses.forEach(item => {
      if (!selectedExpenses.filter(el => el.value === item.value).length) {
        newRejectedExpensesReasons.push({
          id: item.value,
          expense: item.label,
          reason: rejectedExpensesReasons[item.label] || rejectedExpensesReasons['All rejected expenses'],
        });
      }
    });
    const workOrderData = {
      work_order_id: currentWorkOrder._id,
      approvedHoursQty,
      approvedDeviceQty,
      finalPayAdjustment,
      adjustmentReason,
      communicationAcceptable: communication,
      workAcceptable: work,
      deliverablesAcceptable: deliverables,
      approvedExpenses: selectedExpenses.map(item => item.value),
      sendAdjustmentToContractor,
      rejectedExpensesReasons: newRejectedExpensesReasons,
    };

    // alert(justSaveData)
    // eslint-disable-next-line consistent-return
    if (justSaveData) return saveApproveData(workOrderData, finalPay);

    approvedWorkOrder(workOrderData, finalPay);
  }

  revertToAssigned = () => {
    const { currentWorkOrder: order } = this.props
    const { _id } = order

    this.props.revertWorkOrderTo('assigned', _id)
  }

  approvePayAdjustment = event => {
    event.preventDefault();
    event.stopPropagation();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.approvePayAdjustmentByContractor(this.props.currentWorkOrder._id);
  }

  // eslint-disable-next-line react/sort-comp
  formRef = createRef();

  render() {
    const {
      quantity,
      approvedHoursQty,
      approvedDeviceQty,
      finalEstimateTotal,
      finalPayAdjustment,
      adjustmentReason,
      communication,
      work,
      deliverables,
      disabled,
      selectedExpenses,
      expenses,
    } = this.state;
    const { currentWorkOrder, auth } = this.props;
    const workOrderControlByStatus = workOrderStatusControl(currentWorkOrder.status);

    // onClick={event => this.submitApprovals(event, false, true)}
    return (
    <form ref={this.formRef} onSubmit={event => this.submitApprovals(event)}>
        <div className="text-in-one-line" style={{ textAlign: 'left' }}>
          <MDBRow>
            <hr className="my-4 hrline2" />
          </MDBRow>
          <MDBRow style={{ marginBottom: '15px' }}>
            <MDBCol><h6>Work Order Approval</h6></MDBCol>
          </MDBRow>
          <MDBRow className="no-margins">
            <MDBCol className="font12" style={{ marginBottom: '6px' }}>
              Base Pay
            </MDBCol>
            <MDBCol className="font12" style={{ marginBottom: '6px' }}>
              Rate Performance
            </MDBCol>
          </MDBRow>
          <MDBRow className="no-margins">
            <MDBCol className="d-flex bordervala wo_app_a">
              <MDBCol>
                <div className="d-flex" style={{ marginBottom: '20px' }}>
                  <MDBCol>
                    <label>{`Amount - ${currentWorkOrder.bidAmountBase.toLocaleString('en-US', localCurrencySettings)}`}</label>
                  </MDBCol>
                </div>
                <div className="d-flex" style={{ marginBottom: '20px' }}>
                  <MDBCol>
                    <label>{`Qty - ${quantity}`}</label>
                  </MDBCol>
                </div>
              </MDBCol>
              <MDBCol>
                <div style={{ marginBottom: '30px' }} className="d-flex">
                  <MDBCol md="3">
                    <label>{`Per - ${currentWorkOrder.basicType}`}</label>
                  </MDBCol>
                </div>
                <div className="d-flex" style={{ marginBottom: '20px' }}>
                  <MDBCol>
                    <label>
                      {`Est.Pay - ${(
                        currentWorkOrder.bidAmountBase * (
                          currentWorkOrder.basicType === 'Site' ? 1 : currentWorkOrder.quantity
                        )
                      ).toLocaleString('en-US', localCurrencySettings)}`}
                    </label>
                  </MDBCol>
                </div>
              </MDBCol>
            </MDBCol>
            <MDBCol className="bordervala wo_app_a">
              <div className="d-flex justify-content-between">
                <MDBCol md="8">
                  <div className="font11">
                    <span style={{ fontWeight: 'bold' }}>Soft Skills</span>
                    <span className="dashdiv">-</span>
                    <span>
                      {this.getPercentValueString(currentWorkOrder.percentCommunication)}
                    </span>
                  </div>
                </MDBCol>
                <MDBCol md="4">
                  <div className="d-flex justify-content-end align-items-center acceptiblecb">
                    <MDBInput
                      filled
                      disabled={disabled}
                      className="float-right"
                      label="Acceptable"
                      type="checkbox"
                      id="communication"
                      name="communication"
                      onChange={this.handlerCheckedChanges}
                      checked={communication}
                      labelClass="font11 color-white"
                    />
                  </div>
                </MDBCol>
              </div>
              <div className="d-flex justify-content-between">
                <MDBCol md="8">
                  <div className="font11">
                    <span style={{ fontWeight: 'bold' }}>Work</span>
                    <span className="dashdiv">-</span>
                    <span>{this.getPercentValueString(currentWorkOrder.percentWork)}</span>
                  </div>
                </MDBCol>
                <MDBCol md="4">
                  <div className="d-flex justify-content-end align-items-center acceptiblecb">
                    <MDBInput
                      filled
                      disabled={disabled}
                      className="float-right"
                      label="Acceptable"
                      type="checkbox"
                      id="work"
                      name="work"
                      onChange={this.handlerCheckedChanges}
                      checked={work}
                      labelClass="font11 color-white"
                    />
                  </div>
                </MDBCol>
              </div>
              <div className="d-flex justify-content-between">
                <MDBCol md="8">
                  <div className="font11">
                    <span style={{ fontWeight: 'bold' }}>Deliverables</span>
                    <span className="dashdiv">-</span>
                    <span>{this.getPercentValueString(currentWorkOrder.percentDeliverables)}</span>
                  </div>
                </MDBCol>
                <MDBCol md="4">
                  <div className="d-flex justify-content-end align-items-center acceptiblecb">
                    <MDBInput
                      filled
                      disabled={disabled}
                      className="float-right"
                      label="Acceptable"
                      type="checkbox"
                      id="deliverables"
                      name="deliverables"
                      onChange={this.handlerCheckedChanges}
                      checked={deliverables}
                      labelClass="font11 color-white"
                    />
                  </div>
                </MDBCol>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow className="no-margins">
            <MDBCol className="font12" style={{ marginBottom: '6px' }}>
              Variable Pay
            </MDBCol>
            <MDBCol className="font12" style={{ marginBottom: '6px' }}>
              Final Pay
            </MDBCol>
          </MDBRow>
          <MDBRow className="no-margins">
            <MDBCol className="bordervala">
              <MDBRow style={{ marginBottom: '15px' }}>
                <MDBCol>
                  <label className="font11">
                    {
                      `Amount - ${
                        (currentWorkOrder.bidAmountVariable || 0).toLocaleString('en-US', localCurrencySettings)
                      }`
                    }
                  </label>
                </MDBCol>
                <MDBCol>
                  <label className="font11">{`Per - ${currentWorkOrder.variableType}`}</label>
                </MDBCol>
              </MDBRow>
              <MDBRow style={{ marginBottom: '15px' }}>
                <MDBCol>
                  <label className="font11">{`After - ${currentWorkOrder.variablePayAfter}`}</label>
                </MDBCol>
                <MDBCol>
                  <label className="font11">{`Max.Qty - ${currentWorkOrder.maxQuantity || 0}`}</label>
                </MDBCol>
              </MDBRow>
              <MDBRow style={{ marginBottom: '15px' }}>
                <MDBCol md="6">
                  <label className="font11">Actual Qty Hours</label>
                  <input
                    min={0.00}
                    step="any"
                    disabled={disabled}
                    type="number"
                    className="form-control woaaq"
                    placeholder={0}
                    name="approvedHoursQty"
                    value={approvedHoursQty}
                    onBlur={this.handlerOnBlurFinalPayParameter}
                    onChange={this.handlerNumberChanges}
                  />
                </MDBCol>
                {
                  isDeviceType(currentWorkOrder.basicType, currentWorkOrder.variableType, currentWorkOrder.variableAmount)
                    ? (
                      <MDBCol md="6">
                        <label className="font11 text-in-one-line">Actual Qty Devices</label>
                        <div style={{ width: 'min-content' }} className="flex-box">
                          <input
                            style={{ width: '70px' }}
                            min={0.00}
                            step="any"
                            disabled={disabled}
                            type="number"
                            className="form-control woaaq"
                            placeholder={0}
                            name="approvedDeviceQty"
                            value={approvedDeviceQty}
                            onBlur={this.handlerOnBlurFinalPayParameter}
                            onChange={this.handlerNumberChanges}
                          />
                          <div
                            className="flex-box align-items-end text-in-one-line"
                            style={{ paddingLeft: '0.5rem' }}
                          >
                            <label
                              className="font11 font-weight-bolder"
                              style={{ color: '#ffff32' }}
                            >
                              {`Contractor Qty: ${currentWorkOrder.qtyDevices || 0}`}
                            </label>
                          </div>
                        </div>
                      </MDBCol>
                    )
                    : <></>
                }
              </MDBRow>
              {
                workOrderControlByStatus.completed && auth.user.userType !== 'contractor'
                && currentWorkOrder.basicType === 'Hour'
                && (
                  currentWorkOrder.approvedHoursQty > (
                    currentWorkOrder.quantity
                    + (
                      currentWorkOrder.variableType === 'Hour'
                        ? currentWorkOrder.maxQuantity - currentWorkOrder.variablePayAfter
                        : 0
                    )
                  )
                )
                  ? (
                    <MDBRow>
                      <MDBCol md="6">
                        <EstimatedTimeOverageLabel
                          text="Over Est. Time!"
                        />
                      </MDBCol>
                    </MDBRow>
                  )
                  : <></>
              }
            </MDBCol>
            <MDBCol className="bordervala">
              <MDBRow style={{ marginBottom: '15px' }}>
                <MDBCol>
                  <label className="font11">Base Pay </label>
                  {' : '}
                  <span>{(currentWorkOrder.bidAmountBase).toLocaleString('en-US', localCurrencySettings)}</span>
                </MDBCol>
                <MDBCol>
                  <label className="font11">Variable Pay </label>
                  {' : '}
                  <span>
                    {(currentWorkOrder.bidAmountVariable || 0).toLocaleString('en-US', localCurrencySettings)}
                  </span>
                </MDBCol>
              </MDBRow>
              <MDBRow style={{ marginBottom: '15px' }}>
                <MDBCol>
                  <label className="font11">Estimated Total </label>
                  {' : '}
                  <span>{finalEstimateTotal.toLocaleString('en-US', localCurrencySettings)}</span>
                </MDBCol>
                <MDBCol>
                  <label className="font11">Pay Adjustments</label>
                  {' : '}
                  <input
                    disabled={disabled}
                    type="number"
                    className="form-control woaaq"
                    placeholder={0}
                    name="finalPayAdjustment"
                    value={finalPayAdjustment}
                    onBlur={this.handlerOnBlurFinalPayParameter}
                    onChange={this.handlerNumberChanges}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow style={{ marginBottom: '0.4rem' }}>
                <MDBCol>
                  <label className="font11">Pay Adjustment Reason</label>
                  {
                    disabled
                      ? (
                        <label className="woamaxq adjustment-label">
                          {adjustmentReason}
                        </label>
                      )
                      : (
                        <textarea
                          disabled={disabled}
                          type="text"
                          className="form-control woamaxq"
                          id="adjustmentReason"
                          placeholder="Type your reason"
                          name="adjustmentReason"
                          value={adjustmentReason}
                          onChange={this.handlerChanges}
                        />
                      )
                  }
                </MDBCol>
              </MDBRow>
              {
                expenses.length
                  ? (
                    <>
                      {
                        currentWorkOrder.status !== 'approved'
                          && (
                            <MDBRow>
                              <MDBCol style={{ textAlign: 'left' }}>
                                <h6
                                  className="font14"
                                  style={{
                                    color: 'green',
                                  }}
                                >
                                  <b>
                                    <span>
                                      Tech Added Expenses!
                                    </span>
                                  </b>
                                </h6>
                              </MDBCol>
                            </MDBRow>
                          )
                      }
                      <MDBRow>
                        <MDBCol style={{ marginBottom: '0.4rem' }}>
                          <Select
                            components={
                              {
                                ValueContainer: CustomValueContainer,
                                MultiValueLabel: CustomMultiValueLabel,
                              }
                            }
                            placeholder="Expenses ..."
                            hideSelectedOptions={false}
                            isMulti
                            closeMenuOnSelect={false}
                            options={expenses}
                            value={selectedExpenses}
                            styles={customSelectStyle}
                            onChange={(value) => this.handlerMultiCheckedChanges('selectedExpenses', value || [])}
                          />
                        </MDBCol>
                        {
                          this.formRef?.current
                            ? (
                              <PortalContainer
                                parentContainer={this.formRef.current}
                                modalName="explanationInputModal"
                              >
                                <ExplanationInputModal
                                  isCloseAvailable
                                  returnExplanation={this.getReasonForDenyingExpenses}
                                  title="Reason to deny expenses"
                                  type="expenses"
                                />
                              </PortalContainer>
                            )
                            : <></>
                        }
                      </MDBRow>
                      <MDBRow>
                        <MDBCol>
                          <label className="font11">
                            {'Total Expenses : '}
                            {this.getApprovedExpensesAmount().toLocaleString('en-US', localCurrencySettings)}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </>
                  )
                  : null
              }
              <MDBRow>
                <MDBCol className="flex-box" style={{ fontStyle: 'italic', justifyContent: 'flex-end' }}>
                  <span style={{ fontSize: '16px' }}>Final Pay : </span>
                  <h5 style={{ color: 'green' }}>
                    {
                      (
                        currentWorkOrder.status === 'approved'
                          ? currentWorkOrder.finalPay
                          : (
                            parseFloat(finalPayAdjustment || 0)
                            + parseFloat(finalEstimateTotal)
                            + this.getApprovedExpensesAmount()
                          )
                      ).toLocaleString('en-US', localCurrencySettings)
                    }
                  </h5>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          {
            currentWorkOrder.status === 'completed'
            && (
              <>
                {
                  // eslint-disable-next-line no-nested-ternary
                  auth.user.userType === 'client'
                    ? (
                      <>
                        <MDBRow>
                          {
                            (finalPayAdjustment < 0)
                            && (
                              <MDBCol>
                                <MDBBtn
                                  color="info"
                                  className="approveandpaybutton"
                                  onClick={event => this.submitApprovals(event, true)}
                                >
                                  Send Adjustment to Contractor
                                </MDBBtn>
                              </MDBCol>
                            )
                          }
                          <MDBCol style={{ justifyContent: 'flex-end', textAlign: 'end' }}>
                            <MDBBtn
                              className="approveandpaybutton"
                              onClick={event => this.submitApprovals(event, false, true)}
                            >
                              SAVE ONLY
                            </MDBBtn>
                          {this.props.profile?.clientUser?.accessctrl !== 'Dispatcher' &&
                            <MDBBtn
                              disabled={
                                finalPayAdjustment < 0
                                && (
                                  !currentWorkOrder.isApproveAdjustment
                                  || (currentWorkOrder.finalPayAdjustment !== finalPayAdjustment)
                                )
                              }
                              color={
                                finalPayAdjustment < 0 && !currentWorkOrder.isApproveAdjustment
                                  ? 'grey'
                                  : 'info'
                              }
                              className="approveandpaybutton"
                              type="submit"
                            >
                              Approve and Pay
                            </MDBBtn>
                          }
                          </MDBCol>
                        </MDBRow>
                      </>
                    )
                    : finalPayAdjustment < 0 && auth.user.userType === 'contractor'
                      ? (
                        <MDBRow>
                          <MDBCol style={{
                            justifyContent: 'flex-end',
                            textAlign: 'end',
                          }}
                          >
                            <MDBBtn
                              color={currentWorkOrder.isApproveAdjustment ? 'green' : 'info'}
                              className="approveandpaybutton"
                              onClick={this.approvePayAdjustment}
                            >
                              {currentWorkOrder.isApproveAdjustment ? 'Approved' : 'Approve'}
                              {' Pay Adjustment'}
                            </MDBBtn>
                          </MDBCol>
                        </MDBRow>
                      )
                      : <></>
                }
                <MDBRow>
                  <MDBCol
                    style={{ justifyContent: 'flex-end', textAlign: 'end' }}
                  >
                    <MDBBtn
                      className="btn Ripple-parent btn-deep-orange editbuttonwom"
                      onClick={event => this.revertToAssigned()}
                      // This was copied from the save button and needs a new method to call
                      // the backend server to change the status.
                    >
                      Revert to Assigned
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol
                    style={{ justifyContent: 'flex-end', textAlign: 'end' }}
                  >
                    <h6
                      className="font14"
                      style={{
                        color: 'green',
                        margin: '.375rem',
                      }}
                    >
                      <b>
                        <span title={rolloverInfoMessage[auth?.user?.userType]}>
                          Have issues with this work order?
                        </span>
                      </b>
                    </h6>
                  </MDBCol>
                </MDBRow>
              </>
            )
          }
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = {
  revertWorkOrderTo,
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps) (ApproveWorkOrder);
