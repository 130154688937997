import React, { useEffect, useState } from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import axios from 'axios';
import Select from 'react-select';
import CustomValueContainer from '../../customSelectElements/ValueContainerForMultiSelect';
import CustomMultiValueLabel from '../../customSelectElements/MultiValueLabelShort';
import Loader from '../../../dashboard/common/Loader';
import ToolsElement from './ToolsElement';
import CustomOption from '../../customSelectElements/CustomSelectOptionElement';
import Notification from '../../../notification';
import { white as customSelectStyle } from '../../../../utils/customSelectStyle';

const SelectWorkOrderWorkTypes = ({
  value, onChange, toolsElementClass, deleteElementIconColor,
}) => {
  const [tools, setTools] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios.get('/api/users/fetch_tools_detail')
      .then((res) => {
        setTools(res.data.tools.map((item) => ({
          label: item.title,
          // eslint-disable-next-line no-underscore-dangle
          value: item._id,
          item,
        })));
        setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('error', error);
        Notification('error', { message: error.response?.data?.message || error.message });
        setIsLoading(false);
      });
    return () => onChange([]);
  }, []);

  const deleteWorkType = (index) => {
    const newSelected = value.filter((item, i) => index !== i);
    onChange(newSelected);
  };

  return isLoading
    ? <Loader />
    : (
      <MDBRow className="border-bottom-line">
        <MDBCol md="5">
          <Select
            isClearable
            isSearchable
            isMulti
            components={{
              ValueContainer: CustomValueContainer,
              MultiValueLabel: CustomMultiValueLabel,
              Option: CustomOption,
            }}
            closeMenuOnSelect
            hideSelectedOptions={false}
            options={tools}
            styles={{
              ...customSelectStyle,
              menuList: (provided) => ({
                ...provided,
                position: 'unset',
              }),
            }}
            onChange={onChange}
            value={value}
            placeholder="Choose Work Type"
          />
        </MDBCol>
        <MDBCol md="7" className="font12 flex-box">
          <MDBRow>
            {
              value && value.map((item, i) => (
                <ToolsElement
                  key={item.value}
                  label={item.label}
                  deleteTools={() => deleteWorkType(i)}
                  className={toolsElementClass}
                  deleteIconColor={deleteElementIconColor}
                />
              ))
            }
          </MDBRow>
        </MDBCol>
      </MDBRow>
    );
};

export default SelectWorkOrderWorkTypes;
