import React from 'react';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import { localCurrencySettings } from '../../../../utils/constans';

const NotEnoughFunds = ({ closeModal, addFunds, fundsInfo }) => (
  <>
    <MDBRow>
      <MDBCol>
        <h5 style={{ textAlign: 'center' }}>Not Enough Funds Available!</h5>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol>
        { (fundsInfo.fundsNeededFromWallet - fundsInfo.holdFunds).toLocaleString('en-US', localCurrencySettings) }
        {' '}
        funds needed!
      </MDBCol>
    </MDBRow>
    <MDBRow className="flex-center">
      <MDBCol className="flex-grow-0">
        <MDBBtn onClick={closeModal}>Close</MDBBtn>
      </MDBCol>
      <MDBCol className="flex-grow-0">
        <MDBBtn className="text-in-one-line" color="success" onClick={addFunds}>Add Funds</MDBBtn>
      </MDBCol>
    </MDBRow>
  </>
);
export default NotEnoughFunds;
