import React, {useContext, useState} from "react";
import {
    MDBBtn,
    MDBIcon,
    MDBTooltip
} from "mdbreact";
import {SocketContext} from "../../../pages";

const MessageSticker = ({message, userId, sendMessage, fetchConversation, replyAvailable = true, isReply = false}) => {
    const {chatSocket: socket} = useContext(SocketContext);
    const [showReply, changeShowReply] = useState(false)
    const [replyMessage, setReplayMessage] = useState(null)
    const submitReply = event => {
        event.preventDefault();
        sendMessage(replyMessage, message._id)
        fetchConversation(socket);
    }
    const isMyMessage = userId === message.fromId.id
    const recipients = message.toId.filter(el => el._id !== message.fromId.id)
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: `${isMyMessage ? 'flex-end' : 'flex-start'}`,
            marginTop: '1rem'
        }}>
            <div style={{
                marginLeft: `${isReply ? '15px' : '0px'}`,
                background: '#21262c',
                borderRadius: '4px',
                width: '90%',
                padding: '10px'
            }}>
                <div style={{
                    background: '#78909c',
                    padding: '10px',
                    borderRadius: '4px',
                    width: '100%'
                }}>
                    <div className='flex-box' style={{justifyContent: 'space-between'}}>
                        {
                            isReply
                                ? <div style={{fontSize: '11px', color: 'red'}}>reply</div>
                                : null
                        }
                        <label style={{fontSize: '11px'}}>
                            {message.fromId.firstName + ' ' + message.fromId.lastName}
                            <MDBTooltip
                                domElement
                                tag="span"
                                placement="top"
                            >
                                <span > {recipients.length > 0 ? ` + ${recipients.length} others` : ''}</span>
                                <span>{recipients.map(el=><span>{el.firstName + ' ' + el.lastName}<br/></span>)}</span>
                            </MDBTooltip>
                        </label>
                        <label style={{
                            fontSize: '11px',
                            marginLeft: '15px'
                        }}>{new Date(message.sentAt).toLocaleString()}</label>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{textAlign: 'left'}}>{message.messageContent}</div>
                        {replyAvailable
                            ? <button onClick={changeShowReply}>Reply</button>
                            : null}
                    </div>
                </div>
                {
                    message.children?.length
                        ? message.children.map(replyMessage => {
                            return <MessageSticker key={replyMessage._id} message={replyMessage} isReply={true}
                                                   replyAvailable={false}/>
                        }
                        )
                        : message.children?.messageContent
                        ? <MessageSticker style={{justifyContent: 'flex-end'}} key={message.children._id}
                                          message={message.children} replyAvailable={false} isReply={true}/>
                        : null
                }
                {
                    showReply
                        ? <form
                            className='flex-box'
                            onSubmit={submitReply}
                        ><input
                            type='text'
                            name='text'
                            value={replyMessage}
                            onChange={(e) => setReplayMessage(e.target.value)}
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 'none',
                            }}
                        />
                            <MDBBtn
                                color='white'
                                type='submit'
                                style={{
                                    margin: 0,
                                    fontSize: '12px',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                <MDBIcon icon='reply' style={{marginRight: '0.5rem'}}/>
                                Reply
                            </MDBBtn>
                        </form>
                        : null
                }
            </div>
        </div>
    )
}

export default MessageSticker;
