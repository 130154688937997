import React, { useEffect, useState } from 'react';
import {
  MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import axios from 'axios';
import Select from 'react-select';
import { toggleModal, toggleSecondModalClose } from '../../storage/actions/modalsActions';
import { white as customSelectStyle } from '../../utils/customSelectStyle';
import { getProfile } from '../../storage/actions/profile';
import {
  blockForCompany,
  blockForWorkOrder,
  setBlackListPerProject,
  setBlackListPerCompany,
} from '../../storage/actions/blockActions';
import Notification from '../notification';

const BlockModal = ({
  modalState,
  currentWorkOrder,
  getProfile,
  toggleModal,
  toggleSecondModalClose,
  profile,
  auth,
  blockForCompany,
  blockForWorkOrder,
  setBlackListPerProject,
  setBlackListPerCompany,
}) => {
  const [projects, setProjects] = useState([]);
  const [blockedProjects, setBlockedProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [woCompanies, setWOCompanies] = useState([]);
  const [selectedWOCompany, setSelectedWOCompany] = useState([]);
  const [blockedWOCompanies, saveBlockedWOCompanies] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (auth.isAuthenticated) getProfile(auth.user.id);
  }, []);

  const fetchData = async () => {
    try {
      setIsLoaded(false);
      toggleModal(true, 'loader', true);
      const promises = [];
      promises.push(fetchProjects());
      promises.push(fetchWOCompanies());
      if (modalState?.type?.contractorId) {
        promises.push(fetchBlockedProjects(modalState?.type?.contractorId));
        promises.push(fetchBlockedWOCompanies(modalState?.type?.contractorId));
      }
      await Promise.all(promises);
      toggleSecondModalClose('loader', true);
      setIsLoaded(true);
    } catch (error) {
      Notification('error', { message: error.response?.data?.message || error.message });
      toggleModal(false, 'loader');
    }
  };

  const fetchProjects = async () => (
    axios.get('/api/projects')
      .then((res) => {
        if (res.data.success && res.data?.payload?.data?.length) {
          setProjects(res.data.payload.data.map(({ _id, name }) => ({
            value: _id,
            label: name,
          })));
        }
      })
  );

  const fetchBlockedProjects = (contractorId) => axios.get(`/api/projects/blocked/${contractorId}`)
    .then((res) => {
      if (res.data.success && res.data?.payload?.data?.length) {
        const newProjects = res.data.payload.data.map(({ _id, name }) => ({
          value: _id,
          label: name,
        }));
        setSelectedProject(newProjects);
        setBlockedProjects(newProjects);
      }
    });

  useEffect(() => {
    fetchData();
  }, [modalState?.type?.contractorId]);

  const fetchWOCompanies = () => axios.get('/api/wo-companies')
    .then((res) => {
      if (res.data.success && res.data?.payload?.data?.length) {
        setWOCompanies(res.data.payload.data.map(({ _id, name }) => ({
          value: _id,
          label: name,
        })));
      }
    });

  const fetchBlockedWOCompanies = (contractorId) => axios.get(`/api/wo-companies/blocked/${contractorId}`)
    .then((res) => {
      if (res.data.success && res.data.payload?.data?.length) {
        const newCompany = res.data.payload.data.map(({ _id, name }) => ({
          value: _id,
          label: name,
        }));
        saveBlockedWOCompanies(newCompany);
        setSelectedWOCompany(newCompany);
      }
    });

  const handleBlockForWOCompany = () => {
    const unblocked = blockedWOCompanies
      .filter((company) => !(selectedWOCompany.find((item) => item.value === company.value)));
    const blocked = selectedWOCompany
      .filter((company) => !(blockedWOCompanies.find((item) => item.value === company.value)));
    setBlackListPerCompany(
      blocked.map((company) => company.value),
      unblocked.map((company) => company.value),
      modalState?.type?.contractorId,
    );
  };

  const handleBlockForProjects = () => {
    const unblocked = blockedProjects
      .filter((project) => !(selectedProject.find((item) => item.value === project.value)));
    const blocked = selectedProject
      .filter((project) => !(blockedProjects.find((item) => item.value === project.value)));
    setBlackListPerProject(
      blocked.map((company) => company.value),
      unblocked.map((company) => company.value),
      modalState?.type?.contractorId,
    );
  };

  if (!(modalState?.isOpen && isLoaded)) return <></>;

  return (
    <MDBModal
      isOpen={modalState.isOpen}
      toggle={() => {
        toggleModal(!modalState.isOpen);
      }}
      centered
      className="signindiv"
    >
      <MDBRow style={{ justifyContent: 'flex-end', paddingRight: '10px' }}>
        <MDBIcon
          far
          icon="times-circle"
          onClick={() => {
            toggleModal(!modalState.isOpen);
          }}
          size="lg"
          className="blue-grey-text"
        />
      </MDBRow>
      <MDBModalBody style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
        <div className="buttondivsignin">
          <MDBRow style={{ justifyContent: 'center' }}>
            <p
              style={{ color: 'black' }}
            >
              Block Contractor
            </p>
          </MDBRow>
          {currentWorkOrder && modalState?.type?.contractorId && (
            <>
              {!(currentWorkOrder.blocked_users
                && currentWorkOrder.blocked_users
                  .includes(modalState?.type?.contractorId))
                ? (
                  <MDBBtn
                    color="success"
                    className="assignbutton"
                    style={{ width: '100%' }}
                    onClick={() => {
                      // eslint-disable-next-line no-underscore-dangle,max-len
                      blockForWorkOrder(currentWorkOrder._id, modalState?.type?.contractorId);
                    }}
                  >
                    block for WO
                  </MDBBtn>
                )
                : (
                  <MDBBtn
                    color="red"
                    style={{ width: '100%' }}
                    className="assignbutton"
                    onClick={() => {
                      // eslint-disable-next-line max-len,no-underscore-dangle
                      blockForWorkOrder(currentWorkOrder._id, modalState?.type?.contractorId);
                    }}
                  >
                    unblock for WO
                  </MDBBtn>
                )}
            </>
          )}
          {profile?.company?.blocked_users.includes(modalState?.type?.contractorId)
            ? (
              <MDBBtn
                color="red"
                className="assignbutton"
                style={{ width: '100%' }}
                onClick={() => blockForCompany(modalState?.type?.contractorId)}
              >
                Unblock for Client
              </MDBBtn>
            )
            : (
              <MDBBtn
                color="success"
                className="assignbutton"
                style={{ width: '100%' }}
                onClick={() => blockForCompany(modalState?.type?.contractorId)}
              >
                Block for Client
              </MDBBtn>
            )}
          <MDBCol md="12">
            <div className="form-group signinput">
              <label htmlFor="formGroupExampleInput" className="font12">
                Choose available company for block
              </label>
              <Select
                isClearable
                isSearchable
                isMulti
                closeMenuOnSelect={false}
                options={woCompanies}
                value={selectedWOCompany}
                styles={customSelectStyle}
                onChange={(value) => {
                  setSelectedWOCompany(value || []);
                }}
              />
            </div>
          </MDBCol>
          <MDBRow>
            <MDBBtn
              color="info"
              className="signbutton"
              onClick={handleBlockForWOCompany}
            >
              BLOCK FOR COMPANY
            </MDBBtn>
          </MDBRow>
          <MDBCol md="12">
            <div className="form-group signinput">
              <label htmlFor="formGroupExampleInput" className="font12">
                Choose available project for block
              </label>
              <Select
                isClearable
                isMulti
                closeMenuOnSelect={false}
                options={projects}
                value={selectedProject}
                styles={customSelectStyle}
                onChange={(value) => {
                  setSelectedProject(value || []);
                }}
              />
            </div>
          </MDBCol>
          <MDBRow>
            <MDBBtn
              color="info"
              className="signbutton"
              onClick={handleBlockForProjects}
            >
              BLOCK FOR PROJECTS
            </MDBBtn>
          </MDBRow>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
  toggleSecondModalClose,
  getProfile,
  blockForCompany,
  blockForWorkOrder,
  setBlackListPerProject,
  setBlackListPerCompany,
};

const mapStateToProps = (state) => ({
  currentWorkOrder: state.workOrder.currentWorkOrder,
  modalState: state.modalState.blockModal,
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockModal);
