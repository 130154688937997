import React from "react";
import {MDBBtn, MDBCol, MDBRow} from "mdbreact";
import formatAMPM from "../../../../utils/formatAMPM";
import getTotalHour from '../../../../utils/getTotalHourByDates';
import {getDateWithOffset} from "../../../../utils/getDateWithOffset";

const CheckInCheckOutElement = ({isControlElementAvailable, checkInCheckOutInfo, editCheckData, deleteCheckData, userType}) => (
    <div className="d-flex viewfile_div">
        <MDBRow className="row_workdetail">
            <div className="font11 d-flex divworkdetail" style={{marginBottom: "15px", marginTop: "6px"}}>
                <MDBCol style={{paddingLeft: "5px"}}>
                    <span style={{fontWeight: "bold"}}>Check In</span>
                    <span className="dashdiv">-</span>
                    <span>
                    {
                        (new Date(
                            checkInCheckOutInfo.check_in_date
                        ).toLocaleDateString('en-US', {timeZone: 'UTC'}) === "Invalid Date")
                            ?
                            ""
                            :
                            new Date(
                                checkInCheckOutInfo.check_in_date
                            ).toLocaleDateString('en-US', {timeZone: 'UTC'})
                            + "-" + formatAMPM(getDateWithOffset(checkInCheckOutInfo.check_in_date))
                    }
                </span>
                </MDBCol>
                <MDBCol>
                    <span style={{fontWeight: "bold"}}>Check Out</span>
                    <span className="dashdiv">-</span>
                    <span>
                    {
                        !checkInCheckOutInfo.check_out_date || (new Date(
                            checkInCheckOutInfo.check_out_date
                        ).toLocaleDateString('en-US', {timeZone: 'UTC'}) === "Invalid Date")
                            ?
                            ""
                            :
                            new Date(
                                checkInCheckOutInfo.check_out_date
                            ).toLocaleDateString('en-US', {timeZone: 'UTC'})
                            + "-" + formatAMPM(getDateWithOffset(checkInCheckOutInfo.check_out_date))
                    }
                    </span>
                </MDBCol>
                <MDBCol>
                    <span style={{fontWeight: "bold"}}>Total Hours</span>
                    <span className="dashdiv">-</span><span>{getTotalHour(new Date(checkInCheckOutInfo.check_in_date), new Date(checkInCheckOutInfo.check_out_date) )}</span>
                </MDBCol>
            </div>
        </MDBRow>
        <MDBRow>
            <MDBCol>
                <div className="font11" style={{marginBottom: "15px"}}>
                    <MDBCol style={{paddingLeft: "0px"}}>
                        <span style={{fontWeight: "bold"}}>Work Description</span>
                        <span className="dashdiv">-</span>
                        <span
                            className="font11"
                            style={{
                                whiteSpace: 'pre-wrap'
                            }}
                        >
                            {
                                (checkInCheckOutInfo.work_description !== "false")
                                    ? checkInCheckOutInfo.work_description
                                    : ""
                            }
                        </span>
                    </MDBCol>
                </div>
            </MDBCol>
        </MDBRow>
        {
            userType !== 'contractor'
            && (
                checkInCheckOutInfo.checkInLocationInfo?.coords
                || checkInCheckOutInfo.checkOutLocationInfo?.coords
            )
                ? <MDBRow>
                    {
                        checkInCheckOutInfo.checkInLocationInfo?.coords
                            ? <MDBCol>
                                <label className='font11' >Check In was added at a distance of {checkInCheckOutInfo.checkInLocationInfo?.distance?.toFixed(2) + " Miles"}</label>
                            </MDBCol>
                            : <></>
                    }
                    {
                        checkInCheckOutInfo.checkOutLocationInfo?.coords
                            ? <MDBCol>
                                <label className='font11' >Check Out was added at a distance of {checkInCheckOutInfo.checkOutLocationInfo?.distance?.toFixed(2) + " Miles"}</label>
                            </MDBCol>
                            : <></>
                    }
                </MDBRow>
                : <></>
        }
        {
            isControlElementAvailable
                && <MDBRow style={{justifyContent: 'flex-end', marginRight: 5}}>
                    <div>
                        <MDBBtn
                            color="info"
                            className=" editbuttonwom"
                            onClick={event => editCheckData(event, checkInCheckOutInfo)}
                        >
                            Edit
                        </MDBBtn>
                    </div>
                    <div>
                        <MDBBtn
                            color="deep-orange"
                            className="deletebuttonwom"
                            onClick={event => deleteCheckData(event, checkInCheckOutInfo._id)}
                        >
                            Delete
                        </MDBBtn>
                    </div>
                </MDBRow>
        }
    </div>
)

export default CheckInCheckOutElement