import React, { useRef, useState } from 'react';
import {
  MDBBtn, MDBIcon, MDBTooltip, MDBCol,
} from 'mdbreact';
import Notification from '../../../notification';
import { isEmailValid, normalizePhoneInput } from '../../../../utils/validators';

const ContractorInfoAddEdit = ({
  contractorToEdit = {},
  returnContractor,
  closeEdit,
  inputClassName,
}) => {
  const [mobile, setMobile] = useState(contractorToEdit?.mobile || '');
  const [officePhone, setOfficePhone] = useState(contractorToEdit?.ofc_phn || '');

  const formRef = useRef();

  const onSubmit = (event) => {
    event.preventDefault();
    const {
      fname,
      role,
      email,
      // eslint-disable-next-line camelcase
      ofc_phn,
      mobile,
    } = formRef.current;
    if (!isEmailValid(email.value)) {
      Notification('error', { message: 'Invalid email address!' });
    } else {
      const result = {
        fname: fname.value,
        role: role.value,
        email: email.value,
        ofc_phn: ofc_phn.value,
        mobile: mobile.value,
      };
      returnContractor(result);
      formRef.current.reset();
      setMobile('');
      setOfficePhone('');
    }
  };

  return (
    <form ref={formRef} className="flex-box">
      <MDBCol>
        <div className="form-group no-margins">
          <input
            type="text"
            className={`small-input border-none-with-padding full-width ${inputClassName || ''}`}
            name="fname"
            placeholder="Name"
            defaultValue={contractorToEdit.fname}
          />
        </div>
      </MDBCol>
      <MDBCol>
        <div className="form-group no-margins">
          <input
            type="text"
            className={`small-input border-none-with-padding full-width ${inputClassName || ''}`}
            name="role"
            placeholder="Role"
            defaultValue={contractorToEdit.role}
          />
        </div>
      </MDBCol>
      <MDBCol>
        <div className="form-group no-margins">
          <input
            type="email"
            className={`small-input border-none-with-padding full-width ${inputClassName || ''}`}
            name="email"
            placeholder="Email"
            defaultValue={contractorToEdit.email}
            required
          />
        </div>
      </MDBCol>
      <MDBCol>
        <div className="form-group no-margins">
          <input
            type="phone"
            className={`small-input border-none-with-padding full-width ${inputClassName || ''}`}
            name="ofc_phn"
            placeholder="Office Phone"
            value={officePhone}
            onChange={event => setOfficePhone(normalizePhoneInput(event.target.value, officePhone))}
          />
        </div>
      </MDBCol>
      <MDBCol>
        <div className="form-group no-margins">
          <input
            type="phone"
            className={`small-input border-none-with-padding full-width ${inputClassName || ''}`}
            name="mobile"
            placeholder="Mobile"
            value={mobile}
            onChange={event => setMobile(normalizePhoneInput(event.target.value, mobile))}
          />
        </div>
      </MDBCol>
      <MDBCol className="flex-box" style={{ justifyContent: 'center' }}>
        {
          contractorToEdit.email
            ? (
              <>
                <MDBTooltip
                  domElement
                  tag="span"
                  placement="top"
                >
                  <span>
                    <MDBIcon
                      style={{ marginTop: 10 }}
                      far
                      icon="check-circle mr-3"
                      onClick={onSubmit}
                    />
                  </span>
                  <span>Save</span>
                </MDBTooltip>
                <MDBTooltip
                  domElement
                  tag="span"
                  placement="top"
                >
                  <span>
                    <MDBIcon
                      style={{ marginTop: 10 }}
                      far
                      icon="times-circle"
                      onClick={closeEdit}
                    />
                  </span>
                  <span>Close</span>
                </MDBTooltip>
              </>
            )
            : <MDBBtn color="info" className="ccwadd" onClick={onSubmit}>Add</MDBBtn>
          }
      </MDBCol>
    </form>
  );
};

export default ContractorInfoAddEdit;
