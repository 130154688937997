import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

const PageHeader = ({
  isLoading,
  WIN,
}) => (
  <MDBContainer fluid style={{ marginBottom: '15px' }}>
    <MDBRow>
      <MDBCol>
        <h6 style={{ fontWeight: 'bold' }}>
          {
            `${
              // eslint-disable-next-line no-nested-ternary
              isLoading
                ? 'Create And Edit'
                : WIN
                  ? 'Edit'
                  : 'Create'
            } Work Order`
          }
        </h6>
        {
          WIN
            ? `WIN - ${WIN}`
            : ''
        }
      </MDBCol>
    </MDBRow>
  </MDBContainer>
);

export default PageHeader;
