import React from 'react';
import {
  MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import { connect } from 'react-redux';
import { toggleModal } from '../../../storage/actions/modalsActions';
import handleForgotPassword from '../../../utils/reduxForm/onSubmit/forgotPassword';
import ForgotPasswordReduxForm from '../../layout/reduxForm/ForgotPasswordReduxForm';

const ForgotPasswordModal = ({ modalState, toggleModal }) => {
  const closeModal = () => {
    toggleModal(!modalState.isOpen, 'forgotPassword');
  };

  return (
    <MDBModal isOpen={modalState.isOpen} toggle={closeModal} centered className="signindiv">
      <MDBRow style={{ justifyContent: 'flex-end', paddingRight: '10px' }}>
        <MDBIcon far icon="times-circle" onClick={closeModal} size="lg" />
      </MDBRow>
      <MDBModalBody style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
        <MDBRow style={{ justifyContent: 'center' }}>
          <p>Forgot Password?</p>
        </MDBRow>
        <ForgotPasswordReduxForm onSubmit={handleForgotPassword} />
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = (state) => ({
  modalState: state.modalState.forgotPasswordModal,
});

export default connect(mapStateToProps, {
  toggleModal,
})(ForgotPasswordModal);
