import React, { useCallback } from 'react';
import {
  MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBRow,
} from 'mdbreact';
import { toggleModal } from '../../storage/actions/modalsActions';
import { connect } from 'react-redux';
import Modal from '../../components/modal';
import { ResourceDataTable } from "../../components/datatables/ResourceDataTable";
import { recruitColumns } from '../../utils/constans';
import deleteRecruit from '../../utils/api/delete/deleteRecruit';
import { reloadData } from '../../storage/actions/applicationStateActions'

const Recruits = ({ toggleModal, mainContainer, isReload, reloadData }) => {
  const Actions = (row) => (
    <MDBIcon
      icon="trash-alt"
      className="v-paddings-half"
      size="lg"
      onClick={() => {
        toggleModal(true, 'loader')
        deleteRecruit(row._id).finally(() => {
          toggleModal(false, 'loader')
        });
        reloadData();
      }}
    />
  )

  return (
    <MDBContainer className="clientdascontentmaindiv">
      <MDBRow>
        <MDBCol style={{ textAlign: 'center' }}>
          Recruits
        </MDBCol>
        <MDBCol style={{ flexGrow: 0 }}>
          <MDBBtn
            className="background-green flex-box flex-align-center text-nowrap mr-1"
            onClick={() => toggleModal(true, 'importRecruitsModal')}
          >
            <MDBIcon className="vwimg" icon="upload" />
            Upload CSV
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <ResourceDataTable
            entity="/api/recruits"
            columns={recruitColumns}
            actions={Actions}
            isReload={isReload}
            sortBy="name"
          />
        </MDBCol>
      </MDBRow>
      <Modal mainContainer={mainContainer.current} />
    </MDBContainer>
  )
};

const mapDispatchToProps = {
  toggleModal,
  reloadData,
};
const mapStateToProps = state => ({
  isReload: state.applicationState.isReload,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Recruits);