import React, {useEffect, useRef, useState} from "react";
import DataTable from "react-data-table-component";
import {useDataTable} from "./useDataTable";
import {TableWrapper, FiltersWrapper} from "./styled";
import {MDBIcon} from "mdbreact";

export const ResourceDataTable = ({entity, Filters, ...props}) => {
  const [query, setQuery] = useState({})
  const [reset, setReset] = useState({})
  const {context, setFilters} = useDataTable(entity, props)

  useEffect(() => setFilters({}), [reset])

  return <TableWrapper>
    {Filters && <FiltersDropdown onApply={() => setFilters(query)} onReset={() => setReset(prev => !prev)}>
      <Filters value={query} onChange={setQuery} reset={reset}/>
    </FiltersDropdown>}
    <DataTable
      columns={context.columns}
      data={context.data.payload}
      pagination
      paginationServer
      paginationRowsPerPageOptions={[5, 15, 30]}
      paginationTotalRows={context.data.total}
      paginationPerPage={+context.perPage}
      progressPending={context.pending}
      progressComponent={<>loading ...</>}
      onChangePage={page => context.setPage(page)}
      onChangeRowsPerPage={perPage => context.setPerPage(perPage)}
      onSort={(column, direction) => {
        context.setSortBy(column.sortField)
        context.setSortDirection(direction === "asc"? 1: -1)
      }}
      theme="sourcew9"
    />
  </TableWrapper>
}

const FiltersDropdown = ({ children, onReset, onApply }) => {
  const wrapperRef = useRef(null)
  const buttonRef = useRef(null)
  const [open, setOpen] = useState(false);

  useOnOutsideClick(() => {
    setOpen(false)
  }, [wrapperRef, buttonRef])

  const toggleOpen = () => {
    setOpen(prev => !prev)
  }

  const action = (handler = () => void 0, close = true) => () => {
    close && setOpen(false)
    handler()
  }

  return <FiltersWrapper>
    <button ref={buttonRef} className="btn btn-default position-absolute m-0" style={{top: '-74px'}} onClick={toggleOpen}>
      <MDBIcon icon="filter" size="sm" className="mr-2"/>
      Filters
    </button>
    <div ref={wrapperRef} className={"position-absolute card text-dark" + (open ? "":" d-none")} style={{zIndex: 999999, top: '-15px'}}>
      <div className="card-body">
        {children}
      </div>
        <div className="card-action d-flex justify-content-end px-3 pb-1">
          <button className="btn btn-outline-primary py-1 px-2" onClick={action(onReset, false)}>reset</button>
          <button className="btn btn-primary py-1 px-2" onClick={action(onApply)}>apply</button>
        </div>
      </div>
  </FiltersWrapper>
}

const useOnOutsideClick = (handler = () => void 0, refs) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (!refs.filter((ref) => ref.current).some(ref => ref.current.contains(event.target))) {
        handler();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, refs);
}
