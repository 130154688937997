import React, { useState } from 'react';
import {
  MDBBtn, MDBCol, MDBInput,
  MDBModal,
  MDBModalBody,
  MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import { toggleSecondModalClose } from '../../storage/actions/modalsActions';
import Notification from '../notification';

const ConfirmModal = ({
  toggleSecondModalClose,
  modalState,
}) => {
  const [value, setValue] = useState('');

  const handlerChanges = event => setValue(event?.target?.value);

  const onClose = () => {
    toggleSecondModalClose('confirmModal', true);
  };

  // eslint-disable-next-line consistent-return
  const onSubmit = () => {
    if (modalState.type?.inputLabel && !value) {
      return Notification('warging', {
        message: `Type ${modalState.type?.inputLabel}!`,
      });
    }
    modalState.type.onAccept(value);
    onClose();
  };

  const onReject = () => {
    modalState.type.onReject();
    onClose()
  }

  return (
    <MDBModal
      isOpen={modalState.isOpen}
      toggle={onClose}
      centered
    >
      <MDBModalBody style={{ fontFamily: 'Arial, Helvetica, sans-serif', backgroundColor: 'white', minWidth: '656px'}}>
        <div className="buttondivsignin">
          <MDBRow style={{ justifyContent: 'center', marginTop: '20px' }}>
            <h5
              style={{ color: 'black' }}
            >
              {
                modalState?.type?.header || 'Are You Sure?'
              }
            </h5>
          </MDBRow>
          {
            modalState.type?.content
              ? modalState.type?.content
              : <></>
          }
          {
            modalState.type?.inputLabel
              ? (
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      id="value"
                      label={modalState.type?.inputLabel}
                      value={value}
                      onChange={handlerChanges}
                    />
                  </MDBCol>
                </MDBRow>
              )
              : <></>
          }
          <MDBRow style={{ justifyContent: 'center' }}>
            <MDBBtn
              color="info"
              className="signbutton"
              onClick={modalState.type.onReject ? onReject : onClose}
            >
              { modalState?.type?.buttonLabels?.reject || 'No'}
            </MDBBtn>
            <MDBBtn
              color="success"
              className="signbutton"
              onClick={onSubmit}
            >
              { modalState?.type?.buttonLabels?.confirm || 'Yes'}
            </MDBBtn>
          </MDBRow>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = state => ({
  modalState: state.modalState.confirmModal,
});

export default connect(mapStateToProps, { toggleSecondModalClose })(ConfirmModal);
