import React, { useEffect, useState } from 'react';
import {
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBRow,
} from 'mdbreact';
import { connect } from 'react-redux';
import { toggleModal, toggleSecondModalClose } from '../../storage/actions/modalsActions';
import AddOrEditGroupsReduxForm from '../layout/reduxForm/AddOrEditGroupReduxForm';
import getGroup from '../../utils/api/get/getContractorGroup';
import { reloadData } from '../../storage/actions/applicationStateActions';
import addOrEditGroup from '../../utils/reduxForm/onSubmit/addOrEditGroup';
import emptyValues from '../../utils/reduxForm/InitialValues/addTeam';
import { compact, uniqBy } from 'lodash';
import { change } from 'redux-form';

const AddOrEditGroups = ({ modalState, toggleModal, toggleSecondModalClose, change }) => {
  const { id } = modalState.type;
  const [selectedContractors, setSelectedContractors] = useState([]);
  const [groupUsers, setGroupUsers] = useState([]);
  const [allContractors, setAllContractors] = useState([]);

  useEffect(() => {
    if (id) fetchGroupUsers();
  }, []);

  const fetchGroupUsers = async () => {
    toggleModal(true, 'loader', true);

    const res = await getGroup(id);
    setGroupUsers({
      _id: res._id,
      name: res.name,
      members: res.groupUsers.map(cur => cur.userId),
    });
    toggleSecondModalClose('loader', true);
  };
  useEffect(() => {
    if(groupUsers.members?.length && allContractors.length) {
      setSelectedContractors(allContractors.filter(item => groupUsers.members.some(i => item.value.userId === i)))
    }
  }, [allContractors, groupUsers])

  const handleSelectContractor = (contractor) => {
    setSelectedContractors(prev => uniqBy([...prev, contractor], 'value.userId'))
  }
  const removeContractor = (contractor) => {
    setSelectedContractors(prev => prev.filter(item => item.value.userId !== contractor))
  }
  useEffect(() => {
    change('addOrEditGroupsReduxForm', 'contractorIds', compact(selectedContractors).map(i => i.value.userId))
  }, [selectedContractors]);

  const closeModal = () => {
    toggleModal(!modalState.isOpen, 'addOrEditGroups', false);
    change('addOrEditGroupsReduxForm', 'contractorIds', [])
  };

  return (
    <MDBModal
      className="applypop"
      isOpen={modalState.isOpen}
      toggle={closeModal}
      fullHeight
      position="right"
    >
      <MDBModalBody style={{ color: 'white' }}>
        <MDBRow className="mb-2">
          <MDBCol md="10">
            <h5 style={{ fontWeight: 'bold' }} className="">
              {`${id ? 'Edit' : 'Add'} group`}
            </h5>
          </MDBCol>
          <MDBCol md="2" style={{ textAlign: 'end' }}>
            <MDBIcon
              far
              icon="times-circle"
              onClick={closeModal}
              size="lg"
              className="blue-grey-text"
            />
          </MDBCol>
        </MDBRow>
        <AddOrEditGroupsReduxForm
          initialValues={id ? groupUsers : emptyValues}
          closeModal={closeModal}
          onSubmit={addOrEditGroup}
          selectedContractors={selectedContractors}
          handleSelectContractor={handleSelectContractor}
          removeContractor={removeContractor}
          setAllContractors={setAllContractors}
        />
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = state => ({
  modalState: state.modalState.addOrEditGroups,
  isReload: state.applicationState.isReload,
});

export default connect(mapStateToProps, {
  change,
  toggleModal,
  reloadData,
  toggleSecondModalClose,
})(AddOrEditGroups);
