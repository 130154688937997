import React from "react";
import { MDBContainer, MDBFooter } from "mdbreact";

const Footer = () => {
  return (
    <MDBFooter color="unique-color-dark" className="font-small mt-4 fixed-bottom">
      <div className="footer-copyright text-center pt-2">
        <MDBContainer fluid>
          {/*&copy; {new Date().getFullYear()} Copyright:{" "}
          <a href="https://www.1099house.com"> 1099house.com </a>*/}
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};

export default Footer;
