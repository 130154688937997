import React, { useRef, useState } from 'react';
import {
  MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import { useHistory } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import axios from 'axios';
import { toggleModal } from '../../storage/actions/modalsActions';
import Notification from '../notification';
import Loader from '../dashboard/common/Loader';
import { localCurrencySettings } from '../../utils/constans';

const PurchaseCertificate = ({
  modalState,
  toggleModal,
}) => {
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [loaded, setLoadedStatus] = useState(true);
  const yearInput = useRef(null);

  const history = useHistory();

  const handlerCardNumberInput = (event) => {
    const typedCardNumber = event.target.value;

    if (typedCardNumber === '') return setCardNumber('');

    if (typedCardNumber.slice(-1) !== ' '
        && !Number(typedCardNumber.slice(-1))
        && Number(typedCardNumber.slice(-1)) !== 0) {
      return setCardNumber(cardNumber);
    }

    if (
      typedCardNumber.length < cardNumber.length
            && (
              typedCardNumber.length === 5
                || typedCardNumber.length === 10
                || typedCardNumber.length === 15
            )
    ) {
      return setCardNumber(typedCardNumber.slice(0, -1));
    }

    if (
      typedCardNumber.length > cardNumber.length
            && (
              typedCardNumber.length === 4
                || typedCardNumber.length === 9
                || typedCardNumber.length === 14
            )
    ) {
      return setCardNumber(`${typedCardNumber} `);
    }

    return setCardNumber(typedCardNumber);
  };

  const handlerSetMonth = (event) => {
    const value = Number(event.target.value);
    if (event.target.value.length === 2 || value > 1) {
      if (value && value > 0) {
        if (value <= 12) {
          yearInput.current.focus();
          return setMonth(event.target.value);
        }
        return Notification('warning', { message: 'max value for month 12' });
      }
      setMonth('01');
      return Notification('warning', { message: 'min value for month 1 or 01' });
    }
    if (event.target.value === '00') {
      return Notification('warning', { message: 'min value for month 1 or 01' });
    }
    return setMonth(event.target.value);
  };

  const handlerSetYear = ({ target }) => {
    const value = Number(target.value) || '';
    setYear(value);
  };

  const clearingDataFields = () => {
    setCardNumber('');
    setYear('');
    setMonth('');
    setCardName('');
  };

  const closeModal = () => {
    clearingDataFields();
    toggleModal(!modalState.isOpen, 'purchaseCertificate', true);
  };

  const handlerSubmit = async (event) => {
    event.preventDefault();
    setLoadedStatus(false);
    await purchaseCertificate(cardNumber, month, year, modalState?.type?.id);
  };

  const purchaseCertificate = async (cardNumber, expMonth, expYear, idCertificateType) => {
    await axios.post('/api/certificates/buy', {
      cardNumber,
      expMonth,
      expYear,
      idCertificateType,
    })
      .then((res) => {
        const notificationType = res.data.success ? 'success' : 'error';
        Notification(notificationType, { message: res.data.message });
        if (res.data.success) {
          history.push(`/certifications/${idCertificateType}/video`);
        }
      })
      .catch((error) => {
        Notification('error', { message: error.response?.data?.message || error.message });
        setLoadedStatus(true);
      });
  };

  return (
    <MDBModal
      className="applypop modal-width-500"
      isOpen={modalState.isOpen}
      toggle={closeModal}
      fullHeight
      position="right"
    >
      {loaded ? (
        <MDBModalBody style={{ color: 'white' }}>

          <MDBIcon
            far
            icon="times-circle"
            onClick={closeModal}
            size="lg"
            className="blue-grey-text"
            style={{ float: 'right' }}
          />
          <MDBRow style={{ flexDirection: 'column' }}>
            <div>
              <h1 style={{ fontSize: '1.5rem', fontWeight: 'bolder', textAlign: 'center' }}>Soft Skills Certification</h1>
            </div>
            <div style={{
              display: 'flex', color: 'yellow', fontWeight: 'bolder', justifyContent: 'center',
            }}
            >
              <h2>
                {
                parseFloat(modalState?.type?.amount || 0)
                  .toLocaleString('en-US', localCurrencySettings)
              }
              </h2>
              <h2 style={{ fontSize: '1rem', paddingTop: '0.8rem', paddingLeft: '0.3rem' }}>Annually</h2>
            </div>

          </MDBRow>

          <MDBRow>
            <MDBCol md="10">
              <label>Add Card Information</label>
            </MDBCol>
          </MDBRow>

          <MDBRow className="h-paddings-half">
            <MDBCol md="6">
              <input
                className="input-field"
                style={{
                  width: 'inherit',
                }}
                type="string"
                name="name"
                placeholder="Name Or Card"
                value={cardName}
                maxLength={150}
                onChange={(event) => setCardName(event.target.value)}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow className="h-paddings-half">
            <MDBCol md="6">
              <input
                className="input-field"
                style={{
                  width: 'inherit',
                }}
                name="cardNumber"
                placeholder="Card Number"
                maxLength="19"
                onChange={handlerCardNumberInput}
                value={cardNumber}
              />
            </MDBCol>
            <MDBCol md="6">
              <MDBRow>
                <MDBCol md="8" style={{ paddingLeft: 0 }}>
                  <div className="card-date-input flex-box">
                    <input
                      className="input-field"
                      name="cardLifeCycle"
                      placeholder="mm"
                      style={{
                        width: '40px',
                        flexGrow: 2,
                      }}
                      maxLength="2"
                      onChange={handlerSetMonth}
                      value={month}
                    />
                    <div>/</div>
                    <input
                      className="input-field"
                      name="cardLifeCycle"
                      ref={yearInput}
                      placeholder="yy"
                      style={{
                        width: '40px',
                        flexGrow: 2,
                      }}
                      maxLength="2"
                      pattern="^\d{4}$"
                      onChange={handlerSetYear}
                      value={year}
                    />
                    <MDBIcon
                      far
                      icon="calendar-alt"
                      size="lg"
                      style={{
                        flexGrow: 1,
                        cursor: 'none',
                      }}
                    />
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow className="h-paddings-half">
            <MDBCol
              className="flex-box"
              style={{ alignItems: 'flex-end' }}
            >
              <MDBBtn
                className="background-green no-margins"
                style={{
                  padding: '0.3rem 0.5rem',
                  color: '#333333',
                  backgroundColor: '#6cff2e',
                }}
                color="false"
                onClick={handlerSubmit}
              >
                <MDBIcon far className="vwimg" icon="credit-card" />
                Submit
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
      ) : <Loader />}
    </MDBModal>
  );
};

const mapStateToProps = (state) => ({
  modalState: state.modalState.purchaseCertificate,
});

export default connect(mapStateToProps, { toggleModal })(PurchaseCertificate);
