import React from 'react';
import { MDBIcon } from 'mdbreact';

const Sel = ({
  item,
  handlerSort,
  sortBy,
}) => (
  <th
    className={item.sort && item.value === sortBy.value ? 'primary-color-dark' : ''}
    onClick={() => handlerSort(item)}
    style={{ cursor: `${sortBy ? 'pointer' : 'default'}` }}
  >
    <label className="padding-right-10">{item.label}</label>
    {
      item.sort && item.value === sortBy.value
        ? (
          <MDBIcon
            icon={sortBy.direction === 1 ? 'arrow-up' : 'arrow-down'}
          />
        )
        : <></>
    }
  </th>
);

export default Sel;
