import React from 'react';
import { sortWOTypes } from '../../../utils/constans';

const SortPanel = ({
  auth, sortType, order, setSort,
}) => (
  <>
    <div className="optiondiv sbzc">
      <select
        className="browser-default custom-select zipselect_con"
        style={{ color: 'white' }}
        name="order"
        onChange={({ target }) => setSort('order', Number(target.value))}
        value={order}
      >
        <option value={1}>Ascending</option>
        <option value={-1}>Descending</option>
      </select>
    </div>
    <div className="optiondiv sbzc">
      <select
        className="browser-default custom-select zipselect_con"
        style={{ color: 'white' }}
        name="sortType"
        onChange={({ target }) => setSort('sortType', target.value)}
        value={sortType}
      >
        {
          auth.isAuthenticated && auth.user?.userType
            ? sortWOTypes[auth.user.userType].map(type => (
              <option key={type.key} value={type.value}>{type.title}</option>
            ))
            : <></>
        }
      </select>
    </div>
  </>
);

export default SortPanel;
