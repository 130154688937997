import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import Select from 'react-select';

import Loader from '../dashboard/common/Loader';
import Notification from '../notification';

import { toggleModal } from '../../storage/actions/modalsActions';
import { sendInviteFromWorkOrderToContractor } from '../../storage/actions/workOrdersActions';
import { white as customSelectStyle } from '../../utils/customSelectStyle';
import getWorkOrders from '../../utils/api/get/getWorkOrders';

const ContractorInviteSelectWorkOrder = ({
  modalState,
  currentApplicant,
  toggleModal,
  sendInviteFromWorkOrderToContractor,
}) => {
  const [selectWorkOrder, setSelectWorkOrder] = useState('');
  const [workOrders, setWorkOrders] = useState([]);
  const [isLoading, setLoader] = useState(true);

  useEffect(() => {
    getWorkOrders('', '', '', {}, {
      needFilterByRouterStatus: { published: true, draft: true },
      selectedWOTypes: ['draft', 'published'],
    })
      .then((allWorkOrders) => {
        setWorkOrders(
          allWorkOrders
            .filter((workOrder) => workOrder.status === 'published' || workOrder.status === 'draft')
            .map(({
              _id, WIN, customTagId: workOrderId, title,
            }) => ({
              value: _id,
              label: `${WIN} ${workOrderId} ${title}`,
            })),
        );
        setLoader(false);
      });
  }, []);

  const closeModal = () => {
    toggleModal(!modalState.isOpen, 'profile');
  };

  const inviteContractor = () => {
    if (!selectWorkOrder.value) {
      Notification('warning', { message: 'Please select  Work Order!' });
    } else {
      // eslint-disable-next-line max-len
      sendInviteFromWorkOrderToContractor({
        workOrdersId: [selectWorkOrder.value],
        contractorId: currentApplicant.contractor_details.userId,
      });
    }
  };

  if (modalState.isOpen) {
    if (isLoading) {
      return <Loader />;
    }
    return (
      <MDBModal
        className="invitepop"
        isOpen={modalState.isOpen}
        toggle={closeModal}
        fullHeight
        position="right"
      >
        <MDBModalBody>
          <MDBRow style={{ marginBottom: '15px' }}>
            <MDBCol md="6" style={{ textAlign: 'left' }}>
              <h6 style={{ fontWeight: 'bold', marginBottom: '15px' }}>Send Invite</h6>
            </MDBCol>
            <MDBCol md="6" style={{ textAlign: 'end' }}>
              <MDBIcon
                icon="times-circle"
                size="lg"
                style={{ color: '#41566a', cursor: 'pointer' }}
                onClick={closeModal}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ marginBottom: '15px' }}>
            <MDBCol>
              <label className="grey-text float-left font12">Work Order</label>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ marginBottom: '15px' }}>
            <MDBCol>
              <Select
                isClearable
                isSearchable
                options={workOrders}
                value={selectWorkOrder}
                styles={customSelectStyle}
                onChange={setSelectWorkOrder}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <MDBBtn
                color="info"
                className="invitebuttonpop"
                onClick={inviteContractor}
              >
                Invite
              </MDBBtn>
              <MDBBtn
                color="orange"
                onClick={closeModal}
                className="canbutpopcf"
              >
                Cancel
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
      </MDBModal>
    );
  }
  return null;
};

const mapDispatchToProps = {
  toggleModal,
  sendInviteFromWorkOrderToContractor,
};

const mapStateToProps = (state) => ({
  currentApplicant: state.workOrder.currentApplicant,
  modalState: state.modalState.contractorInviteSelectWorkOrder,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContractorInviteSelectWorkOrder);
