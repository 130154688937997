import axios from 'axios';
import Notification from '../../../components/notification';

const getUsersForUserManagement = async () => {
  try {
    const response = await axios.get('/api/users/get_clients');
    return response.data?.clients || [];
  } catch (error) {
    Notification('error', {
      message: error.response?.data?.message || error.message,
    });
    return [];
  }
};

export default getUsersForUserManagement;
