import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import BasePaySection from '../../../layout/reduxForm/section/workOrder/payInfoSection/BasePaySection';

const createWorkOrderReduxFormSelector = formValueSelector('createWorkOrderTemplateReduxForm');

const mapStateToProps = state => ({
  amount: createWorkOrderReduxFormSelector(state, 'amount'),
  quantity: createWorkOrderReduxFormSelector(state, 'quantity'),
  basicType: createWorkOrderReduxFormSelector(state, 'basicType'),
  requirements: 'disabled',
  enableEmptyValue: true,
});

export default connect(mapStateToProps)(BasePaySection);
