import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import ContractorInfoAddEdit from './ContractorInfoAddEdit';
import ContactInfoRow from './ContactInfoRow';

const ContactInfoForWorkOrder = ({
  contactList,
  handlerChanges,
  inputClassName,
}) => {
  const addContact = (newContactInfo) => {
    handlerChanges([...contactList, newContactInfo]);
  };

  const editContact = (newContactInfo, index) => {
    handlerChanges(contactList.map((item, i) => (index === i ? newContactInfo : item)));
  };

  const deleteContact = (index) => {
    handlerChanges(contactList.filter((item, i) => index !== i));
  };

  return (
    <>
      <MDBCol md="12" className="h-paddings-half">
        <ContractorInfoAddEdit returnContractor={addContact} inputClassName={inputClassName} />
      </MDBCol>
      <MDBCol md="12">
        <MDBContainer className="ccwo_table">
          <MDBRow className="ccwo_table" style={{ textAlign: 'center' }}>
            <MDBCol><label>Name</label></MDBCol>
            <MDBCol><label>Role</label></MDBCol>
            <MDBCol><label>Email</label></MDBCol>
            <MDBCol><label>Office Phone</label></MDBCol>
            <MDBCol><label>Mobile</label></MDBCol>
            <MDBCol><label>Action</label></MDBCol>
          </MDBRow>
          {contactList
          && contactList.map((contact, i) => (
            <ContactInfoRow
              /* eslint-disable-next-line react/no-array-index-key */
              key={`${contact.email}-${i}`}
              contact={contact}
              deleteContact={() => deleteContact(i)}
              editContact={(newContactInfo) => editContact(newContactInfo, i)}
              inputClassName={inputClassName}
            />
          ))}
        </MDBContainer>
      </MDBCol>
    </>
  );
};

export default ContactInfoForWorkOrder;
