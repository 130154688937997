import React from "react";
import {MDBIcon} from "mdbreact";
import {localCurrencySettings} from "../../../../utils/constans";

const ExpenseCard = (
    {
        expense,
        editExpense,
        deleteExpense,
        isControlButtonAvailable
    }
) => (
    <>
        <td>
            <span className="font11" >
                {new Date(expense.date).toLocaleDateString('en-US',{timeZone: 'UTC'})}
            </span>
        </td>
        <td>
            <span className="font11" >
                {expense.description}
            </span>
            <br/>
            {
                expense.rejection_reason
                    && <span className="font11" >
                        Was rejected due to - {expense.rejection_reason}
                    </span>
            }
        </td>
        <td style={{ textAlign: 'end'}} >
            <span className="font11" >
                {(+expense.amount).toLocaleString('en-US', localCurrencySettings)}
            </span>
        </td>
        {
            isControlButtonAvailable
                && <>
                    <td style={{ padding: 0, position: 'relative' }} >
                        <div className='flex-center' style={{ position: 'absolute', width: '100%' }} >
                            <MDBIcon
                                icon="pen"
                                size="lg"
                                style={{color: "#41566a", cursor: "pointer"}}
                                onClick={() => editExpense(expense)}
                            />
                        </div>
                    </td>
                    <td style={{ padding: 0, position: 'relative' }} >
                        <div className='flex-center' style={{ position: 'absolute', width: '100%' }} >
                            <MDBIcon
                                icon="trash"
                                size="lg"
                                style={{color: "#41566a", cursor: "pointer"}}
                                onClick={() => deleteExpense(expense._id, expense.work_order_id)}
                            />
                        </div>
                    </td>
                </>
        }
    </>
)

export default ExpenseCard;
