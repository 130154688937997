import React, {useState} from "react";
import {MDBBtn, MDBCol, MDBRow} from "mdbreact";
import Notification from '../../../notification';

const IssueReportElement = ({report, userType, resolveIssueReport, sendIssueAgain}) => {
    const [description, setDescription] = useState('');

    const handlerChanges = event => {
        setDescription(event.target.value);
    }

    const resolveIssue = () => {
        if(!description && userType === 'contractor') {
            Notification('warning', {
                message: 'Add description!',
            });
            return;
        }
        const resolveData = {
            report_id: report._id,
            work_order_id: report.work_order_id,
            contractor_id: report.contractor_id,
            description
        }
        resolveIssueReport(userType, resolveData);
    }

    const sendReportIssueAgain = () => {
        const issueInfo = {
            work_order_id: report.work_order_id,
            contractor_id: report.contractor_id,
            report_id: report._id,
            description
        }
        sendIssueAgain(issueInfo);
    }

    return (
        <MDBRow style={{ marginBottom: "15px", display: "block" }} >
            <div>
                <MDBCol>
                    <label className="font11" style={{marginRight: '1rem'}} >
                        Issue Reported :
                    </label>
                    <label> {report.client_issue_description}</label>

                    {
                        report.client_status
                            ? <span style={{ color: 'green', marginLeft: '1rem'}} >Issue has been resolved</span>
                            : report.contractor_status
                                && <span style={{ color: 'green', marginLeft: '1rem'}} >{userType === 'contractor' ? 'You' : 'Contractor'} has resolved this issue</span>
                    }
                    {
                        report.contractor_resolved_issue_description
                            && <div>
                                <label className="font11" style={{marginRight: '1rem'}} >{userType === 'contractor' ? 'You' : 'Contractor'} description: </label>
                                <label> {report.contractor_resolved_issue_description}</label>
                            </div>
                    }
                    {
                        (!report.client_status)
                            &&<>
                                <p>Update your description</p>
                                <textarea
                                    className="form-control raitextarea"
                                    style={{fontSize: "11px"}}
                                    rows="5"
                                    name='description'
                                    placeholder="Description"
                                    onChange={handlerChanges}
                                />
                            </>
                    }
                </MDBCol>
                {
                    (!report.client_status)
                        && <MDBCol style={{ textAlign: "end" }}>
                            <MDBBtn
                                color="deep-orange"
                                className="reportan_issuebut"
                                onClick={resolveIssue}
                            >
                                {
                                    report[`${userType}_status`]
                                        ? 'Update description'
                                        : 'Mark issue as resolved'
                                }
                            </MDBBtn>
                            {
                                ( userType === 'client' && !report.client_status)
                                    && <MDBBtn
                                        color="deep-orange"
                                        className="reportan_issuebut"
                                        onClick={sendReportIssueAgain}
                                    >
                                        {
                                            report.contractor_status
                                                ? 'Mark Report issue again'
                                                : 'Update description'
                                        }
                                    </MDBBtn>
                            }
                        </MDBCol>
                }
            </div>
        </MDBRow>
    )
}

export default IssueReportElement;
