import React from 'react';
import { localCurrencySettings } from '../../../../utils/constans';

const TravelExpenses = ({ amount, description }) => (
  amount && description ? (
    <div className="font12" style={{ paddingTop: '2px' }}>
      <div style={{ listStyle: 'none', padding: '0rem 1rem' }}>
        <h1 style={{ fontWeight: 'bold', fontSize: '1.0rem' }}> Travel Expenses:</h1>
        <div style={{ marginRight: '5px', color: '#28a745', fontWeight: 'bold' }}>
          {`Amount: ${(+amount).toLocaleString('en-US', localCurrencySettings)}`}
        </div>

        <div style={{ marginRight: '5px', maxWidth: '200px', whiteSpace: 'normal' }}>
          <span style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
            Travel Expense Description:
          </span>
          {description || ''}
        </div>
      </div>
    </div>
  ) : <></>
);

export default TravelExpenses;
