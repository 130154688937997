import React from 'react';
import { MDBBtn } from 'mdbreact';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

const ControlButtons = ({
  history,
  submit,
}) => {
  const cancel = (event) => {
    event.preventDefault();
    history.goBack();
  };

  const handlerSave = async event => {
    event.preventDefault();
    submit('createWorkOrderTemplateReduxForm');
  };

  return (
    <div className="flex-box justify-content-end">
      <MDBBtn
        color="info"
        type="button"
        name="save"
        className="sandp"
        onClick={handlerSave}
      >
        Save
      </MDBBtn>
      <MDBBtn color="deep-orange" type="button" className="sandp" onClick={cancel}>Cancel</MDBBtn>
    </div>
  );
};

const mapDispatchToProps = {
  submit,
};

export default connect(null, mapDispatchToProps)(ControlButtons);
