import React, {useEffect, useMemo, useState} from 'react';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import AddCheckInCheckOut from './AddCheckInCheckOut';
import CheckInCheckOutList from './CheckInCheckOutList';
import formatAMPM from '../../../../utils/formatAMPM';
import workOrderStatusControl from '../../../../utils/workOrderStatusControl';
import getTotalHour from '../../../../utils/getTotalHourByDates';
import { getDateWithOffset } from '../../../../utils/getDateWithOffset';

const CheckInCheckOutFields = ({
  userType,
  checkInOutDetails,
  currentWorkOrder,
  checkInCheckOutToWorkOrder,
  deleteCheckInCheckOutData,
  editCheckInCheckOutData,
}) => {
  const [checkInCheckOutToEdit, changeCheckInCheckOutToEdit] = useState({});
  const [showEditCheckInfo, changeShowEditCheckInfo] = useState(false);
  const [showCheckIn, changeShowCheckIn] = useState(true);
  const [showCheckField, changeShowCheckField] = useState(false);

  useEffect(() => {
    changeShowCheckIn((
      !checkInOutDetails.length
        ? true
        : checkInOutDetails[0].check_out_date && checkInOutDetails[0].check_out_date !== 'false'
    ));
  }, [checkInOutDetails]);

  const totalHourFromCheckInCheckOuts = parseFloat(
    currentWorkOrder.checkInOutDetails
      .map(checkInCheckOutInfo => getTotalHour(
        new Date(checkInCheckOutInfo.check_in_date),
        new Date(checkInCheckOutInfo.check_out_date),
      ) || 0)
      .reduce((acc, cur) => acc + +cur, 0),
  ).toFixed(2);

  const hasControlElementAvailable = () => {
    if(userType === 'contractor') {
      return workOrderControlByStatus.inWork && !currentWorkOrder.onHold
    }

    if(userType === 'client') {
      return workOrderControlByStatus.assigned
    }

    return false
  }

  const deleteCheckData = (event, checkInCheckOutId) => {
    event.preventDefault();
    deleteCheckInCheckOutData(currentWorkOrder._id, checkInCheckOutId);
  };

  const editCheckData = (event, data) => {
    if (data) {
      changeShowEditCheckInfo(true);
      const checkInInfo = {
        check_in_out_id: data._id,
        work_description: (data.work_description === 'false') ? '' : data.work_description,
      };
      const checkInStartDate = {};
      const checkInEndDate = {};
      if (data.check_in_date) {
        const checkInDate = getDateWithOffset(data.check_in_date);
        const checkInTimeString = formatAMPM(checkInDate);
        checkInStartDate.edit_check_in_date = checkInDate;
        checkInStartDate.edit_check_in_time = checkInTimeString;
        checkInStartDate.check_in_time_string = checkInTimeString;
      }
      if (data.check_out_date) {
        const checkOutDate = getDateWithOffset(data.check_out_date);
        const checkOutTimeString = formatAMPM(checkOutDate);
        checkInEndDate.edit_check_out_date = checkOutDate;
        checkInEndDate.edit_check_out_time = checkOutTimeString;
        checkInEndDate.check_out_time_string = checkOutTimeString;
      }
      changeCheckInCheckOutToEdit({ ...checkInInfo, ...checkInStartDate, ...checkInEndDate });
    }
  };

  const cancelEdit = () => {
    changeShowEditCheckInfo(false);
    changeCheckInCheckOutToEdit({});
  };

  const workOrderControlByStatus = currentWorkOrder
    ? workOrderStatusControl(currentWorkOrder.status)
    : {};

  const isControlElementAvailable = useMemo(hasControlElementAvailable, [
    userType,
    workOrderControlByStatus,
    currentWorkOrder
  ])

  return (
    !workOrderControlByStatus.beforeAssigned
      ? (
        <div style={{ textAlign: 'left' }}>
          <MDBRow>
            <hr className="my-4 hrline2" />
          </MDBRow>
          <h6 className="font14 flex-box flex-align-center" style={{ justifyContent: 'space-between' }}>
            <b>Check-in / Check-out Details</b>
            <MDBCol style={{ fontSize: '12px', textAlign: 'end' }}>
              <span style={{ fontWeight: 'bold' }}>Total Hours</span>
              <span className="dashdiv">-</span>
              <span>{totalHourFromCheckInCheckOuts}</span>
            </MDBCol>
            {
              workOrderControlByStatus.assigned && (
                <MDBBtn
                  color="info"
                  className="check_inbutton no-margins"
                  disabled={currentWorkOrder.onHold}
                  onClick={() => changeShowCheckField(!showCheckField)}
                >
                  {`Check ${showCheckIn ? 'In' : 'Out'}`}
                </MDBBtn>
              )
            }
          </h6>
          <div>
            {
              workOrderControlByStatus.assigned &&(
                <AddCheckInCheckOut
                  showCheckField={showCheckField}
                  showCheckIn={showCheckIn}
                  currentWorkOrder={currentWorkOrder}
                  checkInCheckOutToWorkOrder={checkInCheckOutToWorkOrder}
                />
              )
            }
            <CheckInCheckOutList
              currentWorkOrder={currentWorkOrder}
              editCheckInCheckOutData={editCheckInCheckOutData}
              showEditCheckInfo={showEditCheckInfo}
              cancelEdit={cancelEdit}
              checkInCheckOutToEdit={checkInCheckOutToEdit}
              checkInOutDetails={checkInOutDetails}
              isControlElementAvailable={isControlElementAvailable}
              deleteCheckData={deleteCheckData}
              editCheckData={editCheckData}
              userType={userType}
            />
          </div>
        </div>
      )
      : <></>
  );
};

export default CheckInCheckOutFields;
