import React from 'react';
import { MDBCol, MDBIcon } from 'mdbreact';
import { connect } from 'react-redux';
import { toggleModal } from '../../storage/actions/modalsActions';
import deleteCustomFieldTemplate from '../../utils/api/delete/deleteCustomFieldTemplate';
import { reloadData } from '../../storage/actions/applicationStateActions';

const ManageCustomFieldsActions = ({ toggleModal, item, reloadData }) => {
  const handleEdit = () => {
    toggleModal(true, 'addOrEditCustomFields', false, item);
  };

  const handleDelete = () => {
    toggleModal(true, 'confirmModal', true, {
      onAccept: () => {
        deleteCustomFieldTemplate(item._id).then(() => reloadData());
      },
      header: `Delete ${item.name} custom field from every work order?`,
      buttonLabels: {
        reject: 'Cancel',
        confirm: 'Delete',
      },
    });
  };

  return (
    <MDBCol>
      <MDBIcon
        far
        icon="trash-alt"
        className="float-right ml-2"
        size="lg"
        onClick={handleDelete}
      />
      <MDBIcon
        far
        icon="edit"
        className="float-right ml-2"
        size="lg"
        onClick={handleEdit}
      />
    </MDBCol>
  );
};
const mapDispatchToProps = {
  toggleModal,
  reloadData,
};

export default connect(null, mapDispatchToProps)(ManageCustomFieldsActions);
