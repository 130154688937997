import React from 'react';
import { MDBContainer } from 'mdbreact';
// eslint-disable-next-line import/no-cycle
import Modal from '../../components/modal';
import Content from '../../components/templates/Content';

const Templates = ({ mainContainer }) => (
  <MDBContainer className="clientcreateworkorderdiv no-margins" fluid>
    <h4>Templates</h4>
    <Content />
    <Modal mainContainer={mainContainer.current} />
  </MDBContainer>
);

export default Templates;
