import React from 'react';
import {
  MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBRow,
} from 'mdbreact';

const FundingSourceCard = ({
  fundingSource, handlerVerify, handlerDelete, makePrimary, auth
}) => (
  <MDBCard className="card-payment-method" style={{ width: '22rem', margin: '1rem' }}>
    <MDBCardBody>
      <MDBCardTitle>
        {fundingSource.name}
      </MDBCardTitle>
      <MDBCardText style={{ color: 'white', fontStyle: 'italic' }}>
        Verification status:
        {' '}
        {fundingSource.status}
      </MDBCardText>
      {
        fundingSource.isPrimary
          ? (
            <MDBRow>
              <MDBCol md="6">
                <label
                  className="padding-02-05"
                  style={{
                    border: '1px solid yellow',
                  }}
                >
                  PRIMARY
                </label>
              </MDBCol>
            </MDBRow>
          )
          : (
            <MDBRow>
              <MDBCol md="6">
                {
                  fundingSource.status === 'verified'
                    ? (
                      <MDBBtn
                        style={{
                          padding: '0.1rem 0.5rem',
                          whiteSpace: 'nowrap',
                          fontSize: '0.8rem',
                        }}
                        outline
                        color="elegant"
                        onClick={() => makePrimary(fundingSource._id)}
                      >
                        <span className="color-white">Make Primary</span>
                      </MDBBtn>
                    )
                    : <></>
                }
              </MDBCol>
              <MDBCol md="6" className="flex-box" style={{ paddingLeft: 0 }}>
                <MDBBtn
                  style={{
                    padding: '0.1rem 0.5rem',
                    whiteSpace: 'nowrap',
                    fontSize: '0.8rem',
                  }}
                  outline
                  color="elegant"
                  onClick={() => handlerDelete(fundingSource._id)}
                >
                  <span className="color-white">Remove</span>
                </MDBBtn>
                {
                    auth.userType === 'superAdmin' && fundingSource.status !== 'verified'
                    ? (
                      <MDBBtn
                        style={{
                          padding: '0.1rem 0.5rem',
                          whiteSpace: 'nowrap',
                          fontSize: '0.8rem',
                        }}
                        outline
                        color="elegant"
                        onClick={() => handlerVerify(fundingSource)}
                      >
                        <span className="color-white">Verify</span>
                      </MDBBtn>
                    )
                    : <></>
                }
              </MDBCol>
            </MDBRow>
          )
      }
    </MDBCardBody>
  </MDBCard>
);

export default FundingSourceCard;
