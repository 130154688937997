import React from 'react';
import { MDBIcon, MDBBtn } from 'mdbreact';
import axios from 'axios';
import fileDownload from 'js-file-download';
import Notification from '../notification';

const TaxInfoButton = () => (
  <div style={Container}>
    <MDBBtn className="width-inherit" onClick={exportTaxInfoContractors}>
      <MDBIcon className="vwimg exportimg" icon="angle-double-left" />
      Export tax info
    </MDBBtn>
  </div>
);
export default TaxInfoButton;

const Container = {
  width: '190px',
};

const exportTaxInfoContractors = async () => {
  await axios.get('/api/admin/contractors/taxinfo/export', {
    responseType: 'blob',
  }).then((res) => {
    fileDownload(res.data, 'taxinfo.xlsx');
  }).catch((error) => {
    Notification('error', {
      message: error.response?.data?.message || error.message,
    });
  });
};
