import React from 'react';
import {
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTable,
  MDBBtn,
  MDBTableBody,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import { toggleModal } from '../../storage/actions/modalsActions';
import {
  setCurrentApplicants,
  assignContractor,
  unRouteWorkOrder,
  sendRecruitMail,
  acceptInvite
} from '../../storage/actions/workOrdersActions';
import Loader from '../dashboard/common/Loader';
import ApplicantCard from '../layout/contractorCards/ApplicantCard';
import InvitedContractorCard from '../layout/contractorCards/InvitedContractorCard';

const ViewAllApplicantsForWorkOrder = ({
  userType,
  modalState,
  toggleModal,
  applicants,
  routedApplicants,
  setCurrentApplicants,
  applicantsLoaded,
  routedApplicantsLoaded,
  currentWorkOrder,
  assignContractor,
  unRouteWorkOrder,
  sendRecruitMail,
  acceptInvite,
}) => (
  (applicantsLoaded && routedApplicantsLoaded)
    ? (
      <MDBModal
        className="applicantappiliedwo"
        isOpen={modalState.isOpen}
        toggle={() => toggleModal(!modalState.isOpen, 'viewAllApplicantsForWorkOrderModal')}
        fullHeight
        scrollable
        position="right"
      >
        <MDBModalBody style={{ overflow: 'hidden auto', color: 'white' }}>
          <div>
            <MDBRow style={{ marginBottom: '15px' }}>
              <MDBCol md="6">
                <h6
                  style={{ fontWeight: 'bold', marginBottom: '15px' }}
                >
                  {`Number of Applicants: ${applicants.contractor ? applicants.contractor.length : '0'}`} 
                  
                  <MDBBtn
                    title="RecruitBoost is a tool that automatically sends a work available notification to all potential candidates within that area to help draw applicants to your job."
                    className='btn btn-success detailbut recruit-btn'
                    disabled={currentWorkOrder.isRemote}
                    onClick={() => sendRecruitMail(currentWorkOrder._id)}>RecruitBoost <span className='recruit-tm'>TM</span></MDBBtn>
                </h6>
                <span style={{ color: '#e87b68' }}>{applicants.message}</span>
              </MDBCol>
              <MDBCol md="6" style={{ textAlign: 'end' }}>
                <MDBIcon
                  icon="times-circle"
                  size="lg"
                  style={{ color: '#41566a', cursor: 'pointer' }}
                  onClick={() => toggleModal(!modalState.isOpen, 'viewAllApplicantsForWorkOrderModal')}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBTable className="applicantappiliedwo_table">
                  {
                    (applicants.contractor)
                      ? (
                        <MDBTableBody>
                          {
                            applicants.contractor.map(contractor => (
                              contractor.contractor_details
                                ? (
                                  <ApplicantCard
                                    key={contractor.contractor_details.unique_id}
                                    contractorInfo={contractor}
                                    setCurrentApplicants={setCurrentApplicants}
                                    toggleModal={toggleModal}
                                    currentWorkOrder={currentWorkOrder}
                                    assignContractor={assignContractor}
                                    userType={userType}
                                  />
                                )
                                : null
                            ))
                          }
                        </MDBTableBody>
                      )
                      : null
                  }
                </MDBTable>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ marginBottom: '15px' }}>
              <MDBCol md="6">
                <h6
                  style={{ fontWeight: 'bold', marginBottom: '15px' }}
                >
                  {`Applicants Invited: ${routedApplicants.data ? routedApplicants.data.length : '0'}`}
                </h6>
                <span style={{ color: '#e87b68' }}>{routedApplicants.message}</span>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBTable className="applicantappiliedwo_table">
                  {
                    (routedApplicants.data)
                      ? (
                        <MDBTableBody>
                          {
                            routedApplicants.data.map((contractor) => (
                              contractor.contractor_details
                                ? (
                                  <InvitedContractorCard
                                    userType={userType}
                                    key={contractor.contractor_id}
                                    contractorInfo={contractor}
                                    toggleModal={toggleModal}
                                    setCurrentApplicants={setCurrentApplicants}
                                    currentWorkOrder={currentWorkOrder}
                                    unRouteWorkOrder={unRouteWorkOrder}
                                    acceptInvite={acceptInvite}
                                  />
                                )
                                : null
                            ))
                          }
                        </MDBTableBody>
                      )
                      : null
                  }
                </MDBTable>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBModalBody>
      </MDBModal>
    )
    : <Loader />
);

const mapDispatchToProps = {
  toggleModal,
  setCurrentApplicants,
  assignContractor,
  unRouteWorkOrder,
  acceptInvite,
  sendRecruitMail,
};

const mapStateToProps = state => ({
  currentWorkOrder: state.workOrder.currentWorkOrder,
  applicantsLoaded: state.workOrder.applicantsLoaded,
  routedApplicantsLoaded: state.workOrder.routedApplicantsLoaded,
  applicants: state.workOrder.applicants,
  routedApplicants: state.workOrder.routedApplicants,
  modalState: state.modalState.viewAllApplicantsForWorkOrderModal,
  userType: state.auth?.user?.userType,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllApplicantsForWorkOrder);
