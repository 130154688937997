import React from 'react';
import { components } from 'react-select';

const CustomValueContainer = ({ children, ...props }) => {
  const selectedElement = Array.isArray(children[0]) ? children[0] : [];
  const newChildren = Array.isArray(children[0])
    ? children.slice(1)
    : children;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.ValueContainer {...props}>
      {
        selectedElement.length
          ? (
            <>
              {selectedElement.filter((item, index) => index < 2)}
              {
                selectedElement.length > 2
                  ? (
                    <div className="css-1rhbuit-multiValue css-12jo7m5">
                      {`+${selectedElement.length - 2}`}
                    </div>
                  )
                  : <></>
              }
            </>
          )
          : <></>
      }
      {newChildren}
    </components.ValueContainer>
  );
};

export default CustomValueContainer;
