import React from 'react';

const ControlElement = ({
  hide,
  children,
}) => (
  hide
    ? <></>
    : children
);

export default ControlElement;
