import React from 'react';
import { MDBBtn } from 'mdbreact';
import { localCurrencySettings } from '../../../../utils/constans';
import getRatingColor from '../../../../utils/getRatingColor';

const ApplyRow = ({
  applyInfo, invite, baseType, variableType, blocked,
  showContractorProfile,
}) => (
  <tr
    className="tr-td-padding tr-td-font-bold tr-td-font-size-08"
    style={{ color: applyInfo.status === 'assigned' ? 'red' : 'white' }}
  >
    <td>
      {applyInfo.contractorInfo.unique_id}
    </td>
    <td>
      <button
        type="button"
        className="disabled-standard-button-style"
        style={{
          fontWeight: 'bold',
          paddingRight: '20px',
        }}
        onClick={() => showContractorProfile(applyInfo.contractorInfo.userId)}
      >
        {applyInfo.contractorInfo.name || ''}
      </button>
    </td>
    <td>
      <a href={`mailto:${applyInfo.contractorInfo.email}`}>{applyInfo.contractorInfo.email || ''}</a>
    </td>
    <td>
      {applyInfo.contractorInfo.phone || ''}
    </td>
    <td style={{ color: getRatingColor(applyInfo.contractorInfo.currentRating) }}>
      {`${parseInt(applyInfo.contractorInfo.currentRating || 100, 10)}%`}
    </td>
    <td>
      {`Base: ${(+applyInfo.bidAmountBase).toLocaleString('en-US', localCurrencySettings)} / ${baseType}`}
    </td>
    <td>
      {`Var: ${(+applyInfo.bidAmountVariable).toLocaleString('en-US', localCurrencySettings)} / ${variableType}`}
    </td>
    <td style={{ color: (blocked || applyInfo.status === 'assigned') ? 'red' : 'white' }}>
      {
        // eslint-disable-next-line no-nested-ternary
        applyInfo.status === 'assigned'
          ? 'Un-assigned'
          : blocked
            ? 'Blocked'
            : (
              <MDBBtn
                color={false}
                onClick={invite}
                style={{
                  padding: '0 0.5rem',
                  color: '#182f40',
                  backgroundColor: '#6cff2e',
                }}
              >
                Invite
              </MDBBtn>
            )
      }
    </td>
  </tr>
);

export default ApplyRow;
