import React, { useEffect, useState } from 'react';
import { MDBRow } from 'mdbreact';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IconElement from './IconElement';
import { toggleModal } from '../../../storage/actions/modalsActions';

const BulkElements = ({
  toggleModal,
  activeTab,
  selectedWorkOrders,
}) => {
  const [mode, setMode] = useState('useIds');

  useEffect(() => {
    setMode(selectedWorkOrders?.length ? 'useIds' : 'useFilters');
  }, [selectedWorkOrders]);

  return (
    <MDBRow className="flex-align-center" style={{ width: 'max-content' }}>
      {
        activeTab === 'approved'
          ? (
            <>
              <IconElement
                mode={mode}
                title={`Mark selected wo's as Paid${mode === 'useFilters' ? ', All In Tab' : ''}`}
                icon="hand-holding-usd"
                onClick={() => toggleModal(true, 'bulkWorkOrderPay', false, { mode })}
              />
            </>
          )
          : <></>
      }
    </MDBRow>
  );
};

const mapDispatchToProps = {
  toggleModal,
};

const mapStateToProps = state => ({
  activeTab: state.applicationState.activeTab,
  selectedWorkOrders: state.applicationState.selectedWorkOrders,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BulkElements));
