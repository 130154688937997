import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import FundsInfoSection from '../../../layout/reduxForm/section/workOrder/payInfoSection/FundsInfoSection';

const createWorkOrderReduxFormSelector = formValueSelector('createWorkOrderTemplateReduxForm');

const mapStateToProps = state => ({
  amount: createWorkOrderReduxFormSelector(state, 'amount'),
  bidAmountBase: createWorkOrderReduxFormSelector(state, 'bidAmountBase'),
  quantity: createWorkOrderReduxFormSelector(state, 'quantity'),
  basicType: createWorkOrderReduxFormSelector(state, 'basicType'),
  variableAmount: createWorkOrderReduxFormSelector(state, 'variableAmount'),
  variablePayAfter: createWorkOrderReduxFormSelector(state, 'variablePayAfter'),
  maxQuantity: createWorkOrderReduxFormSelector(state, 'maxQuantity'),
  profile: state.profile,
  reduxFormName: 'createWorkOrderTemplateReduxForm',
  requirements: 'disabled',
});

export default connect(mapStateToProps)(FundsInfoSection);
