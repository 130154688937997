import React, { useContext, useEffect, useState } from 'react';
import {
  MDBCol, MDBContainer, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import axios from 'axios';
import connect from 'react-redux/es/connect/connect';
import { toggleModal } from '../../../storage/actions/modalsActions';
import Loader from '../../dashboard/common/Loader';
import SelectMessageRecipients from './SelectMessageRecipients';
import NewMessage from './NewMessage';
// eslint-disable-next-line import/no-cycle
import MessageList from './MessageList';
// eslint-disable-next-line import/no-cycle
import { SocketContext } from '../../../pages';

const update = (data, handler) => {
  handler((state) => Array.from(new Set([...state, data])));
};

const WorkOrderMessageSystemModal = ({
  modalState,
  toggleModal,
  auth,
  applicantsLoaded,
  routedApplicantsLoaded,
  applicants,
  routedApplicants,
}) => {
  const [messages, fetchMessages] = useState([]);
  const [companyUsers, fetchCompanyUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isForContractor, setIsNeedSendToContractor] = useState(false);
  const [needScrollToBottom, scrollToBottom] = useState(false);
  const [selectedAllApplicants, setSelectedAllApplicants] = useState(false);

  const { chatSocket: socket } = useContext(SocketContext);

  const fetchConversation = (socket) => {
    fetchMessages([]);
    socket && socket.emit('receiveHistory', { workOrderId: modalState.type?.workOrder?._id });
    socket && socket.on('history', (data) => {
      data.messages && fetchMessages(data.messages);
    });
  };

  useEffect(() => {
    modalState.type?.workOrder?._id && socket && fetchConversation(socket);
    modalState.type?.workOrder?._id && axios.get('/api/users/get-clients').then(res => {
      fetchCompanyUsers(
        res.data.clients
          .filter(el => el.userId)
          .map(el => ({ value: el.userId, label: el.name })),
      );
    });
    socket && socket.emit('openChat', { workOrderId: modalState.type?.workOrder?._id });
    socket && socket.on('reply', () => fetchConversation(socket));
    socket && socket.on(modalState.type?.workOrder?._id, message => {
      update(message, fetchMessages);
      scrollToBottom(true);
    });
  }, [modalState.type?.workOrder, modalState]);

  const scrollToBottomHandler = (ref) => {
    scrollToBottom(false);
    // eslint-disable-next-line no-param-reassign
    ref.current.scrollTop = ref.current.scrollHeight;
  };

  const sendMessage = (message, parentId) => {
    socket.emit('chat', {
      parentId,
      message,
      selectedAllApplicants,
      workOrderId: modalState.type?.workOrder?._id,
      users: selectedUsers,
      isForContractor,
    });
    scrollToBottom(true);
  };

  const closeModal = () => {
    fetchMessages([]);
    toggleModal(false, 'workOrderMessageSystemModal', true);
  };

  const getRecipients = (users, isNeedSendToContractor) => {
    setSelectedUsers(users);
    setIsNeedSendToContractor(isNeedSendToContractor);
  };

  return (
    <MDBModal
      inline={false}
      noClickableBodyWithoutBackdrop
      overflowScroll
      isOpen={modalState.isOpen}
      toggle={closeModal}
      fullHeight
      position="right"
      className="work-order-message-system-modal"
      contentClassName="modal-content-background"
    >
      {
        !applicantsLoaded || !routedApplicantsLoaded
          ? <Loader />
          : (
            <MDBContainer style={{ color: 'white' }}>
              <MDBRow
                className="h-paddings"
                style={{ justifyContent: 'flex-end', padding: '10px 10px 5px 0' }}
              >
                <MDBIcon
                  far
                  icon="times-circle"
                  onClick={closeModal}
                  size="lg"
                  className="blue-grey-text"
                />
              </MDBRow>
              {
                auth.user.userType === 'contractor'
                  ? (
                    <MDBRow>
                      <MDBCol>
                        {`Client: ${modalState.type?.workOrder?.clientInfo?.name || modalState.type?.workOrder?.companyNCR?.name}`}
                      </MDBCol>
                    </MDBRow>
                  )
                  : null
              }
              <MDBRow>
                <MDBCol
                  className="flex-box"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h6 style={{ margin: 0, lineHeight: 1.5 }}>
                    {`WO ID: ${modalState.type?.workOrder?.customTagId}`}
                  </h6>
                  <label style={{ margin: 0 }}>
                    {modalState.type?.workOrder?.title}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBModalBody>
                {
                  auth.user.userType !== 'contractor'
                    ? (
                      <SelectMessageRecipients
                        companyMembers={companyUsers}
                        selectedAllApplicants={selectedAllApplicants}
                        setSelectedAllApplicants={setSelectedAllApplicants}
                        workOrder={modalState.type?.workOrder}
                        getRecipients={getRecipients}
                        applicants={applicants}
                        routedApplicants={routedApplicants}
                      />
                    )
                    : null
                }
                <NewMessage sendMessage={sendMessage} />
                <MessageList
                  userId={auth.user.id}
                  scrollToBottom={scrollToBottomHandler}
                  needScrollToBottom={needScrollToBottom}
                  sendMessage={sendMessage}
                  fetchConversation={fetchConversation}
                  messages={messages}
                />
              </MDBModalBody>
            </MDBContainer>
          )
      }
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
};

const mapStateToProps = state => ({
  auth: state.auth,
  modalState: state.modalState.workOrderMessageSystemModal,
  applicantsLoaded: state.workOrder.applicantsLoaded,
  activeTab: state.applicationState.activeTab,
  routedApplicantsLoaded: state.workOrder.routedApplicantsLoaded,
  applicants: state.workOrder.applicants,
  routedApplicants: state.workOrder.routedApplicants,
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderMessageSystemModal);
