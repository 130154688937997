/* eslint-disable import/no-cycle */
import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import socketIOClient from 'socket.io-client';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { init, setCurrentUser } from '../storage/actions/authActions';
import UnAuthRoute from '../components/layout/CustomRouts/UnAuthRoute';
import AuthRoute from '../components/layout/CustomRouts/AuthRoute';
import ControlPanel from '../components/layout/ControlPanel';
import CertificatesContractors from './admin/CertificatesContractors';
import ClientsManageFunds from './admin/ClientsManageFunds';
import ManageCompanies from './admin/ManageCompanies';
import FundingSources from './admin/FundingSources';
import CreateAndEditWorkOrderTemplates from './clients/CreateAndEditWorkOrderTemplates';
import CreateAndEditWorkOrder from './clients/CreateAndEditWorkOrder';
import ManageCustomFields from './clients/ManageCustomFields';
import PMTools from './clients/PMTools';
import ClientPaymentMethods from './clients/PaymentMethods';
import FindContractor from './clients/FindContractor';
import ContractorGroups from './clients/ContractorGroups';
import UserManagement from './clients/UserManagement';
import ManageTeams from './clients/MangeTeams';
import Templates from './clients/Templates';
import Recruits from './admin/Recruits';
import CertificationTestVideo from './contractors/certification/CertificationTestVideo';
import CertificationTests from './contractors/certification/CertificationTests';
import Certifications from './contractors/certifications/Certifications';
// import JobsDashboard from './JobsDashboard';
import ManageFunds from './ManageFunds';
import Dashboard from './Dashboard';
import Home from './Home';
import { getProfile } from '../storage/actions/profile';

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
const PORT = process.env.REACT_APP_MODE
  ? process.env.REACT_APP_ENDPOINT_PORT_BUILD
  : process.env.REACT_APP_ENDPOINT_PORT_DEBUG;
const PORT2 = process.env.REACT_APP_MODE
  ? process.env.REACT_APP_ENDPOINT2_PORT_BUILD
  : process.env.REACT_APP_ENDPOINT2_PORT_DEBUG;

const ENDPOINT = `${window.location.protocol}//${window.location.hostname}:${PORT}`;
const ENDPOINT2 = `${window.location.protocol}//${window.location.hostname}:${PORT2}`;

export const SocketContext = React.createContext();

const Pages = ({ auth, getProfile }) => {
  const [chatSocket, setSocketForChat] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [notificationSocket, setSocketForNotification] = useState();

  useEffect(() => {
    if(auth.isAuthenticated){
    const token = window.localStorage.getItem('jwtToken');
    const chatConnection = token
      ? socketIOClient(ENDPOINT, {
        query: {
          token,
        },
        withCredentials: true,
        transports: ['websocket', 'polling']
      })
      : null;
    if(chatConnection) {
      chatConnection.on('connect', () => {
        console.log('messaging connected');
      });
    }
    const notificationConnection = token
      ? socketIOClient(ENDPOINT2, {
        query: {
          token,
        },
        withCredentials: true,
        transports: ['websocket', 'polling']
      })
      : null;
    if(notificationConnection) {
      notificationConnection.on('connect', () => {
        console.log('notifications connected');
      });
    }
    setSocketForNotification(notificationConnection);
    // console.log('chatConnetion XXXXXXXXXXXXX------->', chatConnection);
    setSocketForChat(chatConnection);
  }}, [auth]);

  useEffect(() => {
    if (auth.user?.id) {
      getProfile(auth.user?.id);
    }
  }, [auth.user?.id]);

  useEffect(() => {
    setIsVisible(true)
  }, [])

  const mainContainer = useRef();

  return (
    <Router>
      <div ref={mainContainer} id='qweewq'>
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
          <Switch >
            {isVisible && 
              <UnAuthRoute
                auth={auth}
                path={['/home', '/sign_in', '/register']}
                component={() => <Home mainContainer={mainContainer} />}
              />
            }
            <AuthRoute
              key="CommuneComponent"
              auth={auth}
              userType={false}
              path="/"
              render={() => <ControlPanel />}
            />
          </Switch>
        </IntercomProvider>
        <Switch>
          <>
            <SocketContext.Provider value={{ chatSocket, notificationSocket }}>
              <AuthRoute
                userType="Redirect"
                auth={auth}
                exact
                path="/"
                render={() => <Redirect to="/dashboard" />}
              />
              <AuthRoute
                key="Dashboard"
                auth={auth}
                exact
                path={['/dashboard', '/dashboard/:tab']}
                component={() => (
                  <Dashboard
                    mainContainer={mainContainer}
                    useIntercom={useIntercom}
                  />
                )}
              />
              <AuthRoute
                key="ManageFunds"
                auth={auth}
                path="/manage-funds"
                hasNoPermissionRoles={['Manager', 'Dispatcher']}
                component={() => <ManageFunds mainContainer={mainContainer} />}
              />
              {/* Client routes */}
              <AuthRoute
                key="ManageCustomFields"
                auth={auth}
                userType="client"
                exact
                path="/manage-custom-fields"
                component={() => (
                  <ManageCustomFields mainContainer={mainContainer} />
                )}
              />
              <AuthRoute
                key="PMTools"
                auth={auth}
                userType="client"
                exact
                path="/PMTools"
                component={() => (
                  <PMTools mainContainer={mainContainer} />
                )}
              />
              <AuthRoute
                key="ManageTeams"
                auth={auth}
                userType="client"
                exact
                path="/manage-teams"
                component={() => <ManageTeams mainContainer={mainContainer} />}
              />
              <AuthRoute
                key="FindContractor"
                auth={auth}
                userType="client"
                exact
                path="/find-contractor"
                component={() => (
                  <FindContractor mainContainer={mainContainer} />
                )}
              />
              <AuthRoute
                key="ContractorGroups"
                auth={auth}
                userType="client"
                exact
                path="/contractor-groups"
                component={() => (
                  <ContractorGroups mainContainer={mainContainer} />
                )}
              />
              <AuthRoute
                key="UserMang"
                auth={auth}
                userType="client"
                exact
                path="/user-management"
                hasNoPermissionRoles={['Manager', 'Dispatcher']}
                component={() => (
                  <UserManagement mainContainer={mainContainer} />
                )}
              />
              <AuthRoute
                key="CreateAndEditWorkOrder"
                auth={auth}
                userType="client"
                exact
                path={['/create-work-order', '/create-work-order/:id']}
                component={() => (
                  <CreateAndEditWorkOrder mainContainer={mainContainer} />
                )}
              />
              <AuthRoute
                key="Template"
                auth={auth}
                userType="client"
                exact
                path="/templates"
                component={() => <Templates mainContainer={mainContainer} />}
              />
              <AuthRoute
                key="CreateAndEditWorkOrderTemplate"
                auth={auth}
                userType="client"
                exact
                path={[
                  '/create-work-order-template',
                  '/create-work-order-template/:id',
                ]}
                component={() => (
                  <CreateAndEditWorkOrderTemplates
                    mainContainer={mainContainer}
                  />
                )}
              />
              <AuthRoute
                key="PaymentMethods"
                auth={auth}
                userType="client"
                path="/payment-methods"
                hasNoPermissionRoles={['Manager', 'Dispatcher']}
                component={() => (
                  <ClientPaymentMethods mainContainer={mainContainer} />
                )}
              />
              {/* Client routs end */}
              {/* Contractor routs */}
              <AuthRoute
                key="Certifications"
                auth={auth}
                exact
                path="/certifications"
                userType="contractor"
                component={() => (
                  <Certifications mainContainer={mainContainer} />
                )}
              />
              <AuthRoute
                key="WatchVideo"
                auth={auth}
                userType="contractor"
                path="/certifications/:id/video"
                component={() => (
                  <CertificationTestVideo mainContainer={mainContainer} />
                )}
              />
              <AuthRoute
                key="CertificationTests"
                auth={auth}
                userType="contractor"
                path="/certifications/:id/tests"
                component={() => (
                  <CertificationTests mainContainer={mainContainer} />
                )}
              />
              {/* Contractor routs end */}
              {/* Admin routs */}
              <AuthRoute
                key="CertificatesContractors"
                auth={auth}
                userType="superAdmin"
                exact
                path="/certificates-contractors"
                component={() => (
                  <CertificatesContractors mainContainer={mainContainer} />
                )}
              />
              <AuthRoute
                key="Recruits"
                auth={auth}
                userType="superAdmin"
                exact
                path="/recruits"
                component={() => <Recruits mainContainer={mainContainer} />}
              />
              <AuthRoute
                key="ManageCompanies"
                auth={auth}
                userType="superAdmin"
                exact
                path="/manage-companies"
                component={() => (
                  <ManageCompanies mainContainer={mainContainer} />
                )}
              />
              <AuthRoute
                key="ClientsManageFunds"
                auth={auth}
                userType="superAdmin"
                exact
                path="/clients-transactions-history"
                component={() => (
                  <ClientsManageFunds mainContainer={mainContainer} />
                )}
              />
              {/* Admin routs end */}
              <AuthRoute
                key="FundingSources"
                auth={auth}
                userTypes={['superAdmin']}
                exact
                path="/funding-sources"
                hasNoPermissionRoles={['Manager', 'Dispatcher']}
                component={() => (
                  <FundingSources mainContainer={mainContainer} />
                )}
              />
            </SocketContext.Provider>
            <AuthRoute
              key="default"
              auth={auth}
              exact
              path="*"
              render={() => <Redirect to="/dashboard" />}
            />
          </>
        </Switch>
      </div>
    </Router>
  );
};

const mapDispatchToProps = {
  setCurrentUser,
  init,
  getProfile,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
