import React from 'react';
import { MDBCol, MDBIcon } from 'mdbreact';

const ToolsElement = ({
  label, deleteTools, title,
  className = 'tools-element', deleteIconColor = '#1e2f41',
  labelClassName = '',
}) => (
  <MDBCol className={`flex-grow-0 text-in-one-line flex-box align-items-center ${className}`} title={title}>
    <label className={`no-margins ${labelClassName}`}>{label}</label>
    {
      deleteTools
        ? (
          <MDBIcon
            icon="times-circle"
            size="lg"
            onClick={deleteTools}
            style={{
              color: deleteIconColor,
              cursor: 'pointer',
              paddingLeft: '5px',
            }}
          />
        )
        : <></>
    }
  </MDBCol>
);

export default ToolsElement;
