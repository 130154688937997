import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

const rowStyles = { marginTop: 20 };
const colStyles = { padding: '25px 0px 0px 15px' };

const PreviouslyWorkedProjectsSection = ({
  previouslyWorkedProjects = [],
}) => (
  <MDBContainer>
    <MDBRow style={rowStyles}>
      <MDBCol md="12" style={colStyles}>
        <h6>Previously Worked Projects</h6>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol className="font-size-08">
        <ul>
          {
            previouslyWorkedProjects.map(projectName => (
              <li key={projectName}>
                {projectName}
              </li>
            ))
          }
        </ul>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
);

export default PreviouslyWorkedProjectsSection;
