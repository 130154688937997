import React from 'react';
import PropTypes from 'prop-types';
import { MDBRow, MDBBadge } from 'mdbreact';

const workTypeTitleStyles = { marginRight: 5 };
const tollButtonStyles = {
  borderRadius: 0, fontSize: 12, margin: 5, padding: 10, textTransform: 'uppercase',
};

const WorkType = ({
  contractorTools = [],
}) => (
  <div className="contprofiletools_div">
    <div>
      <p className="font12">
        <span className="font-weight-bold" style={workTypeTitleStyles}>Work Type </span>
      </p>
      <MDBRow style={{ marginLeft: 0 }}>
        {
      contractorTools.length ? contractorTools.map((item, index) => (
        <div key={index}>
          {item.toolss && item.toolss.split('/').map((item, i) => (
            <MDBBadge
              className="typework_but"
              color="success"
              style={tollButtonStyles}
              key={i}
            >
              {item}
            </MDBBadge>
          ))}
        </div>
      )) : null
    }
      </MDBRow>
    </div>
  </div>

);

WorkType.propTypes = {
  // eslint-disable-next-line react/require-default-props
  contractorTools: PropTypes.arrayOf(PropTypes.string),
};

export default WorkType;
