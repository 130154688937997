import React, {useState} from "react";
import ExpenseCard from "./ExpenseCard";
import {MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import EditExpense from "./EditExpense";
import workOrderStatusControl from "../../../../utils/workOrderStatusControl";
import {localCurrencySettings} from "../../../../utils/constans";

const ExpensesList = (
    {
        currentWorkOrder,
        editExpense,
        deleteExpense,
        userType
    }
) => {
    const [editingExpenses, setEditingExpenses] = useState({});

    const saveEditExpense = async expense => {
        const result = await editExpense(expense);
        if(result) cancelEdit();
    }

    const cancelEdit = () => {
        setEditingExpenses({});
    }

    const workOrderControlByStatus = currentWorkOrder ? workOrderStatusControl(currentWorkOrder.status) : {};
    const isControlButtonAvailable = userType === 'contractor' && workOrderControlByStatus.assigned;
    return currentWorkOrder.expenses && currentWorkOrder.expenses.length
        ? <>
            <MDBRow style={{borderBottom: '2px solid #dee2e6', margin: 0}} >
                <MDBCol style={{padding: 0}} >
                    <MDBTable>
                        <MDBTableHead textWhite >
                            <tr>
                                <th style={{ width: '9rem' }} >Date</th>
                                <th>Description</th>
                                <th style={{ width: '9rem', textAlign: 'end'}} >Amount</th>
                                {
                                    isControlButtonAvailable
                                        && <>
                                            <th style={{ width: '2.5rem' }}></th>
                                            <th style={{ width: '2.5rem' }}></th>
                                        </>
                                }
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody textWhite >
                            {
                                currentWorkOrder.expenses.map(expense => (
                                    <tr key={expense._id} >
                                        {
                                            expense._id === editingExpenses._id
                                                ? <EditExpense
                                                    expense={editingExpenses}
                                                    saveExpense={saveEditExpense}
                                                    cancelEdit={cancelEdit}
                                                />
                                                : <ExpenseCard
                                                    expense={expense}
                                                    deleteExpense={deleteExpense}
                                                    editExpense={setEditingExpenses}
                                                    isControlButtonAvailable={isControlButtonAvailable}
                                                />
                                        }
                                    </tr>
                                ))
                            }
                        </MDBTableBody>
                    </MDBTable>
                </MDBCol>
            </MDBRow>
            <MDBRow style={{padding: '0.5rem 1rem', justifyContent: 'flex-end'}} >
                <MDBCol md='2' >
                    Total Expenses:
                </MDBCol>
                <MDBCol style={{textAlign: 'end', marginRight: isControlButtonAvailable ? '5.75rem' : '0.75rem', paddingRight: 0, flexGrow: 0}} >
                    {currentWorkOrder.expenses.reduce((acc,cur) => acc + cur.amount, 0).toLocaleString('en-US',localCurrencySettings)}
                </MDBCol>
            </MDBRow>
        </>
        : null
}

export default ExpensesList;