import React from 'react';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';

import Notification from '../../../notification';

const AddReportIssue = ({
  currentWorkOrder,
  user,
  createNewIssueReport,
  setShowAddReportIssue,
}) => {
  const reportAnIssue = async (event) => {
    event.preventDefault();
    if (!event.target.description.value) {
      Notification('warning', { message: 'Add description to report!' });
    } else {
      const { _id, contractor_id: contractorId } = currentWorkOrder;
      const sendIssueReport = {
        work_order_id: _id,
        contractor_id: contractorId,
        client_issue_description: event.target.description.value,
        client_name: user.firstName + user.lastName,
      };
      const result = await createNewIssueReport(sendIssueReport);
      if (result) setShowAddReportIssue(false);
    }
  };

  return (
    <form onSubmit={reportAnIssue}>
      <MDBRow style={{ marginBottom: '15px', display: 'block' }}>
        <MDBCol>
          <label className="font11">Report an Issue</label>
          <textarea
            className="form-control raitextarea"
            style={{ fontSize: '11px' }}
            rows="5"
            name="description"
            placeholder="Description"
          />
        </MDBCol>
        <MDBCol style={{ textAlign: 'end' }}>
          <MDBBtn
            color="deep-orange"
            className="reportan_issuebut"
            type="submit"
          >
            Report an Issue
          </MDBBtn>
          <MDBBtn
            color="blue-grey"
            className="reportan_issuebut"
            onClick={() => {
              setShowAddReportIssue(false);
            }}
          >
            Cancel
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </form>
  );
};

export default AddReportIssue;
