import React from 'react';
import { MDBCol, MDBIcon, MDBNavLink } from 'mdbreact';

const LabelRow = ({
  template,
  // edit,
  deleteTemplate,
  copy,
  copyTemplateId
}) => (
  <>
    <MDBCol md="9">
      <MDBNavLink
        to={`/create-work-order-template/${template.value}`}
        className="white-text"
        title="Click To Edit"
      >
        {template.label}
      </MDBNavLink>
    </MDBCol>
    <MDBCol md="3" className="flex-box align-items-center">
      <MDBNavLink
        to={`/create-work-order-template/${template.value}`}
        className="white-text no-paddings"
        title="Edit"
      >
        <MDBIcon
          far
          icon="edit"
          className="deep-orange-text v-paddings-half"
          size="lg"
        />
      </MDBNavLink>
      <MDBIcon
        far
        icon="copy"
        className="cyan-text v-paddings-half"
        title="Copy"
        onClick={copy}
        size="lg"
      />
      <MDBIcon
        icon="minus-circle"
        className="red-text v-paddings-half"
        title="Delete"
        onClick={deleteTemplate}
        size="lg"
      />
      <MDBIcon
        far
        icon="copy"
        className="v-paddings-half"
        title="Copy templateId"
        onClick={copyTemplateId}
        size="lg"
      />
    </MDBCol>
  </>
);

export default LabelRow;
