/* eslint-disable import/no-cycle */
import React from 'react';
import ViewDetailsWorkOrderModal from './workOrderDetails';
import ApplyModal from './ApplyModal';
import ImportWorkOrderModal from './ImportWorkOrderModal';
import ViewAllApplicantsForWorkOrder from './ViewAplicantsModal';
import UnAssignModal from './UnAssignModal';
import ContractorDetailsModal from './contractorDetailsModal';
import NearByContractorModal from './NearByContractorModal';
import CopyWorkOrderModal from './CopyWorkOrderModal';
import Profile from './profile';
import GoogleMapsModal from './GoogleMapsModal';
import ImageViewModal from './ImageViewModal';
import BlockModal from './BlockModal';
import WorkOrderMessageSystemModal from './workOrderMessageSystemModal';
import MessageRateModal from './MessageRateModal';
import PortalContainer from './Portal';
import ContractorInviteSelectWorkOrder from './ContractorInviteSelectWorkOrder';
import AddFunds from './AddFunds';
import ErrorModal from './errorModal';
import BulkEditWorkOrders from './BulkEditWorkOrders';
import BulkMessageModal from './bulkMessageModal';
import CustomLoader from './CustomLoader';
import BulkWorkOrderApproveModal from './bulkWorkOrderApproveModal';
import BulkWorkOrderRouteContractorModal from './BulkWorkOrderRouteContractorModal';
import BulkWorkOrderAssignContractorModal from './BulkWorkOrderAssignContractorModal';
import ProjectAndCompanyControlModal from './projectAndCompanyControlModal';
import EditCustomFieldModal from './EditCustomFieldModal';
import AddOrEditPaymentMethod from './AddOrEditPaymentMethod';
import AcceptAgreements from './AcceptAgreements';
import AddBankInfoForContractor from './AddBankInfoForContractor';
import SetUpBankInfoForContractor from './SetUpBankInfoForContractor';
import FiltersModal from './filters';
import ContractorTaxInformationModal from './ContractorTaxInformationModal';
import BulkWorkOrderPay from './BulkWorkOrderPay';
import ConfirmModal from './ConfirmModal';
import EditCompanyBySuperAdmin from './EditCompanyBySuperAdmin';
import AddFundsToDwollaBalance from './AddFundsToDwollaBalance';
import AddFundingSourceToDwollaBySuperAdmin from './AddFundingSourceToDwollaBySuperAdmin';
import VerifyFundingSource from './VerifyFundingSource';
import DeleteClientFromCompany from './DeleteClientFromCompany';
import NewApiVersion from './NewApiVersion';
import PurchaseCertificate from './PurchaseCertificate';
import CreateOrAddCustomFieldToWorkOrder from './CreateOrAddCustomFieldToWorkOrder';
import TestFailed from './TestFailed';
import AddOrEditTeams from './AddOrEditTeams';
import AddOrEditUsersForManagement from './AddOrEditUsersForManagement';
import AddOrEditCustomFields from './AddOrEditCustomFields';
import ImportRecruitsModal from './ImportRecruitsModal';
import AddOrEditGroups from './AddOrEditGroups';

const Modal = ({ mainContainer }) => (
  mainContainer
    ? (
      <>
        <PortalContainer parentContainer={mainContainer} modalName="purchaseCertificate">
          <PurchaseCertificate mainContainer={mainContainer} />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="newApiVersion">
          <NewApiVersion mainContainer={mainContainer} />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="viewDetailsWorkOrderModal">
          <ViewDetailsWorkOrderModal mainContainer={mainContainer} />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="applyModal">
          <ApplyModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="importWorkOrderModal">
          <ImportWorkOrderModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="viewAllApplicantsForWorkOrderModal">
          <ViewAllApplicantsForWorkOrder />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="unAssignModal">
          <UnAssignModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="contractorDetailsModal">
          <ContractorDetailsModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="nearByContractorModal">
          <NearByContractorModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="copyWorkOrderModal">
          <CopyWorkOrderModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="profile">
          <Profile />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="googleMapsModal">
          <GoogleMapsModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="imageViewModal">
          <ImageViewModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="blockModal">
          <BlockModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="messageRateModal">
          <MessageRateModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="contractorInviteSelectWorkOrder">
          <ContractorInviteSelectWorkOrder />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="workOrderMessageSystemModal">
          <WorkOrderMessageSystemModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="addFunds">
          <AddFunds />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="addOrEditPaymentMethod">
          <AddOrEditPaymentMethod />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="importRecruitsModal">
          <ImportRecruitsModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="errorModal">
          <ErrorModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="bulkMessageModal">
          <BulkMessageModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="bulkEditWorkOrders">
          <BulkEditWorkOrders />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="bulkWorkOrderApproveModal">
          <BulkWorkOrderApproveModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="bulkWorkOrderRouteContractorModal">
          <BulkWorkOrderRouteContractorModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="bulkWorkOrderAssignContractorModal">
          <BulkWorkOrderAssignContractorModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="acceptAgreements">
          <AcceptAgreements />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="addBankInfoForContractor">
          <AddBankInfoForContractor />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="setUpBankInfoForContractor">
          <SetUpBankInfoForContractor />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="projectAndCompanyControlModal">
          <ProjectAndCompanyControlModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="editCustomFieldModal">
          <EditCustomFieldModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="filtersModal">
          <FiltersModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="contractorTaxInformationModal">
          <ContractorTaxInformationModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="bulkWorkOrderPay">
          <BulkWorkOrderPay />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="confirmModal">
          <ConfirmModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="editCompanyBySuperAdmin">
          <EditCompanyBySuperAdmin />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="addFundsToDwollaBalance">
          <AddFundsToDwollaBalance />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="addFundingSourceToDwollaBySuperAdmin">
          <AddFundingSourceToDwollaBySuperAdmin />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="verifyFundingSource">
          <VerifyFundingSource />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="deleteClientFromCompany">
          <DeleteClientFromCompany />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="createOrAddCustomFieldToWorkOrder">
          <CreateOrAddCustomFieldToWorkOrder />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="testFailed">
          <TestFailed />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="addOrEditTeams">
          <AddOrEditTeams mainContainer={mainContainer} />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="addOrEditGroups">
          <AddOrEditGroups mainContainer={mainContainer} />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="addOrEditUsersForManagement">
          <AddOrEditUsersForManagement mainContainer={mainContainer} />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="addOrEditCustomFields">
          <AddOrEditCustomFields />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="loader">
          <CustomLoader />
        </PortalContainer>
      </>
    )
    : null
);

export default Modal;
