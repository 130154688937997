import React from 'react';
import {
  MDBCol, MDBRow, MDBTable, MDBTableBody,
} from 'mdbreact';
import ApplicantCard from '../../layout/contractorCards/ApplicantCard';

const AppliedContractorDetails = ({
  userType,
  applicants,
  currentWorkOrder,
  setCurrentApplicants,
  toggleModal,
  assignContractor,
}) => (
  (applicants && applicants.contractor)
    ? (
      <MDBRow style={{ marginBottom: '0px' }}>
        <MDBCol>
          <MDBRow><hr className="my-4 hrline2" /></MDBRow>
          <h6 style={{ fontWeight: 'bold' }}>
            Applied Contractor Details
          </h6>
          <div>
            <MDBRow style={{ marginBottom: '15px' }} />
            <MDBRow>
              <MDBCol>
                <MDBTable className="applicantappiliedwo_table">
                  {
                    (applicants && applicants.contractor)
                      && (
                        <MDBTableBody>
                          {
                            applicants.contractor.map(contractor => (
                              contractor.contractor_details
                                ? (
                                  <ApplicantCard
                                    userType={userType}
                                    key={contractor.contractor_details.unique_id}
                                    contractorInfo={contractor}
                                    setCurrentApplicants={setCurrentApplicants}
                                    toggleModal={toggleModal}
                                    currentWorkOrder={currentWorkOrder}
                                    assignContractor={assignContractor}
                                  />
                                )
                                : null
                            ))
                          }
                        </MDBTableBody>
                      )
                  }
                </MDBTable>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBCol>
      </MDBRow>
    )
    : null
);

export default AppliedContractorDetails;
