import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

const SectionContainerOneCol = ({ children }) => (
  <MDBContainer className="p-0">
    {
      children.map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <MDBRow key={index} className="h-paddings-half">
          <MDBCol>
            {child}
          </MDBCol>
        </MDBRow>
      ))
    }
  </MDBContainer>
);

export default SectionContainerOneCol;
