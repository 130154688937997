import React from 'react';
import Clients from './Clients';
import Contractors from './Contractors';
import SuperAdmin from './SuperAdmin';

const ControlsButton = ({
  workOrder,
  activeTab,
  setCurrentWorkOrder,
  userType,
  selectedWorkOrders,
  selectWorkOrder,
  fetchApplicantsForWorkOrder,
  toggleModal,
  publishWorkOrder,
  acceptInvite,
  confirmWorkOrder,
  createWorkOrderTemplateByWorkOrder,
}) => {
  switch (userType) {
    case 'client': return (
      <Clients
        workOrder={workOrder}
        setCurrentWorkOrder={setCurrentWorkOrder}
        selectedWorkOrders={selectedWorkOrders}
        selectWorkOrder={selectWorkOrder}
        fetchApplicantsForWorkOrder={fetchApplicantsForWorkOrder}
        toggleModal={toggleModal}
        publishWorkOrder={publishWorkOrder}
        createWorkOrderTemplateByWorkOrder={createWorkOrderTemplateByWorkOrder}
      />
    );
    case 'contractor': return (
      <Contractors
        workOrder={workOrder}
        setCurrentWorkOrder={setCurrentWorkOrder}
        selectedWorkOrders={selectedWorkOrders}
        selectWorkOrder={selectWorkOrder}
        fetchApplicantsForWorkOrder={fetchApplicantsForWorkOrder}
        toggleModal={toggleModal}
        publishWorkOrder={publishWorkOrder}
        createWorkOrderTemplateByWorkOrder={createWorkOrderTemplateByWorkOrder}
        activeTab={activeTab}
        acceptInvite={acceptInvite}
        confirmWorkOrder={confirmWorkOrder}
      />
    );
    case 'superAdmin': return (
      <SuperAdmin
        workOrder={workOrder}
        selectedWorkOrders={selectedWorkOrders}
        selectWorkOrder={selectWorkOrder}
        toggleModal={toggleModal}
      />
    );
    default: return (<></>);
  }
};

export default ControlsButton;
