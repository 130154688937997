import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';

const detailTitleStyles = { marginRight: '10px', cursor: 'pointer' };
const detailValueGreenStyles = { marginRight: '10px', color: '#009933' };
const addressBlockStyles = { minHeight: '35px' };

const ContractorDetails = ({
  contractorDetails = null,
}) => (
  <>
    <MDBCol md="8">
      <MDBRow>
        <MDBCol md="6">
          <div className="d-flex">
            <h6 style={detailTitleStyles}>ID:</h6>
            <h6 className="font-weight-bold" style={detailValueGreenStyles}>{contractorDetails.unique_id}</h6>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md="6">
          <div className="d-flex">
            <h6 style={detailTitleStyles}>Name: </h6>
            <h6 className="font-weight-bold" style={detailValueGreenStyles}>{contractorDetails.name}</h6>
          </div>
          <div className="font12">
            <span style={detailTitleStyles}>Date Registered:</span>
            <span>{new Date(contractorDetails.date).toLocaleDateString('en-US', { timeZone: 'UTC' })}</span>
            <br />
            <span style={detailTitleStyles}>Last Login:</span>
            <span>
              {contractorDetails.lastLoginAt
                ? new Date(contractorDetails.lastLoginAt).toLocaleString('en-US')
                : 'Not Available'}
            </span>
            <br />
            <span style={detailTitleStyles}>Last WO completed date:</span>
            <span>
              {contractorDetails.lastWorkOrderCompletedAt
                ? new Date(contractorDetails.lastWorkOrderCompletedAt).toLocaleString('en-US')
                : 'Not Available'}
            </span>
            <br />
            <span
              title="WO's Completed For Our Company / WO's Completed on Platform"
              style={detailTitleStyles}
            >
              Work Performed:
            </span>
            <span>
              {`${contractorDetails.completedWorkOrderCount || 0} / ${contractorDetails.allWorkOrdersCount || 0}`}
            </span>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md="6">
          <div className="font12" style={addressBlockStyles}>
            {contractorDetails.address || ''}
            <div className="font12">
              {contractorDetails.address2 || ''}
            </div>
            {contractorDetails.city?.label
              ? <span>{`${contractorDetails.city.label}, `}</span>
              : null}
            {contractorDetails.state?.label
              ? <span>{`${contractorDetails.state.label}, `}</span>
              : null}
            <span>{contractorDetails.zipcode}</span>
            <div>
              {contractorDetails.country?.label
                ? <span>{`${contractorDetails.country.label}`}</span>
                : null}
            </div>
            <div className="font12">
              {contractorDetails.phone
                ? <span><a href={`tel:${contractorDetails.phone}`}>{contractorDetails.phone}</a></span>
                : null}
            </div>
            <div className="font12">
              <span><a href={`mailto:${contractorDetails.email}`}>{contractorDetails.email}</a></span>
            </div>
            <div className="font12">
              <span>{contractorDetails.website || ''}</span>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBCol>
  </>
);

export default ContractorDetails;
