import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdbreact';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import Modal from '../../components/modal';
import { toggleModal } from '../../storage/actions/modalsActions';
import getContractorGroups from '../../utils/api/get/getContractorGroups';
import deleteGroup from '../../utils/api/delete/deleteGroup';
import { reloadData } from '../../storage/actions/applicationStateActions';

const tableFields = ['Group name', 'Members'];

const ContractorGroups = ({
  mainContainer, toggleModal, isReload, reloadData,
}) => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    fetchGroups();
  }, [isReload]);

  const fetchGroups = async () => {
    toggleModal(true, 'loader');

    const data = await getContractorGroups();

    setGroups(data);
    toggleModal(false, 'loader');
  };

  const openEditGroupModal = (groupId) => {
    toggleModal(true, 'addOrEditGroups', false, { id: groupId });
  };

  const openAddGroupModal = () => {
    toggleModal(true, 'addOrEditGroups');
  };

  const onDeleteGroup = (groupId, name) => {
    toggleModal(true, 'confirmModal', true, {
      onAccept() {
        deleteGroup(groupId).then(() => reloadData());
      },
      header: `Delete ${name || 'team'}?`,
      buttonLabels: {
        reject: 'Cancel',
        confirm: 'Delete',
      },
    });
  };

  return (
    <MDBContainer className="clientcreateworkorderdiv" fluid>
      <MDBRow className="flex-box justify-content-center">
        <MDBCol size="8">
          <MDBRow>
            <MDBCol md="9" className="flex-box align-items-center">
              <h4>Preferred Contractor Groups</h4>
            </MDBCol>
            <MDBCol md="3" className="flex-box justify-content-end">
              <MDBBtn color="success" 
                onClick={openAddGroupModal}
              >
                Add Group
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol size="8">
          <MDBTable className="text-white">
            <MDBTableHead>
              <tr>
                {tableFields.map(field => (
                  <th key={field}>{field}</th>
                ))}
                <th> </th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {groups.map(group => {
                const { name, _id, groupUsersCount } = group;
                return (
                  <tr key={_id}>
                    <th
                      className="cursor_pointer"
                      onClick={() => openEditGroupModal(_id, name)}
                    >
                      {name}
                    </th>
                    <th>{groupUsersCount}</th>
                    <th className="text-align-right">
                      <MDBIcon
                        icon="edit"
                        size="lg"
                        onClick={() => openEditGroupModal(_id, name)}
                        title="Edit"
                        style={{
                          color: '#41566a',
                          cursor: 'pointer',
                          padding: '5px',
                        }}
                      />
                      <MDBIcon
                        icon="trash"
                        size="lg"
                        onClick={() => onDeleteGroup(_id, name)}
                        title="Delete"
                        style={{
                          color: '#41566a',
                          cursor: 'pointer',
                          padding: '5px',
                        }}
                      />
                    </th>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
        </MDBCol>
      </MDBRow>
      <Modal mainContainer={mainContainer.current} />
    </MDBContainer>
  );
};

const mapDispatchToProps = {
  toggleModal, reloadData,
};
const mapStateToProps = state => ({
  modalState: state.modalState.addOrEditGroup,
  isReload: state.applicationState.isReload,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractorGroups);
