import React, {useState} from 'react'
import {GoogleMap, LoadScript, InfoWindow, Marker} from '@react-google-maps/api'

const GoogleMaps = (props) => {
    const position = {
        lat: props.coordinates.coordinates[1],
        lng: props.coordinates.coordinates[0]
    };
    const containerStyle = {
        position: 'relative',
        width: '800px',
        height: '800px'
    };

    const [visible, changeVisible] = useState(true)
    return (
        <LoadScript
            googleMapsApiKey="AIzaSyB_4lv02sdDdyjixmv20_Wg_oatXlxKvBU"
        >
            <GoogleMap
                id="InfoWindow-example"
                mapContainerStyle={containerStyle}
                zoom={15}
                center={position}
            >
                <Marker
                    onClick={e => changeVisible(true)}
                    position={position}
                >
                </Marker>
                {
                    visible ? <InfoWindow
                            onCloseClick={() => changeVisible(false)}
                            position={position}
                        >
                            <div>
                                <h1 style={{color: 'black', fontSize: '12px'}}>{props.address}</h1>
                                <a href={`https://www.google.com/maps/dir//${props.address}`} target="_blank">show
                                    direction</a>
                            </div>
                        </InfoWindow>
                        : null
                }

            </GoogleMap>
        </LoadScript>
    );
};

export default React.memo(GoogleMaps)
