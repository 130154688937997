import React, { useEffect, useState } from 'react';
import {
  MDBCol, MDBContainer, MDBBtn, MDBRow,
} from 'mdbreact';
import { connect } from 'react-redux';
import getCustomFieldsList from '../../utils/api/get/getCoustomFieldsList';
import Tablet from '../layout/tablet';
import { toggleModal } from '../../storage/actions/modalsActions';
import { manageCustomFieldsTableColumns as columns } from '../../utils/constans';
import ManageCustomFieldsActions from './ManageCustomFieldsActions';

const Content = ({ isReload, toggleModal }) => {
  const [customFields, setCustomFields] = useState([]);

  const fetchCustomFieldsList = async () => {
    toggleModal(true, 'loader');

    const newCustomFieldsList = await getCustomFieldsList();
    setCustomFields(newCustomFieldsList);

    toggleModal(false, 'loader');
  };

  useEffect(() => {
    fetchCustomFieldsList();
  }, [isReload]);

  return (
    <MDBContainer>
      <MDBRow className="justify-content-lg-between align-items-center m-lg-2">
        <h5 style={{ margin: '6px' }}>Custom Fields</h5>
        <MDBBtn
          color="primary"
          onClick={() => toggleModal(true, 'addOrEditCustomFields')}
        >
          Add Custom Field
        </MDBBtn>
      </MDBRow>
      <MDBCol>
        <Tablet
          columns={columns}
          rows={customFields}
          className="color-white"
          enableActionsCol
          ActionElement={ManageCustomFieldsActions}
          placeholder="No custom fields yet"
        />
      </MDBCol>
    </MDBContainer>
  );
};

const mapDispatchToProps = {
  toggleModal,
};

const mapStateToProps = state => ({
  isReload: state.applicationState.isReload,
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
