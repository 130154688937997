import React, { useEffect, useState } from 'react';
import { MDBContainer } from 'mdbreact';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAccountBalanceByClient } from '../../storage/actions/profile';
import { fetchWorkOrderTemplateById, setCurrentTemplate } from '../../storage/actions/workOrdersActions';
import Loader from '../../components/dashboard/common/Loader';
// eslint-disable-next-line import/no-cycle
import Modal from '../../components/modal';
import PageHeader from '../../components/createWorkOrderTemplate/PageHeader';
import CreateWorkOrderTemplateReduxForm from '../../components/layout/reduxForm/CreateWorkOrderTemplate';
import onSubmit from '../../utils/reduxForm/onSubmit/createWorkOrderTemplate';

const CreateAndEditWorkOrderTemplates = ({
  mainContainer,
  currentTemplate,
  fetchWorkOrderTemplateById,
  getAccountBalanceByClient,
  setCurrentTemplate,
  match,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const getSingleWorkOrderTemplate = async id => {
    if (!id) {
      await setCurrentTemplate({});
    } else {
      await fetchWorkOrderTemplateById(id);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAccountBalanceByClient();
    getSingleWorkOrderTemplate(match?.params?.id);
  }, []);

  return (
    <MDBContainer className="clientcreateworkorderdiv" fluid>
      <PageHeader isLoading={isLoading} name={!isLoading && currentTemplate?.name} />
      {
        isLoading
          ? (
            <div className="flex-box flex-center">
              <Loader />
            </div>
          )
          : (
            <CreateWorkOrderTemplateReduxForm onSubmit={onSubmit} />
          )
      }
      <Modal mainContainer={mainContainer.current} />
    </MDBContainer>
  );
};

const mapDispatchToProps = {
  getAccountBalanceByClient,
  fetchWorkOrderTemplateById,
  setCurrentTemplate,
};

const mapStateToProps = state => ({
  currentTemplate: state.workOrder.currentTemplate,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CreateAndEditWorkOrderTemplates));
