import React from 'react';
import { MDBTooltip } from 'mdbreact';
import { localCurrencySettings } from '../../../../../../utils/constans';
import TooltipTitle from './TooltipTitle';

const AmountInfo = ({
  amountBase,
  amountVariable,
  basicWorkType,
  bidBasicType,
  variableWorkType,
  bidAmountBase,
  bidAmountVariable,
  current,
}) => (
  <div className="white-tooltip-title">
    <MDBTooltip
      domElement
      placement="top"
    >
      <div
        style={{
          // eslint-disable-next-line no-nested-ternary
          color: current
            ? '#ff7043'
            : (
              bidAmountBase === amountBase
              && (!bidAmountVariable || bidAmountVariable === amountVariable)
            )
              ? 'white'
              : '#ffff32',
        }}
      >
        {`${bidAmountBase.toLocaleString('en-US', localCurrencySettings)} / ${!bidBasicType ? basicWorkType : bidBasicType}`}
      </div>
      <div className="amount-tooltip-title">
        <TooltipTitle
          amountBase={amountBase}
          amountVariable={amountVariable}
          basicWorkType={basicWorkType}
          variableWorkType={variableWorkType}
          bidAmountBase={bidAmountBase}
          bidAmountVariable={bidAmountVariable}
        />
      </div>
    </MDBTooltip>
  </div>
);

export default AmountInfo;
