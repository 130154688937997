import React, { useEffect, useState } from 'react';
import { MDBCol } from 'mdbreact';
import axios from 'axios';
import { Field, change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import Notification from '../../notification';
import Loader from '../../dashboard/common/Loader';
import ReactSelectField from '../../layout/reduxForm/customFields/ReactSelectField';

const TeamSection = ({ formName }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);

  const dispatch = useDispatch();

  const { teamId } = useSelector((state) => state.form[`${formName}`]).values;

  const fetchOptions = () => {
    setIsLoading(true);
    axios.get('/api/teams')
      .then((res) => {
        if (res.data.success) {
          setOptions(
            res.data.teams.map(({ _id, name }) => {
              if (_id === teamId) {
                dispatch(change(formName, 'teams', { value: _id, label: name }));
              }
              return ({
                value: _id,
                label: name,
              });
            }),
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        Notification('error', { message: error.response?.data?.message || error.message });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <MDBCol style={{ zIndex: '2', display: 'flex' }}>
      <label className="no-margins" style={{ display: 'flex', alignItems: 'center' }}>Select Team for Notification</label>
      <div style={{ width: '20%', paddingTop: '1rem', paddingLeft: '1rem' }}>
        {
          isLoading
            ? <Loader />
            : (
              <Field
                name="teams"
                component={ReactSelectField}
                className="flex-column"
                options={options}
                isClearable
              />
            )
        }
      </div>
    </MDBCol>
  );
};

export default TeamSection;
