import React, { useEffect } from 'react';
import { MDBCol, MDBContainer } from 'mdbreact';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SearchElement from './SearchElement';
import WorkOrderButtonControl from '../dashboard/workOrderButtonControl';
import { toggleModal } from '../../storage/actions/modalsActions';
import { getAccountBalanceByClient } from '../../storage/actions/profile';
import { localCurrencySettings } from '../../utils/constans';

const Offer = ({
  toggleModal,
  auth,
  profile,
  getSearched,
  hideControlButton = false,
  showInputRange = false,
  getAccountBalanceByClient,
}) => {
  useEffect(() => {
    if (auth?.user?.userType === 'client') {
      getAccountBalanceByClient();
    }
  }, []);

  return (
    <MDBContainer fluid>
      <div className="d-flex upperdivcdc">
        <MDBCol md="3" className="threecoldiv">
          <h6>
            <b>{profile?.user?.firstName ? `Hey ${profile.user.firstName}, Welcome Back!` : ''}</b>
          </h6>
          <span className="font13">Welcome to SourceW9</span>
          {
            profile.userType === 'client'
              ? (
                <div style={{
                  color: 'green',
                  fontWeight: 'bold',
                }}
                >
                  {
                    `Funds Available: ${
                      profile?.balance.total_balance
                        ? Number(profile.balance.total_balance - (profile.balance.on_hold || 0))
                          .toLocaleString('en-US', localCurrencySettings)
                        : '$0.00'
                    }`
                  }
                </div>
              )
              : <></>
          }
        </MDBCol>
        <MDBCol md="9" className=" d-flex justify-content-end searchbardivcdc">
          <SearchElement
            getSearchValues={getSearched}
            showInputRange={showInputRange}
          />
          <WorkOrderButtonControl
            hideControlButton={hideControlButton}
            openImportModal={() => toggleModal(true, 'importWorkOrderModal')}
            userType={auth.user.userType}
          />
        </MDBCol>
      </div>
    </MDBContainer>
  );
};

const mapDispatchToProps = {
  toggleModal,
  getAccountBalanceByClient,
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Offer));
