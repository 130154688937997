import React, { useEffect, useState } from 'react';
import {
  MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import Select from 'react-select';
import Loader from '../dashboard/common/Loader';
import Notification from '../notification';
import { assignContractor, getApplicantsForBulkAssignToWorkOrders } from '../../storage/actions/workOrdersActions';
import { toggleModal } from '../../storage/actions/modalsActions';
import { white as customSelectStyle } from '../../utils/customSelectStyle';
import prepareFilters from '../../utils/prepareFilters';
import { getStatus } from '../../utils/api/get/getWorkOrders';

const BulkWorkOrderAssignContractorModal = ({
  modalState,
  selectedWorkOrders,
  // eslint-disable-next-line no-shadow
  toggleModal, assignContractor, getApplicantsForBulkAssignToWorkOrders,
  filters, search, activeTab,
}) => {
  const [selectedContractor, setSelectedContractor] = useState();
  const [contractors, setContractors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [requestData, setRequestData] = useState({});

  const initRequestData = () => {
    const newRequestData = modalState?.type?.mode === 'useFilters'
      ? {
        ...search,
        filters: prepareFilters(filters),
      }
      : { workOrdersId: selectedWorkOrders };
    setRequestData({ ...newRequestData, ...getStatus(activeTab) });
    return ({ ...newRequestData, ...getStatus(activeTab) });
  };

  const fetchData = async () => {
    const contractorsList = await getApplicantsForBulkAssignToWorkOrders(initRequestData());
    const newContractorsList = contractorsList || [];
    setContractors(newContractorsList.map((contractor) => (
      {
        value: contractor,
        label: `${contractor.name} - ${contractor.unique_id} - ${contractor.address || '!?ADDRESS!?'}, ${contractor.city?.label || '!?CITY!?'}, ${contractor.state?.label || '!?STATE!?'} ${contractor.zipcode || '!?ZIPCODE!?'}`,
      }
    )));
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const closeModal = () => {
    toggleModal(false, 'bulkWorkOrderAssignContractorModal', false, '');
  };

  const handlerSubmit = (event) => {
    event.preventDefault();
    if (!selectedContractor?.value?.userId) {
      Notification('warning', { message: 'Please select contractor!' });
    } else {
      assignContractor(requestData, selectedContractor.value.userId);
    }
  };

  const filterOption = (item, filter = '') => {
    const newRegExp = new RegExp(filter, 'i');
    return (newRegExp.test(item.value.name) || newRegExp.test(item.value.unique_id));
  };

  return (
    <MDBModal
      isOpen={modalState.isOpen}
      toggle={closeModal}
      centered
      className="modal-width-to-max-content"
      contentClassName="modal-container-width-to-max-content"
    >
      <MDBRow className="no-margins" style={{ justifyContent: 'flex-end', paddingRight: '10px' }}>
        <MDBIcon
          far
          icon="times-circle"
          onClick={closeModal}
          size="lg"
          className="blue-grey-text"
        />
      </MDBRow>
      <MDBModalBody style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
        <MDBContainer>
          <MDBRow style={{ justifyContent: 'center' }}>
            <p
              style={{ color: 'black' }}
            >
              Assign Contractor
            </p>
          </MDBRow>
          <MDBRow className="no-margins h-paddings-half">
            <MDBCol>
              <label className="no-margins">
                {/* eslint-disable-next-line max-len */}
                If you do not see a contractor listed, please be sure they have applied to the work order(s) you have selected.
              </label>
            </MDBCol>
          </MDBRow>
          <MDBRow className="h-paddings-half">
            <MDBCol>
              {
                isLoading
                  ? (<div className="flex-box flex-center"><Loader color="black" /></div>)
                  : (
                    <Select
                      isClearable
                      isSearchable
                      filterOption={filterOption}
                      options={contractors}
                      value={selectedContractor}
                      styles={customSelectStyle}
                      onChange={setSelectedContractor}
                      noOptionsMessage={() => 'If you do not see a contractor listed, please be sure they have applied to the work order(s) you have selected.'}
                      placeholder="Select Contractor!"
                    />
                  )
              }
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ justifyContent: 'center' }}>
            <MDBBtn
              color="info"
              className="signbutton"
              onClick={handlerSubmit}
            >
              Assign
            </MDBBtn>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
  assignContractor,
  getApplicantsForBulkAssignToWorkOrders,
};

const mapStateToProps = (state) => ({
  modalState: state.modalState.bulkWorkOrderAssignContractorModal,
  selectedWorkOrders: state.applicationState.selectedWorkOrders,
  filters: state.workOrder?.filters,
  search: state.workOrder?.searchData,
  activeTab: state.applicationState.activeTab,
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkWorkOrderAssignContractorModal);
