import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  MDBCol,
  MDBIcon, MDBModal,
  MDBModalBody, MDBRow, MDBTable, MDBTableBody, MDBTableHead,
} from 'mdbreact';
import { toggleModal } from '../../../storage/actions/modalsActions';
import TableElement from './TableElement';
import CreateDocumentForm from './CreateDocumentForm';
import {
  addWorkOrderProjectOrCompany,
  editWorkOrderProjectOrCompany,
  deleteWorkOrderProjectOrCompany,
} from '../../../storage/actions/workOrdersActions';
import Notification from '../../notification';

const ProjectAndCompanyControlModal = ({
  modalState,
  toggleModal,
  addWorkOrderProjectOrCompany,
  editWorkOrderProjectOrCompany,
  deleteWorkOrderProjectOrCompany,
}) => {
  const [options, setOptions] = useState(modalState?.type?.options || []);

  const name = modalState?.type?.name?.replace(/^\w/, c => c.toUpperCase());

  const handlerUpdateOptions = newOptions => {
    modalState.type.updateData(newOptions);
    setOptions(newOptions);
  };

  // eslint-disable-next-line consistent-return
  const handlerCreate = async event => {
    event.preventDefault();
    const { value: documentName } = event.target.documentName;
    if (!documentName.trim()) {
      return Notification('warning', {
        message: 'Please enter a name before saving.',
      });
    }
    const resData = await addWorkOrderProjectOrCompany(modalState.type.linkName, documentName);
    if (resData?.success) {
      const newOptions = [
        ...modalState.type.options,
        {
          value: resData?.payload?.newDocument?._id,
          label: resData?.payload?.newDocument?.name,
        },
      ];
      handlerUpdateOptions(newOptions);
    }
  };

  const handlerDelete = async id => {
    await deleteWorkOrderProjectOrCompany(modalState.type.linkName, id);
    const newOptions = options.filter(item => item.value !== id);
    handlerUpdateOptions(newOptions);
  };

  // eslint-disable-next-line consistent-return
  const handlerEdit = async (id, newName) => {
    if (!newName.trim() || !id) {
      return Notification('warning', {
        message: 'Please enter name before saving changes.',
      });
    }
    await editWorkOrderProjectOrCompany(modalState.type.linkName, id, newName);
    const newOptions = options.map(item => (
      item.value !== id
        ? item
        : { ...item, label: newName }
    ));
    handlerUpdateOptions(newOptions);
  };

  return (
    <MDBModal
      className="applypop modal-width-500"
      isOpen={modalState.isOpen}
      toggle={() => {
        toggleModal(!modalState.isOpen, 'projectAndCompanyControlModal');
      }}
      fullHeight
      position="right"
    >
      <MDBModalBody style={{ color: 'white' }}>
        <MDBRow className="h-paddings-half">
          <MDBCol style={{ textAlign: 'left' }}>
            <h6
              className="font-weight-bold"
              style={{ marginBottom: '15px' }}
            >
              {name}
            </h6>
          </MDBCol>
          <MDBCol md="2" style={{ textAlign: 'end' }}>
            <MDBIcon
              far
              icon="times-circle"
              onClick={() => {
                toggleModal(!modalState.isOpen, 'projectAndCompanyControlModal');
              }}
              size="lg"
              className="blue-grey-text"
            />
          </MDBCol>
        </MDBRow>
        <CreateDocumentForm
          name={name}
          handlerCreate={handlerCreate}
        />
        <div>
          <MDBTable responsive className="clienttable">
            <MDBTableHead>
              <tr>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {
                options.map(({ label, value }) => (
                  <TableElement
                    key={value}
                    label={label}
                    value={value}
                    onChange={handlerEdit}
                    onDelete={handlerDelete}
                  />
                ))
              }
            </MDBTableBody>
          </MDBTable>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
  addWorkOrderProjectOrCompany,
  editWorkOrderProjectOrCompany,
  deleteWorkOrderProjectOrCompany,
};

const mapStateToProps = state => ({
  modalState: state.modalState.projectAndCompanyControlModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAndCompanyControlModal);
