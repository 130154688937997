import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Loader from '../dashboard/common/Loader';
import Notification from '../notification';
import { white as customSelectStyle } from '../../utils/customSelectStyle';

const SelectContractor = ({
  selectedContractor,
  getSelectedContractor,
  placeholder,
  loaderColor = 'while',
  setFetchedContractors,
}) => {
  const [contractors, setContractors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchContractors = () => {
    setIsLoading(true);
    axios.get('/api/users/contractors')
      .then((res) => {
        const newContractorsList = res.data.contractors || [];
        setContractors(newContractorsList.map((contractor) => (
          {
            value: contractor,
            label: `${contractor.name} - ${contractor.unique_id} - ${contractor.address || '!?ADDRESS!?'}, ${contractor.city?.label || '!?CITY!?'}, ${contractor.state?.label || '!?STATE!?'} ${contractor.zipcode || '!?ZIPCODE!?'}`,
          }
        )));
        if(setFetchedContractors) {
          setFetchedContractors(newContractorsList.map((contractor) => (
            {
              value: contractor,
              label: `${contractor.name} - ${contractor.unique_id} - ${contractor.address || '!?ADDRESS!?'}, ${contractor.city?.label || '!?CITY!?'}, ${contractor.state?.label || '!?STATE!?'} ${contractor.zipcode || '!?ZIPCODE!?'}`,
            }
          )))
        }
        setIsLoading(false);
      }).catch((error) => {
        setIsLoading(false);
        // eslint-disable-next-line no-console
        console.error('error', error);
        Notification('error', { message: error.response?.data?.message || error.message });
      });
  };

  useEffect(() => {
    fetchContractors();
  }, []);

  const handlerContractorSelect = (item) => {
    getSelectedContractor(item);
  };

  const filterOption = (item, filter = '') => {
    const newRegExp = new RegExp(filter, 'i');
    return filter.length > 2
      && (newRegExp.test(item.value.name) || newRegExp.test(item.value.unique_id));
  };

  return isLoading
    ? (
      <div className="flex-box flex-center">
        <Loader color={loaderColor} />
      </div>
    )
    : (
      <Select
        isClearable
        isSearchable
        filterOption={filterOption}
        options={contractors}
        value={selectedContractor}
        styles={customSelectStyle}
        onChange={handlerContractorSelect}
        noOptionsMessage={() => null}
        placeholder={placeholder || ''}
      />
    );
};

export default SelectContractor;
