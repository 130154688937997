import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthRoute = ({
  auth, userType, userTypes = [], hasNoPermissionRoles = [],
  exact, path,
  ...props
}) => {
  const isAuthenticated = auth && auth.isAuthenticated;
  // eslint-disable-next-line no-nested-ternary
  const profile = useSelector((state) => state.profile);
  userType && userTypes.push(userType)

  return isAuthenticated
    ? ((!userTypes.length || userTypes.includes(auth.user.userType))
        && !hasNoPermissionRoles.includes(profile?.clientUser?.accessctrl || profile?.userType)
      )
      // eslint-disable-next-line react/jsx-props-no-spreading
      ? <Route exact={exact} path={path} {...props} />
      : (
        <Route exact={exact} path={path}>
          <Redirect
            to={{
              pathname: '/dashboard',
              search: window.location?.search,
            }}
          />
        </Route>
      )
    : (
      <Route exact={exact} path={path}>
        <Redirect
          to={{
            pathname: '/home',
            search: window.location?.search,
          }}
        />
      </Route>
    );
};

export default AuthRoute;
