import React from "react";
import {MDBBtn} from "mdbreact";

const ControlErrorButton = ({onClick, label}) => (
    <MDBBtn
        color='info'
        className='signbutton'
        onClick={onClick}
    >
        {label}
    </MDBBtn>
)

export default ControlErrorButton;