import React from "react";
import {MDBBtn, MDBCol, MDBRow} from "mdbreact";
import {localCurrencySettings} from "../../../../utils/constans";

const AvailableToApproveWorkOrder = ({ closeModal, submitApprove, fundsInfo, workOrdersCount }) => (
    <>
        <MDBRow>
            <MDBCol>
                <h5 style={{textAlign: 'center'}} >{workOrdersCount} Work Order Ready To Approve</h5>
            </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol>
                {fundsInfo.fundsNeededFromWallet.toLocaleString('en-US',localCurrencySettings)} Will Pay From Wallet!
            </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol>
                {(fundsInfo.fundsNeeded - fundsInfo.fundsNeededFromWallet).toLocaleString('en-US',localCurrencySettings)} Will Pay Outside Of App!
            </MDBCol>
        </MDBRow>
        <MDBRow className='flex-center' >
            <MDBCol className='flex-grow-0' >
                <MDBBtn onClick={closeModal} >Close</MDBBtn>
            </MDBCol>
            <MDBCol className='flex-grow-0' >
                <MDBBtn color='success' onClick={submitApprove} >Approve</MDBBtn>
            </MDBCol>
        </MDBRow>
    </>
)

export default AvailableToApproveWorkOrder;