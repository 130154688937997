import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  MDBCol, MDBContainer, MDBIcon, MDBRow, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle,
} from 'mdbreact';
import { toggleModal } from '../../storage/actions/modalsActions';
// eslint-disable-next-line import/no-cycle
import Modal from '../../components/modal';
import { deleteFundingSource, makePrimaryFundingSource } from '../../storage/actions/superAdmin';

const PMTools = ({
  mainContainer, isReload,
}) => {
  useEffect(() => {
    // getTools();
  }, [isReload]);

  return (
    <MDBContainer className="clientdascontentmaindiv" fluid>
      <MDBRow>
        <MDBCol style={{ textAlign: 'center' }}>
          PM Tools
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol style={{ textAlign: 'center', fontSize: 12, fontStyle: 'italic' }}>
          These features are in beta testing.  Inquire with
          <br />
          <a href="mailto:support@sourcew9.com?subject=SW9 PM Tools Inquiry">support@sourcew9.com</a>
          {' '}
          if interested in being a beta client
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol style={{ textAlign: 'center' }}>
          <MDBCard className="card-payment-method" style={{ width: '22rem', margin: '1rem' }}>
            <MDBCardBody>
              <MDBCardTitle style={{ fontSize: 18, marginBottom: 40 }}>
                <MDBIcon icon="route" className="padding-02-05" />
                Site Routing
              </MDBCardTitle>
              <MDBCardText style={{ color: 'white', fontStyle: 'italic' }}>
                Route your site list
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol style={{ textAlign: 'center' }}>
          <MDBCard className="card-payment-method" style={{ width: '22rem', margin: '1rem' }}>
            <MDBCardBody>
              <MDBCardTitle style={{ fontSize: 18, marginBottom: 40 }}>
                <MDBIcon icon="chart-line" className="padding-02-05" />
                Project Control Center
              </MDBCardTitle>
              <MDBCardText style={{ color: 'white', fontStyle: 'italic' }}>
                Track everything about your project
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol style={{ textAlign: 'center' }}>
          <MDBCard className="card-payment-method" style={{ width: '22rem', margin: '1rem' }}>
            <MDBCardBody>
              <MDBCardTitle style={{ fontSize: 18, marginBottom: 40 }}>
                <MDBIcon icon="lightbulb" className="padding-02-05" />
                SW9STEIN
              </MDBCardTitle>
              <MDBCardText style={{ color: 'white', fontStyle: 'italic' }}>
                Ask SW9 AI
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <Modal mainContainer={mainContainer.current} />
    </MDBContainer>
  );
};

const mapDispatchToProps = {
  toggleModal,
  deleteFundingSource,
  makePrimaryFundingSource,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isReload: state.applicationState.isReload,
});

export default connect(mapStateToProps, mapDispatchToProps)(PMTools);
