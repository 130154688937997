import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Loader from '../../dashboard/common/Loader';
import Notification from '../../notification';
import { white as customSelectStyle } from '../../../utils/customSelectStyle';

const SelectWorkOrderCompany = ({ name }) => {
  const [woCompanies, setWOCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios.get('/api/wo-companies')
      .then((res) => {
        if (res.data.success) {
          setWOCompanies(res.data.payload?.data?.map(({ _id, name }) => ({
            value: _id,
            label: name,
          })));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('error', error);
        Notification('error', { message: error.response?.data?.message || error.message });
        setIsLoading(false);
      });
  }, []);

  return isLoading
    ? <Loader />
    : (
      <Select
        name={name}
        hideSelectedOptions={false}
        options={woCompanies}
        styles={customSelectStyle}
        onChange={(selected) => {
          SelectWorkOrderCompany.value = selected?.value || '';
        }}
        placeholder="Choose Company"
      />
    );
};

export default SelectWorkOrderCompany;
