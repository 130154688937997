import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  MDBCol, MDBContainer, MDBRow,
} from 'mdbreact';
// eslint-disable-next-line import/no-cycle
import Modal from '../../../components/modal';
import getCertificationTests from '../../../utils/api/get/getCertificationTests';
import { toggleModal } from '../../../storage/actions/modalsActions';
import TestsReduxForm from '../../../components/layout/reduxForm/TestsReduxForm';
import onSubmit from '../../../utils/reduxForm/onSubmit/tests';

const CertificationTests = ({
  mainContainer, toggleModal, history, isReload,
}) => {
  const [testInfo, setTestInfo] = useState([]);

  const { id: certificationTypeId } = useParams();

  const fetchData = async () => {
    toggleModal(true, 'loader');
    setTestInfo([]);
    const newTestInfo = await getCertificationTests(certificationTypeId, history);
    setTestInfo(newTestInfo);
    toggleModal(false, 'loader');
  };

  useEffect(() => {
    fetchData();
  }, [isReload]);

  return (
    <MDBContainer className="clientdascontentmaindiv" fluid>
      <MDBRow>
        <MDBCol className="text-center">
          <h5>{testInfo.name}</h5>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <TestsReduxForm
            onSubmit={onSubmit}
            tests={testInfo.tests || []}
            certificationTypeId={certificationTypeId}
          />
        </MDBCol>
      </MDBRow>
      <Modal mainContainer={mainContainer.current} />
    </MDBContainer>
  );
};

const mapDispatchToProps = {
  toggleModal,
};

const mapStateToProps = state => ({
  isReload: state.applicationState.isReload,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CertificationTests));
