import React, { useEffect, useState } from 'react';
import {
  MDBBtn, MDBCol, MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBRow,
} from 'mdbreact';
import Select from 'react-select';
import connect from 'react-redux/es/connect/connect';
import { toggleSecondModalClose, toggleModal } from '../../storage/actions/modalsActions';
import { white as customSelectStyle } from '../../utils/customSelectStyle';
import getCompanyMembers from '../../utils/api/get/getCompanyMembers';
import { delete_user_Details as deleteUserDetails } from '../../storage/actions/authActions';
import Notification from '../notification';

const DeleteClientFromCompany = ({
  deleteUserDetails,
  toggleModal,
  toggleSecondModalClose,
  modalState,
}) => {
  const [clientsList, setClientsList] = useState('');
  const [selectedClient, setSelectedClient] = useState('');

  const fetchData = async () => {
    toggleModal(true, 'loader', true);

    const newClientsList = await getCompanyMembers()
      .then(clients => (
        clients
          .filter(el => el.userId !== modalState.type.client.userId)
          .map(el => ({ value: el.userId, label: el.name }))
      ));
    setClientsList(newClientsList);

    toggleSecondModalClose('loader', true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onClose = () => {
    toggleModal(false, 'deleteClientFromCompany');
  };

  // eslint-disable-next-line consistent-return
  const onSubmit = () => {
    if (!selectedClient?.value) return Notification('warning', { message: 'Please, select user first!' });
    deleteUserDetails(modalState.type.client._id, selectedClient.value);
  };

  return (
    <MDBModal
      className="applypop"
      isOpen={modalState.isOpen}
      toggle={onClose}
      centered
    >
      <MDBModalBody>
        <MDBContainer>
          <MDBRow style={{ justifyContent: 'center', marginTop: '20px' }}>
            <MDBCol>
              <h5
                className="margin-bottom-10"
                style={{ color: 'yellow' }}
              >
                Work Orders created by this user should be reassigned to:
              </h5>
            </MDBCol>
          </MDBRow>
          <MDBRow className="margin-bottom-10">
            <MDBCol>
              <Select
                isSearchable
                options={clientsList}
                value={selectedClient}
                styles={customSelectStyle}
                onChange={setSelectedClient}
                placeholder="Select another user"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ justifyContent: 'center' }}>
            <MDBBtn
              color="grey"
              className="signbutton"
              onClick={onClose}
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              color="blue"
              className="signbutton"
              onClick={onSubmit}
            >
              Delete User
            </MDBBtn>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  deleteUserDetails,
  toggleModal,
  toggleSecondModalClose,
};

const mapStateToProps = state => ({
  modalState: state.modalState.deleteClientFromCompany,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteClientFromCompany);
