import React, { useEffect, useState } from 'react';
import { MDBTableBody } from 'mdbreact';
import Row from './Row';
import getSliceOfArray from '../../../../utils/getSliceOfArray';

const Body = ({
  rows, columns,
  ActionElement,
  enableActionsCol,
  placeholder,
  enablePagination,
  perPage,
  pageNumber,
}) => {
  const [rowsToShow, setRowsToShow] = useState([]);

  useEffect(() => {
    const newRowsToShow = enablePagination
      ? getSliceOfArray(rows, pageNumber * perPage, ((pageNumber + 1) * perPage))
      : rows;
    setRowsToShow(newRowsToShow);
  }, [rows, pageNumber, perPage]);

  return (
    <MDBTableBody>
      {
        !rowsToShow || !rowsToShow.length
          ? <tr><td>{placeholder || 'Elements not found'}</td></tr>
          : (
            rowsToShow.map(item => (
              <Row
                key={item.value || item._id}
                columns={columns}
                item={item}
                enableActionsCol={enableActionsCol}
                ActionElement={ActionElement}
              />
            ))
          )
      }
    </MDBTableBody>
  );
};

export default Body;
