import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";

export const useDataTable = (entity, options = {}) => {
  const [pending, setPending] = React.useState(true);
  const [initial, setInitial] = useState(true)
  const [data, setData] = useState({})
  const [page, setPage] = useState(options.page ?? 1)
  const [perPage, setPerPage] = useState(options.perPage ?? 5)
  const [sortBy, setSortBy] = useState(options.sortBy ?? 'updateAt')
  const [sortDirection, setSortDirection] = useState(options.sortDirection ?? -1)
  const [filters, setFilters] = useState({})

  const { columns, isReload } = options

  const params = {
    page,
    sortBy,
    sortDirection,
    perPage,
    filters
  }

  const refetch = useCallback(() => {
    setPending(true)
    axios.get(entity, {params})
      .then(res => res.data)
      .then(setData)
      .catch(() => setData({}))
  }, [entity, params])

  const context = {
    refetch,
    columns,
    data,
    page,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    perPage,
    setPerPage,
    setFilters,
    pending
  }

  useEffect(() => initial && setInitial(false), [initial])

  useEffect(() => {
    if(!options.actions) return;
    columns.push({
      button: true,
      width: '150px',
      center: true,
      cell: options.actions
    })
  }, [options.actions])

  useEffect(() => {
    if(initial) return;

    setPending(false)
  }, [data, initial])

  useEffect(() => {
    if(initial) return;

    setPage(1)
  }, [sortBy, sortDirection, perPage, filters, initial])

  useEffect(() => {
    refetch()
  }, [page, perPage, filters, sortBy, sortDirection, isReload])

  return {...context, context}
}