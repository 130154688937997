/* eslint-disable import/no-cycle */
import React from 'react';
import MessageRateModal from '../MessageRateModal';
import PortalContainer from '../Portal';
import ResetPassword from './ResetPasswordModal';
import UnsubscribeFromNotifications from './UnsubscribeFromNotificationsModal';
import LoginModal from './LoginModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import SignUpModal from './SignUpModal';
import ContactUsModal from '../ContactUsModal';
import VerifyAccountModal from "./VerifyAccountModal";

const HomeModal = ({ mainContainer }) => (
  mainContainer
    ? (
      <>
        <PortalContainer parentContainer={mainContainer} modalName="messageRateModal">
          <MessageRateModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="resetPassword">
          <ResetPassword />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="unsubscribeFromNotifications">
          <UnsubscribeFromNotifications />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="loginModal">
          <LoginModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="forgotPasswordModal">
          <ForgotPasswordModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="verifyAccountModal">
          <VerifyAccountModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="signUpModal">
          <SignUpModal />
        </PortalContainer>
        <PortalContainer parentContainer={mainContainer} modalName="contactUsModal">
          <ContactUsModal />
        </PortalContainer>
      </>
    )
    : null
);

export default HomeModal;
