import React from 'react';
import { MDBTableHead } from 'mdbreact';
import Sel from './Sel';

const Headers = ({
  columns,
  handlerSort,
  enableActionsCol,
  sortBy,
}) => (
  <MDBTableHead color="mdb-color">
    <tr>
      {
        columns.map(item => (
          <Sel
            key={item.value}
            item={item}
            handlerSort={handlerSort}
            sortBy={sortBy}
          />
        ))
      }
      {
        enableActionsCol
          ? <th> </th>
          : <></>
      }
    </tr>
  </MDBTableHead>
);

export default Headers;
