import React from 'react';
import {MDBRow} from 'mdbreact';
import {errorActions} from '../../../../utils/constans';
import ControlErrorButton from "./ControlErrorButton";

const ErrorModalControlErrorElements = ({error, history, toggleModal}) => {
    const currentErrorAction = errorActions[error.message];

    const getAction = () => {
        switch (currentErrorAction.actionType) {
            case 'modal': return toggleModal;
            default: return () => {}
        }
    }

    const getElement = () => {
        switch (currentErrorAction.elementType){
            case 'button':
                return <ControlErrorButton
                    onClick={() => currentErrorAction.action(getAction())}
                    label={currentErrorAction.label}
                />
            default: return <></>
        }
    }

    return currentErrorAction
        ? <MDBRow style={{ justifyContent: 'center' }}>
            {getElement()}
        </MDBRow>
        : <></>
}

export default ErrorModalControlErrorElements;