// Date picker filter function. Increa max year by 10 years from current year
export const startDateFilter = (date) => {
  const pickedYear = new Date(date).getFullYear();
  return pickedYear < new Date().getFullYear() + 10;
};

export const endDateFilter = (date, startDate) => {
  const pickedYear = new Date(date).getFullYear();
  return startDate
    ? pickedYear < new Date(startDate).getFullYear() + 10
    : pickedYear < new Date().getFullYear() + 10;
};
