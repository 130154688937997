import React, { useEffect, useState } from 'react';
import { MDBRow } from 'mdbreact';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ExplanationInputModal from '../../modal/ExplenationInputModal';
import IconElement from './IconElement';
import prepareFilters from '../../../utils/prepareFilters';
import { getStatus } from '../../../utils/api/get/getWorkOrders';
import { toggleModal, toggleSecondModalClose } from '../../../storage/actions/modalsActions';
import {
  editOnHoldStatusWorkOrder,
  deleteWorkOrderDetails,
  cancelWorkOrder,
} from '../../../storage/actions/workOrdersActions';

const BulkElements = ({
  selectedWorkOrders,
  editOnHoldStatusWorkOrder,
  toggleModal,
  toggleSecondModalClose,
  activeTab,
  deleteWorkOrderDetails,
  history,
  cancelWorkOrder,
  search,
  filters,
  workOrders,
  profile
}) => {
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState('useIds');
  const [requestData, setRequestData] = useState({});

  useEffect(() => {
    const newRequestData = mode === 'useFilters'
      ? {
        ...search,
        filters: prepareFilters(filters),
      }
      : { workOrdersId: selectedWorkOrders };
    setRequestData({ ...newRequestData, ...getStatus(activeTab) });
  }, [mode, search, filters, activeTab, selectedWorkOrders]);

  const getReasonForCancelWorkOrder = async reason => {
    await cancelWorkOrder({ ...requestData, reason });
    toggleSecondModalClose('explanationInputModal', true);
    setShowModal(false);
  };

  const openModalToGetReason = () => {
    setShowModal(true);
    toggleModal(true, 'explanationInputModal', true, 'bulkCancel');
  };

  useEffect(() => {
    setMode(selectedWorkOrders?.length ? 'useIds' : 'useFilters');
  }, [selectedWorkOrders, activeTab]);

  return (
    <MDBRow className="flex-align-center" style={{ width: 'max-content' }}>
      {
        activeTab === 'assigned'
          ? (
            <>
              <IconElement
                mode={mode}
                title={`Cancel Hold${mode === 'useFilters' ? ', All In Tab' : ''}`}
                far
                icon="hand-rock"
                onClick={() => editOnHoldStatusWorkOrder({
                  ...requestData,
                  onHold: false,
                })}
              />
              <IconElement
                mode={mode}
                title={`Hold${mode === 'useFilters' ? ' All In Tab' : ''}`}
                far
                icon="hand-paper"
                onClick={() => editOnHoldStatusWorkOrder({
                  ...requestData,
                  onHold: true,
                })}
              />
              <IconElement
                mode={mode}
                title={`Send Message${mode === 'useFilters' ? ' to All In Tab' : ''}`}
                far
                icon="comment"
                onClick={() => toggleModal(true, 'bulkMessageModal', false, { mode, workOrders })}
              />
              <IconElement mode={mode} title="Cancel WO" icon="ban" onClick={openModalToGetReason} />
              {
                showModal
                  ? (
                    <ExplanationInputModal
                      returnExplanation={getReasonForCancelWorkOrder}
                      title={`Reason to cancel work order${mode === 'useFilters' ? ', All In Tab' : ''}`}
                      type="bulkCancel"
                      isCloseAvailable
                    />
                  )
                  : <></>
              }
              <IconElement
                mode={mode}
                title={`Un-assign WO${mode === 'useFilters' ? ', All In Tab' : ''}`}
                icon="user-times"
                onClick={() => toggleModal(true, 'unAssignModal', false, requestData)}
              />
            </>
          )
          : <></>
      }
      {
        activeTab === 'draft' || activeTab === 'available' || activeTab === 'routed'
          ? (
            <>
              <IconElement
                mode={mode}
                title={`Delete WO${mode === 'useFilters' ? ', All In Tab' : ''}`}
                far
                icon="trash-alt"
                onClick={() => deleteWorkOrderDetails(requestData, history)}
              />
              <IconElement
                mode={mode}
                title={`Route WO${mode === 'useFilters' ? ', All In Tab' : ''}`}
                far
                icon="paper-plane"
                onClick={() => toggleModal(
                  true,
                  'bulkWorkOrderRouteContractorModal',
                  false,
                  { mode },
                )}
              />
            </>
          )
          : <></>
      }
      {
        activeTab === 'draft' || activeTab === 'available' || activeTab === 'routed' || activeTab === 'assigned'
          ? (
            <IconElement
              mode={mode}
              title={`Edit WO${mode === 'useFilters' ? ', All In Tab' : ''}`}
              icon="pencil-alt"
              onClick={() => toggleModal(true, 'bulkEditWorkOrders', false, { mode })}
            />
          )
          : <></>
      }
      {
        activeTab === 'completed' && profile?.clientUser?.accessctrl !== "Dispatcher"
          ? (
            <>
              <IconElement
                mode={mode}
                title={`Approve${mode === 'useFilters' ? ' All In Tab' : ''}`}
                icon="dollar-sign"
                onClick={() => toggleModal(true, 'bulkWorkOrderApproveModal', false, { mode })}
              />
            </>
          )
          : <></>
      }
      {
        activeTab === 'available'
          ? (
            <>
              <IconElement
                mode={mode}
                title={`Assign WO${mode === 'useFilters' ? ', All In Tab' : ''}`}
                icon="user-plus"
                onClick={() => toggleModal(true, 'bulkWorkOrderAssignContractorModal', false, { mode })}
              />
            </>
          )
          : <></>
      }
      {
        activeTab === 'available' || activeTab === 'routed' || activeTab === 'completed'
          ? (
              <IconElement
                mode={mode}
                title={`Send Message${mode === 'useFilters' ? ' to All In Tab' : ''}`}
                far
                icon="comment"
                onClick={() => toggleModal(true, 'bulkMessageModal', false, { mode, workOrders })}
              />
          )
          : <></>
      }

      {
        activeTab === 'approved' && profile?.clientUser?.accessctrl !== "Dispatcher"
          ? (
            <>
              <IconElement
                mode={mode}
                title={`Mark selected wo's as Paid${mode === 'useFilters' ? ', All In Tab' : ''}`}
                icon="hand-holding-usd"
                onClick={() => toggleModal(true, 'bulkWorkOrderPay', false, { mode })}
              />
            </>
          )
          : <></>
      }
    </MDBRow>
  );
};

const mapDispatchToProps = {
  toggleModal,
  toggleSecondModalClose,
  editOnHoldStatusWorkOrder,
  deleteWorkOrderDetails,
  cancelWorkOrder,
};

const mapStateToProps = state => ({
  filters: state.workOrder?.filters,
  search: state.workOrder?.searchData,
  activeTab: state.applicationState.activeTab,
  selectedWorkOrders: state.applicationState.selectedWorkOrders,
  profile: state.profile
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BulkElements));
