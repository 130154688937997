import React, { useState } from 'react';
import {
  MDBCol,
  MDBIcon,
  MDBRow,
  MDBTooltip,
} from 'mdbreact';
import ContractorInfoAddEdit from './ContractorInfoAddEdit';

const ContactInfoRow = ({
  contact,
  editContact,
  deleteContact,
  inputClassName,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const editContactInfo = data => {
    editContact(data);
    setIsEdit(false);
  };

  return (
    <MDBRow className="h-paddings-half">
      {
        isEdit
          ? (
            <ContractorInfoAddEdit
              contractorToEdit={contact}
              returnContractor={editContactInfo}
              closeEdit={() => setIsEdit(false)}
              inputClassName={inputClassName}
            />
          )
          : (
            <>
              <MDBCol>{contact.fname}</MDBCol>
              <MDBCol>{contact.role}</MDBCol>
              <MDBCol>{contact.email}</MDBCol>
              <MDBCol>{contact.ofc_phn}</MDBCol>
              <MDBCol>{contact.mobile}</MDBCol>
              <MDBCol className="flex-box" style={{ justifyContent: 'center' }}>
                <MDBTooltip
                  domElement
                  tag="span"
                  placement="top"
                >
                  <span>
                    <MDBIcon
                      far
                      icon="edit mr-3"
                      onClick={() => setIsEdit(true)}
                    />
                  </span>
                  <span>Edit</span>
                </MDBTooltip>
                <MDBTooltip
                  domElement
                  tag="span"
                  placement="top"
                >
                  <span>
                    <MDBIcon
                      far
                      icon="trash-alt"
                      onClick={deleteContact}
                    />
                  </span>
                  <span>Delete</span>
                </MDBTooltip>
              </MDBCol>
            </>
          )
      }
    </MDBRow>
  );
};

export default ContactInfoRow;
