import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdbreact';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Notification from '../notification';

const WorkOrderTasks = ({ tasks, handler }) => {
  const [onSiteRequirements, setOnSiteRequirements] = useState(tasks);
  const [isModalOpen, changeModalState] = useState(false);
  const [selectedCategory, changeCategory] = useState('preArrivalRequirements');
  const [newTask, addDescriptionForNewTask] = useState('');
  const SortableGifsContainer = sortableContainer(({ children }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }} className="gifs">{children}</div>
  ));
  const TaskElement = ({ name, index, collection }) => (
    <span style={{ marginBottom: 10, width: 'fit-content' }} className="chip">
      {name}
      <MDBIcon
        icon="times-circle"
        size="lg"
        onClick={() => {
          setOnSiteRequirements({
            ...onSiteRequirements,
            [collection]: onSiteRequirements[collection].filter((el) => el.index !== index),
          });
          Notification('success', { message: 'Task was deleted' });
        }}
        style={{ color: '#41566a', cursor: 'pointer', paddingLeft: '2px' }}
      />
    </span>
  );
  const addNewTask = () => {
    if (!newTask || !selectedCategory) {
      Notification('warning', { message: 'Please enter a requirement.' });
    } else {
      Notification('success', { message: 'Task was added' });
      setOnSiteRequirements({
        ...onSiteRequirements,
        [selectedCategory]: [
          ...onSiteRequirements[selectedCategory],
          { name: newTask, checked: false, index: onSiteRequirements[selectedCategory].length },
        ],
      });
      addDescriptionForNewTask('');
    }
  };
  const SortableGif = sortableElement(({ element: { name, index, collection } }) => (
    <TaskElement name={name} index={index} collection={collection} />
  ));
  const onSortEnd = ({ oldIndex, newIndex, collection }) => {
    setOnSiteRequirements({
      ...onSiteRequirements,
      [collection]: arrayMove(onSiteRequirements[collection], oldIndex, newIndex)
        .reduce((acc, el, index) => {
          acc.push({ ...el, index });
          return acc;
        }, []),
    });
  };
  useEffect(() => {
    setOnSiteRequirements(tasks);
  }, [tasks]);
  useEffect(() => {
    handler(onSiteRequirements);
  }, [onSiteRequirements]);
  return (
    <div className="full-width">
      <MDBBtn color="primary" className="cancle_button" onClick={() => changeModalState(!isModalOpen)}>
        Add Required Steps
      </MDBBtn>
      <MDBRow>
        <MDBCol md="12">
          <MDBTable className="ccwo_table">
            <MDBTableHead>
              <tr>
                <th>Pre Arrival Requirements</th>
                <th>On Site Requirements</th>
                <th>Completion Requirements</th>
              </tr>
            </MDBTableHead>
            {onSiteRequirements && (
              <MDBTableBody>
                <tr>
                  <td>
                    <SortableGifsContainer onSortEnd={onSortEnd} distance={1}>
                      {onSiteRequirements.preArrivalRequirements.map((el, i) => (
                        <SortableGif
                          index={i}
                          /* eslint-disable-next-line react/no-array-index-key */
                          key={`key-${i}`}
                          element={{ ...el, collection: 'preArrivalRequirements' }}
                          collection="preArrivalRequirements"
                        />
                      ))}
                    </SortableGifsContainer>
                  </td>
                  <td>
                    <SortableGifsContainer onSortEnd={onSortEnd} distance={1}>
                      {onSiteRequirements.onSiteRequirements.map((el, i) => (
                        <SortableGif
                          index={i}
                          /* eslint-disable-next-line react/no-array-index-key */
                          key={i}
                          element={{ ...el, collection: 'onSiteRequirements' }}
                          collection="onSiteRequirements"
                        />
                      ))}
                    </SortableGifsContainer>
                  </td>
                  <td>
                    <SortableGifsContainer onSortEnd={onSortEnd} distance={1}>
                      {onSiteRequirements.completionRequirements.map((el, i) => (
                        <SortableGif
                          index={i}
                          /* eslint-disable-next-line react/no-array-index-key */
                          key={i}
                          element={{ ...el, collection: 'completionRequirements' }}
                          collection="completionRequirements"
                        />
                      ))}
                    </SortableGifsContainer>
                  </td>
                </tr>
              </MDBTableBody>
            )}
          </MDBTable>
        </MDBCol>
      </MDBRow>
      <MDBModal
        className="add_clientpop"
        isOpen={isModalOpen}
        toggle={() => {
          addDescriptionForNewTask('');
          changeModalState(!isModalOpen);
        }}
        fullHeight
        position="right"
      >
        <MDBModalBody>
          <MDBRow style={{ marginBottom: '15px' }}>
            <MDBCol md="6" style={{ textAlign: 'left' }}>
              <h6 style={{ fontWeight: 'bold', marginBottom: '15px' }}>Add Requirement</h6>
            </MDBCol>
            <MDBCol md="6" style={{ textAlign: 'end' }}>
              <MDBIcon
                icon="times-circle"
                size="lg"
                style={{ color: '#41566a', cursor: 'pointer' }}
                onClick={() => {
                  addDescriptionForNewTask('');
                  changeModalState(!isModalOpen);
                }}
              />
            </MDBCol>
          </MDBRow>
          <div className="d-flex flex-column">
            <div className="font12">Select Requirement Type</div>
            <select
              style={{ marginTop: '.5rem' }}
              className="browser-default custom-select selectac"
              onChange={(e) => changeCategory(e.target.value)}
              value={selectedCategory}
              placeholder="Select"
            >
              <option value="preArrivalRequirements" name="type">
                Pre Arrival Requirements
              </option>
              <option value="onSiteRequirements" name="type">
                On Site Requirements
              </option>
              <option value="completionRequirements" name="type">
                Completion Requirements
              </option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="name" style={{ marginBottom: 0 }}>
              Requirement
              <div className="d-flex" style={{ marginTop: '.5rem' }}>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Custom Field Name"
                  value={newTask}
                  onChange={(e) => addDescriptionForNewTask(e.target.value)}
                  style={{ backgroundColor: '#182837', width: '240px' }}
                />
              </div>
            </label>
          </div>
          <div className="" style={{ textAlign: 'end' }}>
            <MDBBtn
              color="secondary"
              onClick={() => {
                addDescriptionForNewTask('');
                changeModalState(!isModalOpen);
              }}
              className="clientclose"
            >
              Close
            </MDBBtn>
            <MDBBtn
              color="primary"
              className="clientsave"
              onClick={addNewTask}
            >
              add
            </MDBBtn>
          </div>
        </MDBModalBody>
      </MDBModal>
    </div>
  );
};

export default WorkOrderTasks;
