import React, { useEffect, useState } from 'react';
import { MDBCol } from 'mdbreact';
import { connect } from 'react-redux';
import { Field, formValueSelector, change } from 'redux-form';
import InputField from '../../layout/reduxForm/customFields/InputField';
import { fetchCountries, fetchStatesOfCountry } from '../../../storage/actions/workOrdersActions';
import ReactSelectField from '../../layout/reduxForm/customFields/ReactSelectField';
import findOption from '../../../utils/addressSection/findOption';
import handlerValidateZipCode from '../../../utils/addressSection/handlerValidateZipCode';

const createWorkOrderReduxFormSelector = formValueSelector('createWorkOrderReduxForm');

const AddressSection = ({
  selectedState,
  country,
  change,
  fetchCountries,
  fetchStatesOfCountry,
  isRemote
}) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const getStates = async countryId => {
    const newStates = await fetchStatesOfCountry(countryId);
    setStates(newStates);
    if (!newStates.some(item => (
      item.isoCode === selectedState?.value
      || item.isoCode === selectedState?.label
      || item.label === selectedState?.label
    ))) {
      change('createWorkOrderReduxForm', 'state', '');
    } else if (!selectedState?.value || selectedState?.label) {
      const newState = findOption(newStates, ['label', 'value'], selectedState?.label, selectedState);
      change('createWorkOrderReduxForm', 'state', newState);
    }
  };

  const getCountries = async () => {
    const newCountriesList = await fetchCountries();
    const newCountries = newCountriesList.map(item => ({
      ...item, value: item.isoCode, label: item.name,
    }));
    setCountries(newCountries);
    if (newCountries.length && !country?.value && !country?.label) {
      const newCountry = findOption(newCountries, ['name'], 'United States');
      change('createWorkOrderReduxForm', 'country', newCountry);
    } else if (newCountries.length && !country?.value && country?.label) {
      const newCountry = findOption(newCountries, ['name'], country?.label, country);
      change('createWorkOrderReduxForm', 'country', newCountry);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!countries.length) getCountries();
    if (country?.value) getStates(country.value);
  }, [country]);

  return (
    <>
      <MDBCol md="3">
        <Field
          name="address1"
          component={InputField}
          type="text"
          placeholder="Street Address"
          label="Site Address"
          required={!isRemote}
          className="flex-column font-size-08"
          inputClassName="form-control custom-input-style"
          disabled={isRemote}
        />
      </MDBCol>
      <MDBCol md="9" />
      <MDBCol md="3">
        <Field
          name="address2"
          component={InputField}
          type="text"
          disabled={isRemote}
          placeholder="Bldg, Suite, Etc."
          label=""
          className="flex-column font-size-08"
          inputClassName="form-control custom-input-style"
        />
      </MDBCol>
      <MDBCol md="9" />
      <MDBCol size="12" md="3">
        <Field
          name="city"
          component={InputField}
          type="text"
          disabled={isRemote}
          placeholder="City"
          label=""
          required={!isRemote}
          className="flex-column font-size-08"
          inputClassName="form-control custom-input-style"
        />
      </MDBCol>
      <MDBCol size="12" md="3" className="font-size-08">
        <Field
          name="state"
          component={ReactSelectField}
          className="flex-column"
          placeholder="State"
          options={states}
          required={!isRemote}
          disabled={isRemote}
        />
      </MDBCol>
      <MDBCol size="12" md="3">
        <Field
          name="zipCode"
          component={InputField}
          type="text"
          placeholder="Zip / Postal Code"
          label=""
          required={!isRemote}
          disabled={isRemote}
          className="flex-column font-size-08"
          inputClassName="form-control custom-input-style"
          validate={value => handlerValidateZipCode(value, country)}
        />
      </MDBCol>
      <MDBCol size="12" md="3" className="font-size-08">
        <Field
          name="country"
          component={ReactSelectField}
          className="flex-column"
          placeholder="Country"
          options={countries}
          required={!isRemote}
          disabled={isRemote}
        />
      </MDBCol>
    </>
  );
};

const mapDispatchToProps = {
  change,
  fetchCountries,
  fetchStatesOfCountry,
};

const mapStateToProps = state => ({
  country: createWorkOrderReduxFormSelector(state, 'country'),
  selectedState: createWorkOrderReduxFormSelector(state, 'state'),
  isRemote: createWorkOrderReduxFormSelector(state, 'isRemote'),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressSection);
