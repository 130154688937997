import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';

const Header = () => (
  <MDBRow className="no-margins h-paddings">
    <MDBCol>
      <h4>Manage Companies</h4>
    </MDBCol>
  </MDBRow>
);

export default Header;
