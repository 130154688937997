import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import BasePaySection from './BasePaySection';
import DividedPaySection from './DividedPaySection';
import VariablePaySection from './VariablePaySection';
import FundsInfoSection from './FundsInfoSection';

const PayInfoSection = ({ isWorkOrderAssigned }) => (
  <MDBCol>
    <MDBContainer fluid className="font-size-08">
      <MDBRow className="justify-content-between">
        <BasePaySection isWorkOrderAssigned={isWorkOrderAssigned} />
        <DividedPaySection />
      </MDBRow>
      <MDBRow className="justify-content-between">
        <VariablePaySection isWorkOrderAssigned={isWorkOrderAssigned} />
        <FundsInfoSection />
      </MDBRow>
    </MDBContainer>
  </MDBCol>
);

export default PayInfoSection;
