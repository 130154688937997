/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';
import {
  MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow, MDBInput,
} from 'mdbreact';
import { toggleModal } from '../../../storage/actions/modalsActions';
import { reloadData } from '../../../storage/actions/applicationStateActions';
import { normalizePhoneInput } from '../../../utils/validators';
import Notification from '../../notification';

class SignUpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPass: false,
      showContractorInfo: false,
      showClientInfo: false,
      usertype: 'contractor',
      firstname: '',
      lastname: '',
      email1: '',
      password1: '',
      password2: '',
      isChecked: false,
      compelted: false,
      compelted1: false,
      message_reg: '',
      invite: false,
      email_veri: '',
      address1: '',
      address2: '',
      city: { label: '', value: '' },
      state: {},
      country: {},
      zip: '',
      myArray1: [],
      myArray2: [],
      myArray3: [],
      company: '',
      website: '',
      phone: '',
      company_name: '',
      referredPersonName: '',
    };
    this.typeHandler = this.typeHandler.bind(this);
    this.StateHandler2 = this.StateHandler2.bind(this);
    this.onCityChange = this.onCityChange.bind(this);
  }

  componentDidMount() {
    this.getAllCountries();

    const queryParams = new URLSearchParams(window.location.search)

    if (queryParams.get('SID')) {
      this.setState({ invite: true });
      axios
        .post('/api/auth/fetch_invited_client_users', {
          rand_id: queryParams.get('id'),
        })
        .then((res) => {
          const fetchInvite = res.data.ClientUsers;
          this.props.toggleModal(true, 'signUpModal');
          return this.setState({
            firstname: fetchInvite.firstname,
            lastname: fetchInvite.lastname,
            email1: fetchInvite.email,
            phone: fetchInvite.phone,
            company_name: fetchInvite.company?.name,
            companyId: fetchInvite.companyId,
          });
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('error: ', error);
          Notification('error', {
            message: error.response?.data?.message || error.message,
          });
        });
    }
  }

  onChange = (e) => {
    if (e.target.id === 'phone') {
      const { phone } = this.state;
      const phone1 = normalizePhoneInput(e.target.value, phone);
      this.setState({ [e.target.id]: phone1 });
    } else {
      this.setState({ [e.target.id]: e.target.value });
    }
  };

  getAllCountries() {
    axios
      .get('/api/address/getAllCountries')
      .then((res) => {
        const css = res.data;
        const arr = [];

        for (let i = 0; i < css.length; i++) {
          arr.push({
            value: css[i].isoCode,
            label: css[i].name,
          });
          if (css[i].isoCode === 'US') {
            this.getAllStates(css[i].isoCode);
            this.setState({
              country: {
                value: css[i].isoCode,
                label: css[i].name,
              },
            });
          }
        }
        this.setState({
          myArray1: arr,
        });
      })
      .catch((err) => console.log('error: ', err));
  }

  getAllStates(country) {
    axios
      .get('/api/address/getStatesOfCountry', {
        params: { countryId: country },
      })
      .then((res) => {
        this.setState({
          myArray2: res.data?.payload?.states || [],
        });
      })
      .catch((err) => console.log('error: ', err));
  }

  getAllCities(stateId, countryId) {
    axios
      .get('/api/address/getCitiesOfState', {
        params: {
          stateId,
          countryId,
        },
      })
      .then((res) => {
        this.setState({
          myArray3: res.data?.payload?.cities,
        });
      })
      .catch((err) => console.log('error: ', err));
  }

  onCountryChange2 = (e) => {
    this.setState({
      country: e,
    });
    this.getAllStates(e.value);
  };

  StateHandler2 = (e) => {
    const data = {
      value: e.value,
      label: e.label,
    };
    this.setState({
      state: data,
    });
    this.getAllCities(data.value, this.state?.country?.value);
  };

  onCityChange = (e) => {
    const data1 = {
      value: e.target.value,
      label: e.target.value,
    };

    this.setState({
      city: data1,
    });
    // }
  };

  resendLinkForVerify = (email) => {
    if (!email) {
      Notification('warning', {
        message: 'Type your email first!',
      });
    } else {
      this.props.resendVerifyEmail(email);
    }
  };

  toggle = (nr) => {
    this.props.toggleModal(!this.props.modalState.isOpen, 'signUpModal');

    if (nr === 2) {
      this.setState({
        firstname: '',
        lastname: '',
        email1: '',
        phone: '',
        password1: '',
        password2: '',
        address1: '',
        address2: '',
        website: '',
        company: '',
        isChecked: false,
      });
    }
  };

  onCheckedChange = () => {
    const { isChecked } = this.state;
    this.setState({
      isChecked: !isChecked,
    });
  };

  onemailChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
    const email_veri = event.target.value;
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{4,}))$/i;

    if (!re.test(email_veri)) {
      this.setState({ email1: event.target.value });
    } else {
      this.setState({
        email_veri: 'enter valid email',
      });
    }
  };

  onSubmit = (e) => {
    const { state } = this;
    this.setState({
      message_reg: '',
    });

    const queryParams = new URLSearchParams(window.location.search)

    if (!this.state.usertype) {
      this.setState({
        usertype: 'client',
      });
    }

    let newUser = {};
    const userInf = {
      userType: state.usertype,
      firstName: state.firstname,
      lastName: state.lastname,
      email: state.email1,
      password: state.password1,
      confirmPassword: state.password2,
      phone: state.phone,
    };

    e.preventDefault();
    if (!queryParams.get('SID') && state.usertype === 'contractor' && !state.state.label) {
      return this.setState({
        message_reg: 'State is required',
      });
    }
    if (queryParams.get('SID')) {
      newUser = {
        rand_id: queryParams.get('id'),
        userType: 'client',
        website: '',
        companyId: state.companyId,
      };
    } else {
      newUser = {
        company: state.usertype === 'client' ? state.company : undefined,
        website: state.usertype === 'client' ? state.website : undefined,
        address: state.usertype === 'contractor' ? state.address1 : undefined,
        address2: state.usertype === 'contractor' ? state.address2 : undefined,
        country: state.country,
        state: state.state,
        city: state.city,
        zipcode: state.zip,
        referredPersonName: state.referredPersonName ?? undefined,
      };
    }

    axios
      .post('/api/auth/register', { ...userInf, ...newUser })
      .then((res) => {
        const data1 = res.data;
        const data = res.data.message;
        if (data1.success === true) {
          this.registerEmail = state.email1;
          setTimeout(() => {
            this.setState({
              message_reg: data,
              firstname: '',
              lastname: '',
              email1: '',
              phone: '',
              password1: '',
              password2: '',
              address1: '',
              address2: '',
              website: '',
              company: '',
              country: { value: '231', label: 'United States' },
              state: {},
              city: {},
              zip: '',
              isChecked: false,
            });
            if (queryParams.get('SID')) {
              this.props.toggleModal(true, 'loadingModal');
            }
          }, 1000);
        } else {
          setTimeout(() => {
            this.setState({
              message_reg: data,
            });
          }, 1000);
        }
      })
      .catch((error) => {
        Notification('error', {
          message: error.response?.data?.message || error.message,
        });
      });
  };

  onContinue(e) {
    e.preventDefault();
    if (this.state.usertype === 'contractor') {
      this.setState({
        showContractorInfo: true,
        showClientInfo: false,
      })
    } else {
      this.setState({
        showContractorInfo: false,
        showClientInfo: true,
      })
    }
  }

  typeHandler = (e, data) => {
    e.preventDefault();
    if (data === 'contractor') {
      this.setState({
        usertype: data,
        compelted: true,
        compelted1: false,
      });
    } else {
      this.setState({
        usertype: data,
        compelted: false,
        compelted1: true,
      });
    }
  };

  render() {
    const isEnabled = this.state.isChecked === true;
    const options = this.state.myArray1;
    const options2 = this.state.myArray2;
    const options3 = this.state.myArray3;
    const { state } = this;
    const { modalState } = this.props;

    return (
      <MDBModal
        isOpen={modalState.isOpen}
        toggle={() => this.toggle(2)}
        centered
        className="register_div"
        wrapClassName="modal-overflow"
      >
        <MDBRow style={{ justifyContent: 'flex-end', paddingRight: '0px' }}>
          <MDBIcon far icon="times-circle" onClick={() => this.toggle(2)} size="lg" />
        </MDBRow>
        <MDBModalBody>
          <div className="headingdivr">
            <h2>{state.invite ? 'Complete Your Registration' : 'Create Your Free Account'}</h2>
          </div>
          {!state.showClientInfo && !state.showContractorInfo &&
            <div>
              <div className="subHeadingdiv">
                {state.invite ? `You have been invited by "${state.company_name}"` : 'I want to:'}
              </div>
            </div>
          }
          <div>
            {!state.invite && !state.showClientInfo && !state.showContractorInfo ? (
              <div className="ccbutton d-flex">
                <MDBBtn
                  className={`${state.compelted && 'cobutton12'} cobutton`}
                  name={state.contractor}
                  value="contractor"
                  onClick={(e) => this.typeHandler(e, 'contractor')}
                  required
                >
                  Find Work
                </MDBBtn>
                <MDBBtn
                  className={`${state.compelted1 && 'cobutton12'} cibutton`}
                  name={state.client}
                  value="client"
                  onClick={(e) => this.typeHandler(e, 'client')}
                  required
                >
                  Hire a Contractor
                </MDBBtn>
              </div>
            ) : (
              <></>
            )}
          </div>
          {(state.showContractorInfo || state.invite) && this.contractorInfo({
            state,
            isEnabled,
            options,
            options2,
            options3,
          })}
          {state.showClientInfo && this.clientInfo(
            {
              state,
              isEnabled,
              options,
              options2,
              options3,
            }
          )}


          <MDBRow />
          <div style={{ color: 'red', textAlign: 'center' }}>
            <p>{state.message_reg}</p>
            {state.message_reg ===
              'Registration Success. Please check your email to activate your account.' && (
                <p
                  style={{
                    cursor: 'pointer',
                    color: 'rgb(38, 52, 113)',
                    textDecorationLine: 'underline',
                    fontSize: 12,
                  }}
                  onClick={() => this.resendLinkForVerify(this.registerEmail)}
                >
                  Resend Email
                </p>
              )}
          </div>
          {!state.showClientInfo && !state.showContractorInfo && !state.invite ?
            <MDBRow style={{ justifyContent: 'center' }}>
              <MDBBtn
                color="info"
                className="signbutton"
                type="submit"
                onClick={(e) => this.onContinue(e)}
                disabled={!state.compelted && !state.compelted1}
              >
                Continue
              </MDBBtn>
            </MDBRow> :
            <>
              <div className="checkboxdivres">
                <MDBInput
                  className="regcheck"
                  label="."
                  filled
                  type="checkbox"
                  id="checkbox"
                  name="check"
                  defaultChecked={state.check}
                  onChange={this.onCheckedChange}
                />
                <span className="font12">
                  I have read and agreed to the{' '}
                  <a
                    href="https://www.sourcew9.com/SW9_Terms_Updated_2023.03.pdf"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textDecorationLine: 'underline',
                      color: '#e87b68',
                    }}
                  >
                    Term of Service
                  </a>
                  {', '}
                  <a
                    href="https://www.sourcew9.com/Privacy_Policy.pdf"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textDecorationLine: 'underline',
                      color: '#e87b68',
                    }}
                  >
                    Customer Privacy Policy
                  </a>
                </span>
              </div>
              <MDBRow style={{ justifyContent: 'center' }}>
                <MDBBtn
                  color="info"
                  className="signbutton"
                  type="submit"
                  onClick={this.onSubmit}
                  disabled={!isEnabled}
                >
                  Agree & Sign up
                </MDBBtn>
              </MDBRow>
            </>
          }
        </MDBModalBody>
      </MDBModal>
    );
  }

  clientInfo({
    state,
    options,
    options2,
  }) {
    return (
      <>

        <MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <div className="form-group registeinput text-align-center">
                <label htmlFor="firstname" className="font12">
                  First Name
                </label>
                <input
                  label="First Name"
                  disabled={state.invite}
                  type="text"
                  className="form-control"
                  id="firstname"
                  name={state.firstname}
                  value={state.firstname}
                  required
                  onChange={this.onChange}
                />
              </div>
            </MDBCol>
            <MDBCol md="6">
              <div className="form-group registeinput text-align-center">
                <label htmlFor="lastname" className="font12">
                  Last Name
                </label>
                <input
                  disabled={state.invite}
                  type="text"
                  className="form-control"
                  id="lastname"
                  name={state.lastname}
                  value={state.lastname}
                  required
                  onChange={this.onChange}
                />
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">
              <div className="form-group registeinput text-align-center">
                <label htmlFor="email" className="font12 giantLabel">
                  Email
                </label>
                <input
                  disabled={state.invite}
                  type="email"
                  className="form-control"
                  id="email1"
                  name={state.email1}
                  value={state.email1}
                  required
                  onChange={(event) => this.onemailChange(event)}
                />
              </div>
              <span>{state.email_veri}</span>
            </MDBCol>
            <MDBCol md="6">
              <div className="form-group registeinput text-align-center">
                <label htmlFor="phone" className="font12">
                  Contact Phone
                </label>
                <input
                  disabled={state.invite}
                  type="text"
                  className="form-control"
                  id="phone"
                  name={state.phone}
                  value={state.phone}
                  required
                  onChange={this.onChange}
                />
              </div>
              <span>{state.phone_veri}</span>
            </MDBCol>
          </MDBRow>
          
          {!state.invite && (
            <MDBRow>
              <MDBCol md="6">
                <div className="form-group registeinput text-align-center">
                  <label htmlFor="company" className="font12">
                    Company
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="company"
                    name={state.company}
                    value={state.company}
                    required
                    onChange={this.onChange}
                    disabled={state.companyId}
                  />
                </div>
              </MDBCol>
              <MDBCol md="6">
                <div className="form-group registeinput text-align-center">
                  <label htmlFor="website" className="font12">
                    Website
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="website"
                    name={state.website}
                    value={state.website}
                    required
                    onChange={this.onChange}
                  />
                </div>
              </MDBCol>
            </MDBRow>
          )}
          <MDBRow>
            <MDBCol md="6">
              <div className="form-group registeinput text-align-center">
                <label htmlFor="password1" className="font12">
                  Password
                </label>
                {state.showPass ? (
                  <MDBIcon
                    icon="eye-slash"
                    onClick={() => this.setState({ showPass: false })}
                    style={{
                      position: 'absolute',
                      top: '40px',
                      right: '20px',
                    }}
                  />
                ) : (
                  <MDBIcon
                    icon="eye"
                    onClick={() => this.setState({ showPass: true })}
                    style={{
                      position: 'absolute',
                      top: '40px',
                      right: '20px',
                    }}
                  />
                )}
                <input
                  type={`${state.showPass ? 'text' : 'password'}`}
                  className="form-control"
                  id="password1"
                  name={state.password1}
                  value={state.password1}
                  required
                  onChange={this.onChange}
                />
              </div>
            </MDBCol>
            <MDBCol md="6">
              <div className="form-group registeinput text-align-center">
                <label htmlFor="password2" className="font12">
                  Confirm Password
                </label>
                {state.showPass ? (
                  <MDBIcon
                    icon="eye-slash"
                    onClick={() => this.setState({ showPass: false })}
                    style={{
                      position: 'absolute',
                      top: '40px',
                      right: '20px',
                    }}
                  />
                ) : (
                  <MDBIcon
                    icon="eye"
                    onClick={() => this.setState({ showPass: true })}
                    style={{
                      position: 'absolute',
                      top: '40px',
                      right: '20px',
                    }}
                  />
                )}
                <input
                  type={`${state.showPass ? 'text' : 'password'}`}
                  className="form-control"
                  id="password2"
                  name={state.password2}
                  value={state.password2}
                  required
                  onChange={this.onChange}
                />
              </div>
            </MDBCol>
          </MDBRow>
        </MDBRow>
        <div className="form-group registeinput text-align-center">
          <label htmlFor="referredPersonName" className="font12">
            Name of person who referred you?
          </label>
          <input
            type="text"
            className="form-control"
            id="referredPersonName"
            name={state.referredPersonName}
            value={state.referredPersonName}
            required
            onChange={this.onChange}
          />
        </div>

      </>
    )
  }
  contractorInfo({
    state,
    isEnabled,
    options,
    options2,
    options3
  }) {
    return (
      <MDBRow>
        <MDBRow>
          <MDBCol md="6">
            <div className="form-group registeinput text-align-center">
              <label htmlFor="firstname" className="font12">
                First Name
              </label>
              <input
                label="First Name"
                disabled={state.invite}
                type="text"
                className="form-control"
                id="firstname"
                name={state.firstname}
                value={state.firstname}
                required
                onChange={this.onChange}
              />
            </div>
          </MDBCol>
          <MDBCol md="6">
            <div className="form-group registeinput text-align-center">
              <label htmlFor="lastname" className="font12">
                Last Name
              </label>
              <input
                disabled={state.invite}
                type="text"
                className="form-control"
                id="lastname"
                name={state.lastname}
                value={state.lastname}
                required
                onChange={this.onChange}
              />
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6">
            <div className="form-group registeinput text-align-center">
              <label htmlFor="email" className="font12 giantLabel">
                Email
              </label>
              <input
                disabled={state.invite}
                type="email"
                className="form-control"
                id="email1"
                name={state.email1}
                value={state.email1}
                required
                onChange={(event) => this.onemailChange(event)}
              />
            </div>
            <span>{state.email_veri}</span>
          </MDBCol>
          <MDBCol md="6">
            <div className="form-group registeinput text-align-center">
              {state.usertype === 'client' ? (
                <label htmlFor="phone" className="font12">
                  Contact Phone
                </label>
              ) : (
                <label htmlFor="phone" className="font12">
                  Mobile Phone
                </label>
              )}
              <input
                disabled={state.invite}
                type="text"
                className="form-control"
                id="phone"
                name={state.phone}
                value={state.phone}
                required
                onChange={this.onChange}
              />
            </div>
            <span>{state.phone_veri}</span>
          </MDBCol>
        </MDBRow>
        {!state.invite && (
          <>
            <MDBRow>
              <MDBCol>
                <div className="form-group registeinput text-align-center">
                  <label htmlFor="address1" className="font12">
                    Street Address
                  </label>
                  <input
                    className="form-control"
                    id="address1"
                    name={state.address1}
                    value={state.address1}
                    required
                    onChange={this.onChange}
                  />
                </div>
              </MDBCol>
              <MDBCol>
                <div className="form-group registeinput text-align-center">
                  <label htmlFor="address2" className="font12">
                    Suite, Apt, Etc.
                  </label>
                  <input
                    className="form-control"
                    id="address2"
                    name={state.address2}
                    value={state.address2}
                    required
                    onChange={this.onChange}
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="registercol5">
                <div className="form-group registeinput  text-align-center">
                  <label htmlFor="city" className="font12">
                    City
                  </label>
                  <input
                    type="text"
                    onChange={this.onCityChange}
                    value={state.city.label}
                    className="form-control"
                  />
                </div>
              </MDBCol>
              <MDBCol className="registercol4">
                <div className="form-group registeinput text-align-center">
                  <label htmlFor="state" className="font12">
                    State
                  </label>
                  <Select
                    isSearchable
                    options={options2}
                    styles={{
                      singleValue: (styles) => ({
                        ...styles,
                        color: '#000000',
                        fontSize: 15,
                      }),
                      control: (styles) => ({
                        ...styles,
                        backgroundColor: '#ffffff',
                        borderColor: 'rgb(206, 212, 218)',
                        height: 30,
                      }),
                      option: (styles, { isFocused, isSelected }) => ({
                        ...styles,
                        fontWeight: isFocused ? 'bold' : 'normal',
                        backgroundColor: isSelected ? '#e6e3e3' : 'white',
                        color: '#000000',
                        fontSize: 15,
                      }),
                    }}
                    onChange={this.StateHandler2}
                    value={state.state}
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="registercol4">
                <div className="form-group registeinput text-align-center">
                  <label htmlFor="country" className="font12">
                    Country
                  </label>
                  <Select
                    options={options}
                    onChange={this.onCountryChange2}
                    styles={{
                      singleValue: (styles) => ({
                        ...styles,
                        color: '#000000',
                        fontSize: 15,
                      }),
                      control: (styles) => ({
                        ...styles,
                        backgroundColor: '#ffffff',
                        borderColor: 'rgb(206, 212, 218)',
                        height: 30,
                      }),
                      option: (styles, { isFocused, isSelected }) => ({
                        ...styles,
                        fontWeight: isFocused ? 'bold' : 'normal',
                        backgroundColor: isSelected ? '#e6e3e3' : 'white',
                        color: '#000000',
                        fontSize: 15,
                      }),
                    }}
                    value={
                      state.country !== undefined &&
                        state.country !== null &&
                        state.country.value !== undefined
                        ? state.country
                        : {
                          value: '231',
                          label: 'United States',
                        }
                    }
                  />
                </div>
              </MDBCol>
              <MDBCol>
                <div className="form-group registeinput text-align-center">
                  <label htmlFor="zip" className="font12">
                    Zip
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="zip"
                    name="zip"
                    value={state.zip}
                    required
                    onChange={this.onChange}
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow />
          </>
        )}
        {state.usertype === 'client' && !state.invite && (
          <MDBRow>
            <MDBCol md="6">
              <div className="form-group registeinput text-align-center">
                <label htmlFor="company" className="font12">
                  Company
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="company"
                  name={state.company}
                  value={state.company}
                  required
                  onChange={this.onChange}
                  disabled={state.companyId}
                />
              </div>
            </MDBCol>
            <MDBCol md="6">
              <div className="form-group registeinput text-align-center">
                <label htmlFor="website" className="font12">
                  Website
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="website"
                  name={state.website}
                  value={state.website}
                  required
                  onChange={this.onChange}
                />
              </div>
            </MDBCol>
          </MDBRow>
        )}
        <MDBRow>
          <MDBCol md="6">
            <div className="form-group registeinput text-align-center">
              <label htmlFor="password1" className="font12">
                Password
              </label>
              {state.showPass ? (
                <MDBIcon
                  icon="eye-slash"
                  onClick={() => this.setState({ showPass: false })}
                  style={{
                    position: 'absolute',
                    top: '40px',
                    right: '20px',
                  }}
                />
              ) : (
                <MDBIcon
                  icon="eye"
                  onClick={() => this.setState({ showPass: true })}
                  style={{
                    position: 'absolute',
                    top: '40px',
                    right: '20px',
                  }}
                />
              )}
              <input
                type={`${state.showPass ? 'text' : 'password'}`}
                className="form-control"
                id="password1"
                name={state.password1}
                value={state.password1}
                required
                onChange={this.onChange}
              />
            </div>
          </MDBCol>
          <MDBCol md="6">
            <div className="form-group registeinput text-align-center">
              <label htmlFor="password2" className="font12">
                Confirm Password
              </label>
              {state.showPass ? (
                <MDBIcon
                  icon="eye-slash"
                  onClick={() => this.setState({ showPass: false })}
                  style={{
                    position: 'absolute',
                    top: '40px',
                    right: '20px',
                  }}
                />
              ) : (
                <MDBIcon
                  icon="eye"
                  onClick={() => this.setState({ showPass: true })}
                  style={{
                    position: 'absolute',
                    top: '40px',
                    right: '20px',
                  }}
                />
              )}
              <input
                type={`${state.showPass ? 'text' : 'password'}`}
                className="form-control"
                id="password2"
                name={state.password2}
                value={state.password2}
                required
                onChange={this.onChange}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBRow>
    )
  }
}

const mapDispatchToProps = {
  toggleModal,
  reloadData,
};

const mapStateToProps = (state) => ({
  modalState: state.modalState.signUpModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpModal);
