import React, {useState} from "react";
import {MDBBtn, MDBCol, MDBIcon, MDBRow} from "mdbreact";

const NewMessage = ({sendMessage}) => {
    const [message, setMessage] = useState('');
    return (
        <>
            <MDBRow>
                <MDBCol>
                    Message
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                    <textarea
                        value={message}
                        onChange={ event => setMessage(event.target.value) }
                        style={{
                            resize: 'none',
                            width: '100%',
                            minHeight: '7rem'
                        }}
                    />
                </MDBCol>
            </MDBRow>
            <MDBRow style={{ justifyContent: "flex-end", padding: "10px 10px 5px 0" }} >
                <MDBBtn
                    className='background-green'
                    style={{
                        padding: '0.1rem 0.5rem',
                        color: '#333333',
                        backgroundColor: '#6cff2e'
                    }}
                    color='false'
                    onClick={() => {
                        setMessage('');
                        sendMessage(message)}}
                >
                    <MDBIcon icon='paper-plane' />
                    Send
                </MDBBtn>
            </MDBRow>
        </>
    )
}

export default NewMessage;
