import React from 'react';
import { localCurrencySettings } from '../../../../../../utils/constans';

const TooltipTitle = ({
  amountBase,
  amountVariable,
  basicWorkType,
  variableWorkType,
  bidAmountBase,
  bidAmountVariable,
}) => {
  if (
    bidAmountBase === amountBase
    && (!bidAmountVariable || bidAmountVariable === amountVariable)
  ) {
    return (
      <div>
        <div>
          {`Base ${amountBase.toLocaleString('en-US', localCurrencySettings)} / ${basicWorkType}`}
        </div>
        <div>
          {`Var: ${
            amountVariable
              ? `${amountVariable.toLocaleString('en-US', localCurrencySettings)} / ${variableWorkType}`
              : 'None'
          }`}
        </div>
      </div>
    );
  }
  return (
    <div>
      <div>Counter Offer!</div>
      <div>
        {`Base ${bidAmountBase.toLocaleString('en-US', localCurrencySettings)} / ${basicWorkType}`}
      </div>
      <div>
        {`Var: ${
          bidAmountVariable
            ? `${bidAmountVariable.toLocaleString('en-US', localCurrencySettings)} / ${variableWorkType}`
            : 'None'
        }`}
      </div>
      <br />
      <div>Original Offer!</div>
      <div>
        {`Base ${amountBase.toLocaleString('en-US', localCurrencySettings)} / ${basicWorkType}`}
      </div>
      <div>
        {`Var: ${
          amountVariable
            ? `${amountVariable.toLocaleString('en-US', localCurrencySettings)} / ${variableWorkType}`
            : 'None'
        }`}
      </div>
    </div>
  );
};

export default TooltipTitle;
