import React from 'react';
import ToolsElement from '../../workOrderEditElements/selectWorkOrderWorkTypes/ToolsElement';

const FilterBadge = ({ label, deleteTools }) => (
  <ToolsElement
    label={label}
    deleteTools={deleteTools}
    className="chip"
    deleteIconColor="#41566a"
    labelClassName="text-ellipsis"
    title={label}
  />
);

export default FilterBadge;
