import React, {useState} from "react";
import IssueReportElement from './IssueReportElement';
import AddReportIssue from "./AddReportIssue";
import ReportIssueControlButtons from './ReportIssueControllButtons';

export default ({ user, currentWorkOrder, createNewIssueReport, resolveIssueReport, sendIssueAgain }) => {
    const [showAddReportIssue, setShowAddReportIssue] = useState(false);

    return (
        <>
            {
                currentWorkOrder.report_issue_details && currentWorkOrder.report_issue_details.length > 0
                    && currentWorkOrder.report_issue_details.map(issueReport => (
                        <IssueReportElement 
                            key={issueReport.date} 
                            report={issueReport} 
                            userType={user.userType} 
                            resolveIssueReport={resolveIssueReport}
                            sendIssueAgain={sendIssueAgain}
                        />
                    ))
            }
            {
                showAddReportIssue && <AddReportIssue
                    currentWorkOrder={currentWorkOrder}
                    user={user}
                    createNewIssueReport={createNewIssueReport}
                    setShowAddReportIssue={setShowAddReportIssue}
                />
            }
            {
                user.userType === 'client' && currentWorkOrder.status === 'assigned'
                    && <ReportIssueControlButtons
                        currentWorkOrder={currentWorkOrder}
                        setShowAddReportIssue={setShowAddReportIssue}
                        showAddReportIssue={showAddReportIssue}
                    />
            }
        </>
    )
}