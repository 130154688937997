import React from 'react';
import {
  MDBPagination, MDBPageItem, MDBPageNav, MDBCol, MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import Select from 'react-select';
import {
  setActiveTab,
  setPage,
  setPerPage,
} from '../../storage/actions/applicationStateActions';
import { dark as customSelectStyle } from '../../utils/customSelectStyle';

const PaginationPage = ({
  applicationState, workOrderCountByTabs, setPage,
  setPerPage,
}) => {
  const PageComponent = ({ number }) => (
    <MDBPageItem active={number === applicationState.currentPage}>
      <MDBPageNav aria-label="Previous" onClick={() => {
        window.scrollTo(0, 0);
        return number ? setPage(number) : null;
      }}>
        <span aria-hidden="true" style={{ color: 'white' }}>{number || '...'}</span>
      </MDBPageNav>
    </MDBPageItem>
  );
  const options = [
    {
      value: 10,
      label: 10,
    },
    {
      value: 25,
      label: 25,
    },
    {
      value: 50,
      label: 50,
    },
  ];
  const allPages = (counts, currentPage) => {
    if (counts <= 5 || Number.isNaN(counts)) {
      return Array.from({ length: counts })
        // eslint-disable-next-line react/no-array-index-key
        .map((el, ind) => <PageComponent key={ind + 1} number={ind + 1} />);
    }
    if (currentPage >= 1 && currentPage <= 3) {
      return (
        <>
          {currentPage > 1 ? <PageComponent number={currentPage - 1} /> : null}
          <PageComponent number={currentPage} />
          <PageComponent number={currentPage + 1} />
          <PageComponent />
          <PageComponent number={counts} />
        </>
      );
    }
    return (
      <>
        <PageComponent number={1} />
        <PageComponent />
        <PageComponent number={currentPage - 1} />
        <PageComponent number={currentPage} />
        {(counts - currentPage) > 1 ? <PageComponent number={currentPage + 1} /> : null}
        {(counts - currentPage) > 2 ? <PageComponent /> : null}
        {(counts - currentPage) > 0 ? <PageComponent number={counts} /> : null}
      </>
    );
  };

  const totalWorkOrders = workOrderCountByTabs[applicationState.activeTab];
  const countOfPages = Math.ceil(totalWorkOrders / applicationState.perPage);
  return (
    <MDBRow style={{ marginTop: '5px' }}>
      <MDBCol
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <MDBPagination color="red">
          {applicationState.currentPage > 1 ? (
            <MDBPageItem>
              <MDBPageNav
                disable
                aria-label="Previous"
                onClick={() => {
                  window.scrollTo(0, 0);
                  return setPage(applicationState.currentPage - 1);
                }}
              >
                <span aria-hidden="true" style={{ color: 'white' }}>Previous</span>
              </MDBPageNav>
            </MDBPageItem>
          ) : null}
          {
            allPages(countOfPages, applicationState.currentPage)
          }
          {countOfPages > applicationState.currentPage ? (
            <MDBPageItem>
              <MDBPageNav
                aria-label="Previous"
                onClick={() => {
                  window.scrollTo(0, 0);
                  return setPage(applicationState.currentPage + 1)
                }}
              >
                <span aria-hidden="true" style={{ color: 'white' }}>Next</span>
              </MDBPageNav>
            </MDBPageItem>
          ) : null}
        </MDBPagination>
      </MDBCol>
      <MDBCol md="1" style={{ minWidth: '100px' }}>
        <Select
          styles={customSelectStyle}
          closeMenuOnSelect
          hideSelectedOptions={false}
          options={options}
          onChange={({ value }) => {
            setPerPage(value);
            setPage(1);
          }}
          value={{
            value: applicationState.perPage,
            label: applicationState.perPage,
          }}
        />
      </MDBCol>
    </MDBRow>
  );
};

const mapDispatchToProps = {
  setActiveTab,
  setPage,
  setPerPage,
};

const mapStateToProps = state => ({
  applicationState: state.applicationState,
});

export default connect(mapStateToProps, mapDispatchToProps)(PaginationPage);
