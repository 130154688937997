import React from 'react';

const DeviceQty = ({ value = 0, handler }) => (
  <div style={{ marginTop: '30px' }}>
    <label htmlFor="qryDevices">
      Qty Devices:
    </label>
    <input
      style={{
        width: '50px',
        marginLeft: '10px',
      }}
      id="qryDevices"
      type="number"
      value={value}
      onChange={(event) => handler(event.target.value)}
    />
  </div>
);

export default DeviceQty;
