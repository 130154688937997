import { useLocation } from 'react-router-dom';
import React, { Component } from 'react';
import { MDBModal, MDBModalBody } from 'mdbreact';
import { connect } from 'react-redux';
import { toggleModal } from '../../../storage/actions/modalsActions';
import ResetPasswordReduxForm from '../../layout/reduxForm/ResetPasswordReduxForm';
import handleSubmit from '../../../utils/reduxForm/onSubmit/resetPassword';

class ResetPassword extends Component {
  closeModal = () => {
    const { modalState, toggleModal } = this.props;
    toggleModal(!modalState.isOpen, 'resetPassword');
  };

  render() {
    const { modalState } = this.props;
    const params = new URLSearchParams(window.location.search)

    return (
      <MDBModal isOpen={modalState.isOpen} toggle={this.closeModal} centered className="register_div">
        <MDBModalBody>
          <ResetPasswordReduxForm onSubmit={handleSubmit} id={params.get('id')} />
        </MDBModalBody>
      </MDBModal>
    );
  }
}

const mapStateToProps = (state) => ({
  modalState: state.modalState.resetPassword,
});

export default connect(mapStateToProps, {
  toggleModal,
})(ResetPassword);