import React from 'react';
import {
  MDBModal,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';

const NewApiVersion = ({ modalState }) => (
  <MDBModal
    isOpen={modalState.isOpen}
    position="center"
    centered
    toggle={() => {
    }}
  >
    <div>New Api version is loaded, please refresh page</div>
  </MDBModal>
);

const mapStateToProps = state => ({
  modalState: state.modalState.newApiVersion,
});

export default connect(mapStateToProps)(NewApiVersion);
