import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { MDBContainer } from 'mdbreact';
import { toggleModal } from '../../storage/actions/modalsActions';
import Content from '../../components/fundingSources/Content';
// eslint-disable-next-line import/no-cycle
import Modal from '../../components/modal';
import Header from '../../components/fundingSources/Header';
import getFundingSourcesList from '../../utils/api/get/getFundingSourcesList';
import { deleteFundingSource, makePrimaryFundingSource } from '../../storage/actions/superAdmin';


const FundingSources = ({mainContainer, auth, ... props}) => {
  const context = useFoundingSourceContext(props)

  return (
    <MDBContainer className="clientdascontentmaindiv" fluid>
      <Header />
      <Content {...context} auth={auth}/>
      <Modal mainContainer={mainContainer.current} />
    </MDBContainer>
  );
};

export const useFoundingSourceContext = ({
  toggleModal, isReload, deleteFundingSource, makePrimaryFundingSource,
}) => {
  const [fundingSources, setFundingSources] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchData = async () => {
    setIsLoaded(false);
    toggleModal(true, 'loader');
    const newFundingSourcesList = await getFundingSourcesList('all');
    await setFundingSources(newFundingSourcesList);
    setIsLoaded(true);
    toggleModal(false, 'loader');
  };

  const handlerVerify = fundingSource => {
    toggleModal(true, 'verifyFundingSource', false, { fundingSource });
  };

  const handlerDelete = (fundingSourceId) => {
    deleteFundingSource(fundingSourceId);
  };

  const makePrimary = fundingSourceId => {
    makePrimaryFundingSource(fundingSourceId);
  };

  useEffect(() => {
    fetchData();
  }, [isReload]);

  return {fundingSources, isLoaded, handlerVerify, handlerDelete, makePrimary}
}

const mapDispatchToProps = {
  toggleModal,
  deleteFundingSource,
  makePrimaryFundingSource,
};

const mapStateToProps = (state) => ({
  isReload: state.applicationState.isReload,
  auth: state.auth
});

export default connect(mapStateToProps, mapDispatchToProps)(FundingSources);
