import React from 'react';
import PropTypes from 'prop-types';
import { MDBCol, MDBIcon, MDBRow } from 'mdbreact';

const closeIconStyles = { color: '#41566a', cursor: 'pointer' };

const ModalHeader = ({
  closeModal = null,
}) => (
  <>
    <MDBRow className="margin-bottom-10">
      <MDBCol md="6">
        <h6 className="font-weight-bold">
          Contractor Profile
        </h6>
      </MDBCol>
      <MDBCol md="6" style={{ textAlign: 'end' }}>
        <MDBIcon
          icon="arrow-circle-left"
          size="lg"
          style={closeIconStyles}
          onClick={closeModal}
        />
      </MDBCol>
    </MDBRow>
  </>
);

ModalHeader.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalHeader;
