const values = {
  basicType: 'Hour',
  variableType: 'Hour',
  payOutsideOfApp: false,
  payBaseRateAsMinimum: false,
  canUpload: true,
  documentVisibility: {},
  removePreviousDocuments: true,
  status: 'draft',
};

export default values;
