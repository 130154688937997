import React from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdbreact';

const ContractorCertificatesChecks = ({
  certificates = [],
  textAlign = 'left',
  infoType = 'icon',
  size = '',
  col = '3',
}) => {
  const getTitle = certificate => (`${
    certificate.certificates_info.name
  }${
    certificate.passed_at
      ? ` - ${new Date(certificate.passed_at).toLocaleDateString('en-US', { timeZone: 'UTC' })}`
      : ''
  }
  ${
    certificate.expires_at
      ? ` - Exp: ${new Date(certificate.expires_at).toLocaleDateString('en-US', { timeZone: 'UTC' })}`
      : ''
    }`
  );

  const getInfoElement = (certificate) => {
    switch (infoType) {
      case 'icon': return (
        <MDBIcon
          icon={certificate.certificates_info.icon}
          style={{ marginRight: '0.25rem', ...(certificate.certificates_info.icon_styles || {}) }}
        />
      );
      case 'label': return `${certificate.certificates_info.label} `;
      case 'name': return `${certificate.certificates_info.name} `;
      default: return `${certificate.certificates_info.label} `;
    }
  };

  return (
    <div className="flex-box flex-align-center" style={{ width: size || '10rem', textAlign, fontSize: '0.9rem' }}>
      <MDBRow className="no-margins" style={{ width: '100%', justifyContent: 'center' }}>
        {
          certificates?.map(certificate => (
            <MDBCol
              key={certificate._id}
              md={col}
              style={{
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                color: certificate.certificates_info.color,
                minWidth: 'max-content',
              }}
              title={getTitle(certificate)}
            >
              {getInfoElement(certificate)}
              <MDBIcon icon="check" />
            </MDBCol>
          ))
        }
      </MDBRow>
    </div>
  );
};

export default ContractorCertificatesChecks;
