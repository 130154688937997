import "react-datepicker/dist/react-datepicker.css";
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {toggleModal} from '../../storage/actions/modalsActions';
import {ResourceDataTable} from "../../components/datatables/ResourceDataTable";
import Modal from '../../components/modal';
import {
  MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBRow,
} from 'mdbreact';
import {
  datatableManagerFundsColumnsForSuperAdmin as columns,
} from '../../utils/constans';

const ManageFunds = ({
  toggleModal, mainContainer, isReload,
}) => {
  return (
    <MDBContainer className="clientdascontentmaindiv" fluid>
      <MDBRow>
        <MDBCol style={{ textAlign: 'center' }}>
          Transaction History
        </MDBCol>
        <MDBCol style={{ flexGrow: 0 }}>
          <MDBBtn
            className="background-green flex-box flex-align-center text-nowrap"
            onClick={() => toggleModal(true, 'addFundsToDwollaBalance')}
          >
            <MDBIcon far className="vwimg" icon="credit-card" />
            Add funds
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <ResourceDataTable
            entity="/api/dwolla/history"
            columns={columns}
            Filters={Filters}
            isReload={isReload}/>
        </MDBCol>
      </MDBRow>
      <Modal mainContainer={mainContainer.current} />
    </MDBContainer>
  )
};

const Filters = ({ onChange = (_) => void 0, value: filters = {}, reset = false }) => {
  const [amount, setAmount] = useState( filters.amount || '')
  const [amountMin, setAmountMin] = useState( filters.amount?.$gte || '')
  const [amountMax, setAmountMax] = useState( filters.amount?.$lte || '')

  const [createdMin, setCreatedMin] = useState(filters.created?.$gte || '')
  const [createdMax, setCreatedMax] = useState(filters.created?.$lte || '')
  const [updatedMin, setUpdatedMin] = useState(filters.updated?.$gte || '')
  const [updatedMax, setUpdatedMax] = useState(filters.updated?.$lte || '')

  useEffect(resetFilters, [reset])

  useEffect(() => setAmount(''), [amountMin, amountMax])
  useEffect(() => {
    setAmountMin('')
    setAmountMax('')
  }, [amount])

  useEffect(() => {
    let filters = {
      ... (amount !== ''? {amount: +amount}: {}),
      ... (amountMin !== '' || amountMax !== ''? {amount: {
          ... (amountMin !== ''? {$gte: +amountMin}: {}),
          ... (amountMax !== ''? {$lte: +amountMax}: {})
      }}: {}),
      ... (createdMin !== '' || createdMax !== ''? {createdAt: {
        ... (createdMin !== ''? {$gte: createdMin}: {}),
        ... (createdMax !== ''? {$lte: createdMax}: {})
      }}: {}),
      ... (updatedMin !== '' || updatedMax !== ''? {updateAt: {
        ... (updatedMin !== ''? {$gte: updatedMin}: {}),
        ... (updatedMax !== ''? {$lte: updatedMax}: {})
      }}: {}),
    }

    onChange(filters)
  }, [amount, amountMin, amountMax, createdMin, createdMax, updatedMin, updatedMax])

  function resetFilters () {
    setAmount('')
    setAmountMin('')
    setAmountMax('')
    setCreatedMin('')
    setCreatedMax('')
    setUpdatedMin('')
    setUpdatedMax('')
  }

  return (<>
    <h5 className="mb-3">Amount</h5>
    <div className="mb-3">
      <input
        type="number"
        placeholder="SEARCH AMOUNT"
        className="form-control"
        value={amount}
        onChange={e => setAmount(e.target.value)}/>
    </div>
    <div className="d-flex mb-3">
      <input
        type="number"
        placeholder="MINIMUM"
        className="form-control mr-3"
        value={amountMin}
        onChange={e => setAmountMin(e.target.value)}/>
      <input
        type="number"
        placeholder="MAXIMUM"
        className="form-control"
        value={amountMax}
        onChange={e => setAmountMax(e.target.value)}/>
    </div>
    <hr/>
    <h5 className="mb-3">Created At</h5>
    <div className="d-flex mb-3">
      <input
        type="date"
        className="form-control mr-3"
        value={createdMin}
        onChange={e => setCreatedMin(e.target.value)}/>
      <input
        type="date"
        className="form-control"
        value={createdMax}
        onChange={e => setCreatedMax(e.target.value)}/>
    </div>
    <h5 className="mb-3">Updated At</h5>
    <div className="d-flex mb-3">
      <input
        type="date"
        className="form-control mr-3"
        value={updatedMin}
        onChange={e => setUpdatedMin(e.target.value)}/>
      <input
        type="date"
        className="form-control"
        value={updatedMax}
        onChange={e => setUpdatedMax(e.target.value)}/>
    </div>
    </>
  )
}

const mapDispatchToProps = {
  toggleModal,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  isReload: state.applicationState.isReload,
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageFunds);

