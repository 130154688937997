import React from 'react';
import { MDBInput } from 'mdbreact';

const SelectDropDownElement = ({
  isCurrentPageSelected,
  selectCurrentPage,
}) => (
  <div className="position-relative">
    <div className="flex-box">
      <MDBInput
        className="float-right"
        type="checkbox"
        checked={isCurrentPageSelected}
        label="Select"
        id="selectDropbox"
        onChange={selectCurrentPage}
      />
    </div>
  </div>
);

export default SelectDropDownElement;
