import React, { useEffect, useState } from 'react';
import { MDBBtn } from 'mdbreact';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import InputRange from '../dashboard/inputRange';

const SearchElement = ({
  searchData, profile, getSearchValues, showInputRange, activeTab,
}) => {
  const [range, handlerRange] = useState(
    showInputRange
      ? searchData.range : (searchData.range || 60),
  );

  useEffect(() => {
    if (searchData.range !== range) {
      handlerRange(searchData.range);
    }
  }, [searchData.range]);

  const handlerSubmit = event => {
    event.preventDefault();
    const result = {
      search: event.target.search.value,
      search_zip: event.target.search_zip.value,
    };
    if (
      showInputRange || profile.userType === 'contractor'
    ) return getSearchValues({ ...result, range });
    return getSearchValues(result);
  };

  return (
    <form onSubmit={handlerSubmit}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <div className="input-group md-form form-sm form-1 pl-0 searchinput">
            <input
              className="form-control my-0 py-1 searchdiv"
              type="text"
              placeholder="Search"
              aria-label="Search"
              name="search"
              style={{ borderBottom: 'none' }}
              defaultValue={searchData.search}
            />
          </div>
          <div className="input-group md-form form-sm form-1 pl-0 searchinput">
            <input
              className="form-control my-0 py-1 searchdiv"
              type="text"
              max="8"
              placeholder="Zip Code"
              aria-label="Zip Code"
              name="search_zip"
              style={{ borderBottom: 'none' }}
              maxLength="6"
              defaultValue={searchData.search_zip || ''}
            />
          </div>
        </div>
        {
          showInputRange
          || (profile.userType === 'contractor' && activeTab === 'available')
            ? (
              <InputRange
                defaultValue={range}
                handler={handlerRange}
              />
            )
            : null
        }
        <div
          className="input-group-prepend"
          style={{ height: '84px', margin: '0 4px' }}
        >
          <MDBBtn
            type="submit"
            className="cwo_butt"
            style={{ height: 'calc(100% - 16px)' }}
            color="info"
          >
            Search
          </MDBBtn>
        </div>
      </span>
    </form>
  );
};

const mapStateToProps = state => ({
  profile: state.profile,
  searchData: state.workOrder.searchData,
  activeTab: state.applicationState.activeTab,
});

export default connect(mapStateToProps)(withRouter(SearchElement));
