import React, { useState } from 'react';
import { MDBCol, MDBIcon, MDBInput } from 'mdbreact';

const EditRow = ({ template, cancel, save }) => {
  const [name, setName] = useState(template?.label);

  const handlerChanges = event => {
    setName(event.target.value);
  };

  const handlerSave = () => {
    save(name, template.value);
    cancel();
  };

  return (
    <>
      <MDBCol md="9">
        <MDBInput
          containerClass="no-margins"
          id="name"
          name="name"
          value={name}
          onChange={handlerChanges}
        />
      </MDBCol>
      <MDBCol md="3" className="flex-box align-items-center">
        <MDBIcon
          icon="save"
          title="Save"
          onClick={handlerSave}
          className="v-paddings-half green-text"
          size="lg"
        />
        <MDBIcon
          far
          icon="times-circle"
          title="Cancel"
          onClick={cancel}
          className="v-paddings-half deep-orange-text"
          size="lg"
        />
      </MDBCol>
    </>
  );
};

export default EditRow;
