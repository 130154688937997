import React, { useEffect, useState } from 'react';

const InputRange = ({ handler, defaultValue = NaN }) => {
  const range = [
    '10 Miles', '20 Miles', '30 Miles', '40 Miles', '50 Miles', '60 Miles', '100 Miles', '200 Miles', '250 Miles', '300 Miles', '500 Miles', 'Unlimited Miles',
  ];
  const [value, setValue] = useState(4);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const buf = !defaultValue ? 10 : range.indexOf(`${defaultValue} Miles`);
    if (buf !== value) setValue(buf);
  }, [defaultValue]);

  return (
    <div>
      <label htmlFor="customRange1">{range[value]}</label>
      <input
        type="range"
        className="custom-range"
        id="customRange1"
        max={11}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onMouseUp={(e) => {
          // eslint-disable-next-line radix
          handler(parseInt(range[e.target.value]));
        }}
      />
    </div>
  );
};

export default InputRange;
