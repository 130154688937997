import React from "react";
import {components} from "react-select";

const CustomMultiValueLabel = ({ children, ...props }) => {
    const fixedLabelLength = children.length <= 20 ? children  : children.substr(0, 17)+'...'
    return (
        <components.MultiValueLabel {...props} >
            {fixedLabelLength}
        </components.MultiValueLabel>
    );
}

export default CustomMultiValueLabel