import React from 'react';
import { MDBModal } from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import Loader from '../dashboard/common/Loader';

const CustomLoader = ({ modalState }) => (
  <MDBModal
    isOpen={modalState.isOpen}
    inline={false}
    toggle={() => {}}
    noClickableBodyWithoutBackdrop
    overflowScroll={false}
    position="center"
    centered
    contentClassName="loader-element"
  >
    <Loader color={modalState.type || 'white'} />
  </MDBModal>
);

const mapStateToProps = state => ({
  modalState: state.modalState.loader,
});

export default connect(mapStateToProps)(CustomLoader);
