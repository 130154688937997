import React from 'react';
import Clients from './ForClients';
import SuperAdmins from './ForSuperAdmins';

const WorkOrderButtonControl = ({
  hideControlButton,
  openImportModal,
  userType,
}) => {
  if (hideControlButton) return <></>;

  switch (userType) {
    case 'client': return (
      <Clients openImportModal={openImportModal} userType={userType} />
    );
    case 'contractor': return <></>;
    case 'superAdmin': return (
      <SuperAdmins userType={userType} />
    );
    default: return <></>;
  }
};

export default WorkOrderButtonControl;
