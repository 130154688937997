import React from 'react';
import { connect } from 'react-redux';
import {
  MDBBtn, MDBCol, MDBIcon, MDBRow,
} from 'mdbreact';
import { toggleModal } from '../../storage/actions/modalsActions';

const Header = ({
  toggleModal,
}) => (
  <>
    <MDBRow>
      <MDBCol style={{ textAlign: 'center' }}>
        Funding Sources
      </MDBCol>
    </MDBRow>
    <MDBRow className="position-relative" style={{top: "-35px"}}>
      <MDBCol>
        <MDBBtn
          className="background-green flex-box flex-align-center text-nowrap float-right"
          onClick={() => toggleModal(true, 'addFundingSourceToDwollaBySuperAdmin')}
        >
          <MDBIcon className="vwimg" icon="plus" />
          Add Funding Source
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  </>
);
// <MDBRow>
//   <MDBCol style={{ textAlign: 'center' }}>
//     Payment Methods
//   </MDBCol>
// </MDBRow>
// <MDBRow className="position-relative" style={{top: "-35px"}}>
//   <MDBCol>
//     <MDBBtn
//       className="background-green flex-box flex-align-center text-nowrap float-right"
//       onClick={() => toggleModal(true, 'addOrEditPaymentMethod')}
//     >
//       <MDBIcon className="vwimg" icon="plus" />
//       Add Payment Method
//     </MDBBtn>
//   </MDBCol>
// </MDBRow>

const mapDispatchToProps = {
  toggleModal,
};

export default connect(null, mapDispatchToProps)(Header);
