import React, { useContext, useEffect, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  MDBContainer, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import axios from 'axios';
import { toggleModal } from '../../../storage/actions/modalsActions';
import SelectMessageRecipients from './SelectMessageRecipients';
import NewMessage from '../workOrderMessageSystemModal/NewMessage';
import { selectWorkOrder } from '../../../storage/actions/applicationStateActions';
// eslint-disable-next-line import/no-cycle
import { SocketContext } from '../../../pages';

const BulkMessage = ({
  modalState,
  toggleModal,
  auth,
  selectedWorkOrders,
  selectWorkOrder,
  activeTab,
}) => {
  const [companyUsers, fetchCompanyUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isForContractor, setIsNeedSendToContractor] = useState(false);

  const { chatSocket: socket } = useContext(SocketContext);

  useEffect(() => {
    axios.get('/api/users/get-clients').then(res => {
      fetchCompanyUsers(
        res.data.clients
          .filter(el => el.userId)
          .map(el => ({ value: el.userId, label: el.name })),
      );
    });
  }, [modalState]);

  const closeModal = () => {
    selectWorkOrder([]);
    toggleModal(false, 'bulkMessageModal', true);
  };

  const sendMessage = (message, parentId) => {
    const workOrders = modalState.type.mode !== 'useIds' ? modalState.type.workOrders.map(i => i._id) : selectedWorkOrders
    socket.emit('chat', {
      parentId,
      message,
      selectedAllApplicants: true,
      workOrderId: workOrders,
      users: selectedUsers.map(el => el.value),
      isForContractor,
      activeTab
    });
    closeModal();
  };

  return (
    <MDBModal
      isOpen={modalState.isOpen}
      toggle={closeModal}
      className="work-order-message-system-modal"
      contentClassName="modal-content-background"
    >
      <MDBContainer style={{ color: 'white' }}>
        <MDBRow
          className="h-paddings"
          style={{ justifyContent: 'flex-end', padding: '10px 10px 5px 0' }}
        >
          <MDBIcon
            far
            icon="times-circle"
            onClick={closeModal}
            size="lg"
            className="blue-grey-text"
          />
        </MDBRow>
        <MDBModalBody>
          {
            auth.user.userType !== 'contractor'
              ? (
                <SelectMessageRecipients
                  companyMembers={companyUsers}
                  activeTab={activeTab}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  isForContractor={isForContractor}
                  setIsNeedSendToContractor={setIsNeedSendToContractor}
                />
              )
              : null
          }
          <NewMessage sendMessage={sendMessage} />
        </MDBModalBody>
      </MDBContainer>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal, selectWorkOrder,
};

const mapStateToProps = state => ({
  currentWorkOrder: state.workOrder.currentWorkOrder,
  selectedWorkOrders: state.applicationState.selectedWorkOrders,
  auth: state.auth,
  activeTab: state.applicationState.activeTab,
  modalState: state.modalState.bulkMessageModal,
  applicantsLoaded: state.workOrder.applicantsLoaded,
  routedApplicantsLoaded: state.workOrder.routedApplicantsLoaded,
  applicants: state.workOrder.applicants,
  routedApplicants: state.workOrder.routedApplicants,
});
export default connect(mapStateToProps, mapDispatchToProps)(BulkMessage);
