import React from 'react';
import {
  MDBBtn, MDBCol, MDBLink, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import { toggleModal } from '../../storage/actions/modalsActions';
import { reloadData } from '../../storage/actions/applicationStateActions';

const TestFailed = ({
  modalState,
  toggleModal,
  reloadData,
}) => {
  const closeModal = () => {
    toggleModal(!modalState.isOpen, 'testFailed');
  };

  const retakeExam = () => {
    modalState.type.reset('testsReduxForm');
    reloadData();
    closeModal();
  };

  return (
    <MDBModal
      isOpen={modalState.isOpen}
      toggle={() => {}}
      centered
      className="signindiv"
    >
      <MDBModalBody
        style={{
          fontFamily: 'Arial, Helvetica, sans-serif',
          borderRadius: '4px',
        }}
      >
        <MDBRow>
          <MDBCol>
            <label
              className="red-text font-weight-bold text-in-one-line"
            >
              {modalState.type.message}
            </label>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <MDBLink
              className="inline-block btn btn-pink text-in-one-line"
              to={`/certifications/${modalState.type.certificationTypeId}/video`}
              onClick={closeModal}
            >
              Re-watch Video
            </MDBLink>
          </MDBCol>
          <MDBCol>
            <MDBBtn
              onClick={retakeExam}
              color="indigo"
            >
              Retake Exam
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
  reloadData,
};

const mapStateToProps = state => ({
  modalState: state.modalState.testFailed,
});

export default connect(mapStateToProps, mapDispatchToProps)(TestFailed);
