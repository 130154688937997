import React from 'react';
import { MDBCol } from 'mdbreact';
import { Field } from 'redux-form';
import InputField from '../../layout/reduxForm/customFields/InputField';

const SiteInfoSection = () => (
  <>
    <MDBCol className="flex-grow-0" style={{ minWidth: 'max-content' }}>
      <Field
        name="siteId"
        component={InputField}
        type="text"
        placeholder="Site ID"
        label="Site ID"
        required
        className="flex-column font-size-08"
        inputClassName="form-control custom-input-style"
      />
    </MDBCol>
    <MDBCol className="flex-grow-0" style={{ minWidth: 'max-content' }}>
      <Field
        name="siteName"
        component={InputField}
        type="text"
        placeholder="Site Name"
        label="Site Name"
        required
        className="flex-column font-size-08"
        inputClassName="form-control custom-input-style"
      />
    </MDBCol>
  </>
);

export default SiteInfoSection;
