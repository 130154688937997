import React, { useEffect } from 'react';
import WorkOrderTasks from '../../../../../createWorkOrder/WorkOrderTasks';

const TasksField = ({
  input: { value, onChange },
}) => {
  useEffect(() => {
    if (!value) {
      onChange({
        preArrivalRequirements: [],
        onSiteRequirements: [],
        completionRequirements: [],
      });
    }
  }, []);

  return <WorkOrderTasks handler={onChange} tasks={value} />;
};

export default TasksField;
