import React from 'react';
import { MDBCol } from 'mdbreact';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import DatePickedField from '../../layout/reduxForm/customFields/DatePickerField';
import TimePickedField from '../../layout/reduxForm/customFields/TimePickerField';
import { endDateFilter, startDateFilter } from '../../../utils/pickerDateFilters';

const createWorkOrderReduxFormSelector = formValueSelector('createWorkOrderReduxForm');

const DateSection = ({ startDate }) => (
  <>
    <MDBCol className="flex-grow-0" style={{ minWidth: 'max-content' }}>
      <Field
        name="startDate"
        component={DatePickedField}
        label="Start Date"
        inputClassName="form-control width-inherit"
        dateFormat="MM/dd/yyyy"
        required
        filterDate={startDateFilter}
      />
    </MDBCol>
    <MDBCol className="flex-grow-0" style={{ minWidth: 'max-content' }}>
      <Field
        name="endDate"
        component={DatePickedField}
        label="End Date"
        inputClassName="form-control"
        dateFormat="MM/dd/yyyy"
        required
        minDate={startDate || new Date()}
        filterDate={(date) => endDateFilter(date, startDate)}
      />
    </MDBCol>
    <MDBCol className="flex-grow-0" style={{ minWidth: 'max-content' }}>
      <Field
        name="startTime"
        component={TimePickedField}
        label="Start Time"
        required
      />
    </MDBCol>
    <MDBCol className="flex-grow-0" style={{ minWidth: 'max-content' }}>
      <Field
        name="endTime"
        component={TimePickedField}
        label="End Time"
      />
    </MDBCol>
  </>
);

const mapStateToProps = state => ({
  startDate: createWorkOrderReduxFormSelector(state, 'startDate'),
});

export default connect(mapStateToProps)(DateSection);
