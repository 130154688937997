import React from 'react';
import { MDBBtn } from 'mdbreact';
import { connect } from 'react-redux';
import { toggleModal } from '../../../../storage/actions/modalsActions';
import { setFiltersObject } from '../../../../storage/actions/workOrdersActions';
import FilterBadges from './FilterBadges';

const FiltersPanel = ({
  toggleModal,
  setFiltersObject,
}) => (
  <>
    <div className="optiondiv sbzc">
      <MDBBtn className="filters-button" onClick={() => toggleModal(true, 'filtersModal')}>Filters</MDBBtn>
    </div>
    <div className="optiondiv sbzc">
      <MDBBtn
        color="blue-grey"
        className="filters-button"
        onClick={() => setFiltersObject({})}
      >
        Reset Filters
      </MDBBtn>
    </div>
    <FilterBadges />
  </>
);

const mapDispatchToProps = {
  toggleModal,
  setFiltersObject,
};

export default connect(null, mapDispatchToProps)(FiltersPanel);
