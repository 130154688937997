import React from 'react';
import FieldLabel from '../../FieldLabel';

const InputField = field => {
  return (
    <div className={field.paddingBottomClassName || 'padding-bottom-10'}>
      <div className={`flex-box justify-content-between ${field.className || ''}`}>
        <FieldLabel
          label={field.label}
          className={field.labelClassName}
          required={field.required}
        />
        <span className={field.dimensionClassName}>
          <input
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field.input}
            type={field.type}
            placeholder={field.placeholder}
            required={field.required}
            className={field.inputClassName}
            disabled={field.disabled}
            title={field.title || ''}
            min={field.min}
            max={field.max}
            step={field.step}
            style={field.dimension ? ({ paddingRight: 0 }) : ({})}
          />
          {field.dimension || ''}
        </span>
      </div>
      {
        field.meta.touched && field.meta.error
        && <span className="span-error">{field.meta.error}</span>
      }
    </div>
  )
};

export default InputField;
