export const GET_ERRORS = 'GET_ERRORS';
export const SET_IS_DOWNLOAD_DOCUMENTS = 'SET_IS_DOWNLOAD_DOCUMENTS';
export const USER_LOADING = 'USER_LOADING';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const INIT = 'INIT';
export const SET_CURRENT_WORK_ORDER = 'SET_CURRENT_WORK_ORDER';
export const SET_APPLICANTS_FOR_CURRENT_WORK_ORDER = 'SET_APPLICANTS_FOR_CURRENT_WORK_ORDER';
export const SET_ROUTED_FOR_CURRENT_WORK_ORDER = 'SET_ROUTED_FOR_CURRENT_WORK_ORDER';
export const SET_CURRENT_APPLICANTS = 'SET_CURRENT_APPLICANTS';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const FETCH_AVAILABLE_APPLICANTS = 'FETCH_AVAILABLE_APPLICANTS';
export const SET_LOADED_STATUS = 'SET_LOADED_STATUS';
export const SUBMIT_WORK_ORDER = 'SUBMIT_WORK_ORDER';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_WORK_ORDER_FILTERS = 'SET_WORK_ORDER_FILTERS';
export const SET_WORK_ORDER_SORT_BY = 'SET_WORK_ORDER_SORT_BY';
export const SET_WORK_ORDER_SEARCH = 'SET_WORK_ORDER_SEARCH';
export const RELOAD_DATA = 'RELOAD_DATA';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_SELECTED_WORK_ORDERS_ID = 'SET_SELECTED_WORK_ORDERS_ID';
export const SET_MESSAGES_COUNTERS = 'SET_MESSAGES_COUNTERS';
export const SET_PAGE = 'SET_PAGE';
export const SET_PER_PAGE = 'SET_PER_PAGE';
export const SET_TAX_INFORMATION = 'SET_TAX_INFORMATION';
export const SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE';
export const SET_BANK_INFORMATION = 'SET_BANK_INFORMATION';
