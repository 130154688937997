import React from 'react';
import {
  MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import { connect } from 'react-redux';
import { toggleModal } from '../../storage/actions/modalsActions';
import AddOrEditCustomFieldsReduxForm from '../layout/reduxForm/AddOrEditCustomFieldsReduxForm';
import handleSubmit from '../../utils/reduxForm/onSubmit/addOrEditCustomFields';

const AddOrEditCustomFields = ({ modalState, toggleModal }) => {
  const { name, _id } = modalState.type;

  const closeModal = () => {
    toggleModal(!modalState.isOpen, 'addOrEditCustomFields');
  };

  return (
    <MDBModal
      className="applypop"
      isOpen={modalState.isOpen}
      toggle={closeModal}
      fullHeight
      position="right"
    >
      <MDBModalBody style={{ color: 'white' }}>
        <MDBRow>
          <MDBCol>
            <h5 style={{ fontWeight: 'bold' }} className="">
              {`${name ? 'Edit custom field name' : 'Create custom field'}`}
            </h5>
          </MDBCol>
          <MDBCol md="2" style={{ textAlign: 'end' }}>
            <MDBIcon far icon="times-circle" onClick={closeModal} size="lg" />
          </MDBCol>
        </MDBRow>
        <AddOrEditCustomFieldsReduxForm
          initialValues={{ name, _id }}
          onSubmit={handleSubmit}
          closeModal={closeModal}
        />
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = state => ({
  modalState: state.modalState.addOrEditCustomFields,
});

export default connect(mapStateToProps, {
  toggleModal,
})(AddOrEditCustomFields);
