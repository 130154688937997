import React, { useState } from 'react';
import {
  MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import { toggleModal } from '../../storage/actions/modalsActions';
import { copyWorkOrder } from '../../storage/actions/workOrdersActions';

const CopyWorkOrderModal = ({
  modalState, toggleModal, copyWorkOrder,
}) => {
  const [countCopy, setCountCopy] = useState(1);

  const handlerSubmit = () => {
    copyWorkOrder(modalState.type.workOrderId, countCopy);
  };

  const closeModal = () => toggleModal(false, 'copyWorkOrderModal');

  return (
    <MDBModal
      isOpen={modalState.isOpen}
      toggle={closeModal}
      centered
      className="signindiv"
    >
      <MDBRow style={{ justifyContent: 'flex-end', paddingRight: '10px' }}>
        <MDBIcon
          far
          icon="times-circle"
          onClick={closeModal}
          size="lg"
          className="blue-grey-text"
        />
      </MDBRow>
      <MDBModalBody style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
        <div className="buttondivsignin">
          <MDBRow style={{ justifyContent: 'center' }}>
            <p
              style={{ color: 'black' }}
            >
              Duplicate Work Order
            </p>
          </MDBRow>
          <MDBCol md="12">
            <div className="form-group signinput">
              <label htmlFor="formGroupExampleInput" className="font12">
                Duplication Count
              </label>
              <input
                type="number"
                className="form-control"
                id="copy_count"
                value={countCopy}
                onChange={({ target }) => {
                  setCountCopy(target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handlerSubmit();
                }}
                required
              />
            </div>
          </MDBCol>
          <MDBRow style={{ justifyContent: 'center' }}>
            <MDBBtn
              color="info"
              className="signbutton"
              onClick={handlerSubmit}
            >
              Submit
            </MDBBtn>
          </MDBRow>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = (state) => ({
  modalState: state.modalState.copyWorkOrderModal,
});

export default connect(mapStateToProps, { toggleModal, copyWorkOrder })(CopyWorkOrderModal);
