import React, {useState} from 'react';
import {
  MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBRow,
} from 'mdbreact';
import { localCurrencySettings } from '../../../utils/constans';
import ContractorRating from '../ContractorRating';
import ContractorCertificatesChecks from '../ContractorCertificatesChecks';
import TravelExpenses from './CounterOfferElements/TravelExpenses';
import DateAndTime from './CounterOfferElements/DateAndTime';
import workOrderStatusControl from "../../../utils/workOrderStatusControl";
import ExplanationInputModal from "../../modal/ExplenationInputModal";
import ContractorPreferedGroupsChecks from '../PreferedGroupsCheck';

const InvitedContractorCard = ({
  userType,
  contractorInfo,
  toggleModal,
  setCurrentApplicants,
  currentWorkOrder,
  unRouteWorkOrder,
  acceptInvite,
}) => {
    const [showModal, setShowModal] = useState(false)
    const workOrderControlByStatus = currentWorkOrder?.status
        ? workOrderStatusControl(currentWorkOrder.status)
        : {};


    const acceptOnBehalf = message => {
        acceptInvite({
            workOrdersId: [currentWorkOrder._id],
            contractorId: contractorInfo.contractor_id,
            historyMessage: message
        })
    }

    return (
      <tr>
        <td style={{paddingLeft: '5px'}}>
          <div className="d-flex">
        <span>
          <button
              type="button"
              className="disabled-standard-button-style"
              style={{
                fontWeight: 'bold',
                paddingRight: '20px',
              }}
              onClick={() => {
                setCurrentApplicants(contractorInfo);
                toggleModal(true, 'contractorDetailsModal', false, {contractorId: contractorInfo.contractor_details.userId});
              }}
          >
            {contractorInfo.contractor_name}
          </button>
        </span>
          </div>
          <div className="font12">
            {contractorInfo.contractor_details.address}
          </div>
          <div className="font12">
            {
              `${
                  (contractorInfo.contractor_details.city && contractorInfo.contractor_details.state)
                      ? `${
                          contractorInfo.contractor_details.city.label
                      }, ${
                          contractorInfo.contractor_details.state.label
                      }, `
                      : ''
              }${
                  contractorInfo.contractor_details.zipcode
              }`
            }
          </div>
          <div className="font12">{`ID - ${contractorInfo.contractor_details.unique_id}`}</div>
          <div className="font12">{`Comment - ${(contractorInfo.comment) ? contractorInfo.comment : ''}`}</div>
        </td>
        <td>
          <MDBContainer>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div>
                <MDBRow className="font12">
                  <MDBCol>
                    <MDBIcon icon="phone" size="lg" className="float-left mr-2 pt-1"/>
                    <a
                        href={`tel:${contractorInfo.contractor_details.phone}`}
                        style={{color: 'white'}}
                    >
                      {contractorInfo.contractor_details.phone}
                    </a>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="font12">
                  <MDBCol style={{paddingTop: '3px'}}>
                    <MDBIcon icon="envelope" size="lg" className="float-left pr-2 pt-1"/>
                    <a
                        href={`mailto:${contractorInfo.contractor_details.email}`}
                        style={{color: 'white'}}
                    >
                      {contractorInfo.contractor_details.email}
                    </a>
                  </MDBCol>
                </MDBRow>
              </div>
              <div>
            <span style={{fontWeight: 'bold'}}>
              Work Performed
            </span>
                <p
                    title={'WO\'s Completed For Our Company / WO\'s Completed on Platform'}
                    style={{cursor: 'pointer', textAlign: 'center'}}
                >
                  {
                    `${
                        contractorInfo.contractor_details.completedWorkOrderCount
                    } / ${
                        contractorInfo.contractor_details.allWorkOrdersCount
                    }`
                  }
                </p>
              </div>
            </div>
            <MDBRow className="flex-box">
              <MDBCol>
                <ContractorCertificatesChecks
                    certificates={contractorInfo.contractor_details.certificates}
                    textAlign="center"
                />
              </MDBCol>
              {contractorInfo.groups?.length ? 
                <MDBCol>
                  <ContractorPreferedGroupsChecks />
                </MDBCol> : <></>
              }
            </MDBRow>
            <MDBRow>
              <ContractorRating contractor={contractorInfo.contractor_details} iconsSize="lg"/>
            </MDBRow>
          </MDBContainer>
        </td>
        <td style={{textAlign: 'right'}}>
          <MDBRow style={{width: 'max-content'}}>
            {
              contractorInfo.contractor_details?.distance
                  ? (
                      <MDBCol
                          className="flex-grow-0 text-in-one-line font-weight-bold"
                          style={{fontSize: '1rem'}}
                      >
                        {`${contractorInfo.contractor_details?.distance?.toFixed(2)} Miles`}
                      </MDBCol>
                  )
                  : <></>
            }
            <MDBCol>
              <div className="dollercont">
                <h5
                    style={{
                      color: '#28a745',
                      marginBottom: '-6px',
                      fontWeight: 'bold',
                    }}
                >
                  {`Base Pay: ${(+currentWorkOrder.amount).toLocaleString('en-US', localCurrencySettings)}`}
                </h5>
              </div>
              <div className="dollercont">
                <h5
                    style={{
                      color: '#28a745',
                      fontWeight: 'bold',
                      marginTop: 10,
                    }}
                >
                  {`Variable Pay: ${(+currentWorkOrder.variableAmount || 0).toLocaleString('en-US', localCurrencySettings)}`}
                </h5>
              </div>
            </MDBCol>
            <DateAndTime
                startDate={contractorInfo.startDate}
                startTime={contractorInfo.startTime}
            />
            <MDBRow style={{
              paddingTop: '0.2rem', display: 'flex', justifyContent: 'flex-end', width: '100%',
            }}
            >
              <TravelExpenses
                  amount={contractorInfo.amountExpense}
                  description={contractorInfo.descriptionExpense}
              />
            </MDBRow>
          </MDBRow>
          {
            workOrderControlByStatus.beforeAssigned && userType === 'client'
                ? (
                    <div className="flex-box justify-content-end">
                      {
                        Object.values(contractorInfo.block).some(el => el)
                            ? (
                                <MDBBtn
                                    color="red"
                                    className="assignbutton"
                                    onClick={() => {
                                      toggleModal(true, 'blockModal', false, {contractorId: contractorInfo.contractor_id});
                                    }}
                                >
                                  Blocked
                                </MDBBtn>
                            )
                            : (
                                <MDBBtn
                                    color="success"
                                    className="assignbutton"
                                >
                                  Routed
                                </MDBBtn>
                            )
                      }
                      <MDBBtn
                          color="red"
                          className="assignbutton"
                          onClick={() => {
                            unRouteWorkOrder(currentWorkOrder._id, contractorInfo.contractor_id);
                          }}
                      >
                        Un-Route
                      </MDBBtn>
                      <MDBBtn
                        color="orange"
                        className="assignbutton d-block"
                        onClick={() => {
                          // acceptInvite({ workOrdersId: [currentWorkOrder._id], contractorId: contractorInfo.contractor_id })
                          setShowModal(true)
                        }}
                      >
                        Accept On Behalf
                      </MDBBtn>
                        <ExplanationInputModal
                          title="Reason to accept on behalf"
                          warningMessage="Please enter valid reason to accept the contractor."
                          isCloseAvailable
                          useOutOfState
                          isOpen={showModal}
                          changeIsOpen={setShowModal}
                          returnExplanation={acceptOnBehalf}
                        />
                    </div>
                )
                : <></>
          }
        </td>
      </tr>
    )
};

export default InvitedContractorCard;
