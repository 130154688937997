import React, {useEffect, useMemo, useState} from 'react';
import {
  MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import { toggleSecondModalClose } from '../../storage/actions/modalsActions';
import Loader from '../dashboard/common/Loader';
import Notification from '../notification';

const ExplanationInputModal = ({
  toggleSecondModalClose,
  returnExplanation,
  title,
  modalState,
  type,
  isCloseAvailable = false,
  useOutOfState = false,
  warningMessage = 'Please enter valid reason to unassign the contractor.',
  isOpen = false,
  changeIsOpen,
}) => {
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  const [show, setShow] = useState(isOpen)

  isOpen = isOpen || show
  changeIsOpen = changeIsOpen || setShow

  const showModal = useMemo(() => useOutOfState
    ? isOpen
    : modalState.isOpen && modalState.type === type
    , [modalState, isOpen])

  const closeModal = () => {
    if (!isCloseAvailable || showLoader) return;

    setMessage('');

    if(useOutOfState) changeIsOpen(false);

    toggleSecondModalClose('explanationInputModal', true);
  }

  return (
    <MDBModal
      isOpen={showModal}
      toggle={closeModal}
      centered
    >
      <MDBModalBody style={{ fontFamily: 'Arial, Helvetica, sans-serif', backgroundColor: 'white' }}>
        {showLoader
          ? (
            <div className="flex-box flex-center" style={{ color: 'black' }}>
              <Loader color="black" />
            </div>
          )
          : (
            <>
              {isCloseAvailable && (
                <MDBRow style={{ justifyContent: 'flex-end', paddingRight: '10px' }}>
                  <MDBIcon
                    far
                    icon="times-circle"
                    onClick={closeModal}
                    size="lg"
                    className="blue-grey-text"
                  />
                </MDBRow>
              )}
              <div className="buttondivsignin">
                <MDBRow style={{ justifyContent: 'center' }}>
                  <h5
                    style={{ color: 'black' }}
                  >
                    {title}
                  </h5>
                </MDBRow>
                <MDBCol md="12">
                  <div className="form-group signinput d-flex flex-column align-items-start">
                    <label htmlFor="formGroupExampleInput" className="font12">
                      Please Type the Reason
                    </label>
                    <label style={{ fontWeight: 'bold', paddingLeft: '0.5rem' }}>
                      (This message will be sent to the contractor)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="reason_to_unroute"
                      value={message}
                      onChange={({ target }) => setMessage(target.value)}
                      required
                    />
                  </div>
                </MDBCol>
                <MDBRow style={{ justifyContent: 'center' }}>
                  <MDBBtn
                    color="info"
                    className="signbutton"
                    onClick={() => {
                      if (!message.trim()) {
                        Notification('warning', {
                          message: warningMessage,
                        });
                      } else {
                        returnExplanation(message);
                        setMessage('');
                        setShowLoader(true);
                      }
                    }}
                  >
                    Submit
                  </MDBBtn>
                </MDBRow>
              </div>
            </>
          )}
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = state => ({
  modalState: state.modalState.explanationInputModal,
});

export default connect(mapStateToProps, { toggleSecondModalClose })(ExplanationInputModal);
