/* eslint-disable import/no-cycle */
import React from 'react';
import { connect } from 'react-redux';
import ClientManageFunds from './clients/ManageFunds';
import ContractorManageFunds from './contractors/ManageFunds';
import AdminManageFunds from './admin/ManageFunds';

const ManegeFunds = ({
  auth,
  mainContainer,
}) => {
  switch (auth?.user?.userType) {
    case 'client': return (<ClientManageFunds mainContainer={mainContainer} />);
    case 'contractor': return (<ContractorManageFunds mainContainer={mainContainer} />);
    case 'superAdmin': return (<AdminManageFunds mainContainer={mainContainer} />);
    default: return (<></>);
  }
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ManegeFunds);
