import React from 'react';
import { MDBBtn, MDBRow } from 'mdbreact';
import ContractorRating from '../ContractorRating';
import ContractorCertificatesChecks from '../ContractorCertificatesChecks';
import ContractorPreferedGroupsChecks from '../PreferedGroupsCheck';

const SearchContractorCard = ({
  contractor, openInfo, inviteContractor, block,
  showBlockModalForCurrentContractor,
}) => (
  <tr>
    <td style={{ textAlign: 'left', width: '40px' }}>
      <img src={contractor.image ? contractor.image : "https://www.w3schools.com/css/img_avatar.png"} alt="Avatar" className="findclientphoto" />
    </td>
    <td>
      <MDBRow style={{ marginBottom: '5px', justifyContent: 'space-between' }}>
        <div style={{ marginRight: '20px' }}>
          <div className="d-flex cursor_pointer">
            <span>
              <button
                type="button"
                className="disabled-standard-button-style"
                style={{
                  fontWeight: 'bold',
                  paddingRight: '20px',
                  color: '#009933',
                }}
                onClick={() => openInfo(contractor)}
              >
                {contractor.name}
              </button>
            </span>
          </div>
          <div style={{ fontWeight: 'bold', marginBottom: '3px' }}>{contractor.unique_id}</div>
          <div className="font12">{contractor.address}</div>
          <div
            className="font12"
            style={{ paddingBottom: '5px' }}
          >
            {(contractor.city && contractor.city.label) ? ` ${contractor.city.label},` : ''}
            {(contractor.state && contractor.state.label) ? ` ${contractor.state.label},` : ''}
            <span>{` ${contractor.zipcode}`}</span>
          </div>
          <div className="font12" />
        </div>
        {contractor.groups?.length ? 
          <ContractorPreferedGroupsChecks /> : <></>
        }
        <ContractorCertificatesChecks certificates={contractor.certificates} />
      </MDBRow>
      <MDBRow style={{ marginBottom: '10px' }}>
        <div className="font12">
          <span className="text-in-one-line" style={{ paddingRight: '1rem' }}>{contractor.phone}</span>
          <span className="text-in-one-line" style={{ paddingRight: '1rem' }}>{contractor.email}</span>
          {
            (contractor.distance || contractor.distance === 0)
              && (
                <span
                  className="text-in-one-line"
                  style={{ paddingRight: '1rem' }}
                >
                  {`Distance - ${contractor.distance.toFixed(2)} Miles`}
                </span>
              )
          }
        </div>
      </MDBRow>
    </td>
    <td style={{ textAlign: 'right', verticalAlign: 'bottom' }}>
      <ContractorRating contractor={contractor} />
      <div>
        <span>
          {
            block
              ? (
                <MDBBtn
                  color="red"
                  className="assignbutton"
                  onClick={() => showBlockModalForCurrentContractor(contractor)}
                >
                  Blocked
                </MDBBtn>
              )
              : (
                <MDBBtn
                  color="info"
                  className="invitebutton"
                  onClick={() => inviteContractor(contractor)}
                >
                  Invite
                </MDBBtn>
              )
          }
        </span>
      </div>
    </td>
  </tr>
);

export default SearchContractorCard;
