import React from 'react';
import {MDBIcon, MDBModal, MDBModalBody, MDBRow} from "mdbreact";
import connect from "react-redux/es/connect/connect";
import {toggleModal} from "../../storage/actions/modalsActions";

const MessageRateModal = ({modalState, toggleModal}) => {

    const onClose = () => {
        const url = new URL(window.location);
        url.searchParams.delete('type');
        url.searchParams.delete('id');
        url.searchParams.delete('rate');
        window.history.replaceState(null, null, url);
        toggleModal(!modalState.isOpen);
    }

    return (
        <MDBModal
            isOpen={modalState.isOpen}
            toggle={onClose}
            centered
            className="signindiv"
        >
            <MDBRow style={{ justifyContent: "flex-end", paddingRight: "10px" }}>
                <MDBIcon
                    far
                    icon="times-circle"
                    onClick={onClose}
                    size="lg"
                    className="blue-grey-text"
                />
            </MDBRow>
            <MDBModalBody style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
                <div className="buttondivsignin">
                    <MDBRow style={{ justifyContent: "center" }}>
                        <div className="headingdivr">
                            <h2>Thank you for your rating!</h2>
                        </div>
                    </MDBRow>
                </div>
            </MDBModalBody>
        </MDBModal>
    );
};


const mapStateToProps = state => ({
    modalState: state.modalState.messageRateModal
});

export default connect(mapStateToProps, {toggleModal})(MessageRateModal);
