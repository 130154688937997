import React, { useEffect, useState } from 'react';
import {
  MDBCol, MDBContainer, MDBRow, MDBDataTableV5,
} from 'mdbreact';
import axios from 'axios';
import { datatableManagerFundsColumnsForContractor as columns, localCurrencySettings } from '../../utils/constans';
// eslint-disable-next-line import/no-cycle
import Modal from '../../components/modal';
import Notification from '../../components/notification';

const ContractorManageFunds = ({ mainContainer }) => {
  const [datatable, setDatatable] = useState([]);

  const getPaymentStatus = (workOrderStatus, paymentStatus) => {
    if (paymentStatus) return paymentStatus.replace(/^\w/, c => c.toUpperCase());
    if (workOrderStatus === 'paid') return 'Direct Payment';
    return 'Pending';
  };

  useEffect(() => {
    axios.get('/api/payment/contractor/history')
      .then(res => (
        setDatatable(res.data.workOrders.map(workOrder => ({
          ...workOrder,
          paidAt: new Date(workOrder.approvedAt).toLocaleString('en-US'),
          clientName: workOrder.clientName,
          amount: `+${(workOrder.finalPay || 0).toLocaleString('en-US', localCurrencySettings)}`,
          workOrderNamed: `${workOrder.WIN} - ${workOrder.title}`,
          status: getPaymentStatus(workOrder.status, workOrder?.payment?.status),
        })) || [])
      ))
      .catch(error => {
        Notification('error', {
          message: error.response?.data.message || error.message,
        });
      });
  }, []);

  return (
    <MDBContainer className="clientdascontentmaindiv" fluid>
      <MDBRow>
        <MDBCol style={{ textAlign: 'center' }}>
          Payment History
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <MDBDataTableV5
            hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={{ columns, rows: datatable || [] }}
            tbodyTextWhite
            theadTextWhite
            searching={false}
            className="payment-data-tables"
          />
        </MDBCol>
      </MDBRow>
      <Modal mainContainer={mainContainer.current} />
    </MDBContainer>
  );
};

export default ContractorManageFunds;
