import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import FundingSourceCard from './FundingSourceCard';

const Content = ({
  auth, fundingSources = [], handlerVerify, handlerDelete, makePrimary, isLoaded,
}) => (
  <MDBRow>
    {
      // eslint-disable-next-line no-nested-ternary
      fundingSources && fundingSources.length
        ? (
          fundingSources.map(fundingSource => (
            <FundingSourceCard
              key={fundingSource._id}
              fundingSource={fundingSource}
              handlerVerify={handlerVerify}
              handlerDelete={handlerDelete}
              makePrimary={makePrimary}
              auth={auth}
            />
          ))
        )
        : isLoaded
          ? (
            <MDBCol className="flex-box justify-content-center">
              <label>None Bank Info Found</label>
            </MDBCol>
          )
          : <></>
    }
  </MDBRow>
);

export default Content;
