import { localCurrencySettings } from '../constans';

export const getLocalCurrencyString = (value) => {
  if (!value && value !== 0) return '';
  const number = Number(value);
  if (Number.isNaN(number)) return '';
  return number.toLocaleString('en-US', localCurrencySettings);
};

export const getLocalAmountString = value => {
  const localString = getLocalCurrencyString(value);
  return localString || getLocalCurrencyString(0);
};
