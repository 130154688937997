import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MDBInput } from 'mdbreact';
import ApplyInfoRow from './ApplyInfoRow';
import Loader from '../../../../dashboard/common/Loader';

const AllRequestedWorkView = ({
  contractor, isShowAllWork, selectedApplies, selectApplies,
}) => {
  const [applyInfoArray, setApplyInfoArray] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [currentApply, setCurrentApply] = useState({});

  const getApplyInfo = async () => {
    setLoading(true);
    await axios.get(`/api/client/contractors/${contractor.contractor_id}/apply/all`)
      .then((res) => {
        setLoading(false);
        const newApplyList = [];
        res.data.payload.applies.forEach((applyInfo) => {
          if (applyInfo.workOrderId === contractor.work_order_id) return setCurrentApply(applyInfo);
          return newApplyList.push(applyInfo);
        });
        setApplyInfoArray(newApplyList);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isShowAllWork) {
      getApplyInfo();
    }
  }, [isShowAllWork]);

  const getNewApplyList = (target) => {
    if (target.checked) {
      if (target.name === 'selectAll') {
        return [
          currentApply.workOrderId,
          ...applyInfoArray.map((applyInfo) => applyInfo.workOrderId),
        ];
      }
      return [currentApply.workOrderId, ...selectedApplies, target.name];
    }
    if (target.name === 'selectAll') {
      return [currentApply.workOrderId];
    }
    const newSelectedList = new Set(selectedApplies);
    newSelectedList.delete(target.name);
    return [currentApply.workOrderId, ...newSelectedList];
  };

  const handlerInputChanges = (event) => {
    selectApplies([...new Set(getNewApplyList(event.target))]);
  };

  return isShowAllWork
    ? (
      <tr>
        <td colSpan="3" className="no-paddings">
          {
            isLoading
              ? <Loader />
              : (
                <table className="full-width" style={{ tableLayout: 'fixed' }}>
                  <tbody>
                    <tr>
                      <td style={tdStyle}>WIN</td>
                      <td style={tdStyle}>WO ID</td>
                      <td style={tdStyle}>WO TITLE</td>
                      <td style={tdStyle}>TRAVEL EXPENSE</td>
                      <td style={{ ...tdStyle, width: '150px' }}>START DATE</td>
                      <td style={tdStyle}>ADDRESS</td>
                      <td style={tdStyle}>PRICE</td>
                    </tr>
                    <ApplyInfoRow
                      applyInfo={currentApply}
                      current
                      selectedApplies={selectedApplies}
                      handlerInputChanges={() => {}}
                    />
                    {
                      applyInfoArray.length
                        ? (
                          <tr>
                            <td className="padding-05-02-rem">
                              <MDBInput
                                filled
                                type="checkbox"
                                label="Select All"
                                id="selectAllApplies"
                                name="selectAll"
                                checked={(applyInfoArray.length + 1) === selectedApplies.length}
                                onChange={handlerInputChanges}
                              />
                            </td>
                          </tr>
                        )
                        : <></>
                    }
                    {
                      applyInfoArray.map((applyInfo) => (
                        <ApplyInfoRow
                          key={applyInfo.workOrderId}
                          applyInfo={applyInfo}
                          selectedApplies={selectedApplies}
                          handlerInputChanges={handlerInputChanges}
                        />
                      ))
                    }
                  </tbody>
                </table>
              )
          }
        </td>
      </tr>
    )
    : <></>;
};

const tdStyle = { color: '#ee0404', textAlign: 'center' };
export default AllRequestedWorkView;
