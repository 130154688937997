import React, { useEffect, useState } from 'react';
import {
  MDBBtn, MDBCol, MDBInput, MDBRow,
} from 'mdbreact';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Editor from '../../../layout/editor';

const ContractorToolsBox = ({
  profile, isEdit, form, handlerChanges,
}) => {
  const [toolsInfo, setToolsInfo] = useState([]);
  const [selectedTools, setSelectedTools] = useState(new Set());

  useEffect(() => {
    setSelectedTools(new Set(profile.tools ? profile.tools.map(item => item.toolss) : []));
    axios
      .get('/api/users/fetch_tools_detail')
      .then(res => {
        setToolsInfo(res.data.tools);
      })
      .catch(error => console.error('error: ', error));
  }, []);

  const handlerCheckChanges = toolName => {
    const operation = selectedTools.has(toolName) ? 'delete' : 'add';
    const newSelect = new Set([...selectedTools]);
    if (operation === 'delete' && toolName === 'Minimum Toolbox') {
      const isManagerToolboxInclude = newSelect.has('Project Management Toolbox');
      newSelect.clear();
      if (isManagerToolboxInclude) newSelect.add('Project Management Toolbox');
    }
    newSelect[operation](toolName);
    setSelectedTools(newSelect);
    handlerChanges({ name: 'tools', value: [...newSelect].map(item => ({ toolss: item })) });
  };

  return (
    <>
      <div>
        <div className="contprofiletools_div">
          <div>
            <p className="font12">
              <span style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}>
                Minimum Toolbox
              </span>
              <p>
                <span style={{ fontWeight: 'bold', color: 'red' }}>
                  REQUIRED
                </span>
              </p>
            </p>
            <MDBRow>
              <MDBInput
                style={{
                  marginLeft: (isEdit) ? 0 : -10,
                  marginTop: (isEdit) ? 0 : -7,
                }}
                disabled={!isEdit}
                label={(isEdit) ? '.' : ''}
                type="checkbox"
                id="Minimum Toolbox"
                onChange={() => handlerCheckChanges('Minimum Toolbox')}
                checked={selectedTools.has('Minimum Toolbox')}
              />
              <p className="font12" style={{ marginLeft: (isEdit) ? 0 : 15 }}>
                I have a standard minimum tools and equipment needed for all work
              </p>
            </MDBRow>
            <p className="font12">
              <span style={{ fontWeight: 'bold' }}>Standard Toolbox items </span>
              {/* eslint-disable-next-line max-len */}
              : Smart Phones, Wireless Charger, Headset for Phones, Windows Laptop, Cordless Drill, Standard PC Tools,
              PTouch Labeler or similar, Tape Measure ,Level
            </p>
            <p className="font12">
              <span style={{ fontWeight: 'bold' }}>Standard Materials </span>
              : Zip ties, Velcro
            </p>
          </div>
          <MDBRow style={{ paddingLeft: '10px', paddingRight: '10px', border: '1px solid #1e2f41' }}>
            <MDBCol md="9">
              <MDBRow>
                <MDBCol>
                  {
                    toolsInfo.map((item) => (
                      <div key={item.title} style={{ marginLeft: -20 }}>
                        <div style={{ backgroundColor: 'white', width: '100%', height: 1 }} />
                        <div className="labeltools font12" style={{ marginLeft: -13 }}>
                          <span style={{ textDecorationLine: 'underline' }}>{item.title}</span>
                        </div>
                        <div className="d-flex" style={{ marginLeft: -14 }}>
                          <MDBInput
                            style={{
                              marginLeft: (isEdit) ? 0 : 0,
                              marginTop: (isEdit) ? 0 : -7,
                            }}
                            disabled={
                              !(
                                isEdit
                                && (
                                  selectedTools.has('Minimum Toolbox')
                                || item.title === 'Project Management Toolbox')
                              )
                            }
                            label={(isEdit) ? '.' : ''}
                            type="checkbox"
                            id={item.title}
                            onChange={() => handlerCheckChanges(item.title)}
                            checked={selectedTools.has(item.title)}
                          />
                          <span>
                            <p className="font12" style={{ marginLeft: (isEdit) ? 0 : 25 }}>
                              I have a standard minimum tools and equipment needed for all work
                            </p>
                            <p className="font12">
                              <span style={{ fontWeight: 'bold' }}>Standard Toolbox items:</span>
                              {
                                item.tools.map(tools => (
                                  <span key={tools}>{`${tools},`}</span>
                                ))
                              }
                            </p>
                            <p className="font12">
                              <span style={{ fontWeight: 'bold' }}>Standard Materials:</span>
                              {
                                item.materials.map(materials => (
                                  <span key={materials}>{`${materials},`}</span>
                                ))
                              }
                            </p>
                          </span>
                        </div>
                      </div>
                    ))
                  }
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <hr className="my-4 hrline" />
              </MDBRow>
            </MDBCol>
            <MDBCol className="worktype_col">
              <MDBRow style={{ justifyContent: 'center', paddingTop: '10px' }}>
                <h6 style={{ fontWeight: 'bold' }}>Work Types</h6>
              </MDBRow>
              <MDBRow>
                {
                  toolsInfo.map((item) => (
                    <div key={item.title}>
                      {
                        item.title.split('/').map(element => (
                          <MDBBtn
                            key={element}
                            className="typework_but"
                            color={selectedTools.has(item.title) ? 'success' : 'grey'}
                            style={{
                              borderRadius: '0px',
                              fontSize: '12px',
                            }}
                          >
                            {element}
                          </MDBBtn>
                        ))
                      }
                    </div>
                  ))
                }
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </div>
      </div>
      {
        !isEdit
          ? (
            <MDBRow>
              <MDBCol md="12" style={{ padding: '25px 0px 0px 15px' }}>
                <h6 className="font12">Other types of work I can do :</h6>
                <div
                  style={{ fontSize: 12 }}
                  dangerouslySetInnerHTML={{ __html: profile.other_tools }}
                />
              </MDBCol>
            </MDBRow>
          )
          : (
            <MDBRow style={{ paddingTop: 20 }}>
              <MDBCol className="profile-col-padding">
                <div>
                  <label>Other types of work I can do</label>
                  <Editor
                    data={form.other_tools}
                    onChange={event => handlerChanges({ name: 'other_tools', value: event })}
                  />
                </div>
              </MDBCol>
            </MDBRow>
          )
      }
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps)(withRouter(ContractorToolsBox));
