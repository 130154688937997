import {MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBRow} from "mdbreact";
import {ResourceDataTable} from "../../components/datatables/ResourceDataTable";
import {datatableManagerFundsColumnsForSuperAdmin as columns} from "../../utils/constans";
import Modal from "../../components/modal";
import React from "react";
import {toggleModal} from "../../storage/actions/modalsActions";
import {connect} from "react-redux";

const ManageFunds = ({
  toggleModal, mainContainer, isReload,
}) => {
  return (
    <MDBContainer className="clientdascontentmaindiv" fluid>
      <MDBRow>
        <MDBCol style={{textAlign: 'center'}}>
          Clients Transactions History
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <ResourceDataTable
            entity="/api/admin/dwolla/history"
            columns={columns}
            isReload={isReload}/>
        </MDBCol>
      </MDBRow>
      <Modal mainContainer={mainContainer.current}/>
    </MDBContainer>
  )
}

const mapDispatchToProps = {
  toggleModal,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  isReload: state.applicationState.isReload,
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageFunds);