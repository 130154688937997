import React from "react";
import {MDBCol, MDBRow, MDBTable, MDBTableBody} from "mdbreact";
import HistoryCard from "./HistoryCard";

const WorkOrderHistory = ({ history }) => {
    return <MDBRow
        style={{ marginBottom: "0px" }}
    >
        <MDBCol className="clientinner_table">
            <MDBRow><hr className="my-4 hrline2" /></MDBRow>
            <h6 style={{ fontWeight: "bold" }}>
                Work Order History
            </h6>
            <div>
                <MDBRow style={{ marginBottom: "15px" }} />
                <MDBRow>
                    <MDBCol>
                        <MDBTable className="applicantappiliedwo_table">
                            <MDBTableBody>
                                <tr>
                                    <th>Create</th>
                                    <th>Operation type</th>
                                    <th>Work order status</th>
                                    <th>Description</th>
                                </tr>
                                {
                                    history && history.map( historyInfo => (
                                        <HistoryCard key={historyInfo._id} historyInfo={historyInfo} />
                                    ))
                                }
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCol>
                </MDBRow>
            </div>
        </MDBCol>
    </MDBRow>
}

export default WorkOrderHistory;
