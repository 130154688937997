import React, { Component } from 'react';
import { MDBModal, MDBModalBody } from 'mdbreact';
import { connect } from 'react-redux';
import { toggleModal } from '../../../storage/actions/modalsActions';
import UnsubscribeFromNotificationsReduxForm from '../../layout/reduxForm/UnsubscribeFromNotificationsReduxForm';
import handleSubmit from '../../../utils/reduxForm/onSubmit/unsubscribeFromNotifications';

class UnsubscribeFromNotifications extends Component {
  closeModal = () => {
    const { modalState, toggleModal } = this.props;
    toggleModal(!modalState.isOpen, 'unsubscribeFromNotifications');
  };

  render() {
    const { modalState } = this.props;
    const params = new URLSearchParams(window.location.search)

    return (
      <MDBModal isOpen={modalState.isOpen} toggle={this.closeModal} centered className="register_div">
        <MDBModalBody>
          <UnsubscribeFromNotificationsReduxForm onSubmit={handleSubmit} unsubscribeId={params.get('unsub')} />
        </MDBModalBody>
      </MDBModal>
    );
  }
}

const mapStateToProps = (state) => ({
  modalState: state.modalState.unsubscribeFromNotifications,
});

export default connect(mapStateToProps, {
  toggleModal,
})(UnsubscribeFromNotifications);