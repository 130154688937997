import React from 'react';
import { MDBCol, MDBIcon } from 'mdbreact';

const IconElement = ({
  title,
  icon, far,
  onClick,
  mode,
}) => (
  <MDBCol className="flex-grow-0 position-relative">
    <MDBIcon
      far={far}
      title={title}
      icon={icon}
      onClick={onClick}
    />
    {
      mode !== 'useIds'
        ? <label className="icon-label">ALL</label>
        : <></>
    }
  </MDBCol>
);

export default IconElement;
