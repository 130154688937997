import React, { useEffect, useState } from 'react';
import {
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
} from 'mdbreact';
import Select from 'react-select';
import { white as customSelectStyle } from '../../../utils/customSelectStyle';
import workOrderStatusControl from '../../../utils/workOrderStatusControl';

const SelectMessageRecipients = ({
  companyMembers,
  workOrder,
  getRecipients,
  selectedAllApplicants,
  setSelectedAllApplicants,
  applicants = [],
  routedApplicants = [],
}) => {
  const [selectedCompanyUsers, setSelectedCompanyUsers] = useState([]);
  const [isNeedSendToContractor, setIsNeedSendToContractor] = useState(false);
  const [allApplicants, setApplicants] = useState([]);
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [workOrderControlByStatus, setWorkOrderControlByStatus] = useState({});

  useEffect(() => {
    if (workOrder?.status) {
      setWorkOrderControlByStatus(workOrderStatusControl(workOrder.status));
    }
  }, [workOrder]);

  useEffect(() => {
    if (!workOrderControlByStatus.beforeAssigned) {
      const objOfContractor = {};
      routedApplicants.data && routedApplicants.data.forEach(item => {
        objOfContractor[item.contractor_id] = item.contractor_name;
      });
      applicants.contractor && applicants.contractor.forEach(item => {
        objOfContractor[item.contractor_details.userId] = item.contractor_details.name;
      });
      setApplicants(Object.keys(objOfContractor).map(value => ({
        value, label: objOfContractor[value],
      })));
    }
  }, [applicants, routedApplicants]);

  const sendRecipients = () => {
    const arrOfSelectedApplicantsId = selectedAllApplicants
      ? allApplicants.map(el => el.value)
      : selectedApplicants.map(el => el.value);
    getRecipients(
      [
        ...selectedCompanyUsers.map(el => el.value),
        ...arrOfSelectedApplicantsId,
      ],
      isNeedSendToContractor,
    );
  };

  const handlerOnChange = ({ value, name }) => {
    switch (name) {
      case 'selectedCompanyUsers': setSelectedCompanyUsers(value || []); break;
      case 'isNeedSendToContractor': setIsNeedSendToContractor(value); break;
      case 'selectedApplicants': setSelectedApplicants(value || []); break;
      default: break;
    }
  };

  useEffect(() => {
    sendRecipients();
  }, [selectedCompanyUsers, isNeedSendToContractor, selectedApplicants, selectedAllApplicants]);

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol>
          <label
            style={{
              fontSize: '1.3rem',
              color: '#ffda6d',
            }}
          >
            Send Message To
          </label>
        </MDBCol>
      </MDBRow>
      {
        !workOrderControlByStatus.beforeAssigned
          ? (
            <>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    type="checkbox"
                    name="isNeedSendToContractor"
                    id="isNeedSendToContractor"
                    value={isNeedSendToContractor}
                    checked={isNeedSendToContractor}
                    onChange={
                      () => handlerOnChange({
                        name: 'isNeedSendToContractor',
                        value: !isNeedSendToContractor,
                      })
                    }
                    label="Assigned Contractor"
                  />
                </MDBCol>
              </MDBRow>
              <br />
            </>
          )
          : <></>
      }
      {
        workOrderControlByStatus.beforeAssigned
        && allApplicants.length
          ? (
            <>
              <MDBRow>
                <MDBCol className="flex-box">
                  <MDBInput
                    onClick={() => setSelectedAllApplicants(true)}
                    checked={selectedAllApplicants}
                    label="All Applicants"
                    type="radio"
                    id="allApplicants"
                    labelClass="radio-input-label"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className="flex-box">
                  <MDBInput
                    onClick={() => setSelectedAllApplicants(false)}
                    checked={!selectedAllApplicants}
                    label="Single Contractor(s):"
                    type="radio"
                    id="applicants"
                    labelClass="radio-input-label"
                    containerClass="flex-box flex-align-center"
                  />
                  <Select
                    isDisabled={selectedAllApplicants}
                    isClearable
                    isSearchable
                    isMulti
                    closeMenuOnSelect={false}
                    options={allApplicants}
                    value={selectedApplicants}
                    styles={customSelectStyle}
                    onChange={value => handlerOnChange({ name: 'selectedApplicants', value })}
                  />
                </MDBCol>
              </MDBRow>
              <br />
            </>
          )
          : <></>

      }
      <MDBRow>
        <MDBCol className="flex-box">
          <label
            className="flex-box flex-align-center"
            style={{
              fontSize: '0.85rem',
              whiteSpace: 'nowrap',
              margin: '0 0.5rem',
            }}
          >
            Company User(s):
          </label>
          <Select
            isClearable
            isSearchable
            isMulti
            closeMenuOnSelect={false}
            options={companyMembers}
            value={selectedCompanyUsers}
            styles={customSelectStyle}
            onChange={value => handlerOnChange({ name: 'selectedCompanyUsers', value })}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default SelectMessageRecipients;
