import React from 'react';
import {MDBIcon, MDBModal, MDBModalBody, MDBRow} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import {toggleModal} from '../../../storage/actions/modalsActions';
import ErrorDescription from './ErrorDescription';
import ErrorModalControlErrorElements from './errorModalControlErrorElements';
import {withRouter} from 'react-router-dom';
import {errorTitle} from '../../../utils/constans';

const ErrorModal = ({ toggleModal, modalState, errors, history }) => {
    return (
        <MDBModal
            isOpen={modalState.isOpen}
            toggle={() => toggleModal(!modalState.isOpen, 'errorModal', true)}
            centered
        >
            <MDBModalBody style={{ fontFamily: 'Arial, Helvetica, sans-serif', backgroundColor: 'white' }}>
                {
                    errors
                        ? <>
                             <MDBRow style={{justifyContent: 'flex-end', paddingRight: '10px'}}>
                                 <MDBIcon
                                     far
                                     icon='times-circle'
                                     onClick={() => {
                                         toggleModal(!modalState.isOpen, 'errorModal', true);
                                     }}
                                     size='lg'
                                     className='blue-grey-text'
                                 />
                            </MDBRow>
                            <div className='buttondivsignin'>
                                <MDBRow style={{ justifyContent: 'center' }}>
                                    <h5
                                        style={{ color: 'black' }}
                                    >
                                        {errorTitle[errors.message] || 'New Error'}
                                    </h5>
                                </MDBRow>
                                <ErrorDescription error={errors} />
                                <ErrorModalControlErrorElements error={errors} history={history} toggleModal={toggleModal} />
                            </div>
                         </>
                        :<></>
                }
            </MDBModalBody>
        </MDBModal>
    );
};


const mapStateToProps = state => ({
    modalState: state.modalState.errorModal,
    errors: state.errors
});

export default connect(mapStateToProps, {toggleModal})(withRouter(ErrorModal));


