import React from 'react';
import { MDBBtn, MDBCol, MDBInput } from 'mdbreact';
import ContractorCertificatesChecks from '../ContractorCertificatesChecks';
import ContractorRating from '../ContractorRating';
import ContractorPreferedGroupsChecks from '../PreferedGroupsCheck';

const NearByCard = ({
  user,
  handler,
  profileInfo,
  blocked,
  invitedContractors,
  selectedContractors,
  handlerCheckbox,
}) => {
  const isBlocked = Object.values(user.block).some(el => el);
  const isInvited = invitedContractors.includes(user.userId);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }} key={user._id}>
      <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
          <div style={{ position: 'relative' }}>
            <img
              src={user.image || 'https://www.w3schools.com/css/img_avatar.png'}
              alt="Avatar"
              className="findclientphoto"
            />
            {
              isBlocked || isInvited
                ? <></>
                : (
                  <div className="position-absolute-top-left-corner">
                    <MDBInput
                      id={user.userId}
                      name={user.userId}
                      type="checkbox"
                      label=" "
                      className="float-right"
                      checked={selectedContractors.has(user.userId)}
                      onChange={event => handlerCheckbox(event, user.userId)}
                    />
                  </div>
                )
            }
          </div>
          <div className="flex-box flex-center">
            <button
              type="button"
              className="disabled-standard-button-style"
              onClick={() => profileInfo(user)}
            >
              <h6
                className="no-margins"
                style={{ fontWeight: 'bold', padding: '5px 0' }}
              >
                {user.name}
              </h6>
            </button>
          </div>
          <div style={{ verticalAlign: 'bottom' }}>
            {
              // eslint-disable-next-line no-nested-ternary
              isBlocked
                ? (
                  <MDBBtn
                    color="red"
                    className="invitebutton"
                    onClick={() => blocked(user)}
                  >
                    BLOCKED
                  </MDBBtn>
                )
                : isInvited
                  ? (
                    <MDBBtn
                      color="success"
                      className="invitebutton"
                    >
                      Invited
                    </MDBBtn>
                  )
                  : (
                    <MDBBtn
                      color="info"
                      className="invitebutton"
                      onClick={() => handler(user)}
                    >
                      Invite
                    </MDBBtn>
                  )
            }
          </div>
        </div>
        <div style={{ marginLeft: '20px' }}>
          <div className="font12">{user.address}</div>
          <div className="font12" style={{ paddingBottom: '5px' }}>
            {(user.city && user.city.label) ? ` ${user.city.label}` : ''}
            {
              (
                (user.city && user.city.value)
                && ((user.state && user.state.label !== undefined))
              )
                ? ','
                : ''
            }
            {
              (user.state && user.state.label)
              && <span>{` ${user.state.label}`}</span>
            }
          </div>
          <div className="font12">{user.zipcode}</div>
          <div className="font12">{`Phone - ${user.phone}`}</div>
          <div className="font12">{`Email - ${user.email}`}</div>
          <div>{`ID - ${user.unique_id}`}</div>
        </div>
      </div>
      <div style={{ width: '35%', textAlign: 'center' }}>
        <div>
          <div className="flex-box" style={{ position: 'relative', padding: '0.5rem 0' }}>
            <MDBCol className="flex-box justify-content-center">
              {user.groups?.length ?
                <ContractorPreferedGroupsChecks /> : <></>
              }
            </MDBCol>
            <MDBCol className="flex-box justify-content-center">
              <ContractorCertificatesChecks certificates={user.certificates} textAlign="center" />
            </MDBCol>
            <MDBCol md="3" style={{ marginRight: '2px' }}>
              <p
                title={"WO's Completed For Our Company / WO's Completed on Platform"}
                style={{ cursor: 'pointer' }}
              >
                {`${user.completedWorkOrderCount} / ${user.allWorkOrdersCount}`}
              </p>
            </MDBCol>
          </div>
        </div>
        <div style={{ paddingTop: '0.2rem' }}>
          <ContractorRating contractor={user} iconsSize="lg" />
        </div>
      </div>
      <div style={{ width: '13%', textAlign: 'right' }}>
        {`${user.distance?.toFixed(2)} Miles`}
      </div>
    </div>
  );
};

export default NearByCard;
