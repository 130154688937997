import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Field, Form, reduxForm, reset,
} from 'redux-form';
import { MDBBtn, MDBCol } from 'mdbreact';
import { startDateFilter } from '../../utils/pickerDateFilters';
import DatePickedField from '../layout/reduxForm/customFields/DatePickerField';
import TimePickedField from '../layout/reduxForm/customFields/TimePickerField';
import InputField from '../layout/reduxForm/customFields/InputField';
import SelectField from '../layout/reduxForm/customFields/SelectField';

let FormApplyModal = ({
  currentWorkOrder,
  modalState,
  handleSubmit,
  toggleModal,
  submitApply,
}) => {
  const dispatch = useDispatch();

  const [isCounterOffer, setIsCounterOffer] = useState(modalState?.type?.openCounterOffer || false);

  const rateTypes = currentWorkOrder.basicType === 'Device' ? [{
    value: 'Device',
    label: 'Device',
  }] : [
    {
      value: 'Hour',
      label: 'Hour',
    },
    {
      value: 'Site',
      label: 'Site / Flat',
    },
  ];

  const submit = (formData) => {
    submitApply(currentWorkOrder, formData, toggleModal, isCounterOffer);
  };

  return (
    <div style={{ width: '100%', marginLeft: '0.7rem' }}>

      <Form onSubmit={handleSubmit(submit)}>
        <div>
          {isCounterOffer ? (
            <>
              <MDBCol className="flex-grow-0" style={{ minWidth: 'max-content' }}>
                <Field
                  name="startDate"
                  component={DatePickedField}
                  label="Start Date"
                  inputClassName="form-control width-inherit"
                  dateFormat="MM/dd/yyyy"
                  minDate={new Date()}
                  filterDate={startDateFilter}
                />
              </MDBCol>
              <MDBCol className="flex-grow-0" style={{ minWidth: 'max-content' }}>
                <Field
                  name="startTime"
                  component={TimePickedField}
                  label="Start Time"
                />
              </MDBCol>

              <BasePayBidAmount rateTypes={rateTypes} />

              <VariablePayBidAmount currentWorkOrder={currentWorkOrder} />

              <AddTravelExpense />
            </>
          ) : null}
          <MDBCol className="flex-grow-0" style={{ minWidth: 'max-content', marginTop: '1rem' }}>
            <span className="red-text">
              THERE ARE NO CONTRACTOR FEES ON SOURCEW9
            </span>
            <Field
              name="comment"
              component={Comment}
              label="Comment"
            />
          </MDBCol>
        </div>
        <MDBBtn
          color="primary"
          className="submitapply"
          type="submit"
        >
          Submit
        </MDBBtn>

        {!isCounterOffer ? (
          <MDBBtn
            color="info"
            className="submitapply"
            onClick={() => setIsCounterOffer(true)}
          >
            Counter Offer
          </MDBBtn>
        ) : null}

        <MDBBtn
          color="secondary"
          className="closeapply"
          onClick={() => {
            dispatch(reset('FormApplyModel'));
            toggleModal(!modalState.isOpen, 'applyModal');
          }}
        >
          Cancel
        </MDBBtn>

      </Form>

    </div>
  );
};

const VariablePayBidAmount = ({ currentWorkOrder }) => (

  (currentWorkOrder.variableAmount)
    && (currentWorkOrder.variableAmount !== 0)
    && (currentWorkOrder.variableAmount !== '0') ? (
      <div style={{ marginLeft: '0.7rem', display: 'flex' }}>

        <Field
          name="variablePayBidAmount"
          component={InputField}
          type="number"
          placeholder="$0.00"
          label="Variable Pay Bid Amount:"
          inputClassName="form-control small-styled-input"
          min={0.00}
        />

        <p style={{ fontSize: '12px', marginTop: '0.4rem' }}>
          {' '}
          {
             (currentWorkOrder.variableType && currentWorkOrder.variableType !== '')
               ? `Per ${
                 (currentWorkOrder.variableType === 'Hours')
                   ? 'Hour'
                   : currentWorkOrder.variableType
               }`
               : ''
         }
        </p>
      </div>
    ) : null

);

const BasePayBidAmount = ({ rateTypes }) => (
  <div style={{ padding: '12px', display: 'flex', marginTop: '7px' }}>
    <Field
      name="basePayBidAmount"
      component={InputField}
      type="number"
      placeholder="$0.00"
      label="Base Pay Bid Amount"
      inputClassName="form-control small-styled-input"
      min={0.00}
    />

    <Field
      name="basicType"
      component={SelectField}
      label="Per"
      className="padding-bottom-10 basic-type-block font12 form-apply-modal-basic-type"
      inputClassName="browser-default custom-select per"
      options={rateTypes}
    />
  </div>
);

const AddTravelExpense = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <span className="red-text">
        THERE ARE NO CONTRACTOR FEES ON SOURCEW9
      </span>
      <MDBBtn
        color="orange"
        className="check_inbutton"
        style={{ margin: 0, marginLeft: '12px' }}
        onClick={() => setIsOpen(!isOpen)}
      >
        $ Add Travel expenses
      </MDBBtn>
      {isOpen ? (
        <>
          <Amount />
          <Description />
        </>
      ) : null}
    </>
  );
};

const Amount = () => (
  <div style={{ width: '15%', marginTop: 10, marginLeft: '10px' }}>
    <Field
      name="amountExpense"
      component={InputField}
      type="number"
      placeholder="$0.00"
      label="Amount"
      inputClassName="form-control small-styled-input"
      min={0.00}
    />
  </div>
);

const Description = () => (
  <div style={{ marginLeft: '10px', width: '94%' }}>
    <Field
      name="descriptionExpense"
      component={TravelDescription}
      type="text"
      label="Description"
    />

  </div>
);

const TravelDescription = ({ input }) => (
  <>
    <p style={{ display: 'flex' }}>Travel Expense Description</p>
    <textarea
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...input}
      className="form-control commenttextarea"
      id="Description"
      rows="2"
      name="Description"
    />
  </>
);

const Comment = ({ input }) => (
  <div>
    <p style={{ display: 'flex' }}>Comments</p>
    <textarea
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...input}
      className="form-control commenttextarea"
      id="Comment"
      rows="2"
      name="Comment"
    />
  </div>
);

FormApplyModal = reduxForm({
  form: 'FormApplyModel',
})(FormApplyModal);

export default connect(state => ({
  initialValues: {
    basicType: state.workOrder?.currentWorkOrder?.basicType || '',
  },
}))(FormApplyModal);
