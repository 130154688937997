import React from 'react';
import { MDBCol } from 'mdbreact';

const imageStyles = { width: '160px', height: '160px' };

const ContractorImage = ({
  imageSrc = '/img_avatar.png',
}) => (
  <MDBCol md="4">
    <div>
      <img
        alt=""
        src={imageSrc}
        style={imageStyles}
      />
    </div>
  </MDBCol>
);

export default ContractorImage;
