import React, { useContext, useEffect, useState } from 'react';
import {
  MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import axios from 'axios';
import Select from 'react-select';
import connect from 'react-redux/es/connect/connect';
import docImage from '../../assets/doc_image.png';
import { toggleModal } from '../../storage/actions/modalsActions';
import { reloadData } from '../../storage/actions/applicationStateActions';
import Notification from '../notification';
// eslint-disable-next-line import/no-cycle
import { SocketContext } from '../../pages';
import { white as customSelectStyle } from '../../utils/customSelectStyle';
import getTemplatesList from '../../utils/api/get/getTemplatesList';

const ImportWorkOrderModal = props => {
  const [file, setFile] = useState(null);
  const [messageImportFile, setMessageImportFile] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const { notificationSocket: socket } = useContext(SocketContext);

  const fetchTemplatesList = async () => {
    const newTemplatesList = await getTemplatesList();
    setTemplates(newTemplatesList);
  };

  useEffect(() => {
    if (socket) {
      socket.on('doneImports', (response) => {
        const { message, type } = response;
        Notification(type, { message });

        if (type === 'success' || type === 'error') {
          props.toggleModal(false, 'loader', true);
          closeModal();
          props.reloadData();
        }
      });
    }
    fetchTemplatesList();
    return () => {
      setMessageImportFile(null);
    };
  }, []);

  // eslint-disable-next-line consistent-return
  const handlerUploadFile = () => {
    if (!file) return setMessageImportFile('Please select file to import.');
    if (!file.type.includes('csv') && !file.type.includes('excel')) return setMessageImportFile('Please select .csv file to import.');
    setMessageImportFile('');
    props.toggleModal(true, 'loader', true);
    const fd = new FormData();
    fd.append('file', file);
    if (selectedTemplate?.value) fd.append('templateId', selectedTemplate.value);
    axios
      .post('/api/users/import', fd)
      .then(res => {
        const notificationType = res.data?.success ? 'info' : 'error';
        Notification(notificationType, {
          message: res.data.message,
        });
      })
      .catch(error => {
        props.toggleModal(false, 'loader', true);
        console.error('Import Work Order Error: ', error);
        setMessageImportFile(error.response?.data.message || error.message);
      });
  };

  const closeModal = () => {
    setFile(null);
    props.toggleModal(!props.modalState.isOpen, 'importWorkOrderModal');
  };

  const handlerFile = (event) => {
    if (!event.target.files[0]) {
      setFile(null);
      return setMessageImportFile('Please select .csv file to import.');
    }
    setFile(event.target.files[0]);
    return setMessageImportFile('');
  };

  const handlerSelectTemplate = async select => {
    setSelectedTemplate(select);
  };

  return (
    <MDBModal
      /* eslint-disable-next-line react/destructuring-assignment */
      isOpen={props.modalState.isOpen}
      toggle={closeModal}
      centered
      className="signindiv"
    >
      <MDBRow
        style={{
          justifyContent: 'flex-end',
          paddingRight: '10px',
        }}
      >
        <MDBIcon
          far
          icon="times-circle"
          onClick={closeModal}
          size="lg"
          className="blue-grey-text"
        />
      </MDBRow>
      <MDBModalBody style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
        <div>
          <MDBRow style={{ justifyContent: 'center' }}>
            <p style={{ color: 'black' }}>Import Work Order(s)</p>
          </MDBRow>
          <MDBCol md="12">
            <div className="form-group signinput" style={{ lineHeight: '1.15' }}>
              <label htmlFor="formGroupExampleInput" className="font12 no-margins">
                Select your CSV file to import
              </label>
              <label htmlFor="formGroupExampleInput" className="font12">
                <a
                  href={`${window.location.protocol}//${window.location.hostname}/Sample.xlsx`}
                  download
                  style={{ textDecorationLine: 'underline' }}
                >
                  (Please refer to this sample file to create your csv file to import.)
                </a>
              </label>
              <input
                type="file"
                name="file"
                onChange={handlerFile}
                style={{
                  fontSize: 12,
                  cursor: 'pointer',
                  marginTop: '10px',
                }}
              />
            </div>
          </MDBCol>
          {
            file
            && (
              <div>
                <img
                  alt=""
                  src={docImage}
                  style={{
                    marginBottom: 5,
                    marginLeft: 0,
                    height: 100,
                    cursor: 'pointer',
                  }}
                />
                <div style={{
                  textAlign: 'center',
                  color: 'black',
                  width: 100,
                  marginLeft: 0,
                  fontSize: 12,
                }}
                >
                  {file.name}
                </div>
              </div>
            )
          }
          <MDBCol md="12" className="flex-box align-items-baseline font12">
            <label
              className="no-margins text-in-one-line padding-right-10"
            >
              Use Template
            </label>
            <Select
              isClearable
              styles={customSelectStyle}
              options={templates}
              value={selectedTemplate}
              onChange={handlerSelectTemplate}
              placeholder="Select Template"
            />
          </MDBCol>
          <MDBRow style={{ justifyContent: 'center' }}>
            <div style={{ color: '#e87b68' }}>
              {messageImportFile}
            </div>
          </MDBRow>
          <MDBRow style={{ justifyContent: 'center' }}>
            <MDBBtn
              color="info"
              className="signbutton"
              onClick={handlerUploadFile}
            >
              Upload
            </MDBBtn>
          </MDBRow>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = state => ({
  modalState: state.modalState.importWorkOrderModal,
});

export default connect(mapStateToProps, { toggleModal, reloadData })(ImportWorkOrderModal);
