import React from 'react';
import moment from 'moment'

const DateAndTime = ({ startDate, startTime }) => (
  <div style={{
    marginRight: '10px', display: 'flex', flexDirection: 'column', float: 'right',
  }}
  >
    {startDate ? (
      <div>
        <span className="font11 float-left">Start Date: </span>
        <span className="dashdiv font11Bold float-left">
          {moment(startDate).format('MM/DD/YYYY')}
        </span>
      </div>
    ) : <></>}

    {startTime ? (
      <div>
        <span className="font11 float-left">Start Time:</span>
        <span className="dashdiv font11Bold float-left">
          {startTime}
        </span>
      </div>
    ) : <></>}

  </div>
);

export default DateAndTime;
