import React from 'react';
import { MDBIcon } from 'mdbreact';
import axios from 'axios';
import docImage from '../../assets/doc_image.png';
import getFileInfo from '../../utils/getFileInfoForDisplay';

const FilePreviewElement = ({
  fileInfo, removeImage,
  index, data,
  userType,
  toggleModal,
  contractorId,
  documentVisibility
}) => {
  const fileObject = typeof fileInfo === 'string'
    ? getFileInfo(fileInfo)
    : fileInfo;

  if (userType === 'contractor' && !contractorId) {
    if (documentVisibility) {
      const key = fileObject.name.split('-').slice(1).join('-').replace(/\./g, '%2E');

      if (!documentVisibility[key]) {
        return null
      }
    } else {
      return null
    }
  }

  return (
    <div
      key={fileObject.name}
      style={{ marginLeft: 20, position: 'relative', display: 'flex' }}
    >
      <div
        style={{
          marginRight: 20,
          marginTop: 15,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        title={decodeURIComponent(fileObject.name)}
      >
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <img
          alt=""
          id={`img${index}`}
          src={fileObject.type.includes('image') ? fileObject.file : docImage}
          style={{
            marginBottom: 5,
            height: 100,
            width: 100,
            cursor: data ? 'pointer' : '',
          }}
          onClick={() => {
            if (!data) return;
            if (fileObject.type.includes('image')) {
              toggleModal(true, 'imageViewModal', true, { url: fileObject.file })
            } else {
              axios.post('/api/users/fetch_file_s3', { link: data })
                .then(res => {
                  if (res.data) {
                    const link = document.createElement('a');
                    const url = window.URL.createObjectURL(new Blob(
                      [new Uint8Array(res.data.Body.data)],
                      { type: res.data.ContentType },
                    ));
                    link.href = url;
                    link.setAttribute('download', res.data.name);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                });
            }
          }}
        />
        <div
          style={{
            alignSelf: 'center',
            textAlign: 'center',
            width: 100,
            fontSize: 12,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {decodeURIComponent(fileObject.name)}
        </div>
      </div>
      {
        removeImage
          ? (
            <MDBIcon
              icon="times-circle"
              style={{
                color: '#41566a',
                cursor: 'pointer',
                position: 'absolute',
                right: '1px',
              }}
              onClick={() => {
                removeImage(index);
              }}
            />
          )
          : <></>
      }
      {fileObject.type.includes('image') &&
        <MDBIcon
          icon="download"
          style={{
            color: '#41566a',
            cursor: 'pointer',
            position: 'absolute',
            right: '25px',
            bottom: '25px'
          }}
          onClick={() => {
            axios.post('/api/users/fetch_file_s3', { link: data })
              .then(res => {
                if (res.data) {
                  const link = document.createElement('a');
                  const url = window.URL.createObjectURL(new Blob(
                    [new Uint8Array(res.data.Body.data)],
                    { type: res.data.ContentType },
                  ));
                  link.href = url;
                  link.setAttribute('download', res.data.name);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              });
          }}
        />
      }
    </div>
  );
};

export default FilePreviewElement;
