import React from 'react';
import {MDBBtn, MDBCol, MDBInput, MDBRow} from "mdbreact";

const ContractorTasks = ({section, tasks, handler, isAssigned}) => {
    if (!tasks[section] || tasks[section].length === 0) return null;
    const changeTasksStatus = (section, tasks, handler, id, value) => {
        handler({
            ...tasks,
            [section]: [
                ...tasks[section].reduce((acc, el) => {
                    if (el.index === Number(id)) {
                        acc.push({...el, checked: value});
                        return acc
                    }
                    acc.push(el);
                    return acc
                }, []),

            ]
        })
    }
    return (
        <MDBRow style={{marginBottom: "0px", textAlign: "left"}}>
            <MDBCol>
                <MDBRow>
                    <hr className="my-4 hrline2"/>
                </MDBRow>
                <h6 className="font14">
                    <b>
                        {
                            section === 'preArrivalRequirements'
                                ? 'Pre Arrival Requirements'
                                : section === 'onSiteRequirements'
                                ? 'On Site Requirements'
                                : 'Completion Requirements'
                        }
                    </b>
                </h6>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        {tasks[section].sort((a, b) => a.index - b.index).map(task =>
                            <MDBInput
                                type="checkbox"
                                disabled={!isAssigned}
                                className="float-right task-required"
                                id={task.index + section}
                                label={task.name}
                                checked={task.checked}
                                onChange={(e) => changeTasksStatus(section, tasks, handler, task.index, e.target.checked)}
                            />)}
                    </div>
                </div>
            </MDBCol>
        </MDBRow>
    );
};

export default ContractorTasks;
