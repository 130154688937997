import axios from 'axios';
import Notification from '../../../components/notification';

const unsubscribeFromNotifications = async (unsubscribeId) => {
  try {
    const res = await axios.patch(`/api/notification/unsubscribe/${unsubscribeId}`);

    Notification(res.data.success ? 'success' : 'warning', {
      message: res.data.message,
    });
    return res.data.success;
  } catch (error) {
    Notification('error', {
      message: error.response?.data?.message || error.message,
    });
    return false;
  }
};

export default unsubscribeFromNotifications;
