import React from 'react';
import { MDBContainer } from 'mdbreact';
// eslint-disable-next-line import/no-cycle
import Modal from '../../components/modal';
import Content from '../../components/manageCompanies/Content';
import Header from '../../components/manageCompanies/Header';

const ManageCompanies = ({ mainContainer }) => (
  <MDBContainer className="no-margins color-white" fluid>
    <Header />
    <Content />
    <Modal mainContainer={mainContainer.current} />
  </MDBContainer>
);

export default ManageCompanies;
