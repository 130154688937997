// Annoying modal window. Use if banking information is required for contractor.
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  MDBBtn, MDBCol, MDBContainer, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import { toggleModal } from '../../storage/actions/modalsActions';

const SetUpBankInfoForContractor = (
  {
    modalState,
    toggleModal,
  },
) => {
  const closeModal = () => toggleModal(!modalState.isOpen, 'setUpBankInfoForContractor');
  const submitApprove = () => {
    toggleModal(true, 'addBankInfoForContractor');
  };
  return (
    <MDBModal
      isOpen={modalState.isOpen}
      toggle={closeModal}
      centered
    >
      <MDBModalBody style={{
        fontFamily: 'Arial, Helvetica, sans-serif',
        backgroundColor: 'white',
      }}
      >
        <MDBContainer>
          <div className="flex-box flex-center full-width" style={{ flexWrap: 'wrap' }}>
            <h6>You've not yet set up your banking information.</h6>
            <label>
              <span className="font12">
                You will need to set up your banking information before you can be paid
              </span>
            </label>
            <MDBRow className="flex-center">
              <MDBCol className="flex-grow-0">
                <MDBBtn onClick={closeModal}>Cancel</MDBBtn>
              </MDBCol>
              <MDBCol className="">
                <MDBBtn color="success" onClick={submitApprove}>Set Up Now</MDBBtn>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
};

const mapStateToProps = state => ({
  modalState: state.modalState.setUpBankInfoForContractor,
  profile: state.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(SetUpBankInfoForContractor);
