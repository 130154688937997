import styled from "styled-components";
import {MDBIcon} from "mdbreact";

export const Wrapper = styled.label`
  width: 100%;
  padding: 50px 20px;
  border-radius: 10px;
  text-align: center;
  border: white dashed 1px;
`

export const Results = styled.div`
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
`

export const Storage = styled.input`
  display: none;
`

Storage.defaultProps = { type: "file" }

export const Label = styled.span`
`

export const FileCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: 15px;
  &:hover i {
    display: block;
  }
`

export const FileView = styled.img`
  border-radius: 10px;
  height: 150px;
  cursor: pointer;
`

export const FileName = styled.span`
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
  align-self: center;
  white-space: nowrap;
`

export const RemoveIcon = styled(MDBIcon)`
  position: absolute;
  right: 1px;
  top: 3px;
  cursor: pointer;
  color: #41566a;
`

RemoveIcon.defaultProps = {
  icon: "times-circle",
  size: 'lg'
}

export const DownloadIcon = styled(MDBIcon)`
  position: absolute;
  right: calc(50% - 48px / 2);
  top: calc(50% - (48px / 2 + 10px));
  cursor: pointer;
  color: #41566a;
  display: none;
`

DownloadIcon.defaultProps = {
  icon: "cloud-download-alt",
  size: '3x'
}

