import { formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import DividedPaySection from '../../../layout/reduxForm/section/workOrder/payInfoSection/DividedPaySection';

const createWorkOrderReduxFormSelector = formValueSelector('createWorkOrderTemplateReduxForm');

const mapDispatchToProps = {
  change,
};

const mapStateToProps = state => ({
  percentCommunication: createWorkOrderReduxFormSelector(state, 'percentCommunication'),
  percentWork: createWorkOrderReduxFormSelector(state, 'percentWork'),
  percentDeliverables: createWorkOrderReduxFormSelector(state, 'percentDeliverables'),
  reduxFormName: 'createWorkOrderTemplateReduxForm',
  requirements: 'disabled',
});

export default connect(mapStateToProps, mapDispatchToProps)(DividedPaySection);
