import React, { useEffect, useState } from 'react';
import {
  MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBRow,
} from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import { toggleModal } from '../../storage/actions/modalsActions';
import { payForWorkOrders } from '../../storage/actions/workOrdersActions';
import prepareFilters from '../../utils/prepareFilters';

// eslint-disable-next-line no-shadow
const BulkWorkOrderPay = ({
  modalState,
  toggleModal,
  selectedWorkOrders,
  payForWorkOrders,
  search,
  filters,
  profile,
}) => {
  const [requestData, setRequestData] = useState({});

  useEffect(() => {
    const newRequestData = modalState?.type?.mode === 'useFilters'
      ? {
        ...search,
        filters: prepareFilters(filters),
      }
      : { workOrdersId: selectedWorkOrders };
    setRequestData(newRequestData);
  }, [modalState?.type?.mode]);

  const closeModal = () => {
    toggleModal(false, 'bulkWorkOrderPay');
  };

  return (
    <MDBModal
      isOpen={modalState.isOpen}
      toggle={closeModal}
      centered
      className="signindiv"
    >
      <MDBRow
        style={{
          justifyContent: 'flex-end',
          paddingRight: '10px',
        }}
      >
        <MDBIcon
          far
          icon="times-circle"
          onClick={closeModal}
          size="lg"
          className="blue-grey-text"
        />
      </MDBRow>
      <MDBModalBody style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}>
        <div className="buttondivsignin">
          <MDBRow style={{ justifyContent: 'center' }}>
            <p
              style={{ color: 'black' }}
            >
              {
                `Mark ${
                  modalState?.type?.mode === 'useFilters'
                    ? 'all'
                    : selectedWorkOrders?.length
                } work orders as Paid?`
              }
            </p>
          </MDBRow>
          {
            profile.userType !== 'superAdmin'
              ? (
                <MDBRow className="no-margins">
                  <MDBCol>
                    <p
                      style={{ color: 'red', textAlign: 'center' }}
                    >
                      This will only mark Work Orders as paid
                      if they are set to Pay outside of the system.
                    </p>
                  </MDBCol>
                </MDBRow>
              )
              : <></>
          }
          <MDBRow style={{ justifyContent: 'center' }}>
            <MDBBtn
              color="success"
              className="signbutton"
              onClick={closeModal}
            >
              Cancel
            </MDBBtn>
            <MDBBtn
              color="success"
              className="signbutton"
              onClick={() => payForWorkOrders(requestData)}
            >
              Ok
            </MDBBtn>
          </MDBRow>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapStateToProps = (state) => ({
  modalState: state.modalState.bulkWorkOrderPay,
  selectedWorkOrders: state.applicationState.selectedWorkOrders,
  filters: state.workOrder?.filters,
  search: state.workOrder?.searchData,
  profile: state.profile,
});

export default connect(mapStateToProps, { toggleModal, payForWorkOrders })(BulkWorkOrderPay);
