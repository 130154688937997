import React, { useEffect, useState } from 'react';
import { MDBModal, MDBModalBody, MDBRow } from 'mdbreact';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { toggleModal, toggleSecondModalClose } from '../../../storage/actions/modalsActions';
import getContractorDetails from '../../../utils/api/get/getContractorDetails';
import ContractorBlockInfoRow from '../../layout/ContractorBlockInfoRow';
import ModalHeader from './ModalHeader';
import ContractorDetails from './ContractorDetails';
import ContractorDetailsAdditional from './ContractorDetailsAdditional';
import ContractorImage from './ContractorImage';
import WorkType from './WorkType';
import PreviouslyWorkedProjectsSection from './PreviouslyWorkedProjectsSection';
import NotesSection from './NotesSection';

const ContractorDetailsModal = ({
  modalState, toggleModal, history, profile, toggleSecondModalClose,
}) => {
  const [contractorDetails, setContractorDetails] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  // eslint-disable-next-line consistent-return
  const fetchContractorDetails = async () => {
    setIsLoaded(false);
    toggleModal(true, 'loader', true);
    const newContractorDetails = await getContractorDetails(modalState.type?.contractorId);
    if (!newContractorDetails?.userId) return closeModal();
    setContractorDetails(newContractorDetails);
    toggleSecondModalClose('loader', true);
    setIsLoaded(true);
  };

  useEffect(() => {
    fetchContractorDetails();
  }, []);

  const closeModal = () => {
    history.push(window.location.pathname);
    toggleModal(!modalState.isOpen, 'contractorDetailsModal');
  };

  if (!(modalState?.isOpen && contractorDetails?.userId && isLoaded)) return <></>;

  return (
    <MDBModal
      className="applicantappiliedwo recentlyused_modal"
      isOpen={modalState.isOpen}
      toggle={closeModal}
      fullHeight
      scrollable
      position="right"
    >
      <MDBModalBody style={{ overflow: 'hidden auto', color: 'white' }}>
        <div>
          <ModalHeader closeModal={closeModal} />
          <MDBRow>
            <ContractorDetails contractorDetails={contractorDetails} />
            <ContractorImage imageSrc={contractorDetails.image} />
          </MDBRow>
          <ContractorDetailsAdditional contractorDetails={contractorDetails} />
          <ContractorBlockInfoRow
            contractorDetails={contractorDetails}
            isBlockedForCompany={
              profile.company.blocked_users.includes(
                contractorDetails.userId,
              )
            }
            toggleModal={toggleModal}
          />
          <WorkType contractorTools={contractorDetails.tools} />
          <PreviouslyWorkedProjectsSection
            previouslyWorkedProjects={contractorDetails?.previouslyWorkedProjects}
          />
          <NotesSection
            contractorId={contractorDetails.userId}
            notes={contractorDetails.notes}
          />
        </div>
      </MDBModalBody>
    </MDBModal>
  );
};

const mapDispatchToProps = {
  toggleModal,
  toggleSecondModalClose,
};

const mapStateToProps = (state) => ({
  modalState: state.modalState.contractorDetailsModal,
  profile: state.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContractorDetailsModal));
