import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  MDBBtn, MDBCol, MDBInput, MDBRow,
} from 'mdbreact';
// import workOrderStatusControl from '../../../utils/workOrderStatusControl';

const toggleCheckbox = (workOrder, selected, select) => {
  if (workOrder.status === 'completed') ;
  const id = workOrder._id;
  if (selected.includes(id)) {
    return select(selected.filter((el) => el !== id));
  }
  return select([...selected, id]);
};

const ControlsButton = ({
  workOrder,
  selectedWorkOrders,
  selectWorkOrder,
  toggleModal,
  history,
  match,
// eslint-disable-next-line arrow-body-style
}) => {
  // const workOrderControlByStatus = workOrder ? workOrderStatusControl(workOrder.status) : {};

  return (
    <td>
      <MDBRow>
        <MDBCol
          md="12"
          className="d-flex client_tablecheck"
        >
          <MDBInput
            label="."
            type="checkbox"
            className="float-right"
            id={`checkbox4${workOrder._id}`}
            checked={selectedWorkOrders.includes(workOrder._id)}
            onChange={() => {
              toggleCheckbox(workOrder, selectedWorkOrders, selectWorkOrder);
            }}
          />
        </MDBCol>
        <MDBCol>
          <MDBRow className="no-margins">
            <MDBBtn
              rounded
              className="font12 detailbut"
              color="info"
              style={{ textTransform: 'none' }}
              onClick={() => {
                const { tab } = match.params;
                const pathname = tab ? `/dashboard/${tab}` : '/dashboard';
                history.push({
                  pathname,
                  search: `?type=work-order&id=${workOrder._id}`,
                });
                toggleModal(true, 'viewDetailsWorkOrderModal', false, { id: workOrder._id });
              }}
            >
              Details
            </MDBBtn>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </td>
  );
};

export default withRouter(ControlsButton);
