import React, { useEffect, useState } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdbreact';
import { connect } from 'react-redux';
import { acceptInvite } from '../../../storage/actions/workOrdersActions';
import prepareFilters from '../../../utils/prepareFilters';
import { getStatus } from '../../../utils/api/get/getWorkOrders';

const BulkElements = ({
  selectedWorkOrders,
  activeTab,
  acceptInvite,
  search,
  filters,
}) => {
  const [mode, setMode] = useState('useIds');
  const [requestData, setRequestData] = useState({});

  useEffect(() => {
    const newRequestData = mode === 'useFilters'
      ? {
        ...search,
        filters: prepareFilters(filters),
      }
      : { workOrdersId: selectedWorkOrders };
    setRequestData({ ...newRequestData, ...getStatus(activeTab) });
  }, [mode, search, filters, activeTab]);

  useEffect(() => {
    setMode(selectedWorkOrders?.length ? 'useIds' : 'useFilters');
  }, [selectedWorkOrders, activeTab]);

  return (
    <MDBRow className="flex-align-center" style={{ width: 'max-content' }}>
      {
        activeTab === 'routed'
          ? (
            <>
              <MDBCol className="flex-grow-0">
                <MDBIcon
                  title="Accept WO's"
                  far
                  icon="calendar-check"
                  onClick={() => acceptInvite(requestData)}
                />
              </MDBCol>
            </>
          )
          : <></>
      }
    </MDBRow>
  );
};

const mapDispatchToProps = {
  acceptInvite,
};

const mapStateToProps = state => ({
  filters: state.workOrder?.filters,
  search: state.workOrder?.searchData,
  activeTab: state.applicationState.activeTab,
  selectedWorkOrders: state.applicationState.selectedWorkOrders,
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkElements);
