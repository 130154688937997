import React, { useState } from 'react';
import { MDBCol, MDBIcon, MDBRow } from 'mdbreact';
import FilePreviewElement from '../../layout/FilePreviewElement';
import getFileInfo from '../../../utils/getFileInfoForDisplay';

const DocumentSection = ({
  userType,
  workOrderId,
  documents,
  uploadDocument,
  deleteUpload,
  toggleModal,
  isWorkOrderCanceled,
  contractorId,
  documentVisibility
}) => {
  const handleFileUpload = (event) => {
    const { files } = event.target;
    const fd = new FormData();
    if (files) {
      fd.append('workOrderId', workOrderId);
      Array.from(files).forEach(file => {
        fd.append('files', file);
      });
      toggleModal(
        true,
        'confirmModal',
        true,
        {
          onAccept: () => {
            fd.append('documentVisibility', JSON.stringify({[encodeURIComponent(`${files[0].name}`).replace(/\./g, '%2E')]: true}))
            uploadDocument(fd);
          },
          header: 'Make this document viewable to the contractor prior to assignment?',
          buttonLabels: { reject: 'No' },
          onReject: () => {
            fd.append('documentVisibility', JSON.stringify({[encodeURIComponent(`${files[0].name}`).replace(/\./g, '%2E')]: false}))
            uploadDocument(fd);
          }
        },
      );
    }
  };

  const getConfirmToDeleteDocument = index => {
    toggleModal(
      true,
      'confirmModal',
      true,
      {
        onAccept: () => deleteFile(index),
        header: 'Are you sure you want to delete this file?',
        buttonLabels: { reject: 'Cancel' },
      },
    );
  };

  const deleteFile = index => {
    deleteUpload(documents[index], workOrderId);
  };

  return (
    <MDBRow style={{ marginBottom: '0px', textAlign: 'left' }}>
      <MDBCol>
        <MDBRow>
          <MDBCol>
            <h6 className="font14">
              <b>Documents</b>
            </h6>
          </MDBCol>
          {
            userType === 'client' && !isWorkOrderCanceled
              ? (
                <MDBCol className="flex-box justify-content-start">
                  <div
                    className="drag-and-drop-element flex-box align-items-center flex-column"
                  >
                    <MDBIcon
                      icon="cloud-upload-alt"
                      style={{
                        color: 'gray',
                        opacity: 0.4,
                      }}
                      size="2x"
                    />
                    <input
                      type="file"
                      className="form-control font11 full-width no-paddings"
                      style={{
                        height: '100%',
                      }}
                      placeholder="Upload file"
                      onChange={handleFileUpload}
                    />
                  </div>
                </MDBCol>
              )
              : <></>
          }
        </MDBRow>
        <MDBRow>
          {
            documents?.map((data, i) => FilePreviewElement({
              fileInfo: getFileInfo(data),
              index: i,
              data,
              userType,
              toggleModal,
              contractorId,
              documentVisibility,
              removeImage: userType === 'client'
                ? getConfirmToDeleteDocument
                : false,
            }))
          }
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default DocumentSection;
