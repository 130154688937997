import React from 'react';
import { components } from 'react-select';
import { MDBTooltip } from 'mdbreact';

const CustomSelectOptionElement = (props) => (
  <div className="tooltip-on-right-side white-tooltip-title">
    <MDBTooltip
      domElement
      placement="left"
    >
      <div>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <components.Option {...props} />
      </div>
      <div>
        <p className="font12">
          <span style={{ fontWeight: 'bold' }}>Standard Toolbox items:</span>
          {
            // eslint-disable-next-line react/destructuring-assignment
            props.data.item.tools.map((tool, index, array) => (
              <span key={tool}>{`${tool}${(array.length - 1) === index ? '.' : ', '}`}</span>
            ))
          }
        </p>
        <p className="font12">
          <span style={{ fontWeight: 'bold' }}>Standard Materials:</span>
          {
            // eslint-disable-next-line react/destructuring-assignment
            props.data.item.materials.map((material, index, array) => (
              <span key={material}>{`${material}${(array.length - 1) === index ? '.' : ', '}`}</span>
            ))
          }
        </p>
      </div>
    </MDBTooltip>
  </div>
)


export default CustomSelectOptionElement;
