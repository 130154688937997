import React from 'react';
import styled from 'styled-components';

const NotSupportedAndroidVersion = () => (
  <Container>
    <div>
      <P>
        Sorry, but you need version 8 or higher of android to view this web page.
      </P>
      <P>
        Please upgrade your version of Android or download the Mobile Application:
      </P>
      <P><a href="https://play.google.com/store/apps/details?id=com.sw9"> https://play.google.com/store/apps/details?id=com.sw9</a></P>
    </div>
  </Container>
);

export default NotSupportedAndroidVersion;

const Container = styled.div`
    display: flex;
    justify-content: center;
`;

const P = styled.p`
  color: white;
  margin: 0;
`;
