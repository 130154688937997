import React from 'react';
import ClientBulkElements from './ForClients';
import ContractorBulkElements from './ForContractors';
import SuperAdminBulkElements from './ForSuperAdmins';

const BulkElements = ({ userType, workOrders }) => {
  switch (userType) {
    case 'client': return (
      <ClientBulkElements workOrders={workOrders} />
    );
    case 'contractor': return (
      <ContractorBulkElements />
    );
    case 'superAdmin': return (
      <SuperAdminBulkElements />
    );
    default: return <></>;
  }
};

export default BulkElements;
