import React from 'react';
import {MDBCol, MDBIcon} from 'mdbreact';
import getRatingColor from '../../utils/getRatingColor';

const ContractorRating = ({contractor, iconsSize = '2x', shortInfo = false}) => {
    const commRating = contractor.commRating 
        ? parseInt(contractor.commRating/(contractor.totalJobs +20 )*100)
        : 100;
    const commRatingColor = getRatingColor(commRating);
    const workRating = contractor.workRating
        ? parseInt(contractor.workRating/(contractor.totalJobs +20 )*100)
        : 100;
    const workRatingColor = getRatingColor(workRating);
    const deliverRating = contractor.deliverRating
        ? parseInt(contractor.deliverRating/(contractor.totalJobs +20 )*100)
        : 100;
    const deliverRatingColor = getRatingColor(deliverRating);
    const currentRating = parseInt(contractor.currentRating || 100);
    const currentRatingColor = getRatingColor(currentRating);

    return (
        <div className='flex-box' style={{position: 'relative', width: '100%'}} >
            {
                !shortInfo
                    && <>
                        <MDBCol 
                            title='Soft Skills' 
                            className='flex-box flex-column flex-align-center' 
                            style={{justifyContent: 'center', cursor: 'pointer'}} 
                        >
                            <MDBIcon icon='gavel' style={{ color: commRatingColor }} size={iconsSize} />
                            <label className='no-margins' style={{ color: commRatingColor }} >{commRating}%</label>
                        </MDBCol>
                        <MDBCol
                            title='Work'
                            className='flex-box flex-column flex-align-center'
                            style={{justifyContent: 'center', cursor: 'pointer'}} 
                        >
                            <MDBIcon icon='briefcase' style={{ color: workRatingColor }} size={iconsSize} />
                            <label className='no-margins' style={{ color: workRatingColor }} >{workRating}%</label>
                        </MDBCol>
                        <MDBCol
                            title='Deliverables'
                            className='flex-box flex-column flex-align-center'
                            style={{justifyContent: 'center', cursor: 'pointer'}} 
                        >
                            <MDBIcon icon='book' style={{ color: deliverRatingColor }} size={iconsSize} />
                            <label className='no-margins' style={{ color: deliverRatingColor }} >{deliverRating}%</label>
                        </MDBCol>
                    </>
            }
            <MDBCol
                title='Total Rating'
                className='flex-center'
                style={{
                    border: `2px solid ${currentRatingColor}`,
                    backgroundColor: '#003762',
                    color: currentRatingColor,
                    fontSize: '1.3rem',
                    height: '3.125rem',
                    cursor: 'pointer'
                }}
            >
                {currentRating}%
            </MDBCol>
        </div>
    )
}

export default ContractorRating;