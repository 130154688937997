import React from 'react';
import {MDBCol, MDBRow} from "mdbreact";
import AddExpense from "./AddExpense";
import ExpensesList from "./ExpensesList";

const WorkOrderExpenses = (
    {
        auth,
        currentWorkOrder,
        saveExpense,
        editExpense,
        deleteExpense
    }
) => (
    (auth.user.userType !== 'contractor' || currentWorkOrder.contractor_id === auth.user.id)
        ? <div style={{textAlign: "left"}} >
            <hr className="my-4 hrline2"/>
            <MDBRow style={{marginBottom: '5px'}} >
                <MDBCol>
                    <h6 style={{fontSize: '16px', margin: 0, fontWeight: '900' }} >Expenses</h6>
                </MDBCol>
            </MDBRow>
            <AddExpense
                currentWorkOrder={currentWorkOrder}
                userType={auth.user.userType}
                saveExpense={saveExpense}
            />
            <ExpensesList
                editExpense={editExpense}
                deleteExpense={deleteExpense}
                userType={auth.user.userType}
                currentWorkOrder={currentWorkOrder}
            />
        </div>
        : null
)

export default WorkOrderExpenses;
