import React from 'react';
import Tab from './Tab';

const Tabs = ({
  tabs, activeTab, handler, workOrderCountByTabs = {},
}) => tabs.map(({ title, value }) => (
  <Tab
    key={value}
    title={title}
    active={activeTab === value}
    handler={handler}
    current={value}
    count={workOrderCountByTabs[value]}
  />
));

export default Tabs;
